import { TrainStep } from '../../../commons/model/Train';
import { formatOptionalValue } from '../../../commons/model/common';
import TrainUtils from '../../../commons/model/TrainUtils';
import React from 'react';
import TrainStepSummaryList from './TrainStepSummaryList';

type StepSummaryProps = {
  step: TrainStep;
};

const StepSummary = ({ step }: StepSummaryProps) => (
  <div className="step-summary">
    <div className="header">
      <div className="train-no">{formatOptionalValue(step.trainNumber)}</div>
      <div className="route">{TrainUtils.formatRoute(step)}</div>
    </div>
    <TrainStepSummaryList step={step} parts={['dates', 'agent', 'market', 'status']} />
  </div>
);

export default StepSummary;
