import { initialize, touch } from 'redux-form';

import { successGetPreAdvice, successUpdateWagonEntities, successUpdateWagons } from '../pre-advice/preAdviceDucks';
import helpers from '../../commons/helpers/helpers';
import PreAdviceWagonEntity from '../../commons/entity/PreAdviceWagonEntity';
import { getPreAdvice, getPreAdviceState, getPreAdviceWagon } from '../../commons/selectors/selectors';
import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';
import { startToast } from '../../commons/components/toast/toastDucks';
import { formName, getInitialValues } from './preAdviceWagonFormUtils';
import hasValidationErrors, {
  getValidationMessage,
  mergeValidationErrors,
} from '../../commons/validation/validationUtils';
import { VALIDATION_STATUSES } from '../../commons/model/common';
import apiHelper from '../../api/apiHelper';

/*
 * ACTIONS
 */
const ERROR_LOAD_PRE_ADVICE_WAGON = 'ERROR_LOAD_PRE_ADVICE_WAGON';
const START_LOAD_PRE_ADVICE_WAGON = 'START_LOAD_PRE_ADVICE_WAGON';
const SUCCESS_LOAD_PRE_ADVICE_WAGON = 'SUCCESS_LOAD_PRE_ADVICE_WAGON';
const UNLOAD_PRE_ADVICE_WAGON = 'UNLOAD_PRE_ADVICE_WAGON';

/*
 * ACTION CREATORS
 */
const startLoadPreAdviceWagon = () => ({
  type: START_LOAD_PRE_ADVICE_WAGON,
});

const successLoadPreAdviceWagon = (wagon) => ({
  type: SUCCESS_LOAD_PRE_ADVICE_WAGON,
  payload: { wagon },
});

const errorLoadPreAdviceWagon = () => ({
  type: ERROR_LOAD_PRE_ADVICE_WAGON,
});

export const unloadPreAdviceWagon = () => ({
  type: UNLOAD_PRE_ADVICE_WAGON,
});

export const requestLoadPreAdviceWagon = (preAdviceId, wagonId) => async (dispatch) => {
  dispatch(startLoadPreAdviceWagon());
  try {
    const serverPreAdvice = await apiHelper.get(`/api/pre-advices/${preAdviceId}`);
    const preAdvice = new PreAdviceEntity(serverPreAdvice);
    dispatch(successGetPreAdvice(preAdvice));
    const wagon = preAdvice.wagons.find((w) => w.id === wagonId);
    dispatch(successLoadPreAdviceWagon(wagon));
    dispatch(initialize(formName, getInitialValues(wagon)));
  } catch {
    dispatch(errorLoadPreAdviceWagon());
  }
};

export const requestUpdatePreAdviceWagon = (wagon, propertyToUpdate) => async (dispatch) => {
  helpers.setIn(wagon, propertyToUpdate);
  const requestBody = wagon.toUpdateCommand();

  try {
    const serverWagon = await apiHelper.put(`/api/pre-advices/${wagon.preAdviceId}/wagons/${wagon.id}`, requestBody);
    // update wagon in wagon ducks
    dispatch(successLoadPreAdviceWagon(wagon));
    // update wagon in pre-advice ducks
    dispatch(successUpdateWagons([serverWagon]));
  } catch {
    dispatch(startToast({ text: "Erreur : le wagon n'a pas été enregistré.", className: 'error' }));
  }
};

export const requestUpdateWagonPosition = (wagon, newPosition) => async (dispatch, getState) => {
  const preAdvice = getPreAdviceState(getState()).preAdvice;
  wagon.position = newPosition;

  /*
   * Wagons are re-ordered client-side to improve user experience. Otherwise, the initial
   * order is displayed until the HTTP response is received…
   */
  const wagons = [...preAdvice.wagons];
  wagons.splice(
    wagons.findIndex((w) => w.id === wagon.id),
    1,
  );
  wagons.splice(newPosition, 0, wagon);
  wagons.forEach((w, index) => (w.position = index));
  dispatch(successUpdateWagonEntities(wagons));

  try {
    const serverWagons = await apiHelper.post(`/api/pre-advices/${preAdvice.id}/wagons/${wagon.id}/position`, {
      position: newPosition,
    });
    dispatch(successUpdateWagons(serverWagons));
    const currentlySelectedServerWagon = serverWagons.find((w) => w.id === wagon.id);
    if (currentlySelectedServerWagon) {
      dispatch(successLoadPreAdviceWagon(new PreAdviceWagonEntity(currentlySelectedServerWagon)));
    }
  } catch {
    dispatch(startToast({ text: "Erreur : l'ordre des wagon n'a pas été enregistré.", className: 'error' }));
  }
};

const requestUpdatePreAdviceWagonStatus = (preAdvice, wagon, status, onSuccess) => async (dispatch) => {
  try {
    const serverWagon = await apiHelper.post(`/api/pre-advices/${preAdvice.id}/wagons/${wagon.id}/status`, { status });
    // update wagon in wagon ducks
    dispatch(successLoadPreAdviceWagon(new PreAdviceWagonEntity(serverWagon)));
    // update wagon in pre-advice ducks
    dispatch(successUpdateWagons([serverWagon]));
    onSuccess?.();
  } catch {
    dispatch(startToast({ text: "Erreur : le wagon n'a pas été enregistré.", className: 'error' }));
  }
};

const processVerification = (preAdvice, validationErrors, wagon, dispatch) => {
  if (hasValidationErrors(validationErrors)) {
    dispatch(
      startToast({
        text: 'La vérification a échoué : ' + getValidationMessage(validationErrors),
        className: 'error',
      }),
    );
  } else {
    dispatch(
      requestUpdatePreAdviceWagonStatus(preAdvice, wagon, VALIDATION_STATUSES.VALIDATED, () => {
        if (preAdvice.wagons.filter((w) => w.id !== wagon.id).every((w) => w.isValidated())) {
          dispatch(
            startToast({
              text: 'Tous les wagons ont été vérifiés. Pensez à valider la pré-annonce',
              className: 'success',
            }),
          );
        } else {
          dispatch(
            startToast({
              text: 'Vérification réussie',
              className: 'success',
            }),
          );
        }
      }),
    );
  }
};

export const requestVerifyWagon = () => (dispatch, getState) => {
  const preAdvice = getPreAdvice(getState());
  const wagon = getPreAdviceWagon(getState());

  if (wagon.isValidated()) {
    // Un-validate
    dispatch(
      requestUpdatePreAdviceWagonStatus(preAdvice, wagon, 'NOT_VALIDATED', () =>
        dispatch(startToast({ text: "Le wagon n'est plus vérifié", className: 'success' })),
      ),
    );
  } else {
    // Validate form AND underlying entity to be able to see last incorrect changes,
    // and catch cases where the entity was not correctly updated
    const wagonForm = getState().form[formName];
    const formValidationErrors = PreAdviceWagonEntity.validate(preAdvice.wagons.length)(wagonForm.values);
    const entityValidationErrors = PreAdviceWagonEntity.validate(preAdvice.wagons.length, 0)(wagon);
    const validationErrors = mergeValidationErrors(formValidationErrors, entityValidationErrors);
    processVerification(preAdvice, validationErrors, wagon, dispatch);
    dispatch(
      touch(formName, ...Object.keys(wagonForm.registeredFields).map((key) => wagonForm.registeredFields[key].name)),
    );
  }
};

/*
 * REDUCER
 */
const initialState = {
  error: false,
  loading: false,
  wagon: PreAdviceWagonEntity.emptyWagon(),
};

export default (state = {}, action) => {
  switch (action.type) {
    case START_LOAD_PRE_ADVICE_WAGON:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ERROR_LOAD_PRE_ADVICE_WAGON:
      return {
        ...initialState,
        error: true,
      };
    case SUCCESS_LOAD_PRE_ADVICE_WAGON:
      return {
        ...initialState,
        wagon: action.payload.wagon,
      };
    case UNLOAD_PRE_ADVICE_WAGON:
      return initialState;
    default:
      return state;
  }
};
