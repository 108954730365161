import AbstractTemplateCache from './AbstractTemplateCache';
import helpers from '../helpers/helpers';
import { CachedAte } from '../model/templates';
import { Tx } from './AbstractDbCache';

export class AteCache extends AbstractTemplateCache<'ate', string> {
  private readonly additionalValues: string[];

  constructor(additionalValues: string[]) {
    super();
    this.additionalValues = helpers.deduplicateArray(additionalValues);
  }

  protected getStoreName(): 'ate' {
    return 'ate';
  }

  protected validateInput(_inputValue: string): boolean {
    return true;
  }

  protected addItemMetadata(ate: string): CachedAte {
    return { ate, ateSuffix: ate.substring(6) };
  }

  protected removeItemMetadata(dbItem: CachedAte): string {
    return dbItem.ate;
  }

  protected async findTemplates(inputValue: string, tx: Tx<'ate'>) {
    const [ateMatches, ateSuffixMatches] = await Promise.all([
      this.findItemsByIdPrefix(inputValue, tx),
      this.findItemsByIndexPrefix(inputValue, tx, 'by-suffix'),
    ]);
    const additionalMatches = this.additionalValues.filter(
      (value) => value.startsWith(inputValue) || value.endsWith(inputValue),
    );
    return helpers.deduplicateArray([...additionalMatches, ...ateMatches, ...ateSuffixMatches]);
  }

  public formatItem(ate: string) {
    return ate;
  }
}

export default new AteCache([]);
