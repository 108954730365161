import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import './preAdviceStyles.scss';
import {
  requestGetPreAdvice,
  requestImportCompositionIntoPreAdvice,
  requestLoadNewPreAdvice,
  requestUnloadPreAdvice,
} from './preAdviceDucks';
import { getPreAdvice, getPreAdviceIsLoading, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import PreAdviceWagonsList from './wagons/PreAdviceWagonsList';
import { PRE_ADVICE_WRITE } from '../../commons/security/userRoles';
import CopyWagonsOverlay from '../../commons/components/copy-wagons/CopyWagonsOverlay';
import { PRE_ADVICE_TYPE, TRAIN_TYPE } from '../../commons/model/importSource';
import { useViewportWidth } from '../../commons/responsive/hooks';
import PreAdviceForm from './PreAdviceForm';
import SwipeableViews from 'react-swipeable-views';
import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';
import { usePageTitle } from '../../commons/components/hooks';

export const preAdviceTitle = (preAdvice: PreAdviceEntity) => {
  const partner = preAdvice.isReceivedByVfli()
    ? `reçue de ${preAdvice.sender.label}`
    : `pour ${preAdvice.receiver.label}`;
  return `Pré-annonce ${preAdvice.trainNumber} ${partner}`;
};

const PreAdvice = () => {
  const { preAdviceId } = useParams();
  const dispatch = useDispatch();
  const preAdvice = useSelector(getPreAdvice);
  const loading = useSelector(getPreAdviceIsLoading);
  const canModifyPreAdvices = useSelector(selectSecurityContextRoles).includes(PRE_ADVICE_WRITE);
  const { isMobile } = useViewportWidth();
  const [searchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(searchParams.get('tab') === 'vehicles' ? 1 : 0);

  usePageTitle(preAdvice && preAdvice.id === preAdviceId ? preAdviceTitle(preAdvice) : 'Pré-annonce');

  useEffect(() => {
    if (preAdviceId) {
      dispatch(requestGetPreAdvice(preAdviceId) as any);
    } else {
      dispatch(requestLoadNewPreAdvice());
    }
    return () => {
      dispatch(requestUnloadPreAdvice());
    };
  }, [dispatch, preAdviceId]);

  if (loading || !preAdvice) {
    return <div className="loader" />;
  }

  return (
    <div className="composition pre-advice">
      {isMobile ? (
        <>
          <SwipeableViews animateHeight index={tabIndex} onChangeIndex={(newIndex) => setTabIndex(newIndex)}>
            <PreAdviceForm
              preAdvice={preAdvice}
              canModifyPreAdvices={canModifyPreAdvices}
              onChangeTab={(newIndex) => setTabIndex(newIndex)}
            />
            <PreAdviceWagonsList
              preAdvice={preAdvice}
              canModifyPreAdvices={canModifyPreAdvices}
              onChangeTab={(newIndex) => setTabIndex(newIndex)}
            />
          </SwipeableViews>
        </>
      ) : (
        <>
          <PreAdviceForm preAdvice={preAdvice} canModifyPreAdvices={canModifyPreAdvices} />
          <PreAdviceWagonsList preAdvice={preAdvice} canModifyPreAdvices={canModifyPreAdvices} />
        </>
      )}
      {preAdviceId && (
        <CopyWagonsOverlay
          authorizedTypes={[TRAIN_TYPE, PRE_ADVICE_TYPE]}
          candidateIdToExclude={preAdvice.id}
          copyWagonsAction={requestImportCompositionIntoPreAdvice}
          startDateTime={preAdvice.journey.startDateTime}
          targetType={PRE_ADVICE_TYPE}
        />
      )}
    </div>
  );
};

export default PreAdvice;
