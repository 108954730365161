import React, { ComponentType, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { touch, WrappedFieldProps } from 'redux-form';
import './utiStyles.scss';
import { wagonFormName } from '../vehicles/edit/vehicleEditUtils';
import { Wagon } from '../../model/Vehicle';
import { AppAction } from '../../store/store';

export type UtiFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  disabled: boolean;
  labelText: ReactNode;
  saveAction: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
  data: Wagon;
};

const UtiField: ComponentType<UtiFieldProps & WrappedFieldProps> = ({
  id,
  disabled,
  labelText,
  input,
  meta,
  saveAction,
  data,
}: UtiFieldProps & WrappedFieldProps) => {
  const value: number = input.value || 0;

  const clickHandler = (newValue: number) => (event: MouseEvent) => {
    event.preventDefault();
    meta.dispatch(saveAction(data, { [input.name]: newValue }));
    meta.dispatch(touch(wagonFormName, input.name));
    input.onChange(newValue);
  };

  return (
    <div
      className={classNames('input', {
        'input-label': labelText,
        'input-error': meta && meta.touched && meta.invalid,
        'input-valid': meta && meta.touched && meta.valid,
      })}
    >
      {labelText && <label htmlFor={id}>{labelText}</label>}
      <div className={'uti-field-container'}>
        <span className="uti-value" data-testid="nb-uti-value">
          {value}
        </span>
        {!disabled && (
          <>
            <button
              className="uti-button"
              data-testid="nb-uti-plus-button"
              onClick={clickHandler(value + 1)}
              type="button"
            >
              +
            </button>
            <button
              disabled={value <= 0}
              className="uti-button"
              data-testid="nb-uti-minus-button"
              onClick={clickHandler(value - 1)}
              type="button"
            >
              -
            </button>
          </>
        )}
      </div>
      {meta && meta.touched && meta.error ? (
        <div data-testid="uti-error" className="error-message">
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default UtiField;
