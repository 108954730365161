import React, { useMemo } from 'react';
import './reviewButtonStyles.scss';
import { reviewWagonTemplates } from '../vehiclesLibraryDuck';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { getVehiclesLibrary } from '../../../commons/selectors/selectors';
import { IdRegistration, VehicleTemplate } from '../../../commons/model/templates';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import classNames from 'classnames';

type ReviewButtonProps = {
  readOnly: boolean;
};

const ReviewButton = ({ readOnly }: ReviewButtonProps) => {
  const dispatch = useAppDispatch();
  const { libraryCollection } = useAppSelector(getVehiclesLibrary);
  const { isMobile } = useViewportWidth();

  const wagonToReview: IdRegistration[] = useMemo(
    () =>
      libraryCollection
        .filter((vehicle: VehicleTemplate) => vehicle.wagon && vehicle.status === 'TO_REVIEW')
        .map(({ id, registration }: VehicleTemplate) => ({ id, registration })),
    [libraryCollection],
  );

  if (readOnly) {
    return null;
  }

  const handleClick = () => {
    dispatch(reviewWagonTemplates(wagonToReview));
  };

  return (
    <button
      className={classNames('btn btn-review-wagon-templates', isMobile ? 'btn-round checkmark' : 'btn-link')}
      disabled={wagonToReview.length === 0}
      onClick={handleClick}
      type="button"
    >
      Vérifier les wagons
    </button>
  );
};

export default ReviewButton;
