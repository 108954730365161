import DisabledInput from '../../../commons/components/input/DisabledInput';
import React from 'react';
import { BrakingBulletinObservationType } from '../../../commons/model/BrakingBulletin';
import { useAppSelector } from '../../../commons/store/hooks';
import { formValueSelector } from 'redux-form';
import { brakingBulletinFormName } from './BrakingBulletinForm';
import helpers from '../../../commons/helpers/helpers';

function valueToDisplay(stopping: BrakingBulletinObservationType, fixed: string): string {
  if (stopping) {
    return '';
  } else if (fixed) {
    const numberValue = parseInt(fixed, 10);
    return helpers.integerFormat(numberValue);
  }
  return '?';
}

type FixedBrakingFieldProps = {
  stopping: BrakingBulletinObservationType;
};

const FixedBrakingField = ({ stopping }: FixedBrakingFieldProps) => {
  const fixed: BrakingBulletinObservationType = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'brakedWeightDetails.fixed'),
  );
  const value = valueToDisplay(stopping, fixed);

  return <DisabledInput value={value} labelText="Freinage forfaitaire" unit="t" className="right" />;
};

export default FixedBrakingField;
