import { useAppSelector } from '../../../../commons/store/hooks';
import { selectSecurityContextRoles, selectTrainStep } from '../../../../commons/selectors/selectors';
import {
  displayIndiceCompositionField,
  INDICE_COMPOSITION_OPTIONS,
  indiceCompositionFieldReadonly,
} from './indiceCompositionUtils';
import { Field } from 'redux-form';
import Dropdown from '../../../../commons/components/dropdown/Dropdown';
import { FocusEvent } from 'react';
import {
  BrakingBulletinCompositionSummary,
  BrakingBulletinIndiceComposition,
} from '../../../../commons/model/BrakingBulletin';
import useIndiceComposition from './useIndiceComposition';

type IndiceCompositionFieldProps = {
  compositionSummary: BrakingBulletinCompositionSummary;
  formReadOnly: boolean;
};

const IndiceCompositionField = ({ compositionSummary, formReadOnly }: IndiceCompositionFieldProps) => {
  const { data: step, brakingBulletin } = useAppSelector(selectTrainStep);
  const roles = useAppSelector(selectSecurityContextRoles);
  const { updateFixedBrakedWeight } = useIndiceComposition(compositionSummary);

  if (!step || !brakingBulletin) {
    return null;
  }

  if (!displayIndiceCompositionField(roles, brakingBulletin.indiceComposition, step.indiceComposition)) {
    return null;
  }

  const onChange = (
    _: FocusEvent<any>,
    newIndice?: BrakingBulletinIndiceComposition,
    previousIndice?: BrakingBulletinIndiceComposition,
  ) => {
    if (previousIndice === newIndice) {
      return;
    }
    updateFixedBrakedWeight(newIndice);
  };

  return (
    <div className="indice-composition">
      <Field
        component={Dropdown}
        disabled={indiceCompositionFieldReadonly(roles, formReadOnly)}
        labelText="Indice de composition"
        name="indiceComposition"
        options={INDICE_COMPOSITION_OPTIONS}
        onBlur={onChange}
      />
    </div>
  );
};

export default IndiceCompositionField;
