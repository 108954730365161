/**
 * Modal component
 */
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Input from '../input/Input';
import { normalizePositiveNumber } from '../../redux-form/valueNormalizer';
import {
  closeConfirmDialog,
  ConfirmModalFieldType,
  ConfirmModalFormValues,
  executeActionConfirmDialog,
} from './confirmModalDucks';
import { parseToInt } from '../../redux-form/valueParser';
import CheckboxFormComponent from '../checkbox/CheckboxFormComponent';
import { useAppDispatch } from '../../store/hooks';
import { RootState } from '../../reducers/rootReducer';
import { selectConfirmModal } from '../../selectors/selectors';
import React, { FC, useMemo } from 'react';
import { Validator } from 'redux-form/lib/Field';
import Dropdown from '../dropdown/Dropdown';

const validateNumber =
  (required: boolean) =>
  (value: string | number | null): string | undefined => {
    if (value == null) {
      if (required) {
        return 'Champ obligatoire';
      }
      return;
    }
    if (value <= 0) {
      return 'Le nombre doit être supérieur à 0';
    } else if (value > 99) {
      return 'Le nombre doit être inférieur à 99';
    }
  };

const validateText =
  (required: boolean) =>
  (value: string | null): string | undefined => {
    if (!value) {
      if (required) {
        return 'Champ obligatoire';
      }
      return;
    }
    if (value.length > 50) {
      return 'Longueur max 50';
    }
  };

const ConfirmModalField: FC<{
  fieldName: string;
  type: ConfirmModalFieldType;
  label: string;
  options?: Record<string, string>;
  index: number;
  validate?: Validator;
}> = ({ fieldName, type, label, options, index, validate }) => {
  if (type === 'checkbox') {
    return (
      <div>
        <Field component={CheckboxFormComponent} label={label} name={fieldName} />
      </div>
    );
  }
  if (type === 'dropdown') {
    return <Field component={Dropdown} labelText={label} name={fieldName} options={options} validate={validate} />;
  }
  return (
    <Field
      name={fieldName}
      component={Input}
      type="text"
      autoFocus={index === 0}
      className="right"
      placeholder={label}
      validate={validate}
      {...(type === 'number'
        ? {
            pattern: '[0-9]*',
            parse: parseToInt,
            normalize: normalizePositiveNumber,
            maxLength: 2,
          }
        : { maxLength: 50 })}
    />
  );
};

const ConfirmModal: FC<InjectedFormProps<ConfirmModalFormValues<any>>> = ({ handleSubmit }) => {
  const dispatch = useAppDispatch();
  const confirmModal = useSelector(selectConfirmModal);

  const validators = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(confirmModal.fields).map(([fieldName, { type, required = true }]) => [
          fieldName,
          type === 'number' ? validateNumber(required) : validateText(required),
        ]),
      ),
    [confirmModal.fields],
  );

  if (confirmModal.hidden) {
    return <span />;
  }

  const handleCancel = () => {
    confirmModal.onCancel();
    dispatch(closeConfirmDialog());
  };

  return (
    <form
      onSubmit={handleSubmit((formValues: ConfirmModalFormValues<any>) => {
        dispatch(executeActionConfirmDialog(formValues));
      })}
      autoComplete="off"
    >
      <div className="modal">
        <div className="modal-fade" onClick={handleCancel} />
        <div className="modal-body">
          <div className="modal-title">{confirmModal.title}</div>
          {Object.entries(confirmModal.fields).map(([fieldName, { type, label = 'Saisir', options }], index) => (
            <ConfirmModalField
              key={fieldName}
              fieldName={fieldName}
              type={type}
              label={label}
              options={options}
              index={index}
              validate={validators[fieldName]}
            />
          ))}
          <button type="submit" className={`modal-btn ${confirmModal.actionClass}`}>
            {confirmModal.actionText}
          </button>
          <button type="button" className="modal-btn modal-btn-cancel" onClick={handleCancel}>
            {confirmModal.cancelText}
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: Object.fromEntries(
    Object.entries(state.confirmModal.fields).map(([fieldName, { defaultValue }]) => [fieldName, defaultValue ?? null]),
  ),
});

const ConfirmModalWithReduxForm = reduxForm<ConfirmModalFormValues<any>>({
  form: 'inputConfirmModal',
  enableReinitialize: true,
})(ConfirmModal);

export default connect(mapStateToProps)(ConfirmModalWithReduxForm as any);
