import React from 'react';
import GlobalError from '../../commons/components/GlobalError';

const NotLoadedPreAdviceWagon = ({ error, loading }) => {
  if (loading) {
    return <div className="loader" />;
  }
  if (error) {
    return (
      <GlobalError title="Erreur">Une erreur inattendue s'est produite durant la récupération du wagon.</GlobalError>
    );
  }
  return <GlobalError title="Erreur">Ce vehicle n'existe pas.</GlobalError>;
};

export default NotLoadedPreAdviceWagon;
