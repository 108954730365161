import React from 'react';
import classNames from 'classnames';
import GlobalError from '../../../commons/components/GlobalError';
import SortTableHeader from '../../../commons/components/table/SortTableHeader';
import './mailingListsTableStyle.scss';
import MailingListEntity from '../../../commons/entity/MailingListEntity';

const mailingListTypeLabel = (mailingList) => {
  if (mailingList.type === MailingListEntity.TYPES.COMPOSITION) {
    return 'Composition';
  }
  return 'Pré-annonce';
};

const MailingListsTable = ({ mailingLists, sortMailingLists, startEditMailingList, readOnly }) => {
  if (mailingLists.error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération des listes de diffusion.
      </GlobalError>
    );
  }

  if (!mailingLists.dataProxy || mailingLists.dataProxy.length === 0) {
    return (
      <div className="maincontent">
        {mailingLists.loading ? <div className="loader" /> : <span>Aucune liste de diffusion trouvée.</span>}
      </div>
    );
  }

  return (
    <div className="table table-mailing-lists">
      <div className="table-header">
        <SortTableHeader
          title="Nom"
          className="cell-name"
          field="name"
          sort={mailingLists.sort}
          onClick={sortMailingLists}
        />
        <SortTableHeader
          title="Type"
          className="cell-type"
          field="type"
          sort={mailingLists.sort}
          onClick={sortMailingLists}
        />
        <div className="cell-markets">Marchés</div>
        <SortTableHeader
          title="Origine"
          className="cell-start-station"
          field="startStation"
          sort={mailingLists.sort}
          onClick={sortMailingLists}
        />
        <SortTableHeader
          title="Destination"
          className="cell-end-station"
          field="endStation"
          sort={mailingLists.sort}
          onClick={sortMailingLists}
        />
        <SortTableHeader
          title="Type de mission"
          className="cell-mission-type"
          field="missionType"
          sort={mailingLists.sort}
          onClick={sortMailingLists}
        />
        <div className="cell-emails">Emails</div>
        <div className="cell-attachments" />
      </div>

      <div className="table-body">
        {mailingLists.dataProxy.map((item, i) => (
          <div
            key={`row-${i}`}
            className={classNames('table-body-row', { disabled: readOnly })}
            onClick={() => !readOnly && startEditMailingList(item)}
          >
            <div className="cell-name">{item.name}</div>
            <div className="cell-type">{mailingListTypeLabel(item)}</div>
            <div className="cell-markets">
              {item.markets.map((market, j, arr) => (
                <span key={`market-${j}`}>{market.name + (j < arr.length - 1 ? ', ' : '')}</span>
              ))}
            </div>
            <div className="cell-start-station">{item.startStation && item.startStation.label}</div>
            <div className="cell-end-station">{item.endStation && item.endStation.label}</div>
            <div className="cell-mission-type">{item.missionType}</div>
            <div className="cell-emails">
              {item.emails.map((email, k, arr) => (
                <span key={`email-${k}`}>{email + (k < arr.length - 1 ? ', ' : '')}</span>
              ))}
            </div>
            <div className="cell-attachments">
              {item.sendPdf && <div className="icon pdf" />}
              {item.sendExcel && <div className="icon xls" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MailingListsTable;
