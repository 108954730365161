import AbstractTemplateCache from './AbstractTemplateCache';
import { EngineSeries } from '../model/templates';
import { Tx } from './AbstractDbCache';

class EngineSeriesCache extends AbstractTemplateCache<'engineSeries'> {
  protected getStoreName(): 'engineSeries' {
    return 'engineSeries';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  protected findTemplates(inputValue: string, tx: Tx<'engineSeries'>) {
    return this.findItemsByIndexPrefix(inputValue, tx, 'by-number');
  }

  public formatItem(item: EngineSeries): string {
    return item.seriesNumber;
  }
}

export default new EngineSeriesCache();
