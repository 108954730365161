import React from 'react';
import List from '../../../commons/components/list/List';
import helpers from '../../../commons/helpers/helpers';
import { getCountryName } from '../../../commons/countries/foreignCountries';

const getStartStationLabel = (preAdvice) => {
  return getStationLabel(preAdvice, preAdvice.journey.startStation, 'START');
};

const getEndStationLabel = (preAdvice) => {
  return getStationLabel(preAdvice, preAdvice.journey.endStation, 'END');
};

const getBorderCrossingStationLabel = (preAdvice) => {
  return getStationLabel(preAdvice, preAdvice.borderCrossing.station, 'BORDER');
};

const getStationLabel = (preAdvice, station, stationType) => {
  if (station) {
    return <span>{station.label}</span>;
  }
  return getStationLabelFromImportErrors(preAdvice, stationType);
};

const getStationLabelFromImportErrors = (preAdvice, stationType) => {
  const error = preAdvice.importErrors.find((importError) => importError.stationType === stationType);
  if (!error) {
    return undefined;
  }
  const spanTitle = `Gare inconnue - Préfixe du code CI ${error.code ?? 'non fourni'} - Pays ${
    getCountryName(error.country) ?? 'non fourni'
  }`;
  return (
    <span className="unknown-pre-advice-station" title={spanTitle}>
      {error?.name ?? 'Gare inconnue'}
    </span>
  );
};

const getRoute = (preAdvice) => (
  <span>
    {getStartStationLabel(preAdvice)} → {getEndStationLabel(preAdvice)}
  </span>
);
const formatDateTime = (dateTime) => helpers.dateTimeFormat(dateTime);

export const getPrimaryFields = (preAdvice) => {
  const defaultFields = [
    { key: 'Trajet', value: getRoute(preAdvice) },
    { key: 'Départ', value: formatDateTime(preAdvice.journey.startDateTime) },
    { key: 'Train №', value: preAdvice.trainNumber },
    { key: 'EF émettrice', value: preAdvice.sender.label },
    { key: 'EF réceptrice', value: preAdvice.receiver.label },
  ];
  if (preAdvice.isReceivedByVfli()) {
    return [
      { key: 'Reçue', value: formatDateTime(preAdvice.receptionDateTime) },
      { key: 'Utilisée', value: preAdvice.usedInCompositionStatus === 'USED' ? 'Oui' : 'non' },
    ].concat(defaultFields);
  }
  if (preAdvice.isValidated()) {
    return [
      { key: 'Validée', value: formatDateTime(preAdvice.validation.dateTime) },
      { key: 'Par', value: `${preAdvice.validation.validatorFirstName} ${preAdvice.validation.validatorLastName}` },
    ].concat(defaultFields);
  }
  return defaultFields;
};

export const getSecondaryFields = (preAdvice) => [
  { key: 'À', value: getBorderCrossingStationLabel(preAdvice) },
  { key: 'Initialement prévu', value: formatDateTime(preAdvice.borderCrossing.initialEstimatedDateTime) },
  { key: 'Estimé', value: formatDateTime(preAdvice.borderCrossing.estimatedDateTime) },
];

const PreAdviceFieldsReadOnly = ({ preAdvice }) => (
  <div className="pre-advice-read-only-field">
    <List items={getPrimaryFields(preAdvice)} />
    <h2 className="subtitle">Passage à la frontière</h2>
    <List items={getSecondaryFields(preAdvice)} />
  </div>
);

export default PreAdviceFieldsReadOnly;
