import { reset, SubmissionError } from 'redux-form';
import { hideOverlay, showOverlay } from '../../commons/components/overlay/overlayDucks';
import { startToast } from '../../commons/components/toast/toastDucks';
import { startGetUsers } from './usersDucks';
import { AppDispatch } from '../../commons/store/store';
import { AnyAction } from 'redux';
import { User } from '../../commons/model/userProfile';
import apiHelper from '../../api/apiHelper';
import ApiError from '../../api/ApiError';

// ACTIONS
const LOAD_USER = 'LOAD_USER';
const CREATE_USER = 'CREATE_USER';
const PERSIST_USER_START = 'PERSIST_USER_START';
const PERSIST_USER_SUCCESS = 'PERSIST_USER_SUCCESS';
const PERSIST_USER_ERROR = 'PERSIST_USER_ERROR';

// REDUCER
const initialState: UserState = {
  data: null,
  creating: false, // editing or creating
  loading: false,
  error: false,
};

export type UserState = {
  data: User | null;
  loading: boolean;
  error: boolean;
  creating: boolean;
};

export default (state: UserState = initialState, action: AnyAction): UserState => {
  switch (action.type) {
    case LOAD_USER:
      return {
        data: { ...action.payload },
        creating: false,
        loading: false,
        error: false,
      };
    case CREATE_USER:
      return {
        data: null,
        creating: true,
        loading: false,
        error: false,
      };
    case PERSIST_USER_START:
      return {
        ...state,
        error: false,
      };
    case PERSIST_USER_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

// ACTIONS
export const loadUser = (user: User) => (dispatch: AppDispatch) => {
  dispatch({ type: LOAD_USER, payload: user });
  dispatch(reset('userForm')); // If we load the same user twice, the form is not reinitialized.
  dispatch(showOverlay('user'));
};

export const createUser = () => (dispatch: AppDispatch) => {
  dispatch({ type: CREATE_USER });
  dispatch(reset('userForm')); // If we load the same user twice, the form is not reinitialized.
  dispatch(showOverlay('user'));
};

export const persistUserStart = (user: User, id: string | undefined) => async (dispatch: AppDispatch) => {
  dispatch({ type: PERSIST_USER_START, payload: user });

  const persistRequest = id ? apiHelper.put(`/api/users/${id}`, user) : apiHelper.post('/api/users', user);
  const action = id ? 'mis à jour' : 'ajouté';

  try {
    await persistRequest;
    dispatch({ type: PERSIST_USER_SUCCESS, payload: user });
    dispatch(startGetUsers());
    dispatch(startToast({ text: `L'utilisateur a été ${action}.`, className: 'success' }));
    dispatch(hideOverlay());
  } catch (err) {
    dispatch({ type: PERSIST_USER_ERROR, payload: user });

    if (err instanceof ApiError && err?.httpStatus === 400 && err.errors) {
      var errors = { ...err.errors };
      // rename the error's fieldName
      if (Object.prototype.hasOwnProperty.call(err.errors, 'login')) {
        errors.login = null;
        errors.adUser = err.errors.login;
      }
      // Display a validation error if any
      throw new SubmissionError(errors);
    } else {
      // Otherwise display a generic toast message.
      dispatch(startToast({ text: `Erreur : l'utilisateur n'a pas été ${action}.`, className: 'error' }));
    }
  }
};
