import AbstractDataCache from '../../commons/cache/AbstractDataCache';
import DamageReportEntity from '../../commons/entity/DamageReportEntity';

class DamageReportsDataCache extends AbstractDataCache {
  name = 'damageReportsDataCache';

  createEntity(data) {
    return new DamageReportEntity(data);
  }

  createEntityAndRecover(data) {
    const damageReport = this.createEntity(data);

    if (damageReport.isUpdatingBack()) {
      damageReport.markForUpdatingBackFailure();
    }

    // update DamageReports stored in navigator with the default value if the new field "company" is missing
    if (!Object.hasOwn(damageReport, 'company') || damageReport.company === null) {
      damageReport.setWithDefaultCompanyValue();
    }

    return damageReport;
  }

  getId(data) {
    return data.id;
  }

  createCache(map) {
    return new DamageReportsDataCache(map);
  }
}

export default DamageReportsDataCache;
