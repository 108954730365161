/**
 * Market field component
 */
import React from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import Input, { InputProps } from './Input';
import helpers from '../../helpers/helpers';
import './inputStyles.scss';
import marketCache from '../../templates/marketCache';
import { Market } from '../../model/templates';

type MarketFieldProps = BaseFieldProps & InputProps<Market>;

const MarketField = (props: MarketFieldProps) => (
  <Field
    {...props}
    component={Input<Market>}
    id={`composition-${helpers.camelCaseToHyphen(props.name)}`}
    type="text"
    autocompleter={marketCache}
  />
);

export default MarketField;
