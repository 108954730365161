import React from 'react';
import Input from './Input';

const InputWithTextIcon = ({ label, labelText, ...props }) => (
  <div>
    {labelText && <label>{labelText}</label>}
    <div className="railway-company-label">{label}</div>
    <Input {...props} />
  </div>
);

export default InputWithTextIcon;
