import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import helpers from '../../commons/helpers/helpers';
import { preAdviceSummaryShape } from '../preAdvicesPropTypes';
import { Link } from 'react-router-dom';
import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';
import warningImg from '../../commons/assets/images/warning.svg';
import infoImg from '../../commons/assets/images/info.svg';
import { CAPTRAIN_FRANCE_ID } from '../../commons/model/templates';

const statusClasses = {
  VALIDATED: 'validated',
  NOT_VALIDATED: 'not-validated',
};

const formatDateTime = (dateTime) => helpers.dateTimeFormat(dateTime, 'D MMM à HH:mm');

const shouldDisplayImportErrorStatus = ({ importErrorStatus }) =>
  PreAdviceEntity.IMPORT_ERROR_STATUSES.NO_ERROR !== importErrorStatus;

const getImportErrorStatusPictogram = ({ importErrorStatus }) => {
  if (importErrorStatus === PreAdviceEntity.IMPORT_ERROR_STATUSES.WITH_ERRORS) {
    return warningImg;
  }
  if (importErrorStatus === PreAdviceEntity.IMPORT_ERROR_STATUSES.WITH_WARNINGS) {
    return infoImg;
  }
  return null;
};

// noinspection JSUnresolvedVariable
const PreAdviceRow = ({ summary, getRailwayCompanyName }) => (
  <Link
    className={classNames('table-body-row', statusClasses[summary.status], {
      'received-pre-advice': summary.receiverId === CAPTRAIN_FRANCE_ID,
      'received-pre-advice-used': summary.usedInCompositionStatus === 'USED',
    })}
    to={`/pre-advices/${summary.id}`}
  >
    <div className="cell-train-no">{summary.trainNumber}</div>
    <div className="cell-date">{formatDateTime(summary.startDateTime)}</div>
    <div className="cell-sender-receiver">
      <span className="cell-sender">{getRailwayCompanyName(summary.senderId)}</span>
      <span className="cell-receiver">{getRailwayCompanyName(summary.receiverId)}</span>
    </div>
    {shouldDisplayImportErrorStatus(summary) && (
      <div className="cell-warning">
        <img
          src={getImportErrorStatusPictogram(summary)}
          alt="Une ou plusieurs erreurs ont été rencontrées pendant l'import"
        />
      </div>
    )}
    <div className="cell-route">
      <div className="cell-route-from">{summary.startStationName}</div>
      <div className="cell-route-to">{summary.endStationName}</div>
    </div>
  </Link>
);

PreAdviceRow.propTypes = {
  summary: PropTypes.exact(preAdviceSummaryShape).isRequired,
};

export default PreAdviceRow;
