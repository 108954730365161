/**
 * Custom field component
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Field } from 'redux-form';
import Input from './Input';
import helpers from '../../helpers/helpers';
import './inputStyles.scss';

const DamageReportField = ({ numeric, component, ...props }) => {
  const childProps = { ...props };
  if (numeric) {
    childProps.pattern = '[0-9]*'; // Make keyboard input appear on iPad
    childProps.inputMode = 'numeric';
  }
  return (
    <Field
      component={component || Input}
      id={`damage-report-${helpers.camelCaseToHyphen(props.name)}`}
      type="text"
      {...childProps}
    />
  );
};

DamageReportField.propTypes = {
  name: PropTypes.string.isRequired,
  numeric: PropTypes.bool,
};

export default DamageReportField;
