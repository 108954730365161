import React from 'react';

import VehicleField from '../../../input/VehicleField';
import WagonWeightLabel from '../../vehicle/WagonWeightLabel';
import { formatDecimalAndSeparateThousands } from '../../../../redux-form/valueFormatter';
import { normalizePositiveFloatTwoDecimal } from '../../../../redux-form/valueNormalizer';
import { parseToFloat } from '../../../../redux-form/valueParser';
import { updateWagon } from '../../../../../trains/step/vehicleActions';
import {
  resetUtiFieldsInReduxForm,
  uncheckNoUtiInReduxForm,
  updateCommandWithUtiFieldsReset,
  updateCommandWithUtiUnchecked,
} from '../vehicleEditUtils';
import { Wagon } from '../../../../model/Vehicle';
import { useAppDispatch } from '../../../../store/hooks';

type WagonLoadWeightProps = {
  wagon: Wagon;
  combinedMarket: boolean;
};

/**
 * Component representing the "load weight" field.
 */
const WagonLoadWeight = ({ wagon, combinedMarket }: WagonLoadWeightProps) => {
  const dispatch = useAppDispatch();

  const saveAction = (data: Wagon, propertyToUpdate: Partial<Wagon>) => {
    const loadWeight = propertyToUpdate.loadWeight;
    if (!combinedMarket) {
      return updateWagon(data, propertyToUpdate);
    }
    //from here combinedMarket is always true.
    let updateCommand = { ...propertyToUpdate };
    if (loadWeight === 0) {
      resetUtiFieldsInReduxForm(dispatch);
      updateCommand = updateCommandWithUtiFieldsReset(propertyToUpdate);
    }
    if (!data?.loadWeight && (loadWeight ?? 0) > 0) {
      uncheckNoUtiInReduxForm(dispatch);
      updateCommand = updateCommandWithUtiUnchecked(propertyToUpdate);
    }
    return updateWagon(data, updateCommand);
  };

  return (
    <VehicleField
      name="loadWeight"
      labelText={<WagonWeightLabel hazardousMaterials={wagon.hazardousMaterials} />}
      unit="kg"
      format={formatDecimalAndSeparateThousands}
      numeric={true}
      normalize={normalizePositiveFloatTwoDecimal}
      normalizeOnSave={parseToFloat}
      saveAction={saveAction}
      data={wagon}
    />
  );
};

export default WagonLoadWeight;
