import { AbstractDbCache, SaveItemsOptions } from './AbstractDbCache';
import { Train } from '../model/Train';
import trainStepCache from './trainStepCache';

class TrainCache extends AbstractDbCache<'train'> {
  protected getStoreName(): 'train' {
    return 'train';
  }

  public async saveTrains(trains: Train[], options: SaveItemsOptions = {}): Promise<void> {
    await this.saveItems(
      trains.map((train) => ({
        id: train.id,
        stepIds: train.steps.map((step) => step.id),
      })),
      options,
    );
    await trainStepCache.saveItems(
      trains.flatMap((train) => train.steps),
      options,
    );
  }
}

export default new TrainCache();
