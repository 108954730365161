import FormSection from '../../../commons/components/form/FormSection';
import { TrainStep } from '../../../commons/model/Train';
import ReadyForDispatchUtils from '../../../commons/model/ReadyForDispatchUtils';
import List from '../../../commons/components/list/List';
import classNames from 'classnames';
import './readyForDispatchStyles.scss';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import { isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import { invalidateReadyForDispatch, signReadyForDispatch } from './ReadyForDispatchActions';
import { ReadyForDispatchSignature } from '../../../commons/model/ReadyForDispatch';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';

type ReadyForDispatchSummaryProps = {
  step: TrainStep;
  editable: boolean;
};

const ReadyForDispatchSummary = ({ step, editable }: ReadyForDispatchSummaryProps) => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector(selectSecurityContext);
  const signed = ReadyForDispatchUtils.stepHasSignedReadyForDispatch(step);

  const handleSign = () => {
    dispatch(
      openConfirmDialog({
        title: `Souhaitez-vous valider en tant que ${username} que le train est prêt pour l'expédition ?`,
        actionText: 'Valider',
        action: signReadyForDispatch,
        fields: {
          trackNumber: {
            type: 'text',
            required: false,
            label: 'Voie',
          },
        },
      }),
    );
  };

  const handleInvalidate = (signature: SignatureOrOffline<ReadyForDispatchSignature>) => () => {
    dispatch(
      openConfirmDialog({
        title: `Souhaitez-vous supprimer cette signature ?`,
        actionText: 'Supprimer',
        action: invalidateReadyForDispatch(isOfflineSignature(signature) ? null : signature.date),
        actionClass: 'danger',
      }),
    );
  };

  return (
    <FormSection title="Prêt Pour Expédition" collapsible={true} className={classNames('ppe-summary', { signed })}>
      <div className="ppe-summary-items">
        {step.readyForDispatchSignatures.map((signature, index) => (
          <div className="ppe-summary-item" key={index}>
            <List
              items={[
                ...(signature.trackNumber ? [{ key: 'Voie n°', value: signature.trackNumber }] : []),
                ...SignatureUtils.basicSignatureFields(signature, username),
              ]}
            />
            {editable && (
              <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
            )}
          </div>
        ))}
      </div>
      {editable &&
        step.readyForDispatchSignatures.every((signature) => !isOfflineSignature(signature)) &&
        (BrakingBulletinUtils.stepHasSignedBrakingBulletin(step) ? (
          <button className="btn btn-accent" onClick={handleSign} type="button">
            Saisir
          </button>
        ) : (
          <div className="info-message">
            Veuillez valider le bulletin de freinage avant de saisir le Prêt Pour Expédition
          </div>
        ))}
    </FormSection>
  );
};

export default ReadyForDispatchSummary;
