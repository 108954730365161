/**
 * Railway Company field component
 */
import React from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import './inputStyles.scss';
import { useRailwayCompanies } from '../../templates/railwayCompanyCache';
import { RailwayCompany } from '../../model/templates';
import Dropdown, { DropdownProps } from '../dropdown/Dropdown';

type RailwayCompanyFieldProps = BaseFieldProps &
  Omit<DropdownProps<RailwayCompany>, 'options'> & {
    blacklist?: string[];
  };

const RailwayCompanyField = ({ blacklist = [], ...props }: RailwayCompanyFieldProps) => {
  const { companies } = useRailwayCompanies();
  const companiesOptions = Object.fromEntries(
    companies.filter((company) => !blacklist?.includes(company.id)).map((company) => [company.id, company]),
  );
  return (
    <Field
      component={Dropdown<RailwayCompany>}
      options={companiesOptions}
      formatOption={(company: RailwayCompany) => company.label}
      {...props}
    />
  );
};

export default RailwayCompanyField;
