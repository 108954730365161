import React from 'react';

import SortTableHeader from '../../../commons/components/table/SortTableHeader';
import './ownersTableStyles.scss';

/**
 * Component in charge of displaying the array of owners.
 *
 * @param canModify Boolean indicating if the user can modify owners
 * @param owners The owners to display.
 * @param sort The current sort command
 * @param changeSort The function to call to change the list sort.
 * @param requestDisplayExistingOwnerForm The function to call to display the
 * owner form.
 */
const OwnersTable = ({ canModify, owners, sort, changeSort, requestDisplayExistingOwnerForm }) => (
  <div className="table table-owners">
    <div className="table-header">
      <SortTableHeader className="cell-name" field="name" onClick={changeSort} sort={sort} title="Nom" />
      <SortTableHeader className="cell-vkm" field="vkm" onClick={changeSort} sort={sort} title="VKM" />
      <div className="cell-emails">Emails</div>
    </div>
    <div className="table-body">
      {owners.map((owner) => (
        <div
          key={owner.id}
          className="table-body-row"
          onClick={() => canModify && requestDisplayExistingOwnerForm(owner)}
        >
          <div className="cell-name">{owner.name}</div>
          <div className="cell-vkm">{owner.vkm}</div>
          <div className="cell-emails">{owner.emails.join(', ')}</div>
        </div>
      ))}
    </div>
  </div>
);

export default OwnersTable;
