import { AppDispatch } from '../../../commons/store/store';
import { RootState } from '../../../commons/reducers/rootReducer';
import { selectTrainDiffs, selectTrainStep } from '../../../commons/selectors/selectors';
import { trainStepUpdated } from '../trainStepDucks';
import { applyTrainDiffs } from '../../../commons/model/TrainDiff';
import { offlineUpdateReadyForDispatch, persistTrainsIfNeeded } from '../../offline/offlineTrainsDucks';
import { DateString } from '../../../commons/model/common';
import { TrainStep } from '../../../commons/model/Train';
import { ConfirmModalFormValues } from '../../../commons/components/modal/confirmModalDucks';
import moment from 'moment';

export const afterReadyForDispatchUpdated = (step: TrainStep) => (dispatch: AppDispatch, getState: () => RootState) => {
  const diffs = selectTrainDiffs(step.id)(getState());
  dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
  dispatch(persistTrainsIfNeeded());
};

export const signReadyForDispatch =
  ({ trackNumber }: ConfirmModalFormValues<{ trackNumber: 'text' }>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;
    dispatch(
      offlineUpdateReadyForDispatch({
        stepId: step.id,
        sign: { date: moment().toISOString(), trackNumber },
      }),
    );
    dispatch(afterReadyForDispatchUpdated(step));
  };

export const invalidateReadyForDispatch =
  (date: DateString | null) => () => (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;
    dispatch(
      offlineUpdateReadyForDispatch({
        stepId: step.id,
        invalidate: { date },
      }),
    );
    dispatch(afterReadyForDispatchUpdated(step));
  };
