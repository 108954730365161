/**
 * RID for Hazardous Material.
 */
import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { RID, RIDS } from '../../model/templates';

type RidProps = React.InputHTMLAttributes<HTMLInputElement> & { labelText: string };

const RidInput = ({ id, labelText, input, meta, ...inputProps }: RidProps & WrappedFieldProps) => {
  const ridValue = input.value as RID;
  return (
    <div className="input input-label">
      {labelText && <label htmlFor={id}>{labelText}</label>}

      <input id={id} {...input} {...inputProps} value={RIDS[ridValue ?? 'NONE'] ?? ''} />

      {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
    </div>
  );
};

const Rid = ({ ...props }: Omit<RidProps, 'labelText'>) => (
  <Field {...props} component={RidInput} labelText="Classe RID" disabled={true} />
);

export default Rid;
