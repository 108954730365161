import React from 'react';
import { connect } from 'react-redux';
import MessagesTable from './messages-table/MessagesTable';
import { getSentMessages } from '../../commons/selectors/selectors';
import { startGetSentMessages } from './sentMessagesDucks';

import SentMessagesTopBar from './SentMessagesTopBar';
import { usePageTitle } from '../../commons/components/hooks';

const SentMessages = ({ sentMessages }) => {
  usePageTitle('Messages envoyés');
  return (
    <div className="content-parent table-page">
      <SentMessagesTopBar />
      <div className="table-message">
        Rappel : Pour les trains validés qui vont partir, le message ne sera envoyé que 5 minutes avant l'heure de
        départ théorique
      </div>
      <MessagesTable sentMessages={sentMessages} />
    </div>
  );
};

class SentMessagesComponent extends React.Component {
  componentDidMount() {
    this.props.startGetSentMessages();
  }

  render() {
    return <SentMessages {...this.props} />;
  }
}

const selector = (state) => ({
  sentMessages: getSentMessages(state),
});

export default connect(selector, { startGetSentMessages })(SentMessagesComponent);
