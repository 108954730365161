import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { BrakingBulletinFormData, brakingBulletinFormName } from './BrakingBulletinForm';
import { useAppSelector } from '../../../commons/store/hooks';
import { selectTrainStep } from '../../../commons/selectors/selectors';
import { BrakingBulletin } from '../../../commons/model/BrakingBulletin';
import VehiclesUtils from '../../../commons/model/VehiclesUtils';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';
import useIndiceComposition from './indice-composition/useIndiceComposition';

type BrakingBulletinMessagesProps = {
  brakingBulletin: BrakingBulletin;
};

const BrakingBulletinMessages = ({ brakingBulletin: { status, compositionSummary } }: BrakingBulletinMessagesProps) => {
  const effectiveBrakedWeight =
    compositionSummary.vehiclesBrakedWeight +
    compositionSummary.tractionEnginesBrakedWeight +
    compositionSummary.vehicleEnginesBrakedWeight +
    compositionSummary.pushEnginesBrakedWeight;
  const length =
    compositionSummary.vehiclesLength +
    compositionSummary.tractionEnginesLength +
    compositionSummary.vehicleEnginesLength +
    compositionSummary.pushEnginesLength;
  const formValues = useSelector(getFormValues(brakingBulletinFormName)) as BrakingBulletinFormData;
  const step = useAppSelector(selectTrainStep).data!;
  const wagonsOrVehicleEMs = VehiclesUtils.getWagonsOrVehicleEMs(step.vehicles);
  const actualBrakedWeight = formValues.brakedWeightDetails?.stopping ?? formValues.brakedWeightDetails?.fixed ?? 0;
  const { indiceCompositionOfBrakingBulletin } = useIndiceComposition(compositionSummary);
  const indiceComposition = indiceCompositionOfBrakingBulletin();
  return (
    <div className="braking-bulletin-messages">
      {BrakingBulletinUtils.hasLLObservation(formValues.observations) && (
        <div className="message info">
          Au régime Locomotive Longue, pour les 5 premiers wagons, la masse freinée réalisée prise en compte pour le BF
          correspond à 75% de la masse freinée réalisée réelle.
        </div>
      )}
      {length > 750 && (
        <div className="message warning">Longueur supérieure à 750m. Des règles spécifiques peuvent s'appliquer.</div>
      )}
      {!formValues.brakedWeightDetails?.fixed && (
        <div className="message error">Impossible de déterminer la masse freinée nécessaire.</div>
      )}
      {status !== 'INVALIDATED' && (
        // Contrôles dépendant d'informations non-dénormalisées de la composition,
        // pouvant donc concerner des données obsolètes
        <>
          {wagonsOrVehicleEMs.length > 0 && !wagonsOrVehicleEMs[0].effectiveBrakedWeight && (
            <div className="message error">Premier wagon non freiné</div>
          )}
          {wagonsOrVehicleEMs.length > 0 &&
            !wagonsOrVehicleEMs[wagonsOrVehicleEMs.length - 1].effectiveBrakedWeight && (
              <div className="message error">Dernier wagon non freiné</div>
            )}
        </>
      )}
      {effectiveBrakedWeight < actualBrakedWeight && <div className="message error">Freinage non conforme</div>}
      {(indiceComposition === 'ME120' || indiceComposition === 'ME140') && wagonsOrVehicleEMs.length < 2 && (
        <div className="message error">Au moins 2 wagons ou EMs en véhicule requis pour cet indice de composition</div>
      )}
      {status === 'DRAFT' && step.status !== 'VALIDATED' && (
        <div className="message warning">Veuillez valider la composition avant de signer le bulletin de freinage.</div>
      )}
    </div>
  );
};

export default BrakingBulletinMessages;
