import React from 'react';
import './mailingListsTopbarStyle.scss';
import MailingListAddButton from './MailingListAddButton';

class MailingTopBar extends React.Component {
  debounceReload = (filterName, filterValue) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.props.filterMailingLists({ [filterName]: filterValue });
    }, 500);
  };

  handleFilterChange = (event, filterName) => {
    this.debounceReload(filterName, event.target.value);
  };

  render() {
    const { readOnly } = this.props;

    return (
      <div className="topbar topbar-mailinglists">
        <div className="topbar-mailinglists-title">
          <h1 className="title">Listes de diffusion</h1>
          {!readOnly && <MailingListAddButton />}
        </div>

        <div className="topbar-mailinglists-filters">
          <div className="filters-title">
            <span />
          </div>
          <div className="filters-content">
            <div className="input">
              <input name="name" placeholder="Nom" onChange={(event) => this.handleFilterChange(event, 'nameQuery')} />
            </div>
            <div className="input">
              <input
                name="name"
                placeholder="Marché"
                onChange={(event) => this.handleFilterChange(event, 'marketQuery')}
              />
            </div>
            <div className="input">
              <input
                name="name"
                placeholder="Origine"
                onChange={(event) => this.handleFilterChange(event, 'startStationQuery')}
              />
            </div>
            <div className="input">
              <input
                name="name"
                placeholder="Destination"
                onChange={(event) => this.handleFilterChange(event, 'endStationQuery')}
              />
            </div>
            <div className="input">
              <input
                name="name"
                placeholder="Type de mission"
                onChange={(event) => this.handleFilterChange(event, 'missionType')}
              />
            </div>
            <div className="input">
              <input
                name="name"
                placeholder="Email"
                onChange={(event) => this.handleFilterChange(event, 'emailQuery')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MailingTopBar;
