import React from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import Textarea from '../input/Textarea';
import DamageField from '../input/DamageField';
import './damagesStyles.scss';
import damageCache from '../../templates/damageCache';

const Damages = ({
  allowIntermediary,
  canLabelBeChanged,
  data,
  disabled,
  fields,
  minDamages,
  maxDamages,
  saveAction,
}) => {
  const dispatch = useDispatch();
  /**
   * Add a new damage to the form.
   */
  const addDamage = () => {
    const newDamage = {
      damageTemplateId: '',
      description: '',
    };
    fields.push(newDamage);
    dispatch(saveAction(data, { [fields.name]: fields.getAll().concat([newDamage]) }));
  };

  /**
   * Remove the damage at the given index.
   *
   * @param index {number} The index of the damage to remove.
   */
  const removeDamage = (index) => {
    fields.remove(index);
    const damages = [...fields.getAll()];
    damages.splice(index, 1);
    dispatch(saveAction(data, { [fields.name]: damages }));
  };

  /**
   * Update the given damage report.
   *
   * Case 1 : The user picked a choice in the auto-completion list
   * In this case, the "propertyToUpdate" parameter contains the entire damage template
   * object.
   *
   * Case 2 : The user typed the entire damage template code and clicked outside. In
   * this case, only the code is passed.
   *
   * @param damageFieldName {string} the field name.
   * @param index {number} The index of the updated damage.
   * @returns {function(*=, *): function(*): *}
   */
  const handleChangeDamageTemplate = (damageFieldName, index) => (d, propertyToUpdate) => async () => {
    let damageTemplate;
    if (propertyToUpdate[`${fields.name}.${index}`]) {
      damageTemplate = propertyToUpdate[`${fields.name}.${index}`];
    } else if (propertyToUpdate[`${damageFieldName}.damageTemplateId`]) {
      const id = propertyToUpdate[`${damageFieldName}.damageTemplateId`];
      damageTemplate = (await damageCache.findItemById(id)) ?? {};
    }

    if (damageTemplate) {
      const cmd = {
        [`${fields.name}.${index}`]: {
          damageTemplateId: damageTemplate.id,
          comment: damageTemplate.description || '',
        },
      };
      fields.remove(index);
      fields.insert(index, cmd[`${fields.name}.${index}`]);
      dispatch(saveAction(d, cmd));
    }
  };

  /**
   * Return a boolean indicating whether or not a new damage can be added to the
   * damage report form.
   *
   * @returns {boolean}
   */
  const canAddDamage = () => {
    return !disabled && fields.length < maxDamages;
  };

  /**
   * Return a boolean indicating whether or not an existing damage can be removed
   * from the damage report form.
   *
   * @returns {boolean}
   */
  const canRemoveDamage = () => {
    return !disabled && fields.length > minDamages;
  };

  return (
    <ul className="damages">
      {fields.map((damageFieldName, index) => (
        <li key={index}>
          <div className="header">
            <div className="subtitle">{`Avarie n° ${index + 1} `}</div>
            {canRemoveDamage() && (
              <button className="btn btn-round small remove" onClick={() => removeDamage(index)} type="button" />
            )}
          </div>
          <div className="full-width damage-report-form-damage">
            <div className="damage-report-form-damage-code">
              <DamageField
                allowIntermediary={allowIntermediary}
                data={data}
                disabled={disabled}
                labelText="Code d'avarie *"
                name={`${damageFieldName}.damageTemplateId`}
                placeholder=""
                saveAction={handleChangeDamageTemplate(damageFieldName, index)}
                type="text"
              />
            </div>
            <div className="damage-report-form-damage-description">
              <Field
                component={Textarea}
                data={data}
                disabled={disabled || !canLabelBeChanged}
                labelText={`Désignation du défaut ${canLabelBeChanged ? '*' : ''}`}
                name={`${damageFieldName}.comment`}
                placeholder={canLabelBeChanged ? 'Saisir' : ' '}
                saveAction={saveAction}
                type="text"
              />
            </div>
          </div>
        </li>
      ))}
      {canAddDamage() && (
        <button type="button" className="btn btn-link btn-icon plus add-damage" onClick={addDamage}>
          Ajouter une avarie
        </button>
      )}
    </ul>
  );
};

export default Damages;
