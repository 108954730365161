import ownerCache from '../../commons/templates/ownerCache';
import { sortList } from '../../commons/model/sortUtils';
import { SortDirection } from '../../commons/model/sort';

/*
 ***********************
 * ACTION
 ***********************
 */
const LOAD_OWNERS_START = 'LOAD_OWNERS_START';
const LOAD_OWNERS_SUCCESS = 'LOAD_OWNERS_SUCCESS';
const SORT_OWNERS = 'SORT_OWNERS';

/*
 ***********************
 * ACTION CREATORS
 ***********************
 */

/**
 * Action creator that loads the owners from the templates cache.
 * No HTTP request is triggered.
 */
export const loadOwners = () => async (dispatch) => {
  dispatch({
    type: LOAD_OWNERS_START,
    payload: {},
  });

  /*
   * We are working on copies of the owners in the templates caches.
   */
  const owners = await ownerCache.findAllItems();

  dispatch({
    type: LOAD_OWNERS_SUCCESS,
    payload: {
      owners,
    },
  });
};

/**
 * Action creator to sort the list of owners.
 *
 * @param sort The sort command.
 */
export const sortOwners = (sort) => ({
  type: SORT_OWNERS,
  payload: {
    sort,
  },
});

/*
 ***********************
 * REDUCER
 ***********************
 */

const initialState = {
  /**
   * The array contains all the active owners.
   */
  ownersCollection: [],
  /**
   * A boolean indicating whether or not there was an error while loading
   * the owners.
   */
  error: false,
  /**
   * A boolean indicating if there is a pending HTTP request to load the
   * owners.
   */
  loading: false,
  /**
   * The sort command.
   */
  sort: {
    field: 'name',
    direction: SortDirection.ASC,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_OWNERS_START:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case LOAD_OWNERS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        ownersCollection: sortList(payload.owners, state.sort),
      };
    case SORT_OWNERS:
      return {
        ...state,
        sort: payload.sort,
        ownersCollection: sortList(state.ownersCollection, payload.sort),
      };
    default:
      return state;
  }
};
