import React from 'react';
import { useDispatch } from 'react-redux';
import { removeAnnexe } from '../../damageReportDucks';

const AnnexeLine = ({ attachment, damageReport, offline, pending, readOnly }) => {
  const dispatch = useDispatch();
  return (
    <li key={attachment.id}>
      {!offline && !pending && (
        <a href={`/api/damageReports/attachments/${attachment.id}`} rel="noopener noreferrer" target="_blank">
          <span className="attachment-name">{attachment.filename}</span>
          {!readOnly && (
            <span
              className="btn btn-round remove"
              onClick={(e) => {
                e.preventDefault();
                dispatch(removeAnnexe(damageReport, attachment));
              }}
            />
          )}
        </a>
      )}
      {offline && (
        <div>
          <span className="attachment-name">{attachment.filename}</span>
          {!readOnly && (
            <span
              className="btn btn-round remove"
              onClick={(e) => {
                e.preventDefault();
                dispatch(removeAnnexe(damageReport, attachment));
              }}
            />
          )}
        </div>
      )}
      {pending && (
        <div className="pending">
          <span className="attachment-name">{attachment.attachmentName || attachment.name} (upload en cours)</span>
        </div>
      )}
    </li>
  );
};

export default AnnexeLine;
