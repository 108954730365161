import { Company, TractionMode, VersionedEntityBase } from './common';
import { RoleId } from '../security/userRoles';

export type EngineSeries = Readonly<{
  id: number;
  tractionMode: TractionMode;
  seriesNumber: string;
  length: number | null;
  operationalLength: number | null;
  tare: number | null;
  nbAxles: number | null;
}>;

export type EngineTemplate = EngineSeries &
  Readonly<{
    number: string;
    registration: string;
  }>;

export type CachedEngineTemplate = EngineTemplate &
  Readonly<{
    reversedNumber: string;
  }>;

export type WagonTemplateStatus = 'TO_REVIEW' | 'REVIEWED';

export const WAGON_TEMPLATE_STATUSES: WagonTemplateStatus[] = ['TO_REVIEW', 'REVIEWED'];

export type WagonTemplate = Readonly<{
  id?: number;
  registration: string;
  length: number;
  tare: number;
  nbAxles: number;
  ownerId: number | null;
  ownerName: string | null;
  doubleWagon: boolean;
  status: WagonTemplateStatus;
  manager: Company | null;
}>;

export type CachedWagonTemplate = WagonTemplate &
  Readonly<{
    reversedRegistration: string;
  }>;

export type IdRegistration = Required<Pick<WagonTemplate, 'id' | 'registration'>>;

export type VehicleTemplate = (WagonTemplate & { wagon: true }) | (EngineTemplate & { wagon: false });

export type RID =
  | 'NONE'
  | 'RID_1'
  | 'RID_2'
  | 'RID_3'
  | 'RID_4_1'
  | 'RID_4_2'
  | 'RID_4_3'
  | 'RID_5_1'
  | 'RID_5_2'
  | 'RID_6_1'
  | 'RID_6_2'
  | 'RID_7'
  | 'RID_8'
  | 'RID_9';

export const RIDS: Record<RID, string> = {
  NONE: 'Aucun',
  /**
   * Matières et objets explosibles
   */
  RID_1: '1',
  /**
   * Gaz comprimés, liquéfiés ou dissous sous pression
   */
  RID_2: '2',
  /**
   * Matières liquides inflammables
   */
  RID_3: '3',
  /**
   * Matières solides inflammables
   */
  RID_4_1: '4.1',
  /**
   * Matières sujettes à l'inflammation spontanée
   */
  RID_4_2: '4.2',
  /**
   * Matières qui au contact de l'eau dégagent des gaz inflammables
   */
  RID_4_3: '4.3',
  /**
   * Matières comburantes
   */
  RID_5_1: '5.1',
  /**
   * Peroxydes organiques
   */
  RID_5_2: '5.2',
  /**
   * Matières toxiques
   */
  RID_6_1: '6.1',
  /**
   * Matières infectieuses
   */
  RID_6_2: '6.2',
  /**
   * Matières radioactives
   */
  RID_7: '7',
  /**
   * Matières corrosives
   */
  RID_8: '8',
  /**
   * Matières et objets dangereux divers
   */
  RID_9: '9',
};

export type PackingGroup = 'NONE' | 'GROUP_I' | 'GROUP_II' | 'GROUP_III';

export const PACKING_GROUPS: Record<PackingGroup, string> = {
  NONE: 'Aucun',
  GROUP_I: 'Groupe I',
  GROUP_II: 'Groupe II',
  GROUP_III: 'Groupe III',
};

export type DangerLabel =
  | 'DL_1'
  | 'DL_1_4'
  | 'DL_1_5'
  | 'DL_1_6'
  | 'DL_2_1'
  | 'DL_2_2'
  | 'DL_2_3'
  | 'DL_3'
  | 'DL_4_1'
  | 'DL_4_2'
  | 'DL_4_3'
  | 'DL_5_1'
  | 'DL_5_2'
  | 'DL_6_1'
  | 'DL_6_3'
  | 'DL_7'
  | 'DL_8'
  | 'DL_9';

export type HazardousMaterial = Readonly<{
  id: number;
  dangerIdentificationNumber: string;
  unitedNationsCode: string;
  label: string;
  rid: RID;
  packingGroup: PackingGroup;
  dangerLabels: readonly DangerLabel[];
  limitedQuantity: string | null;
}>;

export type Station = Readonly<{
  id: string;
  label: string;
  ci: string;
  isoCountry: string;
  active: boolean;
}>;

export type CachedStation = Station &
  Readonly<{
    normalizedLabel: string;
  }>;

export type Market = Readonly<{
  id: number;
  name: string;
  axis: string;
  active: boolean;
  combined: boolean;
  international: boolean;
}>;

export type Profile = VersionedEntityBase<number> &
  Readonly<{
    name: string;
    roles: readonly RoleId[];
  }>;

export type CachedProfile = Profile &
  Readonly<{
    normalizedName: string;
  }>;

export type DamageTemplate = VersionedEntityBase<string> &
  Readonly<{
    description: string;
    severity: number | null;
    preAdviceDamageTemplateCode: string;
    intermediary: boolean;
    active: boolean;
  }>;

export type Owner = VersionedEntityBase<number> &
  Readonly<{
    name: string;
    emails: readonly string[];
    vkm: string;
    active: boolean;
  }>;

export type CachedAte = Readonly<{
  ate: string;
  ateSuffix: string;
}>;

export type RailwayCompany = Readonly<{
  id: string;
  code: string;
  label: string;
}>;

export const CAPTRAIN_FRANCE_ID = 'VFLI';
export const CAPTRAIN_FRANCE: RailwayCompany = {
  id: CAPTRAIN_FRANCE_ID,
  code: '3233',
  label: 'CAPTRAIN FRANCE',
};
