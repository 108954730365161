import { useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import React, { useEffect, useState } from 'react';
import { isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import List from '../../../commons/components/list/List';
import { ActionCreator } from 'redux';
import { AppAction } from '../../../commons/store/store';
import SignatureUtils from '../../../commons/model/SignatureUtils';

type TraceabilitySubsectionProps = {
  signatures: SignatureOrOffline[];
  editable: boolean;
  /* "female" signature */
  e: boolean;
  onSign?: ActionCreator<AppAction>;
  onInvalidate?: ActionCreator<AppAction>;
};

const TraceabilitySubsection = ({ signatures, editable, e, onSign, onInvalidate }: TraceabilitySubsectionProps) => {
  const { username, email } = useAppSelector(selectSecurityContext);
  const [formOpen, setFormOpen] = useState(editable && signatures.length === 0);

  useEffect(() => {
    setFormOpen(editable && signatures.length === 0);
  }, [editable, signatures]);

  const handleSign = () => {
    if (onSign) {
      onSign();
    }
    setFormOpen(false);
  };

  const handleInvalidate = (signature: SignatureOrOffline) => () => {
    if (onInvalidate) {
      onInvalidate(isOfflineSignature(signature) ? null : signature.userId);
    }
  };

  return (
    <>
      {signatures.map((signature, index) => (
        <div className="subsection-content" key={index}>
          <List items={SignatureUtils.basicSignatureFields(signature, username, e)} />
          {editable && (
            <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
          )}
        </div>
      ))}
      {editable &&
        (formOpen ? (
          <div className="buttons">
            <button className="btn btn-accent" type="button" onClick={handleSign}>
              Valider et signer
            </button>
          </div>
        ) : (
          signatures.every((signature) => !isOfflineSignature(signature) && signature.userEmail !== email) && (
            <button className="btn btn-round small plus" onClick={() => setFormOpen(true)} type="button" />
          )
        ))}
      {!editable && signatures.length === 0 && <div className="empty">Non signé{e ? 'e' : ''}</div>}
    </>
  );
};

export default TraceabilitySubsection;
