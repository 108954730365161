import { ConfigProps } from 'redux-form/lib/reduxForm';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import wagonCache from '../../templates/wagonCache';
import stationCache from '../../templates/stationCache';
import ownerCache from '../../templates/ownerCache';
import marketCache from '../../templates/marketCache';

type Validator = (value: string) => Promise<string | null>;
type Validators = { [fieldName: string]: Validator };

export const asyncValidation = <FormData = {}, P = {}, ErrorType = string>(
  validators: Validators,
): Partial<ConfigProps<FormData, P, ErrorType>> => {
  return {
    asyncChangeFields: [],
    asyncBlurFields: Object.keys(validators),
    asyncValidate: async (values, _dispatch, _props, fieldName: string) => {
      const validator = validators[fieldName];
      if (validator !== undefined) {
        const message = await validator(lodashGet(values, fieldName));
        if (message) {
          // eslint-disable-next-line no-throw-literal
          throw lodashSet({}, fieldName, message); // NOSONAR
        }
      }
    },
  };
};

export const asyncValidateWagon = async (registration?: string) => {
  if (!registration) {
    return null;
  }
  const wagon = await wagonCache.findItemById(registration);
  if (!Boolean(wagon)) {
    return "Le wagon n'existe pas dans le référentiel";
  }
  return null;
};

export const asyncValidateStation = async (stationLabel?: string) => {
  if (!stationLabel) {
    return null;
  }
  const station = await stationCache.findByLabel(stationLabel);
  if (!Boolean(station)) {
    return 'Aucune gare trouvée avec ce nom';
  }
  return null;
};

export const asyncValidateOwner = async (ownerName?: string) => {
  if (!ownerName) {
    return null;
  }
  const owner = await ownerCache.findByName(ownerName);
  if (!Boolean(owner)) {
    return 'Aucun détenteur trouvé avec ce nom';
  }
  return null;
};

export const asyncValidateMarket = async (marketName?: string) => {
  if (!marketName) {
    return null;
  }
  const market = await marketCache.findByName(marketName);
  if (!Boolean(market)) {
    return 'Aucun contrat trouvé avec ce nom';
  }
  return null;
};
