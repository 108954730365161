import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import './switch2Styles.scss';

const height = 40;
const width = 68;
const handleDiameter = 34;
const checkedPos = Math.max(width - height, width - (height + handleDiameter) / 2);
const uncheckedPos = Math.max(0, (height - handleDiameter) / 2);

/**
 * 2-state Switch component.
 *
 * Copied and tweaked from react-switch (https://github.com/yogaboll/react-switch)
 */
class Switch2 extends Component {
  constructor(props) {
    super(props);
    const { input, value } = props;
    this.state = {
      pos: value || input?.value ? checkedPos : uncheckedPos,
      startX: null,
      isDragging: false,
      hasOutline: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ input, value }) {
    const pos = value || (input && input.value) ? checkedPos : uncheckedPos;
    this.setState({ pos });
  }

  getValue() {
    const { input, value } = this.props;
    return value || (input && input.value);
  }

  handleChange = (value, event) => {
    const { disabled, input, onChange, id } = this.props;
    if (disabled) {
      return;
    }
    if (event) {
      event.preventDefault();
    }
    if (input && input.onChange) {
      input.onChange(value);
    }
    if (onChange) {
      onChange(value, event, id);
    }
  };

  handleDragStart = (clientX) => {
    this.setState({ startX: clientX, hasOutline: true });
  };

  handleDrag = (clientX) => {
    const { startX } = this.state;
    const startPos = this.getValue() ? checkedPos : uncheckedPos;
    const newPos = startPos + clientX - startX;
    const pos = Math.min(checkedPos, Math.max(uncheckedPos, newPos));
    this.setState({ pos, isDragging: true });
  };

  handleDragStop = (event) => {
    const { pos, isDragging } = this.state;

    // Simulate clicking the handle
    if (!isDragging) {
      this.setState({ startX: null, hasOutline: false });
      this.handleChange(!this.getValue(), event);
      return;
    }
    if (this.getValue()) {
      if (pos > (checkedPos + uncheckedPos) / 2) {
        this.setState({
          pos: checkedPos,
          startX: null,
          isDragging: false,
          hasOutline: false,
        });
        return;
      }
      this.setState({
        pos: uncheckedPos,
        startX: null,
        isDragging: false,
        hasOutline: false,
      });
      this.handleChange(false, event);
      return;
    }
    if (pos < (checkedPos + uncheckedPos) / 2) {
      this.setState({
        pos: uncheckedPos,
        startX: null,
        isDragging: false,
        hasOutline: false,
      });
      return;
    }
    this.setState({
      pos: checkedPos,
      startX: null,
      isDragging: false,
      hasOutline: false,
    });
    window.setTimeout(() => {
      // If the value has not changed after a small delay, this means the update was rejected
      // (e.g. the validation failed), so we reset the switch position.
      // The delay avoids flickering while the value is set.
      if (!this.getValue()) {
        this.setState({
          pos: uncheckedPos,
        });
      }
    }, 200);
    this.handleChange(true, event);
  };

  handleMouseDown = (event) => {
    event.target.focus();

    // Ignore right click and scroll
    if (typeof event.button === 'number' && event.button !== 0) {
      return;
    }

    this.handleDragStart(event.clientX);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (event) => {
    event.preventDefault();
    this.handleDrag(event.clientX);
  };

  handleMouseUp = (event) => {
    this.handleDragStop(event);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  handleTouchStart = (event) => {
    event.target.focus();
    this.handleDragStart(event.touches[0].clientX);
  };

  handleTouchMove = (event) => {
    this.handleDrag(event.touches[0].clientX);
  };

  handleTouchEnd = (event) => {
    event.preventDefault();
    this.handleDragStop(event);
  };

  handleTouchCancel = () => {
    this.setState({ startX: null, hasOutline: false });
  };

  handleClick = (event) => {
    this.handleChange(!this.getValue(), event);
  };

  handleKeyDown = (event) => {
    const { isDragging } = this.state;
    // Trigger change on spacebar and enter keys (in violation of wai-aria spec).
    if ((event.keyCode === 32 || event.keyCode === 13) && !isDragging) {
      event.preventDefault();
      this.handleChange(!this.getValue(), event);
    }
  };

  render() {
    const { disabled, id, showLock, labelOn, labelOff, className } = this.props;

    const { pos, hasOutline } = this.state;

    return (
      <div
        className={classNames('switch2', {
          on: this.getValue(),
          lock: showLock,
          disabled,
          [className]: className,
        })}
      >
        {labelOn && (
          <label className="switch2-label" htmlFor={id}>
            {this.getValue() ? labelOn : labelOff || labelOn}
          </label>
        )}
        <div className="switch2-root">
          <div className="switch2-bg">
            <button id={id} className="switch2-button" onClick={this.handleClick} disabled={disabled} type="button" />
          </div>
          <div
            className={classNames('switch2-handle', hasOutline && 'outline')}
            role="checkbox"
            tabIndex={disabled ? null : 0}
            onMouseDown={disabled ? null : this.handleMouseDown}
            onTouchStart={disabled ? null : this.handleTouchStart}
            onTouchMove={disabled ? null : this.handleTouchMove}
            onTouchEnd={disabled ? null : this.handleTouchEnd}
            onTouchCancel={disabled ? null : this.handleTouchCancel}
            onKeyDown={this.handleKeyDown}
            onFocus={() => this.setState({ hasOutline: true })}
            onBlur={() => this.setState({ hasOutline: false })}
            style={{ transform: `translateX(${pos}px)` }}
            aria-checked={this.getValue()}
            aria-disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

Switch2.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  showLock: PropTypes.bool,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  className: PropTypes.string,
};

Switch2.defaultProps = {
  disabled: false,
  id: null,
  showLock: false,
  labelOn: 'ON',
  labelOff: '',
};

export default Switch2;
