import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import Checkbox, { CheckboxProps } from './Checkbox';
import './multiCheckboxStyles.scss';
import { AppAction } from '../../store/store';
import { BaseFieldProps } from 'redux-form/lib/Field';

export type Option<O> = {
  label: string;
  value: O;
};

type MultiCheckboxProps<T, O> = Omit<CheckboxProps, 'on' | 'onClick' | 'label'> &
  BaseFieldProps & {
    saveAction: (wagon: T, propertiesToUpdate: object) => AppAction;
    data: T;
    options: Option<O>[];
    labelText: string;
  };

const MultiCheckbox = <T, O>({
  id,
  input,
  meta,
  options,
  disabled,
  saveAction,
  data,
  labelText,
  ...props
}: MultiCheckboxProps<T, O> & WrappedFieldProps) => {
  const { onChange } = input;
  const { touched, error } = meta;
  const inputValue = input.value;

  const checkboxes = options.map(({ label, value }, index) => {
    const onClick = (checked: boolean) => {
      const arr = [...inputValue];
      if (checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      if (saveAction) {
        meta.dispatch(saveAction(data, { [input.name]: arr }));
      }
      return onChange(arr);
    };
    const checked = inputValue.includes(value);
    return (
      <Checkbox
        key={index}
        on={checked}
        disabled={disabled}
        label={label}
        onClick={() => {
          if (!disabled) {
            onClick(!checked);
          }
        }}
        {...props}
      />
    );
  });

  return (
    <div className="multi-checkboxes">
      {labelText && <label htmlFor={id}>{labelText}</label>}
      <div>{checkboxes}</div>
      {touched && error && <p className="error">{error}</p>}
    </div>
  );
};

const MultiCheckboxFormComponent = <T, O>(props: MultiCheckboxProps<T, O>) => (
  // @ts-ignore
  <Field {...props} component={MultiCheckbox} />
);

export default MultiCheckboxFormComponent;
