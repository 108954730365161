/**
 * DamageReports top bar component
 */
import React from 'react';
import { connect } from 'react-redux';
import { addDamageReport } from '../damage-report/damageReportDucks';
import { loadDamageReports } from '../damage-reports-table/damageReportsDucks';
import DamageReportsFilters from '../../commons/components/damage-reports-filters/DamageReportsFilters';
import { getDamageReports, getOfflineState, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { DAMAGE_REPORT_WRITE } from '../../commons/security/userRoles';
import { damageReportsFiltersToParams } from '../../commons/filters/filters';
import apiHelper from '../../api/apiHelper';
import { useViewportWidth } from '../../commons/responsive/hooks';
import classNames from 'classnames';

const DamageReportsTopBar = ({ roles, loadDamageReportsStart, addDamageReportStart, filters, loading, offline }) => {
  const { isMobile, isTablet } = useViewportWidth();

  const exportParams = apiHelper.toQueryString(damageReportsFiltersToParams(filters));
  const exportHref = `/api/damageReports/export?${exportParams}`;

  return (
    <div className="topbar">
      <h1 className="title">PVCA</h1>
      {!offline && loading && <div className="loader" />}
      {!offline && !loading && (
        <button className="btn btn-round refresh" onClick={loadDamageReportsStart} type="button">
          Rafraîchir
        </button>
      )}
      <DamageReportsFilters />
      {!offline && (
        <a
          className={classNames('btn xls', isMobile ? 'btn-round' : 'btn-link btn-icon')}
          href={exportHref}
          target="_blank"
          rel="noreferrer"
        >
          Exporter
        </a>
      )}
      {roles.includes(DAMAGE_REPORT_WRITE) && (
        <button
          className={classNames('btn btn-success', isMobile && 'btn-round plus')}
          onClick={() => addDamageReportStart(true, null, null)}
          type="button"
        >
          {isTablet ? 'Ajouter' : 'Ouvrir un PVCA'}
        </button>
      )}
    </div>
  );
};

const selector = (state) => ({
  offline: getOfflineState(state),
  roles: selectSecurityContextRoles(state),
  filters: getDamageReports(state).filters,
});

export default connect(selector, {
  addDamageReportStart: addDamageReport,
  loadDamageReportsStart: loadDamageReports,
})(DamageReportsTopBar);
