import AbstractTemplateCache from './AbstractTemplateCache';
import { RailwayCompany } from '../model/templates';
import { Tx } from './AbstractDbCache';
import { useEffect, useState } from 'react';

async function sortByLabel(itemsPromise: Promise<RailwayCompany[]>) {
  const items = await itemsPromise;
  items.sort((company1, company2) => company1.label.localeCompare(company2.label));
  return items;
}

class RailwayCompanyCache extends AbstractTemplateCache<'railwayCompany'> {
  protected getStoreName(): 'railwayCompany' {
    return 'railwayCompany';
  }

  protected validateInput(inputValue: string): boolean {
    return true;
  }

  protected findTemplates(inputValue: string, tx: Tx<'railwayCompany'>) {
    if (!inputValue) {
      return sortByLabel(this.findAllItems());
    }
    const normalizedInputValue = this.normalizeInput(inputValue);
    return sortByLabel(
      this.findItemsByMatcher(tx, (company) => company.label.toUpperCase().includes(normalizedInputValue)),
    );
  }

  public formatItem(item: RailwayCompany): string {
    return item.label;
  }
}

const railwayCompanyCache = new RailwayCompanyCache();

export const useRailwayCompanies = () => {
  const [companies, setCompanies] = useState<RailwayCompany[]>([]);
  useEffect(() => {
    (async () => {
      setCompanies(await sortByLabel(railwayCompanyCache.findAllItems()));
    })();
  }, []);
  return {
    companies,
    getRailwayCompanyName: (companyId: string): string =>
      companies.find((company) => company.id === companyId)?.label ?? companyId,
  };
};

export default railwayCompanyCache;
