import React from 'react';
import classNames from 'classnames';
import { getToast } from '../../selectors/selectors';
import { stopToast } from './toastDucks';
import './toastStyles.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

/**
 * Toast component
 */
const Toast = () => {
  const dispatch = useAppDispatch();
  const toast = useAppSelector(getToast);

  if (toast.hidden) {
    return <span />;
  }
  const toastTextLines = toast.text.split('\n');
  return (
    <div
      className={classNames(`toast ${toast.className}`, { off: toast.hidden, left: toast.left, higher: toast.higher })}
      onClick={() => dispatch(stopToast())}
    >
      {toastTextLines.map((item, index) => (
        <span key={`toastTextLine-${index}`}>
          {item}
          {toastTextLines.length !== index + 1 && <br />}
        </span>
      ))}
    </div>
  );
};

export default Toast;
