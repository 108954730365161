import helpers from '../helpers/helpers';

/**
 * ISO-8601-formatted date
 */
export type DateString = string;

export type ValidationStatus = 'EMPTY' | 'NOT_VALIDATED' | 'VALIDATED';

export const VALIDATION_STATUSES: Record<ValidationStatus, ValidationStatus> = {
  EMPTY: 'EMPTY',
  NOT_VALIDATED: 'NOT_VALIDATED',
  VALIDATED: 'VALIDATED',
};

export type TractionMode = 'DIESEL' | 'ELECTRIC';

export type Company = 'CTF' | 'ECORAIL';
export type NoneCompanyOrALL = 'NONE' | 'ALL';

export const CompanyOptions: Record<Company, string> = {
  CTF: 'Captrain France',
  ECORAIL: 'Ecorail',
};
export const NoneableCompanyOptions: Record<Company | NoneCompanyOrALL, string> = {
  CTF: 'Captrain France',
  ECORAIL: 'Ecorail',
  NONE: 'Aucun',
  ALL: 'Tous',
};

export type EntityBase<ID extends number | string> = Readonly<{
  id: ID;
  creationDate: DateString;
  updateDate: DateString;
}>;

export type VersionedEntityBase<ID extends number | string> = EntityBase<ID> &
  Readonly<{
    version: number;
  }>;

export type IdName<ID extends number | string> = Readonly<{
  id: ID;
  name: string;
}>;

export type Labelled = { label: string };

export type SignatureDate = Readonly<{
  date: DateString;
}>;

export type SignatureUser = Readonly<{
  userId: number;
  userName: string;
  userEmail: string;
}>;

export type Signature = SignatureDate & SignatureUser;

export type OfflineSignature = SignatureDate & {
  offline: true;
};

export type SignatureOrOffline<S extends Signature = Signature> = S | (OfflineSignature & Omit<S, keyof SignatureUser>);

export const isOfflineSignature = (signature: SignatureOrOffline<any>): signature is OfflineSignature =>
  (signature as OfflineSignature).offline;

export const createOfflineSignature = <S extends Signature>(
  content: Omit<S, keyof SignatureUser>,
): OfflineSignature & Omit<S, keyof SignatureUser> => ({ offline: true, ...content });

export const getStatusClasses = ({ status }: { status: ValidationStatus }) => ({
  validated: status === 'VALIDATED',
  'not-validated': status === 'NOT_VALIDATED',
});

export const formatOptionalValue = (value?: string | null) => value ?? '-';

export const formatDecimalNumber = (number: number | null, unit: string) =>
  number ? `${helpers.decimalFormat(number)} ${unit}` : '—';

export const formatIntegerNumber = (number: number | null, unit: string) =>
  number ? `${helpers.integerFormat(Math.ceil(number))} ${unit}` : '—';

export const getDamageReportCompanyLabel = (company: Company) => {
  return CompanyOptions[company];
};
