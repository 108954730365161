import React from 'react';
import { Field, reduxForm } from 'redux-form';

import DateTimeField from '../../../commons/components/input/DateTimeField';
import InputWithTextIcon from '../../../commons/components/input/InputWithTextIcon';
import StationField from '../../../commons/components/input/StationField';
import { requestCreatePreAdvice, requestUpdatePreAdvice } from '../preAdviceDucks';
import PreAdviceBorderCrossingDateFields from './PreAdviceBorderCrossingDateFields';
import { formName } from '../preAdviceFormUtils';
import { asyncValidateStation, asyncValidation } from '../../../commons/components/input/asyncValidation';
import RailwayCompanyField from '../../../commons/components/input/RailwayCompanyField';
import { CAPTRAIN_FRANCE_ID } from '../../../commons/model/templates';

const PreAdviceFieldsForm = ({ preAdvice, touch }) => {
  const saveAction = preAdvice.id ? requestUpdatePreAdvice : requestCreatePreAdvice;
  return (
    <form className="inputs" autoComplete="off">
      <StationField
        name="journey.startStation.label"
        className="icon route-from"
        placeholder="De"
        disabled={false}
        saveAction={saveAction}
        data={preAdvice}
      />
      <StationField
        name="journey.endStation.label"
        className="icon route-to"
        data={preAdvice}
        disabled={false}
        placeholder="À"
        saveAction={saveAction}
      />
      <DateTimeField
        name="journey.startDateTime"
        className="icon"
        data={preAdvice}
        disabled={false}
        placeholder="Heure de départ"
        inputReadOnly={true}
        saveAction={saveAction}
        timeFormat="HH:mm"
        touch={touch}
      />
      <RailwayCompanyField
        data={preAdvice}
        disabled={false}
        name="receiver.id"
        iconText="EF"
        placeholder="EF réceptrice"
        saveAction={saveAction}
        blacklist={[CAPTRAIN_FRANCE_ID]}
      />
      <Field
        className="icon"
        component={InputWithTextIcon}
        data={preAdvice}
        inputMode="numeric"
        label="N°"
        placeholder="Train"
        minLength={5}
        maxLength={6}
        name="trainNumber"
        pattern="[0-9]*" // iPad numeric keyboard
        saveAction={saveAction}
      />
      {preAdvice.id && (
        <>
          <h2 className="subtitle">Passage à la frontière</h2>
          <StationField
            className="icon"
            component={InputWithTextIcon}
            data={preAdvice}
            disabled={false}
            label="À"
            name="borderCrossing.station.label"
            placeholder="Gare de passage"
            saveAction={saveAction}
          />
          <PreAdviceBorderCrossingDateFields preAdvice={preAdvice} touch={touch} />
        </>
      )}
    </form>
  );
};

export default reduxForm({
  form: formName,
  ...asyncValidation({
    'journey.startStation.label': asyncValidateStation,
    'journey.endStation.label': asyncValidateStation,
    'borderCrossing.station.label': asyncValidateStation,
  }),
})(PreAdviceFieldsForm);
