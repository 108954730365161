import PreAdviceEntity from '../commons/entity/PreAdviceEntity';

const sortedStatuses = [
  PreAdviceEntity.STATUSES.EMPTY,
  PreAdviceEntity.STATUSES.NOT_VALIDATED,
  PreAdviceEntity.STATUSES.VALIDATED,
];

const sortByField = (direction, field) => (preAdvice1, preAdvice2) =>
  direction * preAdvice1[field].localeCompare(preAdvice2[field]);

const sortByStatus = (direction) => (preAdvice1, preAdvice2) => {
  const result = direction * (sortedStatuses.indexOf(preAdvice1.status) - sortedStatuses.indexOf(preAdvice2.status));
  return result === 0 ? sortByField(direction, 'creationDateTime')(preAdvice1, preAdvice2) : result;
};

export default ({ field, direction }) => {
  if (field === 'status') {
    return sortByStatus(direction);
  }
  return (preAdvice1, preAdvice2) => {
    const result = sortByField(direction, field)(preAdvice1, preAdvice2);
    return result === 0 ? sortByField(direction, 'creationDateTime')(preAdvice1, preAdvice2) : result;
  };
};
