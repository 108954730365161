/**
 * Filters top bar component
 *
 */
import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import Input from '../input/Input';
import StationField from '../input/StationField';
import MarketSelect from '../market-select/MarketSelect';
import DateIntervalFilters from './DateIntervalFilters';
import CheckButton from '../check-button/CheckButton';
import { TrainFiltersFormValues, trainFiltersToFormInitialValues } from '../../filters/filters';
import { getOfflineState, selectTrainSummaries } from '../../selectors/selectors';
import {
  initializeLazyTrainFilters,
  resetTrainFilters,
  updateTrainFilters,
} from '../../../trains/trains-table/trainSummariesDucks';
import './filtersStyles.scss';
import { asyncValidateStation, asyncValidation } from '../input/asyncValidation';
import { RootState } from '../../reducers/rootReducer';
import { useAppDispatch } from '../../store/hooks';
import { useViewportWidth } from '../../responsive/hooks';
import classNames from 'classnames';
import ResetFiltersButton from './ResetFiltersButton';

type FiltersProps = InjectedFormProps<TrainFiltersFormValues>;

/**
 * By default (e.g. the user has never changed the "from" date filter), the
 * lower bound of the date interval is three days ago.
 *
 * @returns {moment.Moment}
 */
const getDefaultFromDate = () => {
  return moment().add(-3, 'days').startOf('day').local();
};

/**
 * By default (e.g. the user has never changed the "to" date filter), the
 * upper bound of the date interval is three days from now.
 *
 * This value will be overridden if the date interval is wider than
 * {@link maxDateInterval}.
 *
 * @returns {moment.Moment}
 */
const getDefaultToDate = () => {
  return moment().add(3, 'days').startOf('day').local();
};

const maxDateInterval = moment.duration(1, 'months');

const TrainFilters = ({ change, handleSubmit }: FiltersProps) => {
  const dispatch = useAppDispatch();
  const offline: boolean = useSelector(getOfflineState);
  const { isMobile } = useViewportWidth();

  const { filters } = useSelector(selectTrainSummaries);
  const filtersValues = useSelector(getFormValues('filtersForm')) as TrainFiltersFormValues;

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    dispatch(initializeLazyTrainFilters());
  }, [dispatch]);

  const onOpen = () => {
    if (filters.defaultFromDate) {
      change('fromDate', getDefaultFromDate().toDate());
    }
    if (filters.defaultToDate) {
      /*
       * The "to" date value is the first date in chronological order between :
       *   - the default "to" date ;
       *   - one month after the "from" date.
       *
       * The comparison between these dates is required if the user fixed the "from"
       * date but not the "to" date. In this case, the date interval will increase
       * day ofter day until equaling the maximal interval (one month).
       */
      const defaultToDate = getDefaultToDate();
      const maxToDate = computeMaxToDateMoment();
      const toDate = defaultToDate.isSameOrBefore(maxToDate) ? defaultToDate : maxToDate;
      change('toDate', toDate.toDate());
    }

    setIsOpened(true);
  };

  const handleClickOutside = () => {
    setIsOpened(false);
  };

  const computeMaxToDateMoment = () => {
    return moment(filtersValues.fromDate).add(maxDateInterval);
  };

  return (
    <div className="filters">
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data: Partial<TrainFiltersFormValues>) => {
          dispatch(updateTrainFilters(null, data));
        })}
      >
        <Field
          component={Input}
          name="trainNumber"
          disabled={offline}
          placeholder={isMobile ? 'Numéro' : 'Filtrer par n°'}
          maxLength={6}
          pattern="[0-9]*" // iPad numeric keyboard
          inputMode="numeric"
          saveAction={updateTrainFilters}
          onChange={(_event?: ChangeEvent, trainNumber?: any) => {
            if (!trainNumber || trainNumber.length >= 5) {
              dispatch(updateTrainFilters(null, { trainNumber }));
            }
          }}
        />
        <div>
          <button
            className={classNames('btn filter', isMobile ? 'btn-round' : 'btn-link btn-icon')}
            type="button"
            onClick={onOpen}
          >
            Autres filtres
          </button>
          {isOpened && (
            <div className="overlay">
              <div className="overlay-fade" onClick={handleClickOutside} />
              <div className="filters-dropdown">
                <StationField
                  name="startStation.label"
                  disabled={offline}
                  className="icon route-from"
                  placeholder="Départ"
                  saveAction={updateTrainFilters}
                />
                <StationField
                  name="endStation.label"
                  disabled={offline}
                  className="icon route-to"
                  placeholder="Arrivée"
                  saveAction={updateTrainFilters}
                />
                <DateIntervalFilters
                  changeFormValue={change}
                  currentFromDateValue={filtersValues.fromDate}
                  currentToDateValue={filtersValues.toDate}
                  maxIntervalWidth={moment.duration(1, 'months')}
                  offline={offline}
                  reloadListAction={updateTrainFilters}
                />
                <Field
                  component={MarketSelect}
                  id="user-markets"
                  name="markets"
                  disabled={offline}
                  className="icon star"
                  placeholder="Marchés"
                  saveAction={updateTrainFilters}
                />
                <div className="btn-group">
                  <Field
                    component={CheckButton}
                    id="filter-status-empty"
                    name="statusEmpty"
                    disabled={offline}
                    className="btn state-1"
                    label="Pas de composition"
                    saveAction={updateTrainFilters}
                  />
                  <Field
                    component={CheckButton}
                    id="filter-status-nv"
                    name="statusNotValidated"
                    disabled={offline}
                    className="btn state-2"
                    label="Non validée"
                    saveAction={updateTrainFilters}
                  />
                  <Field
                    component={CheckButton}
                    id="filter-status-validated"
                    name="statusValidated"
                    disabled={offline}
                    className="btn state-3"
                    label="Validée"
                    saveAction={updateTrainFilters}
                  />
                </div>
                <ResetFiltersButton onClick={() => dispatch(resetTrainFilters())} disabled={offline} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { filters } = selectTrainSummaries(state);
  return {
    initialValues: trainFiltersToFormInitialValues(filters),
    ...asyncValidation({
      'startStation.label': asyncValidateStation,
      'endStation.label': asyncValidateStation,
    }),
  };
};

const FiltersForm = reduxForm<TrainFiltersFormValues>({
  form: 'filtersForm',
})(TrainFilters);

export default connect(mapStateToProps)(FiltersForm as any);
