import { BrakingBulletinCompositionSummary } from '../../../commons/model/BrakingBulletin';
import Checkbox from '../../../commons/components/checkbox/Checkbox';
import DisabledInput from '../../../commons/components/input/DisabledInput';
import FormSection from '../../../commons/components/form/FormSection';
import helpers from '../../../commons/helpers/helpers';
import List from '../../../commons/components/list/List';
import { formatOptionalValue } from '../../../commons/model/common';
import useIndiceComposition from './indice-composition/useIndiceComposition';

type CompositionSummaryProps = {
  compositionSummary: BrakingBulletinCompositionSummary;
};

const CompositionSummarySection = ({ compositionSummary }: CompositionSummaryProps) => {
  const { indiceCompositionOfBrakingBulletin } = useIndiceComposition(compositionSummary);

  return (
    <FormSection title="Rappel de la composition" className="braking-bulletin-composition">
      <List
        items={[{ key: 'Indice de composition', value: formatOptionalValue(indiceCompositionOfBrakingBulletin()) }]}
      />
      <div className="composition-flags">
        <Checkbox on={compositionSummary.hasChargeD} disabled={true} label="Charge D" />
        <Checkbox on={compositionSummary.hasATE} disabled={true} label="ATE" />
        <Checkbox on={compositionSummary.hasGbGauge} disabled={true} label="Gabarit GB" />
        <Checkbox on={compositionSummary.hasDangerousGoods} disabled={true} label="MD" />
      </div>
      <table className="composition-stats">
        <thead>
          <tr>
            <th></th>
            <th>Nb</th>
            <th>Longueur</th>
            <th>Masse</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Wagons</th>
            <td>
              <DisabledInput value={helpers.integerFormat(compositionSummary.vehiclesNumber)} className="right" />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.vehiclesLength)}
                unit="m"
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.vehiclesWeight)}
                unit="t"
                className="right"
              />
            </td>
          </tr>
          <tr>
            <th>EM remorque</th>
            <td>
              <DisabledInput
                value={helpers.integerFormat(compositionSummary.tractionEnginesNumber)}
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.tractionEnginesLength)}
                unit="m"
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.tractionEnginesWeight)}
                unit="t"
                className="right"
              />
            </td>
          </tr>
          <tr>
            <th>EM véhicule</th>
            <td>
              <DisabledInput value={helpers.integerFormat(compositionSummary.vehicleEnginesNumber)} className="right" />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.vehicleEnginesLength)}
                unit="m"
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.vehicleEnginesWeight)}
                unit="t"
                className="right"
              />
            </td>
          </tr>
          <tr>
            <th>EM pousse</th>
            <td>
              <DisabledInput value={helpers.integerFormat(compositionSummary.pushEnginesNumber)} className="right" />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.pushEnginesLength)}
                unit="m"
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.decimalFormat(compositionSummary.pushEnginesWeight)}
                unit="t"
                className="right"
              />
            </td>
          </tr>
          <tr>
            <th>Total</th>
            <td>
              <DisabledInput
                value={helpers.integerFormat(
                  compositionSummary.vehiclesNumber +
                    compositionSummary.tractionEnginesNumber +
                    compositionSummary.vehicleEnginesNumber +
                    compositionSummary.pushEnginesNumber,
                )}
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.integerFormat(
                  Math.ceil(
                    compositionSummary.vehiclesLength +
                      compositionSummary.tractionEnginesLength +
                      compositionSummary.vehicleEnginesLength +
                      compositionSummary.pushEnginesLength,
                  ),
                )}
                unit="m"
                className="right"
              />
            </td>
            <td>
              <DisabledInput
                value={helpers.integerFormat(
                  Math.ceil(
                    compositionSummary.vehiclesWeight +
                      compositionSummary.tractionEnginesWeight +
                      compositionSummary.vehicleEnginesWeight +
                      compositionSummary.pushEnginesWeight,
                  ),
                )}
                unit="t"
                className="right"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FormSection>
  );
};

export default CompositionSummarySection;
