import React from 'react';

import { DAMAGE_REPORT_VALIDATE, DAMAGE_REPORT_WRITE } from '../../../commons/security/userRoles';
import { askForValidation, deactivateDamageReport, deleteDamageReport, validate } from '../damageReportDucks';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import classNames from 'classnames';
import { useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';

const DamageReportActions = ({ roles, damageReport, offline, openConfirmDialogStart }) => {
  const { isMobile } = useViewportWidth();
  const currentUserCompany = useAppSelector(selectSecurityContext).company;
  const canEdit = roles.includes(DAMAGE_REPORT_WRITE) && damageReport.company === currentUserCompany;
  const canValidate = roles.includes(DAMAGE_REPORT_VALIDATE) && damageReport.company === currentUserCompany;

  const openDialogAction =
    (title, actionText, action, actionClass = 'success') =>
    () => {
      openConfirmDialogStart({ title, actionText, action, actionClass });
    };

  const askAction = {
    buttonText: 'Demander la validation',
    onClick: openDialogAction(
      'Vous êtes sur le point de demander la validation du PVCA. Confirmez-vous ?',
      'Demander la validation',
      () => askForValidation(),
    ),
    classes: 'btn btn-success',
  };

  const validateAction = {
    buttonText: 'Valider',
    onClick: openDialogAction('Vous êtes sur le point de valider le PVCA. Confirmez-vous ?', 'Valider', () =>
      validate(),
    ),
    classes: 'btn btn-success',
  };

  const deleteAction = {
    buttonText: '',
    onClick: openDialogAction(
      'Vous êtes sur le point de supprimer le PVCA. Confirmez-vous ?',
      'Supprimer',
      () => deleteDamageReport(damageReport),
      'danger',
    ),
    classes: 'btn btn-round remove',
  };

  const deactivateAction = {
    buttonText: 'Désactiver',
    onClick: openDialogAction(
      'Vous êtes sur le point de désactiver le PVCA. Confirmez-vous ?',
      'Désactiver',
      () => deactivateDamageReport(damageReport),
      'danger',
    ),
    classes: 'btn btn-deactivate btn-icon',
  };

  const actions = [];

  if (damageReport.isStatusNewOrInProgress()) {
    if (canEdit && !canValidate) {
      actions.push(askAction);
      actions.push(deleteAction);
    }
    if (canEdit && canValidate) {
      actions.push(validateAction);
      actions.push(deleteAction);
    }
  }
  if (damageReport.isStatusSubmitted() && canValidate) {
    actions.push(validateAction);
    actions.push(deleteAction);
  }
  if (damageReport.isStatusValidated() && canValidate) {
    actions.push(deactivateAction);
  }

  return (
    <div className="damage-report-header-actions">
      {actions.map((action) => (
        <button key={action.buttonText} className={action.classes} onClick={action.onClick} type="button">
          {action.buttonText}
        </button>
      ))}
      {!offline && (
        <a
          className={classNames('btn pdf', isMobile ? 'btn-round' : 'btn-link btn-icon')}
          href={`/api/damageReports/print/${damageReport.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Télécharger le PVCA
        </a>
      )}
    </div>
  );
};

export default DamageReportActions;
