import React from 'react';
import PropTypes from 'prop-types';
import PreAdviceWagonImportErrorEntity, { errorTypes } from '../../../commons/entity/PreAdviceWagonImportErrorEntity';

export const getUnknownDamageErrorLabel = (error) =>
  `Une avarie n'a pas été reconnue (code : ${error.code ?? 'code manquant'})`;

export const formatDamageTemplateIds = (damageTemplateIds) => {
  // noinspection JSUnresolvedVariable
  if (Intl.ListFormat) {
    // noinspection JSUnresolvedFunction
    return new Intl.ListFormat('fr', { style: 'long', type: 'disjunction' }).format(damageTemplateIds);
  }
  return damageTemplateIds.join(', ');
};

export const getAmbiguousDamageCodeErrorLabel = (error) =>
  `Une avarie n'a pas pu être identifiée de manière certaine. ` +
  `Il peut s'agir de l'une des avaries suivantes: ${formatDamageTemplateIds(error.possibleDamageTemplateIds)}. ` +
  `(code reçu ${error.code})`;

export const getErrorLabel = (error) => {
  if (error.type === errorTypes.DAMAGE) {
    return getUnknownDamageErrorLabel(error);
  }
  return getAmbiguousDamageCodeErrorLabel(error);
};

const PreAdviceWagonUnknownDamageErrors = ({ errors }) => (
  <div className="pre-advice-wagon-import-errors pre-advice-wagon-warning">
    {errors.map((error) => (
      <div key={error.id}>{getErrorLabel(error)}</div>
    ))}
  </div>
);

PreAdviceWagonUnknownDamageErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.instanceOf(PreAdviceWagonImportErrorEntity)).isRequired,
};

export default PreAdviceWagonUnknownDamageErrors;
