import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PreAdviceFilters from './PreAdviceFilters';
import { useViewportWidth } from '../../commons/responsive/hooks';
import classNames from 'classnames';
import { requestUnloadPreAdvice } from '../pre-advice/preAdviceDucks';
import { useAppDispatch } from '../../commons/store/hooks';

const PreAdvicesTopBar = ({ canCreatePreAdvices, loading, loadPreAdviceSummaries }) => {
  const { isMobile } = useViewportWidth();
  const dispatch = useAppDispatch();
  return (
    <div className="topbar">
      <h1 className="title">Pré-annonces</h1>
      {loading ? (
        <div className="loader" />
      ) : (
        <button className="btn btn-round refresh" onClick={loadPreAdviceSummaries} type="button">
          Rafraîchir
        </button>
      )}
      <PreAdviceFilters loadPreAdviceSummaries={loadPreAdviceSummaries} />
      {canCreatePreAdvices && (
        <Link
          className={classNames('btn btn-success', isMobile && 'btn-round plus')}
          to="/pre-advices/new"
          onClick={() => {
            dispatch(requestUnloadPreAdvice());
          }}
        >
          Ajouter
        </Link>
      )}
    </div>
  );
};

PreAdvicesTopBar.propTypes = {
  canCreatePreAdvices: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadPreAdviceSummaries: PropTypes.func.isRequired,
};

export default PreAdvicesTopBar;
