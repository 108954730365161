import React from 'react';
import { getSentMessages } from '../../commons/selectors/selectors';
import { startGetSentMessages } from './sentMessagesDucks';
import SentMessagesFilters from './filters/SentMessagesFilters';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';

const SentMessagesTopBar = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(getSentMessages);

  const handleLoadMessages = () => dispatch(startGetSentMessages());

  return (
    <div className="topbar">
      <h1 className="title">Messages envoyés</h1>
      {loading ? (
        <div className="loader" />
      ) : (
        <button className="btn btn-round refresh" onClick={handleLoadMessages} type="button">
          Rafraîchir
        </button>
      )}
      <SentMessagesFilters />
    </div>
  );
};

export default SentMessagesTopBar;
