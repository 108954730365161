import FormSection from '../../../commons/components/form/FormSection';
import TraceabilityUtils from '../../../commons/model/TraceabilityUtils';
import './traceabilityStyles.scss';
import { TrainStep } from '../../../commons/model/Train';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { showOverlay } from '../../../commons/components/overlay/overlayDucks';
import VehiclesUtils from '../../../commons/model/VehiclesUtils';
import { pdfTraceabilityUrl } from '../braking-bulletin/brakingBulletinActions';
import React, { MouseEvent } from 'react';
import { getOfflineState } from '../../../commons/selectors/selectors';

type TraceabilitySummaryProps = {
  step: TrainStep;
  editable: boolean;
};
const TraceabilitySummary = ({ step, editable }: TraceabilitySummaryProps) => {
  const { traceability, vehicles } = step;
  const partial = TraceabilityUtils.stepAtLeastHasPartiallySignedTraceability(step);
  const signed = TraceabilityUtils.stepHasSignedTraceability(step);
  const dispatch = useAppDispatch();
  const offline = useAppSelector(getOfflineState);

  const onOpen = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(showOverlay('traceability'));
  };

  return (
    <FormSection
      className={classNames('traceability-summary', {
        partial,
        signed,
      })}
      title={
        <>
          <div>Traçabilité</div>
          <div className="buttons">
            {!offline && signed && (
              <a
                className="btn btn-round pdf"
                href={pdfTraceabilityUrl(step)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                Télécharger
              </a>
            )}
            <button className={classNames('btn btn-round', editable ? 'edit' : 'view')} onClick={onOpen} type="button">
              {editable ? 'Modifier' : 'Voir'}
            </button>
          </div>
        </>
      }
      collapsible={true}
      onClick={onOpen}
    >
      {!step.manualTraceability && partial && <div className="copied-message">Copiée de l'étape précédente</div>}
      <div className="statuses">
        {VehiclesUtils.getNbWagon(vehicles) > 0 && (
          <div className={classNames('status', { on: TraceabilityUtils.hasSignedRAT(traceability) })}>RAT</div>
        )}
        <div className={classNames('status', { on: TraceabilityUtils.hasSignedBrakeTest(traceability) })}>
          Essai de freins
        </div>
        <div className={classNames('status', { on: TraceabilityUtils.hasSignedRearSignaling(traceability) })}>
          Signalisation d'arrière
        </div>
        {VehiclesUtils.getNbWagonsOrVehicleEMs(vehicles) > 0 && (
          <div className={classNames('status', { on: TraceabilityUtils.hasSignedFirstLast(traceability) })}>
            Premier et dernier véhicules
          </div>
        )}
        {VehiclesUtils.hasATE(vehicles) && (
          <div className={classNames('status', { on: TraceabilityUtils.hasSignedATE(traceability) })}>
            Transport Exceptionnel
          </div>
        )}
        <div className={classNames('status', { on: TraceabilityUtils.hasSignedVisit(traceability) })}>
          Visite Technique d'Échange
        </div>
      </div>
    </FormSection>
  );
};

export default TraceabilitySummary;
