import { AppDispatch } from '../../../commons/store/store';
import { RootState } from '../../../commons/reducers/rootReducer';
import { selectTrainDiffs, selectTrainStep } from '../../../commons/selectors/selectors';
import { trainStepUpdated } from '../trainStepDucks';
import { applyTrainDiffs } from '../../../commons/model/TrainDiff';
import { offlineUpdateMissionEnd, persistTrainsIfNeeded } from '../../offline/offlineTrainsDucks';
import { TrainStep } from '../../../commons/model/Train';
import moment from 'moment';

export const afterMissionEndUpdated = (step: TrainStep) => (dispatch: AppDispatch, getState: () => RootState) => {
  const diffs = selectTrainDiffs(step.id)(getState());
  dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
  dispatch(persistTrainsIfNeeded());
};

export const signMissionEnd = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const step = selectTrainStep(getState()).data!;
  dispatch(
    offlineUpdateMissionEnd({
      stepId: step.id,
      sign: moment().toISOString(),
    }),
  );
  dispatch(afterMissionEndUpdated(step));
};

export const invalidateMissionEnd =
  (userId: number | null) => () => (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;
    dispatch(
      offlineUpdateMissionEnd({
        stepId: step.id,
        invalidate: { userId },
      }),
    );
    dispatch(afterMissionEndUpdated(step));
  };
