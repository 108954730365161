import React, { ReactNode } from 'react';
import TrainNbVehicles from './TrainNbVehicles';
import List from '../../../commons/components/list/List';
import { TrainStep } from '../../../commons/model/Train';
import TrainUtils from '../../../commons/model/TrainUtils';
import VehiclesUtils from '../../../commons/model/VehiclesUtils';
import warningImg from '../../../commons/assets/images/warning.svg';

export type DetailsPart = 'validation' | 'route' | 'dates' | 'market' | 'status' | 'composition' | 'agent' | 'vehicles';

type TrainDetailsSummaryProps = {
  step: TrainStep;
  parts: DetailsPart[];
};

type Field = {
  key: string;
  value: ReactNode;
  onlyIfValidated?: boolean;
};

/**
 * Return the array of fields to display.
 */
const getListFields = (step: TrainStep, parts: DetailsPart[]) => {
  const fields: Field[] = [];
  if (parts.includes('validation')) {
    fields.push(
      { key: 'Validée', value: TrainUtils.formatValidatedDate(step), onlyIfValidated: true },
      { key: 'Par', value: TrainUtils.formatValidatedBy(step), onlyIfValidated: true },
    );
  }
  if (parts.includes('route')) {
    fields.push({ key: 'Trajet', value: TrainUtils.formatRoute(step) });
  }
  if (parts.includes('dates')) {
    fields.push(
      { key: 'Départ', value: TrainUtils.formatStartDate(step) },
      { key: 'Arrivée théorique', value: TrainUtils.formatEndDate(step) },
    );
  }
  if (parts.includes('market')) {
    fields.push({ key: 'Marché', value: TrainUtils.formatMarketName(step) });
  }
  if (parts.includes('status') && TrainUtils.hasStepProblem(step)) {
    fields.push({
      key: 'Statut',
      value: (
        <>
          <img src={warningImg} alt="" /> {TrainUtils.formatTrainStatus(step)}
        </>
      ),
    });
  }
  if (parts.includes('composition')) {
    fields.push(
      { key: 'Indice de composition', value: TrainUtils.formatIndiceComposition(step) },
      { key: 'Locomotives', value: VehiclesUtils.getNbEngine(step.vehicles), onlyIfValidated: true },
      { key: 'Wagons', value: VehiclesUtils.getNbWagon(step.vehicles), onlyIfValidated: true },
      { key: 'Longueur', value: TrainUtils.formatTotalLength(step) },
      { key: 'Masse', value: TrainUtils.formatTotalWeight(step) },
      { key: 'Masse freinée réalisée', value: TrainUtils.formatTotalEffectiveBrakedWeight(step) },
      { key: 'Train sous ATE et/ou gabarit GB', value: TrainUtils.formatAteOrGauge(step) },
      { key: 'Matières dangereuses', value: TrainUtils.formatHazardousMaterials(step) },
    );
  }
  if (parts.includes('agent')) {
    fields.push({ key: 'Conducteur', value: TrainUtils.formatFirstAgent(step) });
  }
  if (parts.includes('vehicles')) {
    fields.push({ key: 'Véhicules validés', value: <TrainNbVehicles step={step} /> });
  }
  const trainIsValidated = TrainUtils.isStatusValidated(step);
  return fields.filter((field) => trainIsValidated || !field.onlyIfValidated);
};

const TrainStepSummaryList = ({ step, parts }: TrainDetailsSummaryProps) => {
  const fields = getListFields(step, parts);
  return <List items={fields} />;
};

export default TrainStepSummaryList;
