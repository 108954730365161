import React from 'react';
import { TrainStep } from '../../../commons/model/Train';
import VehiclesUtils from '../../../commons/model/VehiclesUtils';

type TrainNbVehiclesProps = {
  step: TrainStep;
};

/**
 * Number of vehicles in a train.
 */
const TrainNbVehicles = ({ step }: TrainNbVehiclesProps) => (
  <span>
    <span className="text-success">{VehiclesUtils.getNbValidatedVehicle(step.vehicles)}</span>
    <span> / </span>
    <span>{step.vehicles.length}</span>
  </span>
);

export default TrainNbVehicles;
