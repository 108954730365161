import moment from 'moment';
import { startToast } from '../toast/toastDucks';
import { ImportSourceCandidate, ImportSourceType } from '../../model/importSource';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiHelper from '../../../api/apiHelper';
import { CopyWagonsFormData } from './CopyWagonsForm';
import { DateString } from '../../model/common';
import { AppDispatch } from '../../store/store';

type CopyWagonsState = {
  importSourceCandidates: ImportSourceCandidate[];
};

type ImportSourceSearchCommand = {
  types: ImportSourceType[];
  startStationId?: string;
  endStationId?: string;
  lowerStartDateTime: DateString | null;
  upperStartDateTime: DateString | null;
  marketIds: number[] | null;
  trainNumber?: string;
};

const initialState: CopyWagonsState = {
  importSourceCandidates: [],
};

export const searchImportSources = createAsyncThunk<
  ImportSourceCandidate[],
  CopyWagonsFormData & { types: ImportSourceType[] },
  {
    dispatch: AppDispatch;
  }
>(
  'copyWagons/search',
  async ({ endStation, market, startDateTime, startStation, trainNumber, types }, { dispatch }) => {
    const params: ImportSourceSearchCommand = {
      trainNumber,
      lowerStartDateTime: moment(startDateTime).startOf('day').local().format(),
      upperStartDateTime: moment(startDateTime).startOf('day').add(1, 'days').subtract(1, 'seconds').local().format(),
      startStationId: startStation?.id,
      endStationId: endStation?.id,
      marketIds: market?.name && market?.id != null ? [market.id] : null,
      types,
    };
    try {
      return await apiHelper.get('/api/import-sources/candidates', params);
    } catch (e) {
      dispatch(startToast({ text: "Une erreur s'est produite pendant la recherche.", className: 'error' }));
      throw e;
    }
  },
);

const copyWagonsSlice = createSlice({
  name: 'copyWagons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchImportSources.fulfilled, (state, { payload }) => {
      state.importSourceCandidates = payload;
    });
  },
});
export default copyWagonsSlice.reducer;
