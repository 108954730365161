import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import AnnexesList from './AnnexesList';
import { addAnnexes } from '../../damageReportDucks';
import { getDamageReportPendingUploads, getOfflineState } from '../../../../commons/selectors/selectors';

import './damageReportAnnexesStyles.scss';

const AnnexesSection = ({ damageReport, readOnly }) => {
  const dispatch = useDispatch();
  const offline = useSelector(getOfflineState);
  const pendingUploads = useSelector(getDamageReportPendingUploads);

  const onDrop = (acceptedFiles) => dispatch(addAnnexes(acceptedFiles, damageReport.id));
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  return (
    <div className="section damage-report-annexes">
      <div className="title">Annexes</div>
      <AnnexesList damageReport={damageReport} offline={offline} pendingUploads={pendingUploads} readOnly={readOnly} />
      {!readOnly && !offline && (
        <div
          {...getRootProps()}
          className={classNames('drop-zone', {
            reject: isDragReject,
            accept: isDragAccept,
            active: isDragActive,
          })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <span>Déposer l'annexe ici</span>
          ) : (
            <span>
              Déposez une annexe ici,
              <br />
              ou cliquez pour sélectionner une annexe à ajouter
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default AnnexesSection;
