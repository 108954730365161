// import debugFactory from 'debug';
import AbstractOfflineEntity from '../entity/AbstractOfflineEntity';
import DamageReportEntity from '../entity/DamageReportEntity';

export const mergeDamageReport = (damageReportInCache, damageReportInPayload) => {
  if (damageReportInCache) {
    const damageReportData = {};
    if (damageReportInCache.isCacheMergeable(damageReportInPayload.version)) {
      Object.assign(damageReportData, damageReportInPayload);
    } else {
      Object.assign(damageReportData, damageReportInCache);
    }
    return new DamageReportEntity(damageReportData);
  }
  return new DamageReportEntity(damageReportInPayload);
};

// This method should probably be named differently, for example
// updateDamageReportsFromServerChanges, as it is tasked with
// reconciling the information from the cache with the information
// from the backend which could have changed when the app was offline.
// The name was kept for consistency with the existing train related method.
export const updateDamageReportsFromOfflineChanges = (cache, damageReports) => {
  let cacheUpdated = cache;

  for (const damageReport of damageReports) {
    const damageReportFromCache = cacheUpdated.loadByKeyFromCache(damageReport.id);
    const updatedDamageReport = { ...damageReportFromCache, ...damageReport };
    cacheUpdated = cacheUpdated.createUpdatedCache(updatedDamageReport);
  }

  return cacheUpdated;
};

const updateItemInCache = (responseMap, itemInRequest, itemFromCache, cacheUpdated) => {
  const itemForUpdatedCache = itemFromCache;
  if (responseMap.has(itemInRequest.id)) {
    // itemInRequest has been updated
    // or deletion has been refused because the damage report is already validated
    // or in error
    const itemFromResponse = responseMap.get(itemInRequest.id);
    if (itemFromResponse.error) {
      itemForUpdatedCache.markForUpdatingBackFailure();
    } else if (itemFromResponse.invalid) {
      itemForUpdatedCache.markInvalid();
    } else {
      itemForUpdatedCache.unMarkForUpdatingBack();
      itemForUpdatedCache.unMarkNew();
      itemForUpdatedCache.version = itemFromResponse.version;
      itemForUpdatedCache.number = itemFromResponse.number;
      itemForUpdatedCache.submittedBy = itemFromResponse.submittedBy;
      itemForUpdatedCache.submittedDate = itemFromResponse.submittedDate;
      itemForUpdatedCache.validatedDate = itemFromResponse.validatedDate;
    }
  } else {
    // the damage report has been deleted, we flag it as deleted.
    itemForUpdatedCache.markDeleted();
  }
  return cacheUpdated.createUpdatedCache(itemForUpdatedCache);
};

export const updateDamageReportsAfterPersist = (cache, damageReportsInRequest = [], damageReportsInResponse = []) => {
  let cacheUpdated = cache;
  const responseMap = AbstractOfflineEntity.toMap(damageReportsInResponse);

  for (const damageReportInRequest of damageReportsInRequest) {
    const damageReportFromCache = new DamageReportEntity({ ...cache.loadByKeyFromCache(damageReportInRequest.id) });
    cacheUpdated = updateItemInCache(responseMap, damageReportInRequest, damageReportFromCache, cacheUpdated);
  }
  return cacheUpdated;
};
