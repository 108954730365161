import debugFactory from 'debug';
import { authVerifier } from '../rest/securityAccessRest';
import { authFailure, authStart, authSuccess, login, userInfoStart } from '../security/securityContextDucks';
import { startToast } from '../components/toast/toastDucks';
import containerInjector from '../components/Container';
import { syncDirtyData } from '../../persist/backendSynchronizer';
import { INIT_START, INIT_SUCCESS_OFFLINE, INIT_SUCCESS_ONLINE, OFFLINE_CHANGE } from './appStateConstants';
import { AppDispatch, AppStore } from '../store/store';
import { selectSecurityContext } from '../selectors/selectors';

const debug = debugFactory('appState');

// ACTIONS
export const initSuccessOnline = (store: AppStore) => (dispatch: AppDispatch) => {
  dispatch({
    type: INIT_SUCCESS_ONLINE,
    payload: { offline: false },
  });

  dispatch(syncDirtyData());

  containerInjector(store);
};

export const initSuccessOffline = (store: AppStore) => (dispatch: AppDispatch) => {
  dispatch({
    type: INIT_SUCCESS_OFFLINE,
    payload: { offline: true },
  });

  containerInjector(store);
};

export const initStart = (store: AppStore) => async (dispatch: AppDispatch) => {
  dispatch({
    type: INIT_START,
  });

  window.addEventListener('focus', () => {
    debug('Refresh user infos');
    dispatch(userInfoStart());
  });

  const securityContext = selectSecurityContext(store.getState());
  if (securityContext.authenticated) {
    // Offline first
    dispatch(initSuccessOffline(store));

    if (navigator.onLine) {
      // is user really authenticated ? request to server side to verify...
      try {
        const response = await authVerifier();
        if (response.ok) {
          debug('Auth verifier OK');
          response.text().then(() => {
            dispatch(authSuccess());
            dispatch(initSuccessOnline(store));
          });
        } else if (response.status === 401) {
          debug('Auth verifier - 401 HTTP status - Clear session and trigger new login');
          await dispatch(login()); // Online and 401
        } else if (response.status > 500) {
          debug('Auth verifier Offline', response.status);
          // Server error or no network : offline mode
          dispatch(authSuccess());
          dispatch(initSuccessOffline(store));
        } else {
          debug('Auth verifier KO');
          await dispatch(authFailure());
          dispatch(initSuccessOnline(store));
        }
      } catch (err: any) {
        if (err.name && err.name === 'TypeMismatchError') {
          // ICPO-471 - A TypeMismatchError is thrown by Edge when returning a 401 Error
          // cf https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8653298/
          debug('Auth verifier KO (Edge)');
          await dispatch(authFailure());
          dispatch(initSuccessOnline(store));
        } else {
          debug('Auth verifier Offline', err);
          // No network : offline mode
          dispatch(authSuccess());
          dispatch(initSuccessOffline(store));
        }
      }
    } else {
      // Browser indicates offline mode
      debug('Offline mode - skip Auth verifier');
      dispatch(authSuccess());
      dispatch(initSuccessOffline(store));
    }
  } else {
    debug('Unauthenticated - try to authenticate');
    await dispatch(authStart(store));
  }
};

export const browserOffline = (isOffline: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: OFFLINE_CHANGE, payload: { offline: isOffline } });
  const text = isOffline ? 'déconnecté du' : 'connecté au';
  dispatch(startToast({ text: `Vous êtes ${text} réseau`, className: isOffline ? 'offline' : 'online' }));
};
