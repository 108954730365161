import React from 'react';
import PropTypes from 'prop-types';

import GlobalError from '../../commons/components/GlobalError';
import PreAdviceRow from './PreAdviceRow';
import PreAdvicesTableHeader from './PreAdvicesTableHeader';
import { preAdviceSummaryShape, sortDefinitionShape } from '../preAdvicesPropTypes';
import './preAdviceTableStyles.scss';
import ScrollableTableBody from '../../commons/components/table/ScrollableTableBody';
import { useRailwayCompanies } from '../../commons/templates/railwayCompanyCache';

const PreAdvicesTable = ({ error, loading, preAdviceSummaries, sortDefinition, updateSortDefinition }) => {
  const { getRailwayCompanyName } = useRailwayCompanies();

  if (error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération des pré-annonces.
      </GlobalError>
    );
  }
  if (preAdviceSummaries.length === 0) {
    return (
      <div className="maincontent">{!loading && <span>Aucune pré-annonce ne correspond à la recherche.</span>}</div>
    );
  }

  return (
    <div className="table table-trains table-pre-advices">
      <PreAdvicesTableHeader sortDefinition={sortDefinition} updateSort={updateSortDefinition} />
      <ScrollableTableBody>
        {preAdviceSummaries.map((summary) => () => (
          <PreAdviceRow key={summary.id} summary={summary} getRailwayCompanyName={getRailwayCompanyName} />
        ))}
      </ScrollableTableBody>
    </div>
  );
};

PreAdvicesTable.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  preAdviceSummaries: PropTypes.arrayOf(PropTypes.exact(preAdviceSummaryShape)).isRequired,
  sortDefinition: PropTypes.exact(sortDefinitionShape).isRequired,
  updateSortDefinition: PropTypes.func.isRequired,
};

export default PreAdvicesTable;
