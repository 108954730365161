import { TrainStep, TrainSummary } from './Train';
import { isWagon, Wagon } from './Vehicle';
import VehicleUtils from './VehicleUtils';
import {
  BrakingBulletin,
  BrakingBulletinCompositionSummary,
  BrakingBulletinIndiceComposition,
  BrakingBulletinObservation,
  COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES,
} from './BrakingBulletin';
import {
  BrakingBulletinFormData,
  BrakingBulletinSignFormData,
} from '../../trains/step/braking-bulletin/BrakingBulletinForm';
import { BrakingBulletinDiff } from './TrainDiff';
import { v4 as uuid } from 'uuid';
import VehiclesUtils from './VehiclesUtils';
import moment from 'moment';
import { indiceCompositionOverrideForNewBrakingBulletin } from '../../trains/step/braking-bulletin/indice-composition/indiceCompositionUtils';

const add = (acc: number, value: number | null | undefined) => acc + (value ?? 0);

const computeBrakingBulletinCompositionSummary = (step: TrainStep, ll: boolean): BrakingBulletinCompositionSummary => ({
  compositionIndex: step.indiceComposition,
  hasChargeD: VehiclesUtils.hasChargeD(step.vehicles),
  hasATE: VehiclesUtils.hasATE(step.vehicles),
  hasGbGauge: VehiclesUtils.hasGbGauge(step.vehicles),
  hasDangerousGoods: VehiclesUtils.hasDangerousGoods(step.vehicles),
  vehiclesNumber: step.vehicles.filter(isWagon).length,
  vehiclesLength: step.vehicles
    .filter(isWagon)
    .map((wagon) => wagon.length)
    .reduce(add, 0),
  vehiclesWeight: step.vehicles.filter(isWagon).map(VehicleUtils.totalWeight).reduce(add, 0) / 1000,
  vehiclesBrakedWeight: VehiclesUtils.computeWagonsEffectiveBrakedWeight(step.vehicles, ll),
  tractionEnginesNumber: step.vehicles.filter(VehicleUtils.isTractionEngine).length,
  tractionEnginesLength: step.vehicles
    .filter(VehicleUtils.isTractionEngine)
    .map((engine) => engine.length)
    .reduce(add, 0),
  tractionEnginesWeight:
    step.vehicles.filter(VehicleUtils.isTractionEngine).map(VehicleUtils.totalWeight).reduce(add, 0) / 1000,
  tractionEnginesBrakedWeight: step.vehicles
    .filter(VehicleUtils.isTractionEngine)
    .map((engine) => engine.effectiveBrakedWeight)
    .reduce(add, 0),
  vehicleEnginesNumber: step.vehicles.filter(VehicleUtils.isVehicleEngine).length,
  vehicleEnginesLength: step.vehicles
    .filter(VehicleUtils.isVehicleEngine)
    .map((engine) => engine.length)
    .reduce(add, 0),
  vehicleEnginesWeight:
    step.vehicles.filter(VehicleUtils.isVehicleEngine).map(VehicleUtils.totalWeight).reduce(add, 0) / 1000,
  vehicleEnginesBrakedWeight: step.vehicles
    .filter(VehicleUtils.isVehicleEngine)
    .map((engine) => engine.effectiveBrakedWeight)
    .reduce(add, 0),
  pushEnginesNumber: step.vehicles.filter(VehicleUtils.isPushEngine).length,
  pushEnginesLength: step.vehicles
    .filter(VehicleUtils.isPushEngine)
    .map((engine) => engine.length)
    .reduce(add, 0),
  pushEnginesWeight:
    step.vehicles.filter(VehicleUtils.isPushEngine).map(VehicleUtils.totalWeight).reduce(add, 0) / 1000,
  pushEnginesBrakedWeight: step.vehicles
    .filter(VehicleUtils.isPushEngine)
    .map((engine) => engine.effectiveBrakedWeight)
    .reduce(add, 0),
});

const computeDefaultObservations = (step: TrainStep): BrakingBulletinObservation[] => {
  const observations: BrakingBulletinObservation[] = [];
  if (VehiclesUtils.hasChargeD(step.vehicles)) {
    observations.push({
      type: 'CHARGE_D',
    });
  }
  if (VehiclesUtils.hasATE(step.vehicles)) {
    const ates = new Set(
      step.vehicles
        .filter((vehicle) => isWagon(vehicle) && vehicle.ateFileNumber)
        .map((wagon) => (wagon as Wagon).ateFileNumber),
    );
    ates.forEach((ate) => {
      observations.push({
        type: 'ATE',
        text1: ate,
      });
    });
  }
  if (VehiclesUtils.hasGbGauge(step.vehicles)) {
    observations.push({
      type: 'GB_GAUGE',
    });
  }
  if (VehiclesUtils.hasDangerousGoods(step.vehicles)) {
    observations.push({
      type: 'DANGEROUS_MATERIAL',
    });
  }
  return observations;
};

const computeUpdatedObservations = (step: TrainStep, oldObservations: readonly BrakingBulletinObservation[]) => {
  // Re-compute the default observations from the composition
  const computedObservations = computeDefaultObservations(step);
  // Copy the additional data from the old default observations, if they still exist
  const defaultObservations = computedObservations.map((observation) => {
    if (observation.text1) {
      // There might be multiple instances of an observation for a given type, in which case, they are identified uniquely by the "text1" field
      const oldObservation = oldObservations.find(
        (oldObservation) => oldObservation.type === observation.type && oldObservation.text1 === observation.text1,
      );
      if (oldObservation) {
        return {
          ...observation,
          text2: oldObservation.text2,
          number1: oldObservation.number1,
          number2: oldObservation.number2,
        };
      }
    }
    return observation;
  });
  // Keep the manually added observations as is
  const nonDefaultObservations = oldObservations.filter(
    (observation) => !COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES.has(observation.type),
  );
  return [...defaultObservations, ...nonDefaultObservations];
};

const hasLLObservation = (observations: readonly BrakingBulletinObservation[]) =>
  observations.some((observation) => observation.type === 'LL');

const hasFullFCVObservation = (observations: readonly BrakingBulletinObservation[]) =>
  observations.some((observation) => observation.type === 'FCV_FULL');

/**
 * Create a diff for a given braking bulletin, with updated data from the composition.
 */
const createBrakingBulletinDiffFromStep = (
  {
    id,
    revision,
    indiceComposition,
    startLocation,
    observations,
    brakedWeightDetails,
    effectiveBrakedWeightDetails,
  }: BrakingBulletin,
  step: TrainStep,
): BrakingBulletinDiff => {
  const hasLL = hasLLObservation(observations);
  const hasFullFCV = hasFullFCVObservation(observations);
  const compositionSummary = computeBrakingBulletinCompositionSummary(step, hasLL);
  return {
    id,
    brakingBulletin: {
      revision,
      startLocation,
      indiceComposition,
      compositionSummary,
      observations: computeUpdatedObservations(step, observations),
      brakedWeightDetails: {
        ...brakedWeightDetails,
        fixed: computeFixedBrakedWeight(compositionSummary, hasLL, hasFullFCV, indiceComposition),
      },
      effectiveBrakedWeightDetails,
    },
  };
};

/**
 * Create a diff for a given braking bulletin, with the form data.
 */
const createBrakingBulletinDiffFromForm = (
  brakingBulletin: BrakingBulletin,
  {
    indiceComposition,
    startLocation,
    observations,
    brakedWeightDetails,
    effectiveBrakedWeightDetails,
    sign = false,
    signatureType,
  }: BrakingBulletinFormData,
): BrakingBulletinDiff => {
  return {
    id: brakingBulletin.id,
    brakingBulletin: {
      revision: brakingBulletin.revision,
      indiceComposition,
      compositionSummary: brakingBulletin.compositionSummary,
      startLocation,
      observations,
      brakedWeightDetails: {
        ...brakedWeightDetails,
        fixed: brakedWeightDetails.stopping ? null : brakedWeightDetails.fixed,
      },
      effectiveBrakedWeightDetails,
    },
    sign: sign ? moment().toISOString() : undefined,
    signatureType,
  };
};

/**
 * Create a diff for signing a given braking bulletin, with no other modifications.
 */
const createBrakingBulletinDiffForSigning = (
  { id }: BrakingBulletin,
  { signatureType }: BrakingBulletinSignFormData,
): BrakingBulletinDiff => ({
  id,
  sign: moment().toISOString(),
  signatureType,
});

/**
 * Create a diff for invalidating a given braking bulletin, with no other modifications.
 */
const createBrakingBulletinDiffForInvalidate = ({ id }: BrakingBulletin): BrakingBulletinDiff => ({
  id,
  invalidate: true,
});

/**
 * Create a diff for deleting a given braking bulletin.
 */
const createBrakingBulletinDiffForDelete = ({ id }: BrakingBulletin): BrakingBulletinDiff => ({
  id,
  delete: true,
});

const createNewBrakingBulletin = (step: TrainStep): BrakingBulletin => {
  const latestBrakingBulletin = step.brakingBulletins[step.brakingBulletins.length - 1] ?? {
    revision: 0,
    brakedWeightDetails: {
      fixed: null,
      stopping: null,
      drift: null,
      driftSecondHalf: null,
    },
    effectiveBrakedWeightDetails: {
      driftSecondHalf: null,
    },
    validatedBy: null,
    validatedDate: null,
    observations: [],
  };
  const hasLL = hasLLObservation(latestBrakingBulletin.observations);
  const hasFullFCV = hasFullFCVObservation(latestBrakingBulletin.observations);
  const compositionSummary = computeBrakingBulletinCompositionSummary(step, hasLL);
  return {
    ...latestBrakingBulletin,
    id: uuid(),
    compositionId: step.id,
    status: 'DRAFT',
    revision: latestBrakingBulletin.revision + 1,
    indiceComposition: indiceCompositionOverrideForNewBrakingBulletin(step, latestBrakingBulletin),
    startLocation: step.brakingBulletins.length === 0 ? step.startLocationLabel : null,
    compositionSummary,
    brakedWeightDetails: {
      ...latestBrakingBulletin.brakedWeightDetails,
      fixed: computeFixedBrakedWeight(compositionSummary, hasLL, hasFullFCV, latestBrakingBulletin.indiceComposition),
    },
    observations: computeUpdatedObservations(step, latestBrakingBulletin.observations),
  };
};

const stepHasSignedBrakingBulletin = (step: TrainStep): boolean => {
  return step.brakingBulletins.some((bb) => bb.status === 'SIGNED');
};

const trainHasSignedBrakingBulletin = (train: TrainSummary): boolean => {
  const manualSteps = train.steps.filter((step) => step.manualBrakingBulletin);
  return manualSteps.length > 0 && manualSteps.every((step) => step.hasSignedBrakingBulletin);
};

const computeFixedBrakedWeightFloat = (
  compositionSummary: BrakingBulletinCompositionSummary,
  hasLL: boolean,
  hasFCV: boolean,
  brakingBulletinIndiceComposition?: BrakingBulletinIndiceComposition | null,
): number | null => {
  const length =
    compositionSummary.vehiclesLength +
    compositionSummary.tractionEnginesLength +
    compositionSummary.vehicleEnginesLength +
    compositionSummary.pushEnginesLength;
  const weight =
    compositionSummary.vehiclesWeight +
    compositionSummary.tractionEnginesWeight +
    compositionSummary.vehicleEnginesWeight +
    compositionSummary.pushEnginesWeight;

  const compositionIndex = brakingBulletinIndiceComposition ?? compositionSummary.compositionIndex;
  if (
    compositionIndex === 'ME100' ||
    compositionIndex === 'HLP' ||
    compositionIndex === 'TM' ||
    (compositionIndex === 'MA100' && (hasLL || hasFCV))
  ) {
    if (length <= 550) {
      return weight * 0.6;
    } else if (length <= 650) {
      return weight * 0.63;
    } else if (length <= 750) {
      return weight * 0.66;
    } else if (length <= 850) {
      return weight * 0.73;
    }
  }

  if (compositionIndex === 'ME120') {
    if (length <= 550) {
      return weight * 0.77;
    } else if (length <= 650) {
      return weight * 0.81;
    } else if (length <= 750) {
      return weight * 0.86;
    } else if (length <= 850) {
      return weight * 0.94;
    }
  }

  if (compositionIndex === 'ME140') {
    if (length <= 550) {
      return weight * 0.97;
    } else if (length <= 650) {
      return weight * 1.02;
    } else if (length <= 700) {
      return weight * 1.05;
    } else if (length <= 750) {
      return weight * 1.08;
    }
  }

  if (compositionIndex === 'MA100') {
    // This is a fallback for MA100(LL) / MA100(FCV) when length > 850
    if (length <= 800) {
      return weight * 0.57;
    } else if (length <= 900) {
      return weight * 0.64;
    } else if (length <= 1000) {
      return weight * 0.69;
    }
  }

  if (compositionIndex === 'MA90') {
    if (length <= 800) {
      return weight * 0.5;
    } else if (length <= 900) {
      return weight * 0.57;
    } else if (length <= 1000) {
      return weight * 0.61;
    }
  }

  return null;
};

const computeFixedBrakedWeight = (
  compositionSummary: BrakingBulletinCompositionSummary,
  hasLL: boolean,
  hasFullFCV: boolean,
  brakingBulletinIndiceComposition?: BrakingBulletinIndiceComposition | null,
): number | null => {
  const fixedBrakedWeight = computeFixedBrakedWeightFloat(
    compositionSummary,
    hasLL,
    hasFullFCV,
    brakingBulletinIndiceComposition,
  );
  if (fixedBrakedWeight === null) {
    return null;
  }
  return Math.ceil(fixedBrakedWeight);
};

const computeTotalEffectiveBrakedWeight = (compositionSummary: BrakingBulletinCompositionSummary) =>
  Math.ceil(
    compositionSummary.vehiclesBrakedWeight +
      compositionSummary.tractionEnginesBrakedWeight +
      compositionSummary.vehicleEnginesBrakedWeight +
      compositionSummary.pushEnginesBrakedWeight,
  );

export default {
  computeBrakingBulletinCompositionSummary,
  createBrakingBulletinDiffFromStep,
  createBrakingBulletinDiffFromForm,
  createBrakingBulletinDiffForSigning,
  createBrakingBulletinDiffForInvalidate,
  createBrakingBulletinDiffForDelete,
  createNewBrakingBulletin,
  stepHasSignedBrakingBulletin,
  trainHasSignedBrakingBulletin,
  computeFixedBrakedWeight,
  computeTotalEffectiveBrakedWeight,
  hasLLObservation,
  hasFullFCVObservation,
};
