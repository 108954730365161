import debugFactory from 'debug';
import * as Sentry from '@sentry/react';
import getSentryConfig from '../rest/sentryRest';

const debug = debugFactory('sentry');

export default async () => {
  const response = await getSentryConfig();
  if (response.ok) {
    const { dsn, environment } = await response.json();
    if (dsn) {
      Sentry.init({
        dsn,
        environment,
        release: process.env.REACT_APP_APP_VERSION ?? 'dev',
        autoSessionTracking: false,
      });
      debug('Sentry configured');
    } else {
      debug('Sentry not configured : config URL is empty');
    }
  } else {
    debug('Sentry not configured : error while getting Sentry DSN');
  }
};
