import { createMigrate } from 'redux-persist';
import { MigrationManifest, PersistConfig, PersistedState } from 'redux-persist/es/types';

import icompoLocalForage from './localForageProvider';
import { cacheTransform } from './persistTransform';
import { SecurityContextState } from '../security/securityContextDucks';
import { TrainSummariesState } from '../../trains/trains-table/trainSummariesDucks';
import { OfflineTrainsState } from '../../trains/offline/offlineTrainsDucks';

const trainSummariesMigrations: MigrationManifest = {
  0: (state) =>
    ({
      ...state,
      data: [],
    } as PersistedState),
};

export const securityContextPersistConfig: PersistConfig<SecurityContextState> = {
  key: 'securityContext',
  storage: icompoLocalForage,
};

export const trainSummariesPersistConfig: PersistConfig<TrainSummariesState> = {
  key: 'trainSummaries',
  storage: icompoLocalForage,
  whitelist: ['data', 'filters'],
  version: 0,
  migrate: createMigrate(trainSummariesMigrations),
};

export const offlineTrainsPersistConfig: PersistConfig<OfflineTrainsState> = {
  key: 'offlineTrains',
  storage: icompoLocalForage,
  whitelist: ['diffs', 'diffsBuffer'],
  transforms: [cacheTransform],
};

export const damageReportsPersistConfig: PersistConfig<any> = {
  key: 'damageReports',
  storage: icompoLocalForage,
  whitelist: ['cache', 'filters'],
  transforms: [cacheTransform],
};
