import classNames from 'classnames';
import React from 'react';
import { isValid } from 'redux-form';

import { requestValidatePreAdvice } from '../preAdviceDucks';
import { formName } from '../preAdviceFormUtils';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';

type PreAdviceValidationProps = {
  preAdvice: PreAdviceEntity;
};

const PreAdviceValidation = ({ preAdvice }: PreAdviceValidationProps) => {
  const dispatch = useAppDispatch();
  const formValid = useAppSelector(isValid(formName));
  if (!preAdvice.id || preAdvice.isValidated()) {
    return null;
  }
  const success = formValid && preAdvice.getNbValidatedWagons() === preAdvice.getNbWagons();
  return (
    <div className="vehicles-validate">
      <button
        className={classNames('btn btn-accent btn-icon lock', {
          disabled: !preAdvice.getNbWagons() || !success,
        })}
        onClick={() => dispatch(requestValidatePreAdvice(preAdvice))}
        type="button"
      >
        Valider
      </button>
    </div>
  );
};

export default PreAdviceValidation;
