/**
 *
 * Main.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Sidebar from '../sidebar/Sidebar';
import ConfirmModal from '../modal/ConfirmModal';
import Toast from '../toast/Toast';
import { getSidebar, selectSecurityContext } from '../../selectors/selectors';
import './mainStyles.scss';
import GlobalError from '../GlobalError';
import {
  COMPOSITION_READ,
  DAMAGE_REPORT_VALIDATE,
  DAMAGE_REPORT_WRITE,
  PRE_ADVICE_READ,
} from '../../security/userRoles';
import Trains from '../../../trains/Trains';
import TrainStepPage from '../../../trains/step/TrainStepPage';
import VehiclePage from '../vehicles/edit/VehiclePage';
import PreAdvices from '../../../pre-advices/PreAdvices';
import PreAdvice from '../../../pre-advices/pre-advice/PreAdvice';
import PreAdviceWagon from '../../../pre-advices/wagon/PreAdviceWagon';
import DamageReports from '../../../damage-reports/DamageReports';
import DamageReport from '../../../damage-reports/damage-report/DamageReport';
import SentMessages from '../../../admin/sent-messages/SentMessages';
import Users from '../../../admin/users/Users';
import Profiles from '../../../admin/users-profiles/Profiles';
import VehiclesLibrary from '../../../admin/vehicles-library/VehiclesLibrary';
import MailingLists from '../../../admin/mailing-lists/MailingLists';
import Owners from '../../../admin/owners/Owners';
import RouteNotFound from '../RouteNotFound';
import { collapseSidebar } from '../sidebar/sidebarDucks';
import TrainPage from '../../../trains/train/TrainPage';
import { useViewportWidth } from '../../responsive/hooks';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// Here we start the browser persistence : user is authenticated and app initialized
const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const securityContext = useSelector(selectSecurityContext);
  const sidebar = useSelector(getSidebar);
  const { isMobile } = useViewportWidth();

  useEffect(() => {
    dispatch(collapseSidebar());
  }, [location.pathname, dispatch]);

  if (!securityContext.authenticated) {
    return null;
  }
  if (location.pathname === '/') {
    const { roles = [] } = securityContext;
    let nextPath;
    if (roles.includes(COMPOSITION_READ)) {
      nextPath = '/trains';
    } else if (roles.includes(PRE_ADVICE_READ)) {
      nextPath = '/pre-advices';
    } else if (roles.includes(DAMAGE_REPORT_WRITE) || roles.includes(DAMAGE_REPORT_VALIDATE)) {
      nextPath = '/damage-reports';
    } else {
      nextPath = '/admin/sent-messages';
    }
    return <Navigate to={nextPath} />;
  }

  return (
    <div>
      <Sidebar />
      <div
        id="content"
        className={classNames('app-container', {
          collapsed: !isMobile && !sidebar.hidden && sidebar.expanded,
          expanded: !isMobile && !sidebar.hidden && !sidebar.expanded,
        })}
      >
        {securityContext.active ? (
          <SentryRoutes>
            <Route path="/trains" element={<Trains />} />
            <Route path="/trains/steps/:stepId/vehicles/:vehicleId" element={<VehiclePage />} />
            <Route path="/trains/steps/:stepId" element={<TrainStepPage />} />
            <Route path="/trains/:trainId" element={<TrainPage />} />
            <Route path="/pre-advices" element={<PreAdvices />} />
            <Route path="/pre-advices/new" element={<PreAdvice />} />
            <Route path="/pre-advices/:preAdviceId" element={<PreAdvice />} />
            <Route path="/pre-advices/:preAdviceId/wagons/:wagonId" element={<PreAdviceWagon />} />
            <Route path="/damage-reports" element={<DamageReports />} />
            <Route path="/damage-reports/:id" element={<DamageReport />} />
            <Route path="/admin/sent-messages" element={<SentMessages />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/profiles" element={<Profiles />} />
            <Route path="/admin/vehicles-library" element={<VehiclesLibrary />} />
            <Route path="/admin/mailing-lists" element={<MailingLists />} />
            <Route path="/admin/owners" element={<Owners />} />
            <Route path="*" element={<RouteNotFound />} />
          </SentryRoutes>
        ) : (
          <GlobalError title="Accès refusé">Vous n'avez pas accès à iCompo, ou votre compte est inactif.</GlobalError>
        )}
      </div>
      <Toast />
      <ConfirmModal />
    </div>
  );
};

export default Main;
