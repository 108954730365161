import React from 'react';
import { Field, formValueSelector } from 'redux-form';

import FormSection from '../../../commons/components/form/FormSection';
import Input from '../../../commons/components/input/Input';
import { formatDecimalAndSeparateThousands } from '../../../commons/redux-form/valueFormatter';
import { normalizePositiveFloatTwoDecimal } from '../../../commons/redux-form/valueNormalizer';
import { BrakingBulletinObservationType } from '../../../commons/model/BrakingBulletin';
import { useAppSelector } from '../../../commons/store/hooks';
import { brakingBulletinFormName } from './BrakingBulletinForm';
import FixedBrakingField from './FixedBrakingField';

type RequiredBrakingSectionProps = {
  readOnly: boolean;
};

const RequiredBrakingSection = ({ readOnly }: RequiredBrakingSectionProps) => {
  const stopping: BrakingBulletinObservationType = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'brakedWeightDetails.stopping'),
  );
  return (
    <FormSection name="brakedWeightDetails" title="Masse freinée nécessaire" className="braking-bulletin-required">
      <FixedBrakingField stopping={stopping} />
      <Field
        component={Input}
        name="stopping"
        labelText="Freinage d'arrêt"
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
          className: 'right',
        }}
        unit="t"
        format={formatDecimalAndSeparateThousands}
        normalize={normalizePositiveFloatTwoDecimal}
        disabled={readOnly}
      />
      <Field
        component={Input}
        name="drift"
        labelText="Freinage de dérive (FD)"
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
          className: 'right',
        }}
        unit="t"
        format={formatDecimalAndSeparateThousands}
        normalize={normalizePositiveFloatTwoDecimal}
        disabled={readOnly}
      />
      <Field
        component={Input}
        name="driftSecondHalf"
        labelText="Freinage de la 2e moitié du train"
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
          className: 'right',
        }}
        unit="t"
        format={formatDecimalAndSeparateThousands}
        normalize={normalizePositiveFloatTwoDecimal}
        disabled={readOnly}
      />
    </FormSection>
  );
};

export default RequiredBrakingSection;
