import moment from 'moment';
import DamageReportEntity from '../entity/DamageReportEntity';
import { DAMAGE_REPORT_VALIDATE, DAMAGE_REPORT_WRITE, RoleId } from '../security/userRoles';
import { Company, DateString, IdName, ValidationStatus } from '../model/common';
import { DamageReportLabelKey, DamageReportStatus } from '../model/DamageReport';

type FiltersParams = {
  filters?: string[];
};

export type TrainFilters = {
  defaultFromDate?: boolean;
  defaultToDate?: boolean;
  trainNumber: string | null;
  startStationId: string | null;
  endStationId: string | null;
  fromDate: DateString | null;
  toDate: DateString | null;
  marketIds: number[];
  statuses: ValidationStatus[];
};

export type TrainFiltersFormValues = {
  trainNumber: string | null;
  startStation: IdName<string> | null;
  endStation: IdName<string> | null;
  fromDate: Date | DateString | null;
  toDate: Date | DateString | null;
  markets: IdName<number>[];
  statusEmpty: boolean;
  statusNotValidated: boolean;
  statusValidated: boolean;
};

export const defaultTrainFilters: TrainFilters = {
  defaultFromDate: true,
  defaultToDate: true,
  trainNumber: null,
  startStationId: null,
  endStationId: null,
  fromDate: null,
  toDate: null,
  marketIds: [],
  statuses: ['EMPTY', 'NOT_VALIDATED', 'VALIDATED'],
};

export type DamageReportFilters = {
  wagonRegistration: string | null;
  number: number | null;
  damageTemplateId: string | null;
  ownerId: string | null;
  fromDate: DateString | null;
  toDate: DateString | null;
  ownerIds: string[];
  marketIds: number[];
  statuses: DamageReportStatus[];
  label: DamageReportLabelKey | null;
  onlyMine: boolean;
  company: Company | null;
};

export type DamageReportFiltersFormValues = {
  wagonRegistration: string | null;
  number: number | null;
  damageTemplateId: string | null;
  ownerId: string | null;
  fromDate: DateString | null;
  toDate: DateString | null;
  owners: IdName<string>[];
  markets: IdName<number>[];
  statusInProgress: boolean;
  statusSubmitted: boolean;
  statusValidated: boolean;
  statusDisabled: boolean;
  label: DamageReportLabelKey | null;
  onlyMine: boolean;
  company: Company | null;
};

export const defaultDamageReportsFilters = (roles: RoleId[], company: Company) => {
  const statuses = [];
  if (roles.includes(DAMAGE_REPORT_VALIDATE)) {
    statuses.push(DamageReportEntity.VALIDATION_STATUSES.SUBMITTED);
  }
  if (roles.includes(DAMAGE_REPORT_WRITE) || statuses.length === 0) {
    statuses.push(DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS);
    statuses.push(DamageReportEntity.VALIDATION_STATUSES.EMPTY);
  }
  return {
    number: null,
    fromDate: null,
    toDate: null,
    wagonRegistration: null,
    ownerIds: [],
    marketIds: [],
    onlyMine: true,
    statuses,
    company: company,
  };
};

export const trainFiltersToParams = ({ defaultFromDate, defaultToDate, ...filters }: TrainFilters) => {
  const params: FiltersParams = {};

  if (filters) {
    const filteredFilters: Omit<TrainFilters, 'defaultFromDate' | 'defaultToDate'> = { ...filters };
    if (defaultFromDate) {
      filteredFilters.fromDate = moment().startOf('day').add(-3, 'days').local().format();
    }
    if (defaultToDate) {
      filteredFilters.toDate = moment().startOf('day').add(3, 'days').local().format();
    }

    if (filteredFilters.toDate) {
      /*
       * Adding one day for the server API
       */
      filteredFilters.toDate = moment(filteredFilters.toDate).startOf('day').add(1, 'day').local().format();
    }

    params.filters = (
      Object.keys(filteredFilters) as Array<keyof Omit<TrainFilters, 'defaultFromDate' | 'defaultToDate'>>
    )
      .filter((filterName) => {
        const filterValue = filteredFilters[filterName];
        // Don't use empty arrays as filter
        if (Array.isArray(filterValue) && !filterValue.length) {
          return false;
        }
        return Boolean(filterValue);
      })
      .map((filterName) => filterName + ',' + filteredFilters[filterName]);
  }

  return params;
};

export const trainFiltersToFormInitialValues = (filters: TrainFilters) => {
  const formValues: TrainFiltersFormValues = {
    trainNumber: filters.trainNumber,
    startStation: filters.startStationId ? { id: filters.startStationId, name: filters.startStationId } : null,
    endStation: filters.endStationId ? { id: filters.endStationId, name: filters.endStationId } : null,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    markets: filters.marketIds.map((marketId) => ({ id: marketId, name: `${marketId}` })),
    statusEmpty: filters.statuses.includes('EMPTY'),
    statusNotValidated: filters.statuses.includes('NOT_VALIDATED'),
    statusValidated: filters.statuses.includes('VALIDATED'),
  };
  if (filters.defaultFromDate) {
    formValues.fromDate = moment().add(-3, 'days').startOf('day').local().toDate();
  }
  if (filters.defaultToDate) {
    formValues.toDate = moment().add(3, 'days').startOf('day').local().toDate();
  }
  return formValues;
};

export const trainFormToFilters = (filters: TrainFilters, propertyToUpdate: Partial<TrainFiltersFormValues>) => {
  const newFilters = { ...filters };
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'trainNumber')) {
    newFilters.trainNumber = propertyToUpdate.trainNumber ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'startStation')) {
    newFilters.startStationId = propertyToUpdate.startStation?.id ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'startStation.label')) {
    newFilters.startStationId = null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'endStation')) {
    newFilters.endStationId = propertyToUpdate.endStation?.id ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'endStation.label')) {
    newFilters.endStationId = null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'fromDate')) {
    newFilters.defaultFromDate = false;
    if (propertyToUpdate.fromDate) {
      newFilters.fromDate = moment(propertyToUpdate.fromDate).local().format();
    } else {
      newFilters.fromDate = null;
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'toDate')) {
    newFilters.defaultToDate = false;
    if (propertyToUpdate.toDate) {
      newFilters.toDate = moment(propertyToUpdate.toDate).local().format();
    } else {
      newFilters.toDate = null;
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'markets')) {
    newFilters.marketIds = propertyToUpdate.markets?.map((market) => market.id) ?? [];
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusEmpty')) {
    if (propertyToUpdate.statusEmpty) {
      newFilters.statuses = [...filters.statuses, 'EMPTY'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'EMPTY');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusNotValidated')) {
    if (propertyToUpdate.statusNotValidated) {
      newFilters.statuses = [...filters.statuses, 'NOT_VALIDATED'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'NOT_VALIDATED');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusValidated')) {
    if (propertyToUpdate.statusValidated) {
      newFilters.statuses = [...filters.statuses, 'VALIDATED'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'VALIDATED');
    }
  }
  return newFilters;
};

export const damageReportsFiltersToParams = (filters: DamageReportFilters) => {
  const filteredFilters = { ...filters };
  if (filteredFilters.toDate) {
    /*
     * Adding one day for the server API
     */
    filteredFilters.toDate = moment(filteredFilters.toDate).startOf('day').add(1, 'day').local().format();
  }
  (Object.keys(filteredFilters) as Array<keyof DamageReportFilters>).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(filteredFilters, key)) {
      if (Array.isArray(filteredFilters[key]) && !(filteredFilters[key] as Array<any>).length)
        delete filteredFilters[key];
      if (Array.isArray(filteredFilters[key]) && (filteredFilters[key] as Array<any>).length) {
        // @ts-ignore
        filteredFilters[key] = (filteredFilters[key] as Array<any>).join(',');
      }
      if (filteredFilters[key] === null || filteredFilters[key] === '') delete filteredFilters[key];
    }
  });
  return filteredFilters;
};

export const damageReportsFiltersToFormInitialValues = (filters: DamageReportFilters) => ({
  wagonRegistration: filters.wagonRegistration,
  number: filters.number,
  damageTemplateId: filters.damageTemplateId,
  ownerId: filters.ownerId,
  fromDate: filters.fromDate,
  toDate: filters.toDate,
  owners: (filters.ownerIds ?? []).map((ownerId) => ({ id: ownerId, name: `${ownerId}` })),
  markets: (filters.marketIds ?? []).map((marketId) => ({ id: marketId, name: `${marketId}` })),
  statusInProgress: filters.statuses.includes('IN_PROGRESS'),
  statusSubmitted: filters.statuses.includes('SUBMITTED'),
  statusValidated: filters.statuses.includes('VALIDATED'),
  statusDisabled: filters.statuses.includes('DISABLED'),
  label: filters.label,
  onlyMine: filters.onlyMine,
  company: filters.company,
});

export const damageReportsFormToFilters = (
  filters: DamageReportFilters,
  propertyToUpdate: Partial<DamageReportFiltersFormValues>,
) => {
  const newFilters = { ...filters };
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'wagonRegistration')) {
    newFilters.wagonRegistration = propertyToUpdate.wagonRegistration ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'number')) {
    newFilters.number = propertyToUpdate.number ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'damageTemplateId')) {
    newFilters.damageTemplateId = propertyToUpdate.damageTemplateId ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'label')) {
    newFilters.label = propertyToUpdate.label ?? null;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'owners')) {
    newFilters.ownerIds = propertyToUpdate.owners?.map((owner) => owner.id) ?? [];
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'markets')) {
    newFilters.marketIds = propertyToUpdate.markets?.map((market) => market.id) ?? [];
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'fromDate')) {
    if (propertyToUpdate.fromDate) {
      newFilters.fromDate = moment(propertyToUpdate.fromDate).local().format();
    } else {
      newFilters.fromDate = null;
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'toDate')) {
    if (propertyToUpdate.toDate) {
      newFilters.toDate = moment(propertyToUpdate.toDate).local().format();
    } else {
      newFilters.toDate = null;
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusInProgress')) {
    if (propertyToUpdate.statusInProgress) {
      newFilters.statuses = [...filters.statuses, 'EMPTY', 'IN_PROGRESS'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'EMPTY' && m !== 'IN_PROGRESS');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusSubmitted')) {
    if (propertyToUpdate.statusSubmitted) {
      newFilters.statuses = [...filters.statuses, 'SUBMITTED'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'SUBMITTED');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusValidated')) {
    if (propertyToUpdate.statusValidated) {
      newFilters.statuses = [...filters.statuses, 'VALIDATED'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'VALIDATED');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'statusDisabled')) {
    if (propertyToUpdate.statusDisabled) {
      newFilters.statuses = [...filters.statuses, 'DISABLED'];
    } else {
      newFilters.statuses = filters.statuses.filter((m) => m !== 'DISABLED');
    }
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'onlyMine')) {
    newFilters.onlyMine = propertyToUpdate.onlyMine !== undefined ? propertyToUpdate.onlyMine : true;
  }
  if (Object.prototype.hasOwnProperty.call(propertyToUpdate, 'company')) {
    newFilters.company = propertyToUpdate.company ?? null;
  }
  return newFilters;
};
