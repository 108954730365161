import React from 'react';
import PreAdviceFieldsForm from './PreAdviceFieldsForm';
import PreAdviceFieldsReadOnly from './PreAdviceFieldsReadOnly';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';

const PreAdviceSummary = ({ preAdvice, readOnly }) =>
  readOnly ? (
    <PreAdviceFieldsReadOnly preAdvice={preAdvice} />
  ) : (
    <PreAdviceFieldsForm initialValues={preAdvice} preAdvice={preAdvice} validate={PreAdviceEntity.validate} />
  );

export default PreAdviceSummary;
