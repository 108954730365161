export default class PreAdviceHazardousMaterialEntity {
  static newEmptyHazardousMaterial = () =>
    new PreAdviceHazardousMaterialEntity({
      hazardousMaterial: {
        id: null,
        dangerIdentificationNumber: null,
        unitedNationsCode: null,
        label: null,
        rid: null,
        packingGroup: null,
        dangerLabels: null,
        limitedQuantity: null,
      },
      weight: null,
      volume: null,
    });

  constructor(serverHazardousMaterial) {
    // noinspection JSUnresolvedVariable
    this.hazardousMaterial = {
      id: serverHazardousMaterial.hazardousMaterialId,
      dangerIdentificationNumber: serverHazardousMaterial.dangerIdentificationNumber,
      unitedNationsCode: serverHazardousMaterial.unitedNationsCode,
      label: serverHazardousMaterial.label,
      rid: serverHazardousMaterial.rid,
      packingGroup: serverHazardousMaterial.packingGroup,
      dangerLabels: serverHazardousMaterial.dangerLabels || [],
      limitedQuantity: serverHazardousMaterial.limitedQuantity,
    };
    this.weight = serverHazardousMaterial.weight;
    this.volume = serverHazardousMaterial.volume;
  }

  toUpdateCommand() {
    return {
      hazardousMaterialId: this.hazardousMaterial?.id,
      weight: this.weight,
      volume: this.volume,
    };
  }
}
