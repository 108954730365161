/**
 * DamageReports component
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import DamageReportsTopbar from './damage-reports-topbar/DamageReportsTopbar';
import DamageReportsTable from './damage-reports-table/DamageReportsTable';
import DamageReportEntity from '../commons/entity/DamageReportEntity';
import { getDamageReports } from '../commons/selectors/selectors';
import { usePageTitle } from '../commons/components/hooks';

const DamageReports = ({ damageReports }) => {
  usePageTitle('PVCA');
  return (
    <div className="content-parent table-page" onClick={() => false}>
      <DamageReportsTopbar loading={damageReports.loading && damageReports.data && damageReports.data.length > 0} />
      <DamageReportsTable />
    </div>
  );
};

DamageReports.propTypes = {
  damageReports: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(DamageReportEntity.reactShape())).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

const selector = (state) => ({
  damageReports: getDamageReports(state),
});

export default connect(selector)(DamageReports);
