import React from 'react';
import { reduxForm } from 'redux-form';
import VehicleField from '../../../input/VehicleField';
import SegmentedControl from '../../../segmented-control/SegmentedControl';
import { updateEngine } from '../../../../../trains/step/vehicleActions';
import { parseToFloat, parseToInt } from '../../../../redux-form/valueParser';
import {
  normalizePositiveFloatTwoDecimal,
  normalizePositiveNumber,
  normalizeRegistration,
  normalizeTons,
} from '../../../../redux-form/valueNormalizer';
import Mask from '../../../../mask/Mask';
import { formatDecimalNumber } from '../../../../redux-form/valueFormatter';
import engineSeriesCache from '../../../../templates/engineSeriesCache';
import engineCache from '../../../../templates/engineCache';
import { Engine, ENGINE_POSITIONS, EnginePosition, TRACTION_MODES } from '../../../../model/Vehicle';
import { validateEngine } from '../../../../validation/validationUtils';
import { TractionMode } from '../../../../model/common';
import CheckboxFormComponent from '../../../checkbox/CheckboxFormComponent';

type EngineEditProps = {
  engine: Engine;
};

export type EngineFormValues = {
  enginePosition: EnginePosition | null;
  number: string | null;
  seriesNumber: string | null;
  registration: string | null;
  length: number | null;
  tare: number | null;
  effectiveBrakedWeight: number | null;
  nbAxles: number | null;
  tractionMode: TractionMode | null;
  rollingGoods: boolean | null;
};

const EngineForm = ({ engine }: EngineEditProps) => (
  <form className="fields" autoComplete="off">
    <VehicleField
      component={SegmentedControl}
      name="enginePosition"
      labelText="Position dans le train *"
      active={engine.enginePosition}
      buttons={ENGINE_POSITIONS}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      name="number"
      labelText="Numéro *"
      autoCapitalize="characters"
      placeholder="Ex: BB27179"
      autocompleter={engineCache}
      saveAction={updateEngine}
      data={engine}
      allowManualValues={true}
    />
    <VehicleField
      name="seriesNumber"
      labelText="Série *"
      autoCapitalize="characters"
      placeholder="Ex: BB27000"
      autocompleter={engineSeriesCache}
      saveAction={updateEngine}
      data={engine}
      allowManualValues={true}
    />
    <VehicleField
      name="registration"
      labelText="Immatriculation *"
      placeholder="Ex: 12 34 567 8901-2"
      format={(value: string) => value && Mask.REGISTRATION.format(value)}
      numeric={true}
      normalize={normalizeRegistration}
      normalizeOnSave={normalizeRegistration}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      name="length"
      labelText="Longueur *"
      unit="m"
      format={formatDecimalNumber}
      numeric={true}
      normalize={normalizePositiveFloatTwoDecimal}
      normalizeOnSave={parseToFloat}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      name="tare"
      labelText="Tare *"
      unit="t"
      format={(value: number) => (value !== undefined && value !== null ? value / 1000 : '')}
      numeric={true}
      normalize={normalizeTons}
      normalizeOnSave={(value: number) => value * 1000}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      name="effectiveBrakedWeight"
      labelText="Masse freinée réalisée *"
      unit="t"
      format={formatDecimalNumber}
      numeric={true}
      normalize={normalizePositiveFloatTwoDecimal}
      normalizeOnSave={parseToFloat}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      name="nbAxles"
      labelText="Nombre d’essieux *"
      numeric={true}
      normalize={normalizePositiveNumber}
      parse={parseToInt}
      saveAction={updateEngine}
      data={engine}
    />
    <VehicleField
      component={SegmentedControl}
      className="two-cols"
      name="tractionMode"
      labelText="Mode de traction *"
      active={engine.tractionMode}
      buttons={TRACTION_MODES}
      saveAction={updateEngine}
      data={engine}
    />
    <div className="input input-checkboxes">
      <VehicleField
        component={CheckboxFormComponent}
        name="rollingGoods"
        label="Marchandise roulante"
        saveAction={updateEngine}
        data={engine}
      />
    </div>
  </form>
);

export default reduxForm<EngineFormValues, EngineEditProps>({
  form: 'engineForm',
  validate: validateEngine,
})(EngineForm);
