import React, { useState, FunctionComponent } from 'react';
import './filtersStyles.scss';
import classNames from 'classnames';
import { useViewportWidth } from '../../responsive/hooks';

const FiltersPopup: FunctionComponent = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { isMobile } = useViewportWidth();

  const onOpen = () => {
    setIsOpened(true);
  };

  const handleClickOutside = () => {
    setIsOpened(false);
  };

  return (
    <div>
      <button
        className={classNames('btn filter', isMobile ? 'btn-round' : 'btn-link btn-icon')}
        type="button"
        onClick={onOpen}
      >
        Autres filtres
      </button>
      {isOpened && (
        <div className="overlay">
          <div className="overlay-fade" onClick={handleClickOutside} />
          {children}
        </div>
      )}
    </div>
  );
};

export default FiltersPopup;
