import { DateString, Signature, SignatureOrOffline } from './common';

export type BrakeTestType = 'FULL' | 'PARTIAL' | 'CONTINUITY' | 'CONNECTION';

export const BRAKE_TEST_TYPES: Record<BrakeTestType, string> = {
  FULL: 'Complet',
  PARTIAL: 'Partiel',
  CONTINUITY: 'Continuité',
  CONNECTION: 'Raccordement',
};

export type BrakeTestSignature = Signature &
  Readonly<{
    type: BrakeTestType;
    testDateTime: DateString | null; // null for existing signature
  }>;

export type FirstLastSignature = Signature &
  Readonly<{
    firstNotBraked: boolean;
    lastNotBraked: boolean;
  }>;

export type AgreementSignature = Signature &
  Readonly<{
    exempt: boolean;
  }>;

export type TransmissionSignature = Signature &
  Readonly<{
    noRestriction: boolean;
  }>;

export type Traceability = Readonly<{
  ratSignature?: SignatureOrOffline;
  brakeTestSignatures: SignatureOrOffline<BrakeTestSignature>[];
  rearSignalingSignatures: SignatureOrOffline[];
  firstLastSignatures: SignatureOrOffline<FirstLastSignature>[];
  ateAgreementSignatures: SignatureOrOffline<AgreementSignature>[];
  ateBrakingBulletinSignatures: SignatureOrOffline[];
  ateTransmissionSignatures: SignatureOrOffline<TransmissionSignature>[];
  visitSignatures: SignatureOrOffline[];
}>;

export const EMPTY_TRACEABILITY: Traceability = {
  brakeTestSignatures: [],
  rearSignalingSignatures: [],
  firstLastSignatures: [],
  ateAgreementSignatures: [],
  ateBrakingBulletinSignatures: [],
  ateTransmissionSignatures: [],
  visitSignatures: [],
};
