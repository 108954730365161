/**
 * Train form
 */
import React from 'react';
import classNames from 'classnames';
import { selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { BRAKING_BULLETIN, COMPOSITION_WRITE } from '../../commons/security/userRoles';
import './trainStepFormStyles.scss';
import { TrainStep } from '../../commons/model/Train';
import TrainUtils from '../../commons/model/TrainUtils';
import { useAppSelector } from '../../commons/store/hooks';
import { Link } from 'react-router-dom';
import BrakingBulletinList from './braking-bulletin/BrakingBulletinList';
import TraceabilitySummary from './traceability/TraceabilitySummary';
import ReadyForDispatchSummary from './ready-for-dispatch/ReadyForDispatchSummary';
import { useViewportWidth } from '../../commons/responsive/hooks';
import StepSummary from './summary/StepSummary';
import CompositionSummary from './summary/CompositionSummary';
import MissionEndSummary from './mission-end/MissionEndSummary';

type TrainFormProps = {
  step: TrainStep;
  loading: boolean;
  onChangeTab?: (newIndex: number) => void;
};

const TrainStepForm = ({ step, loading, onChangeTab }: TrainFormProps) => {
  const roles = useAppSelector(selectSecurityContextRoles);
  const { isMobile } = useViewportWidth();
  const canWriteCompositions = roles.includes(COMPOSITION_WRITE);
  const canSeeBB = roles.includes(BRAKING_BULLETIN);

  return (
    <div className="composition-form">
      <div className="topbar">
        <h1 className="title">
          <Link to={TrainUtils.linkToTrain(step.trainId)} className="btn btn-basic">
            Train
          </Link>{' '}
          &gt; Étape
        </h1>
        <div className="title-actions">
          {loading && <div className="loader" />}
          {isMobile && (
            <button className="btn btn-icon btn-basic wagon-empty" onClick={() => onChangeTab?.(1)} type="button">
              <strong>&gt;</strong>
            </button>
          )}
        </div>
      </div>
      <div className={classNames('composition-form-inner', { valid: TrainUtils.isStatusValidated(step) })}>
        <StepSummary step={step} />
        <CompositionSummary step={step} onClick={isMobile ? () => onChangeTab?.(1) : undefined} />
        {canSeeBB && (
          <>
            <BrakingBulletinList step={step} editable={canWriteCompositions} />
            <TraceabilitySummary step={step} editable={canWriteCompositions} />
            <ReadyForDispatchSummary step={step} editable={canWriteCompositions} />
            <MissionEndSummary step={step} editable={canWriteCompositions} />
          </>
        )}
      </div>
    </div>
  );
};

export default TrainStepForm;
