import * as Sentry from '@sentry/react';
import debugFactory from 'debug';
import { getUserInfo, redirectToLogin, redirectToLogout } from '../rest/securityAccessRest';
import { purgePersistStore } from '../offline/persistStoreProvider';
import offlineUtils from '../offline/offlineUtils';
import { loadTemplates } from '../templates/templatesDucks';
import { initializeTrainFilters, loadTrainSummaries } from '../../trains/trains-table/trainSummariesDucks';
import { loadTrains } from '../../trains/offline/offlineTrainsDucks';
import {
  initializeDamageReportsFilters,
  loadDamageReports,
} from '../../damage-reports/damage-reports-table/damageReportsDucks';
import { initSuccessOnline } from '../app-state/appStateActions';
import { COMPOSITION_READ, DAMAGE_REPORT_VALIDATE, DAMAGE_REPORT_WRITE, RoleId } from './userRoles';
import { AnyAction } from 'redux';
import { AppDispatch, AppStore } from '../store/store';
import { Company } from '../model/common';

const debug = debugFactory('authDucks');

// Actions
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_UNAUTHENTICATED = 'AUTH_UNAUTHENTICATED';

const USER_INFO_START = 'USER_INFO_START';
const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
const USER_INFO_SUCCESS_OFFLINE = 'USER_INFO_SUCCESS_OFFLINE';
const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export type SecurityContextState = UserInfo &
  Readonly<{
    authenticated: boolean;
    error: string | null;
  }>;

type UserInfo = Readonly<{
  username: string;
  email: string;
  profiles: readonly string[];
  roles: RoleId[];
  marketIds: number[];
  active: boolean;
  company: Company;
}>;

const initialSecurityContext: SecurityContextState = {
  authenticated: false,
  active: false,
  username: '',
  email: '',
  profiles: [],
  roles: [],
  marketIds: [],
  error: null,
  company: 'CTF',
};

// Reducer
const securityContext = (state = initialSecurityContext, action: AnyAction): SecurityContextState => {
  switch (action.type) {
    case AUTH_SUCCESS: {
      return {
        ...state,
        authenticated: true,
        error: null,
      };
    }
    case AUTH_FAILURE: {
      return {
        ...state,
        ...initialSecurityContext,
        error: action.payload.errorMessage,
      };
    }
    case USER_INFO_SUCCESS: {
      const user: UserInfo = action.payload;
      Sentry.setUser(user);

      return {
        ...state,
        ...user,
      };
    }
    case USER_INFO_SUCCESS_OFFLINE: {
      const { username, email, profiles, roles, marketIds, active } = state;
      Sentry.setUser({ username, email, profiles, roles, marketIds, active });

      return state;
    }
    case AUTH_UNAUTHENTICATED: {
      return {
        ...state,
        ...initialSecurityContext,
      };
    }
    default:
      return state;
  }
};
export default securityContext;

// Action creators

/*
 * LOGOUT
 */

export const clearSession = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: AUTH_UNAUTHENTICATED,
    payload: {},
  });

  debug('Triggering redux-persist clear on logout');
  try {
    await purgePersistStore();
    debug('redux-persist cleared successfully on logout');
  } catch (e) {
    debug('redux-persist failed on logout');
  }
};

export const login = () => async (dispatch: AppDispatch) => {
  await dispatch(clearSession());
  redirectToLogin();
};

export const logout = () => async (dispatch: AppDispatch) => {
  await dispatch(clearSession());
  redirectToLogout();
};

/*
 * User infos
 */

export const userInfoFailure = (responseStatus: number) => async (dispatch: AppDispatch) => {
  dispatch({
    type: USER_INFO_FAILURE,
  });
  if (responseStatus === 401) {
    await dispatch(clearSession());
    redirectToLogin();
  }
};

export const userInfoSuccess = (userInfo: UserInfo) => ({
  type: USER_INFO_SUCCESS,
  payload: userInfo,
});

export const userInfoOffline = () => ({
  type: USER_INFO_SUCCESS_OFFLINE,
});

export const userInfoStart = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: USER_INFO_START,
  });

  if (!offlineUtils.isOnline()) {
    // Don't try to load the user info if offline
    dispatch(userInfoOffline());
    return null;
  }

  try {
    const response = await getUserInfo();
    if (response.ok) {
      const userInfo = await response.json();
      dispatch(userInfoSuccess(userInfo));
    } else {
      dispatch(userInfoFailure(response.status));
    }
  } catch (e) {
    // Connection lost
    dispatch(userInfoOffline());
  }
};

/*
 * AUTHENTICATION
 */
export const authFailure =
  (errorMessage = '') =>
  async (dispatch: AppDispatch) => {
    // Clear IndexedDB in case some data are still there
    debug('Triggering redux-persist clear on displaying login page');
    try {
      await purgePersistStore();
      debug('redux-persist cleared successfully on displaying login page');
    } catch (e) {
      debug('redux-persist failed on displaying login page');
    }

    dispatch({
      type: AUTH_FAILURE,
      payload: { errorMessage },
    });
  };

export const authSuccess = () => (dispatch: AppDispatch) => {
  dispatch({
    type: AUTH_SUCCESS,
  });

  // retrieve new JSESSIONID + CSRF-TOKEN cookies generated after Spring authentication
  dispatch(loadTemplates());
};

export const authStart = (store: AppStore) => async (dispatch: AppDispatch) => {
  dispatch({
    type: AUTH_START,
  });

  try {
    const response = await getUserInfo();
    if (response.ok) {
      debug('Authenticate OK');
      const userInfo: UserInfo = await response.json();
      dispatch(authSuccess());
      dispatch(userInfoSuccess(userInfo));
      fetchDataForOfflineMode(userInfo, dispatch);
      dispatch(initSuccessOnline(store));
    } else {
      debug('Authenticate KO');
      dispatch(userInfoFailure(response.status));
      dispatch(initSuccessOnline(store));
    }
  } catch (err: any) {
    if (err.name && err.name === 'TypeMismatchError') {
      // ICPO-471 - A TypeMismatchError is thrown by Edge when returning a 401 Error
      // cf https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8653298/
      debug('Authenticate KO (Edge)', err);
      dispatch(userInfoFailure(401));
    } else {
      debug('Authenticate Error', err);
      dispatch(authFailure('Pas de réseau'));
    }
  }
};

const fetchDataForOfflineMode = (userInfo: UserInfo, dispatch: AppDispatch) => {
  if (!userInfo.active) {
    return;
  }
  const { roles } = userInfo;
  if (roles.includes(COMPOSITION_READ) && !['/', '/trains'].includes(window.location.pathname)) {
    dispatch(initializeTrainFilters());
    dispatch(loadTrainSummaries());
    dispatch(loadTrains());
  }
  if (roles.includes(DAMAGE_REPORT_VALIDATE) || roles.includes(DAMAGE_REPORT_WRITE)) {
    dispatch(initializeDamageReportsFilters());
    dispatch(loadDamageReports());
  }
};
