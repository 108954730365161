import React from 'react';

import './preAdviceHeaderStyles.scss';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';

type PreAdviceHeaderProps = {
  preAdvice: PreAdviceEntity;
  onChangeTab?: (newIndex: number) => void;
};

const PreAdviceHeader = ({ preAdvice, onChangeTab }: PreAdviceHeaderProps) => {
  const { isMobile } = useViewportWidth();
  return (
    <div className="topbar">
      {preAdvice.isReceivedByVfli() ? (
        <h1 className="title received-pre-advice-title">Pré-annonce reçue</h1>
      ) : preAdvice.isValidated() ? (
        <h1 className="title validated-pre-advice-title">Pré-annonce validée</h1>
      ) : (
        <h1 className="title">Pré-annonce</h1>
      )}
      <div className="title-actions">
        {isMobile && (
          <button className="btn btn-icon btn-basic wagon-empty" onClick={() => onChangeTab?.(1)} type="button">
            <strong>&gt;</strong>
          </button>
        )}
      </div>
    </div>
  );
};

export default PreAdviceHeader;
