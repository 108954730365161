import { Engine, isEngine, isWagon, Vehicle, Wagon } from './Vehicle';
import { Draft } from '@reduxjs/toolkit';
import { TrainStep } from './Train';
import VehicleUtils from './VehicleUtils';

const getWagonPositionInArrayByIndex = (vehicles: readonly Vehicle[], newIndex: number): number =>
  vehicles.findIndex((vehicle) => isWagon(vehicle) && vehicle.index === newIndex);

const getEngineIndexInArrayByPosition = (vehicles: readonly Vehicle[], position: number): number =>
  vehicles.findIndex((vehicle) => vehicle.position === position);

const computeVehiclesPositions = (vehicles: readonly Vehicle[]) => {
  const vehiclesUpdated: Vehicle[] = [];

  let wagonIndex = 1;
  vehicles.forEach((vehicle, position) => {
    if (vehicle.position !== position) {
      let updatedVehicle: Vehicle = { ...vehicle, position };
      if (isWagon(updatedVehicle) && updatedVehicle.index !== wagonIndex) {
        (updatedVehicle as Draft<Wagon>).index = wagonIndex;
      }
      vehiclesUpdated.push(updatedVehicle);
    }

    if (isWagon(vehicle)) {
      wagonIndex++;
    }
  });

  return vehiclesUpdated;
};

const mapToVehiclesAndIndex = (vehicles: readonly Vehicle[], { id: stepId }: TrainStep): Vehicle[] => {
  let index = 0;
  return [...vehicles]
    .sort((v1, v2) => v1.position - v2.position)
    .map((vehicle) =>
      isWagon(vehicle)
        ? {
            ...vehicle,
            stepId,
            index: ++index,
          }
        : { ...vehicle, stepId },
    );
};

const getVehicle = (vehicles: readonly Vehicle[], id: string): Vehicle | null =>
  vehicles.find((vehicle) => vehicle.id === id) ?? null;

const getEngines = (vehicles: readonly Vehicle[]): Engine[] =>
  vehicles.filter((vehicle) => isEngine(vehicle)) as Engine[];

const getEngine = (vehicles: readonly Vehicle[], id: string): Engine | null =>
  (vehicles.find((vehicle) => isEngine(vehicle) && vehicle.id === id) as Engine) ?? null;

const getNbEngine = (vehicles: readonly Vehicle[]): number => getEngines(vehicles).length;

const getWagons = (vehicles: readonly Vehicle[]): Wagon[] => vehicles.filter((vehicle) => isWagon(vehicle)) as Wagon[];

const getWagon = (vehicles: readonly Vehicle[], id: string): Wagon | null =>
  (vehicles.find((vehicle) => isWagon(vehicle) && vehicle.id === id) as Wagon) ?? null;

const getNbWagon = (vehicles: readonly Vehicle[]): number => getWagons(vehicles).length;

const getWagonsOrVehicleEMs = (vehicles: readonly Vehicle[]): Vehicle[] =>
  vehicles.filter((vehicle) => isWagon(vehicle) || VehicleUtils.isVehicleEngine(vehicle));

const getNbWagonsOrVehicleEMs = (vehicles: readonly Vehicle[]): number => getWagonsOrVehicleEMs(vehicles).length;

const getFirstWagonPositionInArray = (vehicles: readonly Vehicle[]): number | null => {
  const wagons = getWagons(vehicles);
  if (wagons.length > 0) {
    return vehicles.indexOf(wagons[0]);
  }
  return null;
};

const getLastWagonPositionInArray = (vehicles: readonly Vehicle[]): number | null => {
  const wagons = getWagons(vehicles);
  if (wagons.length > 0) {
    return vehicles.indexOf(wagons[wagons.length - 1]);
  }
  return null;
};

const getWagonInsertionPositionData = (vehicles: readonly Vehicle[]) => {
  const rankOfLastWagonInVehiclesArray = getLastWagonPositionInArray(vehicles);
  if (rankOfLastWagonInVehiclesArray != null) {
    const lastWagon = vehicles[rankOfLastWagonInVehiclesArray] as Wagon;
    return {
      index: lastWagon.index + 1,
      position: lastWagon.position + 1,
      indexInVehiclesArray: rankOfLastWagonInVehiclesArray + 1,
    };
  }
  return {
    index: 1,
    position: vehicles.length,
    indexInVehiclesArray: vehicles.length,
  };
};

const getInsertionPositionForWagon = (vehicles: readonly Vehicle[], newIndex: number, insertAfter: boolean): number => {
  const position = getWagonPositionInArrayByIndex(vehicles, newIndex);

  return insertAfter ? position + 1 : position;
};

const getInsertionPositionForEngine = (
  vehicles: readonly Vehicle[],
  newPosition: number,
  insertAfter: boolean,
): number => {
  const position = getEngineIndexInArrayByPosition(vehicles, newPosition);
  if (position === -1) {
    return vehicles.length + 1;
  }
  return insertAfter ? position + 1 : position;
};

const getNbValidatedVehicle = (vehicles: readonly Vehicle[]): number =>
  vehicles.filter((vehicle) => VehicleUtils.isStatusValidated(vehicle)).length;

const isAllVehicleValidated = (vehicles: readonly Vehicle[]): boolean =>
  vehicles.every((vehicle) => VehicleUtils.isStatusValidated(vehicle));

const reorderWagons = (vehicles: readonly Vehicle[], wagonUpdated: Wagon): Vehicle[] => {
  const newVehicles = [...vehicles];
  const wagonInCache = getWagon(newVehicles, wagonUpdated.id)!;
  const positionToRemove = newVehicles.indexOf(wagonInCache);
  newVehicles.splice(positionToRemove, 1);
  const newPosition = getInsertionPositionForWagon(
    newVehicles,
    wagonUpdated.index,
    wagonUpdated.index > wagonInCache.index,
  );
  newVehicles.splice(newPosition, 0, wagonUpdated);

  return computeVehiclesPositions(newVehicles);
};

const reorderVehicles = (vehicles: readonly Vehicle[], vehicleUpdated: Vehicle): Vehicle[] => {
  const newVehicles = [...vehicles];
  const vehicleInCache = getVehicle(newVehicles, vehicleUpdated.id)!;
  const positionToRemove = newVehicles.indexOf(vehicleInCache);
  newVehicles.splice(positionToRemove, 1);
  const newPosition = getInsertionPositionForEngine(
    newVehicles,
    vehicleUpdated.position,
    vehicleUpdated.position > vehicleInCache.position,
  );
  newVehicles.splice(newPosition, 0, vehicleUpdated);

  // The position of the moved engine is already good, so explicitly mark it as updated.
  return computeVehiclesPositions(newVehicles).concat(vehicleUpdated);
};

const removeDuplicates = <T>(array: Array<T>): Array<T> => array.filter((elem, pos) => array.indexOf(elem) === pos);

const getWagonATEs = (wagons: readonly Wagon[]): string[] =>
  removeDuplicates(wagons.filter((wagon) => wagon.ateFileNumber).map((wagon) => wagon.ateFileNumber!));

const hasHazardousMaterials = (wagons: readonly Wagon[]): boolean =>
  wagons.some((wagon) => wagon.hazardousMaterials.length > 0);

const findVehicle = (vehicles: readonly Vehicle[], vehicleId: string) =>
  vehicles.find((vehicle) => vehicle.id === vehicleId);

const add = (acc: number, value: number | null | undefined) => acc + (value ?? 0);

/**
 * Compute wagons effective braked weight.
 *
 * In case of an LL train, the wagons among the first 5 "vehicles" count for 75% of their actual effective braked weight.
 */
const computeWagonsEffectiveBrakedWeight = (vehicles: readonly Vehicle[], ll: boolean): number => {
  const effectiveBrakedWeightIfWagon = (vehicle: Vehicle) =>
    isWagon(vehicle) ? vehicle.effectiveBrakedWeight ?? 0 : 0;
  let remainingPartialWagons = ll ? 5 : 0;
  return getWagonsOrVehicleEMs(vehicles)
    .map((vehicle) => {
      if (remainingPartialWagons > 0) {
        remainingPartialWagons -= isWagon(vehicle) && vehicle.doubleWagon ? 2 : 1;
        return effectiveBrakedWeightIfWagon(vehicle) * 0.75;
      }
      return effectiveBrakedWeightIfWagon(vehicle);
    })
    .reduce(add, 0);
};

const hasChargeD = (vehicles: readonly Vehicle[]) =>
  vehicles.some((vehicle) => isWagon(vehicle) && vehicle.charge === 'D');

const hasATE = (vehicles: readonly Vehicle[]) => vehicles.some((vehicle) => isWagon(vehicle) && vehicle.ateFileNumber);

const hasGbGauge = (vehicles: readonly Vehicle[]) => vehicles.some((vehicle) => isWagon(vehicle) && vehicle.gbGauge);

const hasAteOrGauge = (vehicles: readonly Vehicle[]) =>
  vehicles.some((vehicle) => isWagon(vehicle) && (vehicle.ateFileNumber || vehicle.gbGauge));

const hasDangerousGoods = (vehicles: readonly Vehicle[]) =>
  vehicles.some((vehicle) => isWagon(vehicle) && vehicle.hazardousMaterials.length > 0);

const hasAllRAT = (vehicles: readonly Vehicle[]) =>
  getNbWagon(vehicles) > 0 && vehicles.every((vehicle) => !isWagon(vehicle) || vehicle.rat);

export default {
  getEngineIndexInArrayByPosition,
  computeVehiclesPositions,
  mapToVehiclesAndIndex,
  getEngines,
  getEngine,
  getNbEngine,
  getWagons,
  getWagon,
  getNbWagon,
  getWagonsOrVehicleEMs,
  getNbWagonsOrVehicleEMs,
  getWagonATEs,
  getFirstWagonPositionInArray,
  getLastWagonPositionInArray,
  getWagonInsertionPositionData,
  getInsertionPositionForWagon,
  getNbValidatedVehicle,
  isAllVehicleValidated,
  reorderWagons,
  reorderVehicles,
  findVehicle,
  hasHazardousMaterials,
  computeWagonsEffectiveBrakedWeight,
  hasChargeD,
  hasATE,
  hasGbGauge,
  hasAteOrGauge,
  hasDangerousGoods,
  hasAllRAT,
};
