import { COMPOSITION_WRITE, RoleId } from '../../../security/userRoles';
import { change } from 'redux-form';
import { Vehicle } from '../../../model/Vehicle';
import { AppDispatch } from '../../../store/store';
import VehicleUtils from '../../../model/VehicleUtils';

export const isFieldReadOnly = (
  vehicle: Vehicle,
  currentUserRoles: RoleId[],
  fieldManuallyDisabled: boolean = false,
) => {
  return Boolean(
    fieldManuallyDisabled || !currentUserRoles.includes(COMPOSITION_WRITE) || VehicleUtils.isStatusValidated(vehicle),
  );
};

export const wagonFormName = 'wagonForm';
export const engineFormName = 'engineForm';

export const resetUtiFieldsInReduxForm = (dispatch: AppDispatch) => {
  dispatch(change(wagonFormName, 'nbUtiContainer', 0));
  dispatch(change(wagonFormName, 'nbUtiTrailer', 0));
  dispatch(change(wagonFormName, 'nbUtiVehicle', 0));
  dispatch(change(wagonFormName, 'nbUtiOther', 0));
  dispatch(change(wagonFormName, 'manualEvp', null));
  dispatch(change(wagonFormName, 'noUti', true));
};

export const uncheckNoUtiInReduxForm = (dispatch: AppDispatch) => {
  dispatch(change(wagonFormName, 'noUti', false));
};

export const updateCommandWithUtiFieldsReset = (updateCommand: object): object => ({
  ...updateCommand,
  nbUtiContainer: 0,
  nbUtiTrailer: 0,
  nbUtiVehicle: 0,
  nbUtiOther: 0,
  manualEvp: null,
  noUti: true,
});

export const updateCommandWithUtiUnchecked = (updateCommand: object): object => ({
  ...updateCommand,
  noUti: false,
});
