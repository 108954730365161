import { hideOverlay, showOverlay } from '../../commons/components/overlay/overlayDucks';
import { startGetMailingLists } from './mailingListsDucks';
import { startToast } from '../../commons/components/toast/toastDucks';
import apiHelper from '../../api/apiHelper';

// CONSTANTS

const START_NEW_MAILING_LIST = 'START_NEW_MAILING_LIST';

const START_EDIT_MAILING_LIST = 'START_EDIT_MAILING_LIST';
const CANCEL_EDIT_MAILING_LIST = 'CANCEL_EDIT_MAILING_LIST';

const START_UPDATE_MAILING_LIST = 'START_UPDATE_MAILING_LIST';
const SUCCESS_UPDATE_MAILING_LIST = 'SUCCESS_UPDATE_MAILING_LIST';
const FAILURE_UDPATE_MAILING_LIST = 'FAILURE_UDPATE_MAILING_LIST';

const START_CREATE_MAILING_LIST = 'START_CREATE_MAILING_LIST';
const SUCCESS_CREATE_MAILING_LIST = 'SUCCESS_CREATE_MAILING_LIST';
const FAILURE_CREATE_MAILING_LIST = 'FAILURE_CREATE_MAILING_LIST';

const START_DELETE_MAILING_LIST = 'START_DELETE_MAILING_LIST';
const SUCCESS_DELETE_MAILING_LIST = 'SUCCESS_DELETE_MAILING_LIST';
const FAILURE_DELETE_MAILING_LIST = 'FAILURE_DELETE_MAILING_LIST';

// REDUCER

const defaultMailingList = {
  name: '',
  markets: [],
  startStation: {},
  endStation: {},
  emails: [],
  emailsProxy: '',
};

const initialState = {
  data: { ...defaultMailingList },
  error: false,
};

const mailingListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_NEW_MAILING_LIST:
      return {
        ...state,
        data: { ...defaultMailingList },
        isNew: true,
      };
    case START_EDIT_MAILING_LIST:
      return {
        ...state,
        data: action.payload,
        isNew: false,
      };
    case CANCEL_EDIT_MAILING_LIST:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

// ACTIONS

const getStationVal = (station) => (station && station.label && station.label.length > 1 ? station : null);

const emailsListStringToArray = (str) =>
  str
    .replace(/ /g, ',')
    .split(',')
    .map((email) => email.trim())
    .filter((email) => email.length);

export const startNewMailingList = () => (dispatch) => {
  dispatch({ type: START_NEW_MAILING_LIST });
  dispatch(showOverlay('mailing-list'));
};

export const startEditMailingList = (mailingList) => (dispatch) => {
  dispatch({
    type: START_EDIT_MAILING_LIST,
    payload: mailingList,
  });
  dispatch(showOverlay('mailing-list'));
};

export const cancelEditMailingList = () => (dispatch) => {
  dispatch({
    type: CANCEL_EDIT_MAILING_LIST,
  });
  dispatch(hideOverlay());
};

const successUpdateMailingList = (mailingListUpdated) => ({
  type: SUCCESS_UPDATE_MAILING_LIST,
  payload: mailingListUpdated,
});

const failureUpdateMailingList = () => ({
  type: FAILURE_UDPATE_MAILING_LIST,
});

export const startUpdateMailingList = (mailingList) => async (dispatch) => {
  dispatch({ type: START_UPDATE_MAILING_LIST, payload: mailingList });

  const updatedMailingList = {
    ...mailingList,
    startStation: getStationVal(mailingList.startStation),
    endStation: getStationVal(mailingList.endStation),
    emails: emailsListStringToArray(mailingList.emailsProxy),
  };

  try {
    await apiHelper.put(`/api/mailing-lists/${mailingList.id}`, updatedMailingList);
    dispatch(successUpdateMailingList(mailingList));
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'La liste de diffusion a été modifiée.', className: 'success' }));
    dispatch(startGetMailingLists());
  } catch (e) {
    dispatch(failureUpdateMailingList());
    dispatch(startToast({ text: "Erreur : la liste de diffusion n'a pas été modifiée.", className: 'error' }));
  }
};

const successCreateMailingList = (mailingListCreated) => ({
  type: SUCCESS_CREATE_MAILING_LIST,
  payload: mailingListCreated,
});

const failureCreateMailingList = () => ({
  type: FAILURE_CREATE_MAILING_LIST,
});

export const startCreateMailingList = (mailingList) => async (dispatch) => {
  dispatch({
    type: START_CREATE_MAILING_LIST,
    payload: mailingList,
  });

  const newMailingList = {
    ...mailingList,
    startStation: getStationVal(mailingList.startStation),
    endStation: getStationVal(mailingList.endStation),
    emails: emailsListStringToArray(mailingList.emailsProxy),
  };

  try {
    await apiHelper.post('/api/mailing-lists', newMailingList);
    dispatch(successCreateMailingList(mailingList));
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'La liste de diffusion a été créée.', className: 'success' }));
    dispatch(startGetMailingLists());
  } catch (e) {
    dispatch(failureCreateMailingList());
    dispatch(startToast({ text: "Erreur : la liste de diffusion n'a pas été créée.", className: 'error' }));
  }
};

const successDeleteMailingList = () => ({
  type: SUCCESS_DELETE_MAILING_LIST,
});

const failureDeleteMailingList = () => ({
  type: FAILURE_DELETE_MAILING_LIST,
});

export const startDeleteMailingList = (mailingList) => () => async (dispatch) => {
  dispatch({ type: START_DELETE_MAILING_LIST });
  try {
    await apiHelper.delete(`/api/mailing-lists/${mailingList.id}`);
    dispatch(successDeleteMailingList());
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'La liste de diffusion a été supprimée.', className: 'success' }));
    dispatch(startGetMailingLists());
  } catch (e) {
    dispatch(failureDeleteMailingList());
    dispatch(startToast({ text: "Erreur : la liste de diffusion n'a pas été supprimée.", className: 'error' }));
  }
};

export default mailingListsReducer;
