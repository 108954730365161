import React, { useState } from 'react';
import { Field } from 'redux-form';

import HazardousMaterialHeader from './HazardousMaterialHeader';
import HazardousMaterialPlate from './HazardousMaterialPlate';
import HazardousMaterialLabel from './HazardousMaterialLabel';
import Rid from './Rid';
import Input from '../input/Input';
import { formatDecimalAndSeparateThousands } from '../../redux-form/valueFormatter';
import { normalizePositiveFloatTwoDecimal } from '../../redux-form/valueNormalizer';
import { parseToFloat } from '../../redux-form/valueParser';
import './hazardousMaterialStyles.scss';
import { FieldArrayFieldsProps } from 'redux-form/lib/FieldArray';
import { WagonHazardousMaterialFormValues } from '../vehicles/edit/wagon/WagonForm';
import { Wagon } from '../../model/Vehicle';
import { AppAction } from '../../store/store';
import { HazardousMaterial } from '../../model/templates';

type HazardousMaterialFormFieldsProps = {
  fieldName: string;
  formName: string;
  hazardousMaterialFields: FieldArrayFieldsProps<WagonHazardousMaterialFormValues>;
  onDelete: (wagon: Wagon, index: number) => AppAction;
  onSave: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
  onSaveWeight?: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
  position: number;
  readOnly: boolean;
  wagon: Wagon;
};

const HazardousMaterialFormFields = ({
  fieldName,
  formName,
  hazardousMaterialFields,
  onDelete,
  onSave,
  onSaveWeight,
  position,
  readOnly,
  wagon,
}: HazardousMaterialFormFieldsProps) => {
  const [hazardousMaterialCandidates, setHazardousMaterialCandidates] = useState<HazardousMaterial[]>([]);

  if (!wagon.hazardousMaterials[position]) {
    return null;
  }
  return (
    <div className="hazmat">
      <HazardousMaterialHeader
        deleteAction={onDelete}
        hazardousMaterialFields={hazardousMaterialFields}
        position={position}
        readOnly={readOnly}
        wagon={wagon}
      />
      <div className="fields">
        <HazardousMaterialPlate
          fieldName={fieldName}
          formName={formName}
          saveAction={onSave}
          setHazardousMaterialCandidates={setHazardousMaterialCandidates}
          wagon={wagon}
          readOnly={readOnly}
        />
        <HazardousMaterialLabel
          formName={formName}
          hazardousMaterial={wagon.hazardousMaterials[position]}
          hazardousMaterialCandidates={hazardousMaterialCandidates}
          setHazardousMaterialCandidates={setHazardousMaterialCandidates}
          hazardousMaterialFieldName={fieldName}
          saveAction={onSave}
          wagon={wagon}
          readOnly={readOnly}
        />
        <Rid id={`rid-${position}`} name={`${fieldName}.hazardousMaterial.rid`} />

        <Field
          component={Input}
          data={wagon}
          disabled={readOnly}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText="Masse *"
          name={`${fieldName}.weight`}
          normalize={normalizePositiveFloatTwoDecimal}
          normalizeOnSave={parseToFloat}
          pattern="[0-9]*"
          saveAction={onSaveWeight ?? onSave}
          unit="kg"
        />
        <Field
          component={Input}
          data={wagon}
          disabled={readOnly}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText="Volume"
          name={`${fieldName}.volume`}
          normalize={normalizePositiveFloatTwoDecimal}
          normalizeOnSave={parseToFloat}
          placeholder="Facultatif"
          pattern="[0-9]*"
          saveAction={onSave}
          unit="m&#179;"
        />

        <div className="hazmat-images">
          {(wagon.hazardousMaterials[position].hazardousMaterial?.dangerLabels ?? []).map((label) => (
            <span className={`danger-label ${label}`} key={label} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HazardousMaterialFormFields;
