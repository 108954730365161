import { TrainStep, TrainSummary } from './Train';
import { ReadyForDispatchUpdateCommand } from './TrainDiff';

const stepHasSignedReadyForDispatch = (step: TrainStep): boolean => Boolean(step.readyForDispatchSignatures.length);

const trainHasSignedReadyForDispatch = (train: TrainSummary): boolean => {
  const manualSteps = train.steps.filter((step) => step.manualReadyForDispatch);
  return manualSteps.length > 0 && manualSteps.every((step) => step.hasSignedReadyForDispatch);
};

export const createEmptyReadyForDispatchDiff = (): ReadyForDispatchUpdateCommand => ({
  invalidate: [],
});

export default { stepHasSignedReadyForDispatch, trainHasSignedReadyForDispatch };
