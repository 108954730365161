import React from 'react';
import { NestedKeyOf, Sort, SortDirection } from '../../model/sort';

type SortTableHeaderProps<T> = {
  title: string;
  className?: string;
  field: NestedKeyOf<T>;
  sort: Sort<T>;
  onClick: (sort: Sort<T>) => void;
};

const directionsSymbol = <T,>(field: NestedKeyOf<T>, sort: Sort<T>) => {
  if (sort.direction === 0 || sort.field !== field) {
    return '';
  }
  if (sort.direction > 0) {
    return '▲';
  }
  return '▼';
};

const SortTableHeader = <T,>({ title, className, field, sort, onClick }: SortTableHeaderProps<T>) => (
  <div className={className}>
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        onClick({
          field,
          direction: sort.field === field ? -sort.direction : SortDirection.ASC,
        });
      }}
    >
      {title} {directionsSymbol(field, sort)}
    </a>
  </div>
);

export default SortTableHeader;
