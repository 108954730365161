/**
 * DamageReport component
 */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDamageReport, getOfflineState, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import DamageReportForm from './damage-report-form/DamageReportForm';
import GlobalError from '../../commons/components/GlobalError';
import { resetState } from './damageReportDucks';
import { loadDamageReport } from './damageReportLoadActions';
import { showSidebar } from '../../commons/components/sidebar/sidebarDucks';
import './damage-report-form/damageReportFormStyles.scss';
import DamageReportActions from './damage-report-actions/DamageReportActions';
import { openConfirmDialog } from '../../commons/components/modal/confirmModalDucks';

import './damageReportStyles.scss';
import { usePageTitle } from '../../commons/components/hooks';

const damageReportTitle = (damageReport) => {
  return `PVCA ${damageReport.getNumber()}`;
};
const DamageReportComponent = ({
  damageReport,
  roles,
  offline,
  openConfirmDialogStart,
  showSidebarStart,
  loadDamageReportStart,
  resetStateStart,
}) => {
  const { id } = useParams();
  useEffect(() => {
    showSidebarStart();
    loadDamageReportStart(id);
    return () => resetStateStart();
  }, [id, showSidebarStart, loadDamageReportStart, resetStateStart]);

  usePageTitle(damageReport.data && damageReport.data.id === id ? damageReportTitle(damageReport.data) : 'PVCA');

  if (damageReport.error) {
    let title;
    let message;
    switch (damageReport.error) {
      case 403:
        title = 'Accès refusé';
        message = "Vous n'avez pas accès à ce PVCA.";
        break;
      case 404:
        title = 'PVCA introuvable';
        message = 'Aucun PVCA trouvé pour ce numéro.';
        break;
      default:
        title = 'Erreur';
        message = "Une erreur inattendue s'est produite durant la récupération du PVCA.";
    }
    return <GlobalError title={title}>{message}</GlobalError>;
  }

  if (!damageReport.data) return <div className="loader" />;

  return (
    <div className="damage-report">
      <div className="damage-report-header">
        <h1 className="title">
          {damageReport.data.getNumber()
            ? `PVCA n° ${damageReport.data.getNumber()} - ${damageReport.data.getStatusText()}`
            : 'Nouveau PVCA'}
        </h1>
        <DamageReportActions
          roles={roles}
          damageReport={damageReport.data}
          offline={offline}
          openConfirmDialogStart={openConfirmDialogStart}
        />
      </div>
      <DamageReportForm damageReport={damageReport.data} loading={damageReport.loading} />
    </div>
  );
};

DamageReportComponent.propTypes = {
  damageReport: PropTypes.shape({}),
};

const selector = (state) => ({
  damageReport: getDamageReport(state),
  offline: getOfflineState(state),
  roles: selectSecurityContextRoles(state),
});

export default connect(selector, {
  showSidebarStart: showSidebar,
  loadDamageReportStart: loadDamageReport,
  openConfirmDialogStart: openConfirmDialog,
  resetStateStart: resetState,
})(DamageReportComponent);
