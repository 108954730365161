import PreAdviceHeader from './header/PreAdviceHeader';
import PreAdviceSummary from './summary/PreAdviceSummary';
import React from 'react';
import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';

type PreAdviceFormProps = {
  preAdvice: PreAdviceEntity;
  canModifyPreAdvices: boolean;
  onChangeTab?: (newIndex: number) => void;
};

const PreAdviceForm = ({ preAdvice, canModifyPreAdvices, onChangeTab }: PreAdviceFormProps) => (
  <div className="composition-form">
    <PreAdviceHeader preAdvice={preAdvice} onChangeTab={onChangeTab} />
    <div className="composition-form-inner">
      <PreAdviceSummary preAdvice={preAdvice} readOnly={!canModifyPreAdvices || preAdvice.isValidated()} />
    </div>
  </div>
);

export default PreAdviceForm;
