import { dbPromise, ICompoDBSchema } from '../db/db';
import { IDBPTransaction, IndexKey, IndexNames } from 'idb/build/entry';
import { StoreKey, StoreNames, StoreValue } from 'idb';
import debugFactory from 'debug';

export type Tx<Name extends StoreNames<ICompoDBSchema>> = IDBPTransaction<ICompoDBSchema, [Name]>;
export type Item<Name extends StoreNames<ICompoDBSchema>> = StoreValue<ICompoDBSchema, Name>;
export type Key<Name extends StoreNames<ICompoDBSchema>> = StoreKey<ICompoDBSchema, Name>;

export type SaveItemsOptions = { clear?: boolean };

export abstract class AbstractDbCache<Name extends StoreNames<ICompoDBSchema>, T = Item<Name>> {
  protected abstract getStoreName(): Name;

  private debug = debugFactory(`dbCache:${this.getStoreName()}`);

  protected getMaxResults() {
    return 50;
  }

  protected addItemMetadata(item: T): Item<Name> {
    return item as unknown as Item<Name>;
  }

  protected removeItemMetadata(dbItem: Item<Name>): T {
    return dbItem as unknown as T;
  }

  protected async connect<Mode extends 'readonly' | 'readwrite'>(mode: Mode) {
    const db = await dbPromise;
    const tx = db.transaction(this.getStoreName(), mode);
    const store = tx.store;
    return { store, tx };
  }

  protected async findItemsByIdPrefix(prefix: string, tx: Tx<Name>): Promise<T[]> {
    this.debug(`Finding items with id prefix "${prefix}"`);
    const dbItems = await tx.store.getAll(IDBKeyRange.bound(prefix, prefix + '\uffff'), this.getMaxResults());
    const res = dbItems.map(this.removeItemMetadata);
    this.debug(`Found ${res.length} items with id prefix "${prefix}"`);
    return res;
  }

  protected async findItemsByIndexPrefix(
    prefix: string,
    tx: Tx<Name>,
    indexName: IndexNames<ICompoDBSchema, Name>,
    maxResults?: number,
  ): Promise<T[]> {
    this.debug(`Finding items with index ${String(indexName)} prefix "${prefix}"`);
    const dbItems = await tx.store
      .index(indexName)
      .getAll(IDBKeyRange.bound(prefix, prefix + '\uffff'), maxResults ?? this.getMaxResults());
    const res = dbItems.map(this.removeItemMetadata);
    this.debug(`Found ${res.length} items with index ${String(indexName)} prefix "${prefix}"`);
    return res;
  }

  protected async findItemsByMatcher(tx: Tx<Name>, matcher: (item: Item<Name>) => boolean): Promise<T[]> {
    this.debug('Finding items by matcher');
    const res: T[] = [];
    let cursor = await tx.store.openCursor();
    while (cursor) {
      const item = cursor.value;
      if (matcher(item)) {
        res.push(this.removeItemMetadata(item));
        if (res.length >= this.getMaxResults()) {
          break;
        }
      }
      cursor = await cursor.continue();
    }
    this.debug(`Found ${res.length} items by matcher`);
    return res;
  }

  protected async findItemByIndex<IndexName extends IndexNames<ICompoDBSchema, Name>>(
    value: IndexKey<ICompoDBSchema, Name, IndexName>,
    indexName: IndexName,
  ): Promise<T | undefined> {
    this.debug(`Finding item "${value}" with index ${String(indexName)}`);
    const { store } = await this.connect('readonly');
    const dbItem = await store.index(indexName).get(value);
    this.debug(`${dbItem ? '' : 'Not '}Found item "${value}" with index ${String(indexName)}`);
    return dbItem && this.removeItemMetadata(dbItem);
  }

  public findItemById = async (id: Key<Name>): Promise<T | undefined> => {
    this.debug(`Finding item "${id}"`);
    const { store } = await this.connect('readonly');
    const dbItem = await store.get(id);
    this.debug(`${dbItem ? '' : 'Not '}Found item "${id}"`);
    return dbItem && this.removeItemMetadata(dbItem);
  };

  public findItemsByIds = async (ids: Key<Name>[]): Promise<T[]> => {
    this.debug(`Finding ${ids.length} items by id`);
    const { store } = await this.connect('readonly');
    const result = (await Promise.all(ids.map((id) => store.get(id))))
      .filter((item) => item)
      .map((item) => this.removeItemMetadata(item!));
    this.debug(`Found ${result.length} items by id`);
    return result;
  };

  public findAllItems = async (): Promise<T[]> => {
    this.debug(`Finding all items`);
    const { store } = await this.connect('readonly');
    const dbItems = await store.getAll();
    const result = dbItems.map(this.removeItemMetadata);
    this.debug(`Found all ${result.length} items`);
    return result;
  };

  public saveItems = async (items: T[], { clear = true }: SaveItemsOptions = {}): Promise<void> => {
    this.debug(`Saving ${items.length} items`);
    const { store, tx } = await this.connect('readwrite');

    if (clear) {
      await store.clear();
      this.debug('Cleared store');
    }
    for (const item of items) {
      await store.put(this.addItemMetadata(item));
    }
    await tx.done;
    this.debug(`Saved ${items.length} items`);
  };
}
