import { v4 as uuid } from 'uuid';
import containsErrors, { validateWagon } from '../../validation/validationUtils';
import { COMPOSITION_VALIDATE_WAGONS_ON_COPY, RoleId } from '../../security/userRoles';
import { IMPORT_ERROR_CATEGORIES } from '../../../pre-advices/wagon/preAdviceWagonFormUtils';
import wagonCache from '../../templates/wagonCache';
import { ImportSourceType, PRE_ADVICE_TYPE, TRAIN_TYPE } from '../../model/importSource';
import { Wagon, WagonHazardousMaterial } from '../../model/Vehicle';
import PreAdviceWagonEntity from '../../entity/PreAdviceWagonEntity';
import PreAdviceWagonImportErrorEntity from '../../entity/PreAdviceWagonImportErrorEntity';
import PreAdviceHazardousMaterialEntity from '../../entity/PreAdviceHazardousMaterialEntity';
import { ValidationStatus } from '../../model/common';

type CopyUtiCommand = {
  inputWagon: Wagon;
  isWagonToBeLoaded: boolean;
  isTargetTrainCombined: boolean;
};

type UtiFields = {
  nbUtiContainer: number | null;
  nbUtiOther: number | null;
  nbUtiTrailer: number | null;
  nbUtiVehicle: number | null;
  manualEvp: number | null;
  noUti: boolean | null;
};

const computeUtiFields = (command: CopyUtiCommand): UtiFields => {
  if (!command.isTargetTrainCombined) {
    return {
      nbUtiVehicle: null,
      nbUtiOther: null,
      nbUtiTrailer: null,
      nbUtiContainer: null,
      noUti: null,
      manualEvp: null,
    };
  }
  if (!command.isWagonToBeLoaded) {
    return {
      nbUtiVehicle: 0,
      nbUtiOther: 0,
      nbUtiTrailer: 0,
      nbUtiContainer: 0,
      noUti: true,
      manualEvp: null,
    };
  }

  const { inputWagon } = command;
  return {
    nbUtiVehicle: inputWagon.nbUtiVehicle || 0,
    nbUtiOther: inputWagon.nbUtiOther || 0,
    nbUtiTrailer: inputWagon.nbUtiTrailer || 0,
    nbUtiContainer: inputWagon.nbUtiContainer || 0,
    noUti: typeof inputWagon.noUti === 'boolean' ? inputWagon.noUti : inputWagon.loadWeight === 0,
    manualEvp: inputWagon.manualEvp,
  };
};

export const copyCompositionWagonIntoComposition = ({
  inputWagon,
  stepId,
  position,
  index,
  isWagonToBeLoaded,
  isTargetTrainCombined,
}: {
  inputWagon: Wagon;
  stepId: string;
  position: number;
  index: number;
  isWagonToBeLoaded: boolean;
  isTargetTrainCombined: boolean;
}): Wagon => {
  const id = uuid();
  const date = new Date().toISOString();
  return {
    id: id,
    stepId,
    position,
    index,
    status: inputWagon.status === 'EMPTY' ? 'EMPTY' : 'NOT_VALIDATED',
    type: 'wagon',
    creationDate: date,
    updateDate: date,

    // Referential data
    registration: inputWagon.registration || '',
    length: inputWagon.length || null,
    tare: inputWagon.tare || null,
    nbAxles: inputWagon.nbAxles || null,
    owner: inputWagon.owner || null,
    doubleWagon: inputWagon.doubleWagon,
    loadWeight: computeOutputWagonLoadWeight(inputWagon.loadWeight, isWagonToBeLoaded),
    effectiveBrakedWeight: null,
    hazardousMaterials: computeOutputWagonHazardousMaterials(
      inputWagon.hazardousMaterials,
      id,
      stepId,
      isWagonToBeLoaded,
    ),
    ateFileNumber: null,
    gbGauge: false,
    charge: isWagonToBeLoaded ? inputWagon.charge : null,
    rat: false,
    ...computeUtiFields({ inputWagon, isWagonToBeLoaded, isTargetTrainCombined }),
  };
};

const transformPreAdviceHazardousMaterial = (
  preAdviceHazardousMaterial: PreAdviceHazardousMaterialEntity,
): WagonHazardousMaterial => ({
  hazardousMaterial: {
    id: preAdviceHazardousMaterial.hazardousMaterial?.id,
    dangerIdentificationNumber: preAdviceHazardousMaterial.hazardousMaterial?.dangerIdentificationNumber,
    unitedNationsCode: preAdviceHazardousMaterial.hazardousMaterial?.unitedNationsCode,
    label: preAdviceHazardousMaterial.hazardousMaterial?.label,
    rid: preAdviceHazardousMaterial.hazardousMaterial?.rid,
    packingGroup: preAdviceHazardousMaterial.hazardousMaterial?.packingGroup,
    limitedQuantity: preAdviceHazardousMaterial.hazardousMaterial?.limitedQuantity,
    dangerLabels: preAdviceHazardousMaterial.hazardousMaterial?.dangerLabels,
  },
  weight: preAdviceHazardousMaterial.weight ?? null,
  volume: preAdviceHazardousMaterial.volume ?? null,
});

/**
 * Update the status of the given wagon to validate it if possible.
 */
const updateWagonStatus = (trainWagon: Wagon, inputWagon: PreAdviceWagonEntity): { status?: ValidationStatus } => {
  const hasNoErrorOtherThanMissingChargeAndOwner = () => {
    const validationErrors = validateWagon(trainWagon, false, 999);
    delete validationErrors.charge;
    delete validationErrors.owner;
    return !containsErrors(validationErrors);
  };

  const hasUnknownHazardousMaterials = () => {
    return (inputWagon.errors ?? []).some(
      (error: PreAdviceWagonImportErrorEntity) => error.type === IMPORT_ERROR_CATEGORIES.HAZARDOUS_MATERIAL,
    );
  };

  if (hasNoErrorOtherThanMissingChargeAndOwner() && !hasUnknownHazardousMaterials()) {
    return { status: 'VALIDATED' };
  }
  return {};
};

const computeUtiFieldsFromPreAdviceWagon = (
  preAdviceWagon: PreAdviceWagonEntity,
  isWagonToBeLoaded: boolean,
  isTargetTrainCombined: boolean,
): UtiFields => {
  if (!isTargetTrainCombined) {
    return {
      nbUtiVehicle: null,
      nbUtiOther: null,
      nbUtiTrailer: null,
      nbUtiContainer: null,
      noUti: null,
      manualEvp: null,
    };
  }
  return {
    nbUtiVehicle: 0,
    nbUtiOther: 0,
    nbUtiTrailer: 0,
    nbUtiContainer: 0,
    noUti: Boolean(!preAdviceWagon.loadWeight || !isWagonToBeLoaded),
    manualEvp: null,
  };
};

/**
 * Return a {@link Wagon} matching the given {@link PreAdviceWagonEntity}.
 *
 * If the "shouldTryToValidateWagon" parameter equals true, then the returned
 * wagon will be validated if the train wagon has validation error other than the
 * missing charge.
 */
export const copyPreAdviceWagonIntoComposition = async ({
  inputWagon,
  stepId,
  position,
  index,
  shouldTryToValidateWagon,
  isWagonToBeLoaded,
  isTargetTrainCombined,
}: {
  inputWagon: PreAdviceWagonEntity;
  stepId: string;
  position: number;
  index: number;
  shouldTryToValidateWagon: boolean;
  isWagonToBeLoaded: boolean;
  isTargetTrainCombined: boolean;
}): Promise<Wagon> => {
  const outputWagonId = uuid();
  const date = new Date().toISOString();

  let outputWagon: Wagon = {
    id: outputWagonId,
    stepId,
    position,
    index,
    status: inputWagon.status === 'EMPTY' ? inputWagon.status : 'NOT_VALIDATED',
    type: 'wagon',

    // Referential data
    registration: inputWagon.referentialData.registration,
    length: inputWagon.referentialData.length,
    tare: inputWagon.referentialData.tare,
    nbAxles: inputWagon.referentialData.nbAxles,
    doubleWagon: inputWagon.referentialData.doubleWagon,

    loadWeight: computeOutputWagonLoadWeight(inputWagon.loadWeight, isWagonToBeLoaded),
    effectiveBrakedWeight: inputWagon.effectiveBrakedWeight,

    hazardousMaterials: computeOutputPreAdviceWagonHazardousMaterials(inputWagon.hazardousMaterials, isWagonToBeLoaded),

    ateFileNumber: inputWagon.ateNumbers?.frenchAteNumber || null,
    gbGauge: false,

    // UTI
    ...computeUtiFieldsFromPreAdviceWagon(inputWagon, isWagonToBeLoaded, isTargetTrainCombined),

    // Reset data
    charge: doCalculateCharge(inputWagon, isWagonToBeLoaded),
    rat: false,

    creationDate: date,
    updateDate: date,

    owner: null,
  };
  if (inputWagon.referentialData.registration) {
    const wagonTemplate = await wagonCache.findItemById(inputWagon.referentialData.registration);
    if (wagonTemplate?.ownerId && wagonTemplate?.ownerName) {
      outputWagon = {
        ...outputWagon,
        owner: {
          id: wagonTemplate.ownerId,
          name: wagonTemplate.ownerName,
        },
      };
    }
  }
  if (shouldTryToValidateWagon) {
    outputWagon = { ...outputWagon, ...updateWagonStatus(outputWagon, inputWagon) };
  }

  return outputWagon;
};

/**
 * Return outputWagonLoadWeight regarding isWagonToBeLoaded
 */
const computeOutputWagonLoadWeight = (inputLoadWeight: number | null, isWagonToBeLoaded: boolean): number | null => {
  if (isWagonToBeLoaded) {
    return inputLoadWeight ?? null;
  }
  return null;
};

/**
 * Return outputWagonHazardousMaterials Materials regarding isWagonToBeLoaded
 */
const computeOutputWagonHazardousMaterials = (
  inputHazardousMaterials: readonly WagonHazardousMaterial[],
  outputWagonId: string,
  outputStepId: string,
  isWagonToBeLoaded: boolean,
): readonly WagonHazardousMaterial[] => {
  if (isWagonToBeLoaded) {
    return inputHazardousMaterials.map((hazmat) => ({
      ...hazmat,
      id: uuid(),
      version: 0,
    }));
  }
  return [];
};

const computeOutputPreAdviceWagonHazardousMaterials = (
  hazardousMaterials: PreAdviceHazardousMaterialEntity[],
  isWagonToBeLoaded: boolean,
): WagonHazardousMaterial[] => {
  if (isWagonToBeLoaded) {
    return hazardousMaterials.map((hazardousMaterial) => transformPreAdviceHazardousMaterial(hazardousMaterial));
  }
  return [];
};

const doCalculateCharge = (inputWagon: PreAdviceWagonEntity, isWagonToBeLoaded: boolean): 'C' | 'D' | null => {
  if (!isWagonToBeLoaded) {
    return null;
  }
  if (inputWagon.charge) {
    return inputWagon.charge;
  }
  const nbAxles = inputWagon.referentialData?.nbAxles;
  const tare = inputWagon.referentialData?.tare;
  // Does not accept 0 for the tare and the number of axles
  // but accept 0 for the load weight.
  if (!nbAxles || !tare || typeof inputWagon.loadWeight !== 'number') {
    return null;
  }

  return calculateCharge(inputWagon.getTotalWeight(), nbAxles);
};

export const calculateCharge = (totalWeight: number, nbAxles: number): 'C' | 'D' | null => {
  const ratio = totalWeight / nbAxles;
  const chargeCMax = 20000;
  const chargeDMax = 22500;
  if (ratio <= chargeCMax) {
    return 'C';
  }
  if (ratio <= chargeDMax) {
    return 'D';
  }
  return null;
};

/**
 * Return a boolean indicating whether we should try to validate the
 * copied wagon.
 */
export const shouldTryToValidateWagons = (
  { roles }: { roles: RoleId[] },
  sourceType: ImportSourceType,
  targetType: ImportSourceType,
) => {
  return (
    sourceType === PRE_ADVICE_TYPE && targetType === TRAIN_TYPE && roles.includes(COMPOSITION_VALIDATE_WAGONS_ON_COPY)
  );
};
