/**
 * Trains component
 */
import React from 'react';
import TrainsTopBar from './trains-topbar/TrainsTopBar';
import TrainsTable from './trains-table/TrainsTable';
import { usePageTitle } from '../commons/components/hooks';

const Trains = () => {
  usePageTitle('Trains');
  return (
    <div className="content-parent table-page" onClick={() => false}>
      <TrainsTopBar />
      <TrainsTable />
    </div>
  );
};

export default Trains;
