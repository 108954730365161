import { Link } from 'react-router-dom';
import TrainUtils from '../../commons/model/TrainUtils';
import classNames from 'classnames';
import { formatOptionalValue, getStatusClasses } from '../../commons/model/common';
import { compositionHasStepProblems, TRAIN_PROBLEM_STATUSES, TrainSummary } from '../../commons/model/Train';
import Tooltip from 'rc-tooltip';
import warningImg from '../../commons/assets/images/warning.svg';
import helpers from '../../commons/helpers/helpers';
import BrakingBulletinUtils from '../../commons/model/BrakingBulletinUtils';
import TraceabilityUtils from '../../commons/model/TraceabilityUtils';
import ReadyForDispatchUtils from '../../commons/model/ReadyForDispatchUtils';
import React from 'react';

type TrainItemProps = {
  train: TrainSummary & { sortStepIndex: number };
  canSeeBB: boolean;
};

const DATE_TIME_FORMAT_FULL = 'D MMM à HH:mm';

const TrainItem = ({ train, canSeeBB }: TrainItemProps) => {
  const nbTrainNo = new Set(train.steps.map((step) => step.trainNumber)).size;
  return (
    <Link
      to={TrainUtils.linkToTrain(train.id)}
      className={classNames('table-body-row', getStatusClasses({ status: TrainUtils.trainStatus(train) }))}
    >
      <div className="cell-train-header">
        <div className="cell-train-no">{formatOptionalValue(train.steps[train.sortStepIndex].trainNumber)}</div>
        <div className="cell-contract">{formatOptionalValue(train.steps[train.sortStepIndex].marketName)}</div>
      </div>

      {train.steps.map((step, i) => (
        <div
          className={classNames('cell-train-step', {
            'active-departure': i === train.sortStepIndex,
            'active-arrival': i === train.sortStepIndex + 1,
          })}
          key={step.id}
        >
          {compositionHasStepProblems(step) && (
            <div className="cell-warning">
              <Tooltip
                placement="top"
                trigger={['hover', 'click']}
                overlay={TRAIN_PROBLEM_STATUSES[step.problemStatus]}
              >
                <img src={warningImg} alt={TRAIN_PROBLEM_STATUSES[step.problemStatus]} />
              </Tooltip>
            </div>
          )}
          {nbTrainNo > 1 && (i === 0 || train.steps[i - 1].trainNumber !== step.trainNumber) && (
            <div className="cell-step-no">{formatOptionalValue(step.trainNumber)}</div>
          )}
          <div className="cell-date">{helpers.dateTimeFormat(step.startDate, DATE_TIME_FORMAT_FULL)}</div>
          <div className="cell-location">{step.startLocationLabel}</div>
          <div className={classNames('cell-status', getStatusClasses(step))}></div>
        </div>
      ))}
      <div
        className={classNames('cell-train-arrival', {
          'active-arrival': train.steps.length - 1 === train.sortStepIndex,
        })}
      >
        <div className="cell-date">
          {helpers.dateTimeFormat(train.steps[train.steps.length - 1].endDate, DATE_TIME_FORMAT_FULL)}
        </div>
        <div className="cell-location">{train.steps[train.steps.length - 1].endLocationLabel}</div>
      </div>

      {canSeeBB && (
        <div className="statuses">
          <div className={classNames('status', { on: BrakingBulletinUtils.trainHasSignedBrakingBulletin(train) })}>
            BF
          </div>
          <div className={classNames('status', { on: TraceabilityUtils.trainHasSignedTraceability(train) })}>
            Traçabilité
          </div>
          <div
            className={classNames('status', {
              on: ReadyForDispatchUtils.trainHasSignedReadyForDispatch(train),
            })}
          >
            PPE
          </div>
        </div>
      )}
    </Link>
  );
};

export default TrainItem;
