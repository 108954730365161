import React, { useEffect } from 'react';

import { getOwners, selectOverlay, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { loadOwners, sortOwners } from './ownersDucks';
import { displayExistingOwnerForm, displayNewOwnerForm } from './owner-details/ownerDucks';
import OwnersTable from './owners-table/OwnersTable';
import GlobalError from '../../commons/components/GlobalError';
import { OWNER_WRITE } from '../../commons/security/userRoles';
import OwnerDetails from './owner-details/OwnerDetails';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { useViewportWidth } from '../../commons/responsive/hooks';
import { Owner } from '../../commons/model/templates';
import classNames from 'classnames';
import { usePageTitle } from '../../commons/components/hooks';

/**
 * Component representing the "owners" page.
 */
const Owners = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectSecurityContextRoles);
  const { ownersCollection, error, loading, sort } = useAppSelector(getOwners);
  const overlay = useAppSelector(selectOverlay);
  const { isMobile } = useViewportWidth();
  const canModify = roles.includes(OWNER_WRITE);

  usePageTitle('Détenteurs');

  useEffect(() => {
    dispatch(loadOwners());
  }, [dispatch]);

  const handleLoadOwner = (owner: Owner) => dispatch(displayExistingOwnerForm(owner));
  const handleCreateOwner = () => dispatch(displayNewOwnerForm());
  const handleSortOwners = (sort: any) => dispatch(sortOwners(sort));

  if (loading) {
    return (
      <div className="maincontent">
        <div className="loader" />
      </div>
    );
  }
  if (error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des détenteurs.
      </GlobalError>
    );
  }

  return (
    <div className="content-parent table-page">
      <div className="topbar">
        <h1 className="title">Détenteurs</h1>
        {canModify && (
          <button
            className={classNames('btn btn-success', isMobile && 'btn-round plus')}
            onClick={handleCreateOwner}
            type="button"
          >
            Ajouter
          </button>
        )}
      </div>
      <OwnersTable
        canModify={canModify}
        changeSort={handleSortOwners}
        requestDisplayExistingOwnerForm={handleLoadOwner}
        owners={ownersCollection}
        sort={sort}
      />
      {overlay.active === 'owner' && <OwnerDetails />}
    </div>
  );
};

export default Owners;
