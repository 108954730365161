/**
 * Signature Modal component
 */
import React from 'react';
import './signatureModalStyles.scss';

const SignatureModal = ({ hidden, canvas, resetSignature, closeSignatureDialog, handleSubmit }) => {
  if (hidden) {
    return <span />;
  }
  return (
    <div>
      <div className="signature-modal">
        <div className="modal-fade" onClick={closeSignatureDialog} />
        <div className="modal-body">
          <div className="modal-title">Signature du tiers</div>
          {canvas}
          <button type="button" className="modal-btn success" onClick={handleSubmit}>
            Signer
          </button>
          <button type="button" className="modal-btn modal-btn-cancel" onClick={closeSignatureDialog}>
            Annuler
          </button>
          <button type="button" className="modal-btn" onClick={resetSignature}>
            Effacer
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;
