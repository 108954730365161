import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Input from '../input/Input';
import StationField from '../input/StationField';
import DateTimeField from '../input/DateTimeField';
import MarketField from '../input/MarketField';
import { asyncValidateMarket, asyncValidateStation, asyncValidation } from '../input/asyncValidation';
import { ActionCreator } from 'redux';
import { Station } from '../../model/templates';
import { DateString, IdName } from '../../model/common';

type CopyWagonsFormProps = {
  searchImportSources: ActionCreator<any>;
};

export type CopyWagonsFormData = {
  trainNumber?: string;
  startStation?: Station;
  endStation?: Station;
  startDateTime?: DateString;
  market?: IdName<number>;
};

const CopyWagonsForm = ({ searchImportSources }: CopyWagonsFormProps) => (
  <form className="fields" autoComplete="off">
    <Field
      className="composition-import-train-number"
      component={Input}
      name="trainNumber"
      placeholder="Filtrer par n°"
      saveAction={searchImportSources}
      inputProps={{
        pattern: '[0-9]*',
        inputMode: 'numeric',
        maxLength: 6,
      }}
    />
    <StationField
      name="startStation.label"
      className="icon route-from"
      placeholder="De"
      saveAction={searchImportSources}
      data={{}}
    />
    <StationField
      name="endStation.label"
      className="icon route-to"
      placeholder="A"
      saveAction={searchImportSources}
      data={{}}
    />
    <DateTimeField name="startDateTime" className="icon" saveAction={searchImportSources} data={{}} />
    <MarketField
      name="market.name"
      className="icon star"
      placeholder="Marché"
      saveAction={searchImportSources}
      data={{}}
    />
  </form>
);

export default reduxForm<CopyWagonsFormData, CopyWagonsFormProps>({
  enableReinitialize: true,
  ...asyncValidation({
    'market.name': asyncValidateMarket,
    'startStation.label': asyncValidateStation,
    'endStation.label': asyncValidateStation,
  }),
})(CopyWagonsForm);
