/**
 * DateTime field component
 */
import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import DateTime, { DatetimepickerProps } from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import classNames from 'classnames';
import { AppAction } from '../../store/store';

type DateTimeProps<T> = {
  name: string;
  disabled?: boolean;
  saveAction?: (data: T, propertyToUpdate: any) => AppAction;
  blurAction?: () => any;
  /**
   * The time format.
   * If there is no value, then the time picker will not be displayed.
   */
  timeFormat?: string;
  labelText?: string;
  placeholder?: string;
  inputReadOnly?: boolean;
  data: T;
} & DatetimepickerProps;

const DateTimeComponent = <T,>({
  name,
  labelText,
  input,
  meta,
  placeholder,
  saveAction,
  data,
  disabled,
  inputReadOnly,
  className,
  timeFormat,
  blurAction,
  closeOnSelect: defaultCloseOnSelect,
  ...props
}: DateTimeProps<T> & WrappedFieldProps) => {
  const closeOnSelect = defaultCloseOnSelect ?? !timeFormat;

  return (
    <div
      className={classNames('input', {
        'input-label': labelText,
        'input-error': meta && meta.touched && meta.invalid,
        'input-valid': meta && meta.touched && meta.valid,
      })}
    >
      {labelText && <label htmlFor={name}>{labelText}</label>}
      <DateTime
        locale="fr"
        className={classNames('input', className)}
        dateFormat="D MMM YYYY"
        timeFormat={timeFormat ?? false}
        value={input.value ? moment(input.value) : ''}
        closeOnSelect={closeOnSelect}
        inputProps={{ placeholder, disabled, readOnly: inputReadOnly, name }}
        onChange={(value) => {
          if (!value || moment.isMoment(value)) {
            input.onChange(value);
          }
        }}
        onClose={(callbackValue) => {
          let wantedValue;
          if (closeOnSelect) {
            // the onClose is called when a value or nothing is selected
            // if nothing is selected, keep the displayed value
            // if a value is selected, use this value
            wantedValue = !callbackValue ? moment(input.value) : callbackValue;
          } else {
            // Workaround react-datetime bug:
            // - in date-time mode, the callback value is incorrect (set at midnight) Cf https://github.com/arqex/react-datetime/issues/814
            // - If closeOnSelect is set (i.e. in date mode), the input value is incorrect (the onChange callback has not been called yet)
            wantedValue = timeFormat ? moment(input.value) : callbackValue;
          }

          input.onBlur(wantedValue);

          if (saveAction) {
            // run saveAction only when the value has changed
            if (!wantedValue) {
              meta.dispatch(saveAction(data, { [input.name]: null }));
            } else if (moment.isMoment(wantedValue)) {
              meta.dispatch(saveAction(data, { [input.name]: wantedValue.local().toISOString() }));
            }
          }
          if (blurAction) blurAction();
        }}
        {...props}
      />

      {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
    </div>
  );
};

// @ts-ignore
const DateTimeField = <T,>({ ...props }: DateTimeProps<T>) => <Field component={DateTimeComponent} {...props} />;

export default DateTimeField;
