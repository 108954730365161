/**
 *
 * app.js
 *
 * This is the entry file for the application, mostly just setup and boilerplate
 * code. Routes are configured at the end of this file!
 *
 */
import './polyfills';
import debugFactory from 'debug';
import moment from 'moment';
import { initStart } from './commons/app-state/appStateActions';
import offlineManager from './commons/offline/offlineManager';
import initSentry from './commons/sentry/sentry';
import persistStoreCreator from './commons/offline/persistStoreProvider';

import './commons/styles/index.scss';

import store from './commons/store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const logsEnabled = localStorage.getItem('debug');
if (!logsEnabled || (logsEnabled === '*' && !window.location.host.startsWith('localhost'))) {
  // Setting default log level
  debugFactory.enable('*,-dbCache:*');
}

/**
 * Date formatting.
 * @see src/commons/helpers/helpers.js
 */
moment.locale('fr');
moment.updateLocale('fr', {
  monthsShort: ['Jan.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sep.', 'Oct.', 'Nov.', 'Déc.'],
});

// noinspection JSIgnoredPromiseFromCall
/**
 * Sentry logging provider initialization.
 */
initSentry();

/**
 * Offline mode initialization.
 */
offlineManager();

// Here we start the browser persistence
const callback = () => {
  store.dispatch(initStart(store));
};
persistStoreCreator(store, callback);

serviceWorkerRegistration.register({
  onUpdate: () => window.location.reload(),
});

export default { store };
