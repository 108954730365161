import { DateString, SignatureOrOffline, ValidationStatus } from './common';
import { Vehicle } from './Vehicle';
import { BrakingBulletin } from './BrakingBulletin';
import { Traceability } from './Traceability';
import { ReadyForDispatchSignature } from './ReadyForDispatch';

export type CompositionType = 'TRAIN';

export type TrainProblemStatus = 'STALLED' | 'ACCIDENT' | 'AGT_NON_AVAILABILITY' | 'DIVERSE' | 'OK' | 'CANCELLED';

export const TRAIN_PROBLEM_STATUSES: Record<TrainProblemStatus, string> = {
  STALLED: 'Calé',
  ACCIDENT: 'Accident',
  AGT_NON_AVAILABILITY: 'AGT',
  DIVERSE: 'Problème inconnu',
  OK: 'OK',
  CANCELLED: 'Annulé',
};

export type TrainSummary = Readonly<{
  id: string;
  steps: TrainStepSummary[];
}>;

export type TrainStepSummary = Readonly<{
  id: string;
  type: CompositionType;
  trainNumber: string;
  startDate: DateString;
  endDate: DateString;
  startLocationLabel: string;
  startStationId: string;
  endLocationLabel: string;
  endStationId: string;
  marketName: string;
  status: ValidationStatus;
  problemStatus: TrainProblemStatus;
  hasSignedBrakingBulletin: boolean;
  hasSignedTraceability: boolean;
  hasSignedReadyForDispatch: boolean;
  manuallyUpdated: boolean;
  manualBrakingBulletin: boolean;
  manualTraceability: boolean;
  manualReadyForDispatch: boolean;
  version: number;
}>;

export type Train = Readonly<{
  id: string;
  steps: TrainStep[];
}>;

export type CachedTrain = Readonly<{
  id: string;
  /**
   * CompositionIds of the steps (Not the stepId field)
   */
  stepIds: string[];
}>;

export type TrainStep = Readonly<{
  id: string;
  trainId: string;
  type: CompositionType;
  trainNumber: string;
  startDate: DateString;
  endDate: DateString;
  startLocationLabel: string;
  startStationId: string;
  endLocationLabel: string;
  endStationId: string;
  marketId: number;
  marketName: string;
  status: ValidationStatus;
  stepProblemStatus: TrainProblemStatus;
  version: number;
  validatedDate: DateString | null;
  validatedBy: string | null;
  updateDate: DateString;
  updatedBy: string;
  agentNames: readonly string[];
  combinedMarket: boolean;
  indiceComposition: string | null;
  catStat: string | null;
  manuallyUpdated: boolean;
  manualBrakingBulletin: boolean;
  manualTraceability: boolean;
  manualReadyForDispatch: boolean;
  vehicles: readonly Vehicle[];
  brakingBulletins: readonly BrakingBulletin[];
  traceability?: Traceability;
  readyForDispatchSignatures: readonly SignatureOrOffline<ReadyForDispatchSignature>[];
  missionEndSignatures: readonly SignatureOrOffline[];
}>;

export const compositionHasStepProblems = (composition: TrainStepSummary) => composition.problemStatus !== 'OK';
