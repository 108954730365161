import { getMailingLists } from '../../commons/selectors/selectors';
import apiHelper from '../../api/apiHelper';
import { sortList } from '../../commons/model/sortUtils';
import { SortDirection } from '../../commons/model/sort';

// CONSTANTS

const START_GET_MAILING_LISTS = 'START_GET_MAILING_LISTS';
const SUCCESS_GET_MAILING_LISTS = 'SUCCESS_GET_MAILING_LISTS';
const FAILURE_GET_MAILING_LISTS = 'FAILURE_GET_MAILING_LISTS';

const SORT_MAILING_LISTS = 'SORT_MAILING_LISTS';

const FILTER_MAILING_LISTS = 'FILTER_MAILING_LISTS';

// REDUCER

const initialState = {
  data: [],
  dataProxy: [],
  error: false,
  loading: false,
  sort: { field: 'name', direction: SortDirection.ASC },
  filters: { nameQuery: '', marketQuery: '', startStationQuery: '', endStationQuery: '', emailQuery: '' },
};

const mailingListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_GET_MAILING_LISTS:
      return {
        ...state,
        data: [],
        error: false,
        loading: true,
      };
    case SUCCESS_GET_MAILING_LISTS:
      return {
        ...state,
        data: action.payload.data,
        dataProxy: action.payload.dataProxy,
        error: false,
        loading: false,
      };
    case FAILURE_GET_MAILING_LISTS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SORT_MAILING_LISTS:
      return {
        ...state,
        dataProxy: sortList(state.dataProxy, action.payload.sort, action.payload.key),
        sort: action.payload.sort,
      };
    case FILTER_MAILING_LISTS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filter },
        dataProxy: action.payload.dataProxy,
      };
    default:
      return state;
  }
};

export default mailingListsReducer;

// ACTIONS

const doFilterMailingLists = (data, filters) => {
  let dataProxy = [...data];

  const { nameQuery, marketQuery, startStationQuery, endStationQuery, emailQuery } = filters;

  if (nameQuery && nameQuery !== '') {
    dataProxy = dataProxy.filter(
      (mlist) =>
        mlist.name &&
        (mlist.name.toLowerCase().startsWith(nameQuery.toLowerCase()) ||
          mlist.name.toLowerCase().endsWith(nameQuery.toLowerCase())),
    );
  }

  if (marketQuery && marketQuery !== '') {
    dataProxy = dataProxy.filter(
      (mlist) =>
        mlist.markets.length > 0 &&
        mlist.markets.filter((market) => market.name.toLowerCase().includes(marketQuery.toLowerCase())).length > 0,
    );
  }

  if (startStationQuery && startStationQuery !== '') {
    dataProxy = dataProxy.filter(
      (mlist) =>
        mlist.startStation.label &&
        (mlist.startStation.label.toLowerCase().startsWith(startStationQuery.toLowerCase()) ||
          mlist.startStation.label.toLowerCase().endsWith(startStationQuery.toLowerCase())),
    );
  }

  if (endStationQuery && endStationQuery !== '') {
    dataProxy = dataProxy.filter(
      (mlist) =>
        mlist.endStation.label &&
        (mlist.endStation.label.toLowerCase().startsWith(endStationQuery.toLowerCase()) ||
          mlist.endStation.label.toLowerCase().endsWith(endStationQuery.toLowerCase())),
    );
  }

  if (emailQuery && emailQuery !== '') {
    dataProxy = dataProxy.filter(
      (mlist) =>
        mlist.emails.length > 0 &&
        mlist.emails.filter((email) => email.toLowerCase().startsWith(emailQuery.toLowerCase())).length > 0,
    );
  }

  return dataProxy;
};

export const filterMailingLists = (filter) => (dispatch, getState) => {
  dispatch({
    type: FILTER_MAILING_LISTS,
    payload: {
      filter: { ...filter },
      dataProxy: doFilterMailingLists(getMailingLists(getState()).data, {
        ...getMailingLists(getState()).filters,
        ...filter,
      }),
    },
  });
};

export const failureGetMailingLists = (statusCode) => ({
  type: FAILURE_GET_MAILING_LISTS,
  payload: { statusCode },
});

export const successGetMailingLists = (data, dataProxy) => ({
  type: SUCCESS_GET_MAILING_LISTS,
  payload: {
    data: [...data],
    dataProxy: [...dataProxy],
  },
});

export const startGetMailingLists = () => async (dispatch, getState) => {
  dispatch({ type: START_GET_MAILING_LISTS });

  try {
    const mailingLists = (await apiHelper.get('/api/mailing-lists')).map((mlist) => ({
      ...mlist,
      startStation: { ...mlist.startStation },
      endStation: { ...mlist.endStation },
      emailsProxy: mlist.emails.join(', '),
    }));
    dispatch(
      successGetMailingLists(mailingLists, doFilterMailingLists(mailingLists, getMailingLists(getState()).filters)),
    );
  } catch (err) {
    dispatch(failureGetMailingLists(err.httpStatus));
  }
};

export const sortMailingLists = (sort) => ({
  type: SORT_MAILING_LISTS,
  payload: {
    sort: { ...sort },
    key: sort.field === 'startStation' || sort.field === 'endStation' ? 'label' : false,
  },
});
