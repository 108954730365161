import FormSection from '../../../commons/components/form/FormSection';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { change, Field, formValueSelector } from 'redux-form';
import React from 'react';
import {
  BRAKING_BULLETIN_OBSERVATION_TYPES,
  BrakingBulletin,
  BrakingBulletinCompositionSummary,
  BrakingBulletinObservationType,
  COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES,
  EDITABLE_BRAKING_BULLETIN_OBSERVATION_TYPES,
} from '../../../commons/model/BrakingBulletin';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { brakingBulletinFormName, BrakingBulletinObservationFormData } from './BrakingBulletinForm';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import Input, { InputProps } from '../../../commons/components/input/Input';
import { formatDecimalAndSeparateThousands } from '../../../commons/redux-form/valueFormatter';
import { normalizePositiveNumber } from '../../../commons/redux-form/valueNormalizer';
import { updateBrakingBulletinLLFlag } from './brakingBulletinActions';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';

type ObservationTextFieldProps = { name: string } & InputProps<BrakingBulletin>;
type ObservationNumberFieldProps = { name: string } & InputProps<BrakingBulletin>;
type ObservationDetailsProps = { fieldName: string; onRemove: () => any; readOnly: boolean };
type BrakingBulletinObservationsProps = {
  readOnly: boolean;
  compositionSummary: BrakingBulletinCompositionSummary;
} & WrappedFieldArrayProps;

const ObservationTextField = ({ name, ...props }: ObservationTextFieldProps) => (
  <Field component={Input} name={name} maxLength={256} {...props} autoCorrect="off" autoComplete="off" />
);

const ObservationNumberField = ({ name, ...props }: ObservationNumberFieldProps) => (
  <Field
    component={Input}
    name={name}
    inputMode="numeric"
    pattern="[0-9]*"
    format={formatDecimalAndSeparateThousands}
    normalize={normalizePositiveNumber}
    inputProps={{
      className: 'right',
    }}
    {...props}
  />
);

const ObservationDetails = ({ fieldName, onRemove, readOnly }: ObservationDetailsProps) => {
  const observation: BrakingBulletinObservationFormData = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, fieldName),
  );
  // noinspection JSNonASCIINames
  return (
    <div className="observation">
      <div className="header">
        <div className="title">{BRAKING_BULLETIN_OBSERVATION_TYPES[observation.type]}</div>
        {!readOnly && !COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES.has(observation.type) && (
          <button type="button" className="btn btn-round small remove" onClick={onRemove} />
        )}
      </div>
      <Field component="input" name={`${fieldName}.type`} type="hidden" />
      {observation.type === 'DANGEROUS_MATERIAL' && <div>Liste marchandises dangereuses jointe</div>}
      {observation.type === 'CHARGE_D' && <div>Wagon(s) en charge D</div>}
      {observation.type === 'ATE' && (
        <div>
          Avis de transport exceptionnel n° <ObservationTextField name={`${fieldName}.text1`} disabled={true} />{' '}
          rectifié n° <ObservationTextField name={`${fieldName}.text2`} disabled={readOnly} />
        </div>
      )}
      {observation.type === 'GB_GAUGE' && <div>Wagon(s) au gabarit GB</div>}
      {observation.type === 'VEHICLE_ENGINE' && (
        <div>
          Locotracteur n° <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} />, ne pas dépasser la
          vitesse de <ObservationNumberField name={`${fieldName}.number1`} unit="km/h" disabled={readOnly} />
        </div>
      )}
      {observation.type === 'PNEUMATIC_VEHICLE' && (
        <div>
          <ObservationNumberField name={`${fieldName}.number1`} disabled={readOnly} /> véhicules à suspension
          pneumatique
        </div>
      )}
      {observation.type === 'LL' && <div>Train freiné selon le principe de la Locomotive Longue</div>}
      {observation.type === 'WAGON_SPEED_LIMIT' && (
        <div>
          Ne pas dépasser la vitesse de{' '}
          <ObservationNumberField name={`${fieldName}.number1`} unit="km/h" disabled={readOnly} /> : véhicule(s) n°{' '}
          <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> limité(s) à{' '}
          <ObservationNumberField name={`${fieldName}.number2`} unit="km/h" disabled={readOnly} />
        </div>
      )}
      {observation.type === 'BRAKING_INSUFFICIENT' && (
        <div>
          Ne pas dépasser la vitesse des <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> :
          règles de freinage des <ObservationTextField name={`${fieldName}.text2`} disabled={readOnly} /> non
          satisfaites
        </div>
      )}
      {observation.type === 'ISOLATED_VEHICLE' && (
        <div>
          <Field
            component={Dropdown}
            name={`${fieldName}.text1`}
            options={{ 'Wagon(s)': 'Wagon(s)', 'Voiture(s)': 'Voiture(s)', 'EM(s) en Véhicule': 'EM(s) en Véhicule' }}
            disabled={readOnly}
          />{' '}
          <div className="big-field-line">
            n° <ObservationTextField name={`${fieldName}.text2`} disabled={readOnly} /> frein isolé
          </div>
        </div>
      )}
      {observation.type === 'MAIN_LINE_INTERRUPTED' && <div>Conduite principale non alimentée</div>}
      {observation.type === 'QUEUED_ENGINE' && (
        <div>
          Machine n° <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> acheminée en queue,
          mesures techniques concernant la mise en véhicule et régime de freinage sur M vérifiés sur les engins moteurs
          acheminés en queue
        </div>
      )}
      {observation.type === 'QUEUED_WAGON' && (
        <div>
          Véhicule n° <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> « à mettre en queue »
          acheminé
        </div>
      )}
      {observation.type === 'QUEUED_WAGON_NO_MAIN_LINE' && (
        <div>
          Véhicule de queue n° <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> non relié à la
          CG
        </div>
      )}
      {observation.type === 'FCV_VEHICLES' && (
        <div>
          Masse freinée voyageurs :{' '}
          <ObservationNumberField name={`${fieldName}.number1`} disabled={readOnly} unit="t" />
        </div>
      )}
      {observation.type === 'FCV_FULL' && <div>Train freiné au frein continu voyageurs</div>}
      {observation.type === 'MULTIPLE_WAGONS_UNITS' && (
        <div>
          Transports d’unités flexibles chargées sur plus de deux wagons ; ne pas dépasser la vitesse de 10 km/h en
          manœuvre et sur les zones de limitation permanente et temporaire d’un taux inférieur ou égal à 30 km/h. Ne pas
          dépasser la vitesse de 100 km/h
        </div>
      )}
      {observation.type === 'UNBRAKED_HEAD_VEHICLE' && (
        <div>
          Véhicule de tête n° <ObservationTextField name={`${fieldName}.text1`} disabled={readOnly} /> non freiné
        </div>
      )}
      {observation.type === 'OTHER' && (
        <div>
          <ObservationTextField name={`${fieldName}.text1`} containerClassName="full" disabled={readOnly} />
        </div>
      )}
    </div>
  );
};

const BrakingBulletinObservations = ({ readOnly, compositionSummary, fields }: BrakingBulletinObservationsProps) => {
  const dispatch = useAppDispatch();
  const newObservationType: BrakingBulletinObservationType = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'newObservationType'),
  );
  const observations: BrakingBulletinObservationFormData[] = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'observations'),
  );
  const brakingBulletinIndiceComposition = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'indiceComposition'),
  );

  const handleLLOrFCVChange = ({ hasLL, hasFCV }: { hasLL?: boolean; hasFCV?: boolean }) => {
    const finalHasLL = hasLL ?? BrakingBulletinUtils.hasLLObservation(observations);
    const finalHasFullFCV = hasFCV ?? BrakingBulletinUtils.hasFullFCVObservation(observations);
    const fixedBrakedWeight = BrakingBulletinUtils.computeFixedBrakedWeight(
      compositionSummary,
      finalHasLL,
      finalHasFullFCV,
      brakingBulletinIndiceComposition,
    );
    dispatch(change(brakingBulletinFormName, 'brakedWeightDetails.fixed', fixedBrakedWeight));
  };

  const handleAddObservation = () => () => {
    if (!newObservationType) {
      // "saveAction" is called even when closing the dropdown without clicking on anything.
      return;
    }
    fields.push({ type: newObservationType });
    dispatch(change(brakingBulletinFormName, 'newObservationType', null));
    if (newObservationType === 'LL') {
      dispatch(updateBrakingBulletinLLFlag(true));
      handleLLOrFCVChange({ hasLL: true });
    }
    if (newObservationType === 'FCV_FULL') {
      handleLLOrFCVChange({ hasFCV: true });
    }
  };

  const handleRemoveObservation = (index: number) => () => {
    const removedType = fields.get(index).type;
    if (removedType === 'LL') {
      dispatch(updateBrakingBulletinLLFlag(false));
      handleLLOrFCVChange({ hasLL: false });
    }
    if (removedType === 'FCV_FULL') {
      handleLLOrFCVChange({ hasFCV: false });
    }
    fields.remove(index);
  };

  return (
    <FormSection title="Observations" className="braking-bulletin-observations">
      {fields.map((fieldName, index) => (
        <ObservationDetails
          key={fieldName}
          fieldName={fieldName}
          onRemove={handleRemoveObservation(index)}
          readOnly={readOnly}
        />
      ))}
      {!readOnly && (
        <div className="add">
          <Field
            component={Dropdown}
            name="newObservationType"
            options={EDITABLE_BRAKING_BULLETIN_OBSERVATION_TYPES}
            saveAction={handleAddObservation}
            placeholder="Ajouter une observation"
          />
        </div>
      )}
    </FormSection>
  );
};

export default BrakingBulletinObservations;
