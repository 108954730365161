class AbstractOfflineEntity {
  static toMap = (array) => {
    const map = new Map();
    if (array) {
      for (const item of array) {
        map.set(item.id, item);
      }
    }
    return map;
  };

  constructor(entity) {
    // entity has not yet been saved
    this.new = false;
    // entity has been update
    this.dirty = false;
    // a request has been sent to the server to persist modifications
    this.updatingBack = false;
    // entity is waiting to be deleted on server side
    this.pendingForDeletion = false;
    // entity is deleted on server side
    this.deleted = false;
    // entity version number in database
    this.version = 0;
    // entity persist was refused. it will be overwritten asap.
    this.invalid = false;
    Object.assign(this, entity);
  }

  isNew() {
    return this.new;
  }

  isDirty() {
    return this.dirty;
  }

  isUpdatingBack() {
    return this.updatingBack;
  }

  isNotPendingForDeletion() {
    return !this.pendingForDeletion;
  }

  isDeleted() {
    return this.deleted;
  }

  isInvalid() {
    return this.invalid;
  }

  isNotPendingForDeletionAndNotDeleted() {
    return this.isNotPendingForDeletion() && !this.isDeleted();
  }

  markForDeletion() {
    this.markDirty();
    this.pendingForDeletion = true;
    this.new = false;
    return this;
  }

  markDirty() {
    this.dirty = true;
    this.invalid = false;
    return this;
  }

  unMarkDirty() {
    this.dirty = false;
    return this;
  }

  markForUpdatingBack() {
    this.unMarkDirty();
    this.updatingBack = true;
    return this;
  }

  unMarkForUpdatingBack() {
    this.updatingBack = false;
    return this;
  }

  markForUpdatingBackFailure() {
    this.updatingBack = false;
    this.dirty = true;
    return this;
  }

  markInvalid() {
    this.updatingBack = false;
    this.dirty = false;
    this.invalid = true;
    return this;
  }

  markDeleted() {
    this.dirty = false;
    this.deleted = true;
    this.pendingForDeletion = false;
    this.updatingBack = false;
    this.new = false;
    return this;
  }

  unMarkNew() {
    this.new = false;
    return this;
  }

  getVersion() {
    return this.version;
  }

  isCacheMergeable(versionToCompare) {
    /*
     * this.isDirty() : vehicle has been modified by the user and is waiting to be send to the server
     * this.isUpdatingBack : the server is saving the vehicle
     * this.version <= versionToCompare : we are receiving a version equals or newer than the one in cache
     * this.isDeleted() : vehicle has been deleted
     */
    return (
      this.isInvalid() ||
      (!this.isDirty() && !this.isUpdatingBack() && this.getVersion() <= versionToCompare && !this.isDeleted())
    );
  }

  isSynchable() {
    return (
      (this.isDirty() || this.pendingForDeletion) && !this.isUpdatingBack() && !this.isDeleted() && !this.isInvalid()
    );
  }
}

export default AbstractOfflineEntity;
