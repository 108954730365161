import React from 'react';
import PropTypes from 'prop-types';
import PreAdviceWagonEntity from '../../../commons/entity/PreAdviceWagonEntity';
import PreAdviceWagonUnknownStationErrors from './PreAdviceWagonUnknownStationErrors';
import PreAdviceWagonUnknownDamageErrors from './PreAdviceWagonUnknownDamageErrors';
import PreAdviceWagonUnknownHazardousMaterialErrors from './PreAdviceWagonUnknownHazardousMaterialErrors';
import './preAdviceWagonImportErrors.scss';
import { getErrorTypesForCategory, IMPORT_ERROR_CATEGORIES } from '../preAdviceWagonFormUtils';

const PreAdviceWagonImportErrors = ({ wagon, errorCategory }) => {
  const types = getErrorTypesForCategory(errorCategory);
  const errors = wagon.getErrorsOfTypes(types);
  if (errors.length === 0) {
    return null;
  }

  switch (errorCategory) {
    case IMPORT_ERROR_CATEGORIES.STATION:
      return <PreAdviceWagonUnknownStationErrors errors={errors} />;
    case IMPORT_ERROR_CATEGORIES.DAMAGE:
      return <PreAdviceWagonUnknownDamageErrors errors={errors} />;
    case IMPORT_ERROR_CATEGORIES.HAZARDOUS_MATERIAL:
      return <PreAdviceWagonUnknownHazardousMaterialErrors errors={errors} />;
    default:
      return null;
  }
};

PreAdviceWagonImportErrors.propTypes = {
  errorCategory: PropTypes.oneOf(Object.keys(IMPORT_ERROR_CATEGORIES)).isRequired,
  wagon: PropTypes.instanceOf(PreAdviceWagonEntity).isRequired,
};

export default PreAdviceWagonImportErrors;
