import helpers from '../helpers/helpers';
import {
  getSpeedLimitError,
  validateEffectiveBrakedWeight,
  validateHazardousMaterial,
  validateNbAxles,
  validateRegistration,
  validateWagonCharge,
  validateWagonDamage,
  validateWagonLength,
  validateWagonLoadWeight,
  validateWagonPosition,
  validateWagonSpeedLimit,
  validateWagonTare,
} from '../validation/validationUtils';
import PreAdviceHazardousMaterialEntity from './PreAdviceHazardousMaterialEntity';
import { parseToFloat } from '../redux-form/valueParser';
import PreAdviceWagonImportErrorEntity from './PreAdviceWagonImportErrorEntity';
import { VALIDATION_STATUSES } from '../model/common';

export default class PreAdviceWagonEntity {
  static validate(nbWagons, firstPosition = 1) {
    return (formData) => {
      const {
        referentialData: { nbAxles, length, registration, speedLimit, tare } = {},
        effectiveBrakedWeight,
        loadWeight,
        position,
        brakeType,
        speedLimitForCurrentTrain,
        charge,
        journey: { startStation, endStation, sender, addressee } = {},
        ateNumbers: { frenchAteNumber, foreignAteCountry, foreignAteNumber, speedLimit: ateSpeedLimit } = {},
        hazardousMaterials = [],
        damages: { damageTemplates, speedLimit: damageSpeedLimit } = {},
      } = formData;

      const errors = {
        ateNumbers: {},
        journey: { startStation: {}, endStation: {} },
        damages: {},
        ...validateWagonPosition(nbWagons, firstPosition, position, 'position'),
        ...validateWagonLoadWeight(loadWeight, PreAdviceWagonEntity.getHazardousMaterialWeight(formData)),
        ...validateEffectiveBrakedWeight(effectiveBrakedWeight),
        speedLimitForCurrentTrain: getSpeedLimitError(speedLimitForCurrentTrain),
        charge: validateWagonCharge(charge),
      };

      errors.referentialData = {
        ...validateRegistration(registration),
        ...validateNbAxles(nbAxles),
        ...validateWagonLength(length),
        ...validateWagonSpeedLimit(speedLimit),
        ...validateWagonTare(tare),
      };

      if (!brakeType) {
        errors.brakeType = 'Le régime de freinage est obligatoire';
      }

      if (!startStation?.label) {
        errors.journey.startStation.label = "La gare d'origine est obligatoire";
      }
      if (!endStation?.label) {
        errors.journey.endStation.label = 'La gare de destination est obligatoire';
      }
      if (!sender) {
        errors.journey.sender = "L'expéditeur est obligatoire";
      } else if (sender.length > 24) {
        errors.journey.sender = "Le nom de l'expéditeur doit faire moins de 25 caractères";
      }
      if (!addressee) {
        errors.journey.addressee = 'Le destinataire est obligatoire';
      } else if (addressee.length > 24) {
        errors.journey.addressee = 'Le nom du destinataire doit faire moins de 25 caractères';
      }

      if (frenchAteNumber) {
        errors.ateNumbers = { ...validateWagonSpeedLimit(ateSpeedLimit) };
        if (frenchAteNumber.length > 24) {
          errors.ateNumbers.frenchAteNumber = 'Le numéro doit faire moins de 25 caractères';
        }
        if (!foreignAteNumber) {
          errors.ateNumbers.foreignAteNumber = 'Le numéro étranger est obligatoire';
        } else if (foreignAteNumber.length > 24) {
          errors.ateNumbers.foreignAteNumber = 'Le numéro doit faire moins de 25 caractères';
        }
        if (!foreignAteCountry) {
          errors.ateNumbers.foreignAteCountry = 'Le pays est obligatoire';
        }
      }

      if (damageTemplates?.length) {
        errors.damages = { ...validateWagonSpeedLimit(damageSpeedLimit) };
        errors.damages.damageTemplates = damageTemplates.map(validateWagonDamage);
      }

      errors.hazardousMaterials = hazardousMaterials.map(validateHazardousMaterial);

      return errors;
    };
  }

  static getHazardousMaterialWeight({ hazardousMaterials = [] }) {
    return hazardousMaterials
      .filter((hazardousMaterial) => hazardousMaterial.weight)
      .reduce((weight, hazardousMaterial) => weight + parseToFloat(hazardousMaterial.weight), 0);
  }

  static emptyWagon() {
    return new PreAdviceWagonEntity({
      hazardousMaterials: [],
      damages: {
        damages: [],
      },
      position: -1,
      errors: [],
    });
  }

  constructor(serverWagon) {
    this.id = serverWagon.id;
    this.preAdviceId = serverWagon.preAdviceId;
    this.status = serverWagon.status;
    this.position = serverWagon.position;
    this.referentialData = {
      registration: serverWagon.referentialData?.registration ?? '',
      length: serverWagon.referentialData?.length,
      tare: serverWagon.referentialData?.tare,
      nbAxles: serverWagon.referentialData?.nbAxles,
      speedLimit: serverWagon.referentialData?.speedLimit,
      doubleWagon: serverWagon.referentialData?.doubleWagon,
    };
    this.effectiveBrakedWeight = serverWagon.effectiveBrakedWeight;
    this.brakeType = serverWagon.brakeType;
    this.speedLimitForCurrentTrain = serverWagon.speedLimitForCurrentTrain;
    this.loadWeight = serverWagon.loadWeight;
    this.charge = serverWagon.charge;
    this.ateNumbers = {
      frenchAteNumber: serverWagon.ateNumbers?.frenchAteNumber,
      foreignAteCountry: serverWagon.ateNumbers?.foreignAteCountry,
      foreignAteNumber: serverWagon.ateNumbers?.foreignAteNumber,
      speedLimit: serverWagon.ateNumbers?.speedLimit,
    };
    this.journey = {
      startStation: serverWagon.journey?.startStation,
      endStation: serverWagon.journey?.endStation,
      sender: serverWagon.journey?.sender,
      addressee: serverWagon.journey?.addressee,
    };
    this.hazardousMaterials = serverWagon.hazardousMaterials.map(
      (hazardousMaterial) => new PreAdviceHazardousMaterialEntity(hazardousMaterial),
    );
    this.damages = {
      damageTemplates: serverWagon.damages.damages.map(({ damageTemplateId, damageTemplateLabel }) => ({
        damageTemplateId: damageTemplateId,
        comment: damageTemplateLabel,
      })),
      speedLimit: serverWagon.damages.speedLimit,
    };
    this.errors = serverWagon.errors.map((importError) => new PreAdviceWagonImportErrorEntity(importError));
  }

  getTotalWeight() {
    const weight = (this.loadWeight || 0) + (this.referentialData?.tare || 0);
    return Math.ceil(weight * 100) / 100;
  }

  isValidated() {
    return this.status === VALIDATION_STATUSES.VALIDATED;
  }

  isAte() {
    return Boolean(this.ateNumbers?.frenchAteNumber);
  }

  getFormattedRegistrationNumber() {
    return helpers.registrationFormat(this.referentialData?.registration ?? '') ?? '';
  }

  getHazardousMaterialWeight() {
    return PreAdviceWagonEntity.getHazardousMaterialWeight(this);
  }

  toUpdateCommand() {
    return {
      ...this.ateNumbers,
      ...this.referentialData,
      effectiveBrakedWeight: this.effectiveBrakedWeight,
      brakeType: this.brakeType,
      loadWeight: this.loadWeight,
      charge: this.charge,
      addressee: this.journey.addressee,
      sender: this.journey.sender,
      startStationId: this.journey.startStation?.id,
      endStationId: this.journey.endStation?.id,
      hazardousMaterials: this.hazardousMaterials.map((hazardousMaterial) => hazardousMaterial.toUpdateCommand()),
      damageTemplateIds: this.damages.damageTemplates.map((damageTemplate) => damageTemplate.damageTemplateId),
      damageSpeedLimit: this.damages.speedLimit,
      ateSpeedLimit: this.ateNumbers.speedLimit,
      registration: this.referentialData?.registration ? this.referentialData?.registration : null,
      speedLimitForCurrentTrain: this.speedLimitForCurrentTrain,
    };
  }

  getErrorsOfTypes(errorTypes) {
    return this.errors.filter((importError) => errorTypes.includes(importError.type));
  }
}
