import React from 'react';
import { openConfirmDialog } from '../modal/confirmModalDucks';
import { FieldArrayFieldsProps } from 'redux-form/lib/FieldArray';
import { WagonHazardousMaterialFormValues } from '../vehicles/edit/wagon/WagonForm';
import { Wagon } from '../../model/Vehicle';
import { AppAction } from '../../store/store';
import { useAppDispatch } from '../../store/hooks';

type HazardousMaterialHeaderProps = {
  deleteAction: (wagon: Wagon, index: number) => AppAction;
  hazardousMaterialFields: FieldArrayFieldsProps<WagonHazardousMaterialFormValues>;
  position: number;
  readOnly: boolean;
  wagon: Wagon;
};

const HazardousMaterialHeader = ({
  deleteAction,
  hazardousMaterialFields,
  position,
  readOnly,
  wagon,
}: HazardousMaterialHeaderProps) => {
  const dispatch = useAppDispatch();
  const deleteHazardousMaterial = () => {
    dispatch(
      openConfirmDialog({
        title: `Supprimer la matière dangereuse ${position + 1} ?`,
        actionText: 'Oui, supprimer',
        action: () => () => {
          hazardousMaterialFields.remove(position);
          dispatch(deleteAction(wagon, position));
        },
        actionClass: 'danger',
      }),
    );
  };
  return (
    <div className="hazmat-header">
      <h2 className="title">Matière dangereuse {position + 1}</h2>
      {!readOnly && <button type="button" className="btn btn-round small remove" onClick={deleteHazardousMaterial} />}
    </div>
  );
};

export default HazardousMaterialHeader;
