import {
  DAMAGE_REPORTS_PERSIST_FAILURE,
  DAMAGE_REPORTS_PERSIST_START,
  DAMAGE_REPORTS_PERSIST_SUCCESS,
} from './damageReportPersistActions';

const initialState = {
  savingDamageReports: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DAMAGE_REPORTS_PERSIST_START:
      return { ...state, savingDamageReports: true };
    case DAMAGE_REPORTS_PERSIST_FAILURE:
    case DAMAGE_REPORTS_PERSIST_SUCCESS:
      return { ...state, savingDamageReports: false };
    default:
      return state;
  }
};
