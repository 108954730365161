/**
 * Vehicles list component
 */
import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import config from '../../config/config';
import helpers from '../../helpers/helpers';
import {
  addEngine,
  addWagons,
  deleteEngine,
  deleteWagon,
  inverseWagonsOrder,
  updateEngine,
  updateWagon,
} from '../../../trains/step/vehicleActions';
import { DragAndDropState, startDragEngine, startDragWagon, stopDragVehicle } from './dragAndDropDucks';
import { COMPOSITION_WRITE } from '../../security/userRoles';
import { isEngine, isWagon, Vehicle } from '../../model/Vehicle';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import VehicleListItem from './vehicle/VehicleListItem';
import { Link, useNavigate } from 'react-router-dom';
import { startToast } from '../toast/toastDucks';
import { openConfirmDialog } from '../modal/confirmModalDucks';
import VehicleUtils from '../../model/VehicleUtils';
import { TrainStep } from '../../model/Train';
import TrainUtils from '../../model/TrainUtils';

import './vehiclesStyles.scss';
import './vehicle/vehicleStyles.scss';
import { getOfflineState, selectDragAndDrop, selectSecurityContext } from '../../selectors/selectors';
import { useViewportWidth } from '../../responsive/hooks';
import VehiclesUtils from '../../model/VehiclesUtils';
import { showOverlay } from '../overlay/overlayDucks';
import {
  excelVehiclesReportUrl,
  pdfVehiclesReportUrl,
  resetTrainStep,
  unverifyTrainStep,
  verifyTrainStep,
} from '../../../trains/step/trainStepDucks';

type VehiclesProps = {
  step: TrainStep;
  isCollapsed: boolean;
  isValidated?: boolean;
  activeVehicleId?: string;
  onChangeTab?: (newIndex: number) => void;
};

/**
 * Vehicles list component
 */
const VehicleSortable = SortableElement(
  ({
    vehicle,
    isActive,
    isWriteable,
    trainIsValidated,
  }: {
    vehicle: Vehicle;
    isActive: boolean;
    isWriteable: boolean;
    trainIsValidated: boolean;
  }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const navigateToDetails = () => {
      navigate(VehicleUtils.linkToVehicle(vehicle));
    };
    const onclick = (event: MouseEvent) => {
      event.stopPropagation();
      if (trainIsValidated) {
        dispatch(
          startToast({
            text: 'Pour supprimer un véhicule, veuillez au préalable déverrouiller la composition',
            className: 'error',
          }),
        );
      } else if (VehicleUtils.isStatusValidated(vehicle)) {
        dispatch(
          startToast({
            text: 'Pour supprimer un véhicule, veuillez au préalable le déverrouiller',
            className: 'error',
          }),
        );
      } else if (isEngine(vehicle)) {
        dispatch(
          openConfirmDialog({
            title: `Supprimer la locomotive ${VehicleUtils.formatEngineNumber(vehicle)} ?`,
            actionText: 'Oui, supprimer',
            action: deleteEngine(vehicle, false),
            actionClass: 'danger',
          }),
        );
      } else if (isWagon(vehicle)) {
        dispatch(
          openConfirmDialog({
            title: `Supprimer le wagon ${VehicleUtils.formatRegistration(vehicle)} ?`,
            actionText: 'Oui, supprimer',
            action: deleteWagon(vehicle, false),
            actionClass: 'danger',
          }),
        );
      }
    };

    return (
      <VehicleListItem
        canDeleteItem={isWriteable}
        effectiveBrakedWeight={vehicle.effectiveBrakedWeight}
        hazardousMaterials={VehicleUtils.getHazardousMaterials(vehicle)}
        isActive={isActive}
        isAteOrGauge={VehicleUtils.isAteOrGauge(vehicle)}
        isChargeD={VehicleUtils.isChargeD(vehicle)}
        isRollingGoods={VehicleUtils.isRollingGoods(vehicle)}
        isFilled={VehicleUtils.isFilled(vehicle)}
        isRat={VehicleUtils.isRat(vehicle)}
        length={vehicle.length}
        position={VehicleUtils.formatPosition(vehicle)}
        onClick={navigateToDetails}
        onDelete={onclick}
        registration={vehicle.registration}
        status={vehicle.status}
        totalWeight={VehicleUtils.totalWeight(vehicle)}
        totalWeightText={VehicleUtils.totalWeightLegend(vehicle)}
        type={vehicle.type}
      />
    );
  },
);

const MainSortableContainer = SortableContainer(
  ({
    vehicles,
    activeVehicleId,
    dragAndDrop,
    className,
    isWriteable,
    trainIsValidated,
  }: {
    vehicles: readonly Vehicle[];
    activeVehicleId?: string;
    dragAndDrop: DragAndDropState;
    className: string;
    isWriteable: boolean;
    trainIsValidated: boolean;
  }) => (
    <div className={className}>
      {vehicles.map((vehicle, index) => (
        <VehicleSortable
          vehicle={vehicle}
          isActive={vehicle.id === activeVehicleId}
          index={index}
          key={`vehicle-${vehicle.id}`}
          disabled={
            (isWagon(vehicle) && dragAndDrop.draggingEngine && index !== 0 && index !== vehicles.length - 1) ||
            (!isWagon(vehicle) && dragAndDrop.draggingWagon)
          }
          isWriteable={isWriteable}
          trainIsValidated={trainIsValidated}
        />
      ))}
    </div>
  ),
);

const Vehicles = ({ step, activeVehicleId, isCollapsed, isValidated, onChangeTab }: VehiclesProps) => {
  const dispatch = useAppDispatch();
  const dragAndDrop = useAppSelector(selectDragAndDrop);
  const { roles, username } = useAppSelector(selectSecurityContext);
  const offline = useAppSelector(getOfflineState);
  const { isMobile } = useViewportWidth();
  const canWriteCompositions = roles.includes(COMPOSITION_WRITE);

  const handleImportWagons = () => dispatch(showOverlay('copy-wagons'));

  const handleAddEngine = () => dispatch(addEngine());

  const handleAddWagons = () =>
    dispatch(
      openConfirmDialog({
        title: 'Combien de wagons voulez-vous ajouter ?',
        actionText: 'Ajouter les wagons',
        action: addWagons,
        fields: {
          nbWagons: {
            type: 'number',
            label: 'Nb wagons',
          },
        },
      }),
    );

  const handleInverseWagonsOrder = () =>
    dispatch(
      openConfirmDialog({
        title: 'Souhaitez-vous inverser la composition ?',
        actionText: 'Inverser',
        action: inverseWagonsOrder,
      }),
    );

  const handleResetComposition = () =>
    dispatch(
      openConfirmDialog({
        title: 'Souhaitez-vous réinitialiser la composition du train ?',
        actionText: 'Réinitialiser',
        action: resetTrainStep(step),
        actionClass: 'danger',
      }),
    );

  const handleUnverifyComposition = () => {
    dispatch(
      openConfirmDialog({
        title: 'Souhaitez-vous invalider la composition ?',
        actionText: 'Invalider',
        action: unverifyTrainStep(step),
        actionClass: 'danger',
      }),
    );
  };

  return (
    <div
      className={classNames('vehicles-list-container', {
        collapsed: isCollapsed,
      })}
    >
      {!isCollapsed && (
        <div className="topbar">
          {isMobile ? (
            <button className="btn btn-icon btn-basic engine" onClick={() => onChangeTab?.(0)} type="button">
              <strong>&lt;</strong>
            </button>
          ) : (
            <div />
          )}
          {TrainUtils.isStatusValidated(step) ? (
            <div className="btn-group">
              {!offline && (
                <>
                  <a
                    className="btn btn-round pdf"
                    href={pdfVehiclesReportUrl(step)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Télécharger le relevé au format PDF
                  </a>
                  <a
                    className="btn btn-round xls"
                    href={excelVehiclesReportUrl(step)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Télécharger le relevé au format Excel
                  </a>
                </>
              )}
              {canWriteCompositions && (
                <button className="btn btn-round unlock" onClick={handleUnverifyComposition} type="button">
                  Invalider
                </button>
              )}
            </div>
          ) : (
            <div className="btn-group">
              {canWriteCompositions && (
                <>
                  {!offline && (
                    <button className="btn btn-round template" type="button" onClick={handleImportWagons}>
                      Importer des wagons
                    </button>
                  )}
                  <button className="btn btn-round engine-plus" type="button" onClick={handleAddEngine}>
                    Ajouter une locomotive
                  </button>
                  <button className="btn btn-round wagon-plus" type="button" onClick={handleAddWagons}>
                    Ajouter des wagons
                  </button>
                  <button
                    className="btn btn-round inverse"
                    disabled={VehiclesUtils.getNbWagon(step.vehicles) === 0}
                    onClick={handleInverseWagonsOrder}
                    type="button"
                  >
                    Inverser les wagons
                  </button>
                  {!offline && (
                    <button
                      className="btn btn-round reset"
                      disabled={TrainUtils.isStatusNew(step)}
                      onClick={handleResetComposition}
                      type="button"
                    >
                      Réinitialiser la composition
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <MainSortableContainer
        className={classNames('vehicles-list', {
          'vehicles-list-zero': !step.vehicles.length,
          collapsed: isCollapsed,
        })}
        vehicles={step.vehicles}
        activeVehicleId={activeVehicleId}
        dragAndDrop={dragAndDrop}
        helperClass="drag"
        pressDelay={config.isTouchScreen ? 200 : 0}
        distance={config.isTouchScreen ? 0 : 20}
        shouldCancelStart={() => isCollapsed || isValidated || !canWriteCompositions}
        onSortStart={({ node }) => {
          // Use hasClass instead of { collection }, because it's somehow undefined
          if (helpers.hasClass(node, 'engine')) {
            dispatch(startDragEngine());
          } else {
            dispatch(startDragWagon());
          }
        }}
        onSortEnd={({ oldIndex, newIndex }) => {
          dispatch(stopDragVehicle());
          if (oldIndex !== newIndex) {
            const vehicleToMove = step.vehicles.find((vehicle) => vehicle.position === oldIndex)!;
            if (isWagon(vehicleToMove)) {
              // wagon move
              dispatch(updateWagon(vehicleToMove, { position: newIndex }));
            } else {
              // engine move
              dispatch(updateEngine(vehicleToMove, { position: newIndex }));
            }
          }
        }}
        isWriteable={canWriteCompositions}
        trainIsValidated={TrainUtils.isStatusValidated(step)}
      />
      {!isMobile && isCollapsed && step && (
        <div className="vehicle-edit-back">
          <Link to={TrainUtils.linkToTrainStep(step.id)}>
            <button className="btn btn-accent" type="button">
              Retour
            </button>
          </Link>
        </div>
      )}
      {!isCollapsed && !isValidated && canWriteCompositions && (
        <div className="vehicles-validate">
          <button
            className={classNames('btn btn-accent btn-icon lock', {
              // If all vehicles are not validated, the button looks disabled, but can still be clicked to show a message
              disabled:
                !step.vehicles.length || VehiclesUtils.getNbValidatedVehicle(step.vehicles) !== step.vehicles.length,
            })}
            onClick={() => dispatch(verifyTrainStep(step, username))}
            type="button"
          >
            Valider
          </button>
        </div>
      )}
    </div>
  );
};

export default Vehicles;
