/**
 * User details component
 */
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Overlay from '../../../commons/components/overlay/Overlay';
import Input from '../../../commons/components/input/Input';
import MarketSelect from '../../../commons/components/market-select/MarketSelect';
import ProfileCheckboxes from '../../../commons/components/profile-checkboxes/ProfileCheckboxes';
import Switch2 from '../../../commons/components/switch2/Switch2';
import { getUser } from '../../../commons/selectors/selectors';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import { persistUserStart } from '../userDucks';
import './userDetailsStyles.scss';
import { useAppDispatch } from '../../../commons/store/hooks';
import { AppDispatch } from '../../../commons/store/store';
import { RootState } from '../../../commons/reducers/rootReducer';
import { connect } from 'react-redux';
import { User } from '../../../commons/model/userProfile';
import adUserCache from '../../../commons/templates/adUserCache';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { CompanyOptions } from '../../../commons/model/common';

type UserDetailsProps = InjectedFormProps<User>;

const UserDetails = ({ initialValues: user, handleSubmit }: UserDetailsProps) => {
  const isEdit: boolean = Boolean(user?.id);
  const dispatch: AppDispatch = useAppDispatch();

  const handleCancel = () => dispatch(hideOverlay());

  return (
    <Overlay name="user" className="user-details" title={isEdit ? "Modifier l'utilisateur" : 'Ajouter un utilisateur'}>
      <form
        className="fields"
        autoComplete="off"
        onSubmit={handleSubmit((updatedUser: User) => dispatch(persistUserStart(updatedUser, user?.id)))}
      >
        <Field
          component={Switch2}
          id="user-activation-switch"
          className="user-details-activation-switch"
          labelOff="Inactif"
          labelOn="Actif"
          name="active"
          data={user}
        />
        <div className="overlay-form">
          {!user.login && (
            <Field
              component={Input}
              type="text"
              name="adUser"
              labelText="Recherche AD"
              placeholder="Identifiant, nom ou email"
              data={user}
              autocompleter={adUserCache}
            />
          )}
          <div className="inputs-group">
            <Field component={Input} type="text" name="firstName" labelText="Prénom" data={user} disabled={true} />
            <Field component={Input} type="text" name="lastName" labelText="Nom" data={user} disabled={true} />
          </div>
          <Field component={Input} type="text" name="email" labelText="Email" data={user} disabled={true} />
          <Field component={MarketSelect} id="user-markets" name="markets" labelText="Marchés" data={user} />
          <div className="inputs-group">
            <Field component={ProfileCheckboxes} id="user-profiles" name="profiles" labelText="Profils" data={user} />
          </div>
          <div className="input-dropdown">
            <Field component={Dropdown} labelText="Entreprise" name="company" options={CompanyOptions} data={user} />
          </div>
          <div className="btn-group">
            <button className="btn btn-success" type="submit">
              {isEdit ? 'Enregistrer' : 'Ajouter'}
            </button>
            <button className="btn btn-link" onClick={handleCancel} type="button">
              Annuler
            </button>
          </div>
        </div>
      </form>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: getUser(state).data || { active: true, markets: [], profiles: [] },
});

// Decorate with reduxForm().
const UserDetailsForm = reduxForm<User>({
  form: 'userForm',
  enableReinitialize: true,
})(UserDetails);

export default connect(mapStateToProps)(UserDetailsForm);
