import React from 'react';
import FieldLabelWithTooltip from '../../form/FieldLabelWithTooltip';
import { WagonHazardousMaterial } from '../../../model/Vehicle';

type WagonWeightLabelProps = {
  hazardousMaterials: readonly WagonHazardousMaterial[];
};

const WagonWeightLabel = ({ hazardousMaterials = [] }: WagonWeightLabelProps) => {
  const tooltipText = hazardousMaterials.length > 0 ? 'Matières dangereuses et non dangereuses' : null;
  return <FieldLabelWithTooltip label="Masse de chargement *" tooltipText={tooltipText} />;
};

export default WagonWeightLabel;
