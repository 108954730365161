/**
 * Vehicle edit component
 */
import React, { useCallback, useEffect } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import Vehicles from '../Vehicles';
import VehicleEdit from './vehicle/VehicleEdit';
import GlobalError from '../../GlobalError';
import { loadTrainStep } from '../../../../trains/step/trainStepDucks';
import { hideSidebar } from '../../sidebar/sidebarDucks';
import './vehicleEditStyles.scss';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectTrainStep } from '../../../selectors/selectors';

const VehiclePage = () => {
  const step = useAppSelector(selectTrainStep);
  const dispatch = useAppDispatch();

  const scrollActiveVehicle = useCallback(() => {
    // wait for a paint to scroll to the active vehicle
    window.requestAnimationFrame(() => {
      const activeElement = document.querySelector('.vehicle.active');
      if (activeElement) {
        scrollIntoView(activeElement, { block: 'nearest', scrollMode: 'always', behavior: 'smooth' }).then();
      }
    });
  }, []);

  const { stepId, vehicleId } = useParams();
  useEffect(() => {
    dispatch(hideSidebar());
    dispatch(loadTrainStep(stepId!));
  }, [dispatch, stepId]);
  useEffect(() => {
    scrollActiveVehicle();
  }, [step, scrollActiveVehicle]);

  if (step.error && !step.data) {
    return (
      <GlobalError title="Erreur">Une erreur inattendue s'est produite durant la récupération du train.</GlobalError>
    );
  }

  if (!step.data) {
    return null;
  }

  /**
   * Renders component
   */
  return (
    <div className="composition expanded">
      <Vehicles step={step.data} isCollapsed={true} activeVehicleId={vehicleId} />
      <VehicleEdit vehicleId={vehicleId!} />
    </div>
  );
};

export default VehiclePage;
