import React, { MouseEvent } from 'react';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import {
  BRAKING_BULLETIN_SIGNATURE_TYPES,
  BrakingBulletin,
  BrakingBulletinIndiceComposition,
  BrakingBulletinObservationType,
  BrakingBulletinSignatureType,
} from '../../../commons/model/BrakingBulletin';
import CompositionSummarySection from './CompositionSummarySection';
import RequiredBrakingSection from './RequiredBrakingSection';
import EffectiveBrakingSection from './EffectiveBrakingSection';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { closeBrakingBulletin } from '../trainStepDucks';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import { deleteBrakingBulletin, signBrakingBulletin, updateBrakingBulletin } from './brakingBulletinActions';
import BrakingBulletinObservations from './BrakingBulletinObservations';
import {
  selectSecurityContext,
  selectSecurityContextRoles,
  selectTrainStep,
} from '../../../commons/selectors/selectors';
import { validateBrakingBulletin } from '../../../commons/validation/validationUtils';
import BrakingBulletinMessages from './BrakingBulletinMessages';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { COMPOSITION_WRITE } from '../../../commons/security/userRoles';
import { isOfflineSignature } from '../../../commons/model/common';
import Input from '../../../commons/components/input/Input';
import BrakingBulletinValidationDialogTitle from './dialog/BrakingBulletinValidationDialogTitle';
import { readDefaultSignatureType } from './brakingBulletinUtils';
import IndiceCompositionField from './indice-composition/IndiceCompositionField';

export const brakingBulletinFormName = 'braking-bulletin';

type BrakingBulletinFormProps = {
  brakingBulletin: BrakingBulletin;
};

export type BrakingBulletinObservationFormData = {
  type: BrakingBulletinObservationType;
  text1?: string | null;
  text2?: string | null;
  number1?: number | null;
  number2?: number | null;
};

export type BrakingBulletinSignFormData = {
  signatureType?: BrakingBulletinSignatureType;
};

export type BrakingBulletinFormData = {
  indiceComposition: BrakingBulletinIndiceComposition | null;
  startLocation: string | null;
  observations: BrakingBulletinObservationFormData[];
  brakedWeightDetails: {
    fixed: number | null;
    stopping: number | null;
    drift: number | null;
    driftSecondHalf: number | null;
  };
  effectiveBrakedWeightDetails: {
    driftSecondHalf: number | null;
  };
  sign?: boolean;
} & BrakingBulletinSignFormData;

const BrakingBulletinForm = ({
  brakingBulletin,
  handleSubmit,
  change,
}: BrakingBulletinFormProps & InjectedFormProps<BrakingBulletinFormData>) => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectTrainStep).data!;
  const { email } = useAppSelector(selectSecurityContext);
  const userRoles = useAppSelector(selectSecurityContextRoles);
  const readOnly = brakingBulletin.status !== 'DRAFT' || !userRoles.includes(COMPOSITION_WRITE);

  const handleCancel = () => {
    dispatch(hideOverlay());
    dispatch(closeBrakingBulletin());
  };

  const toggleSign = (sign: boolean) => () => {
    // This enables or skips form validation
    change('sign', sign);
  };

  const doHandleSubmit = (sign: boolean) =>
    readOnly
      ? handleCancel
      : handleSubmit((formValues) => {
          // We toggle the value of the "sign" form field when clicking the "save and sign" button
          // (which is used to determine whether to show validation errors or not), but since updating the form
          // is asynchronous, it is not yet updated here, so we force the value.
          if (sign) {
            dispatch(
              openConfirmDialog({
                title: <BrakingBulletinValidationDialogTitle />,
                fields: {
                  signatureType: {
                    type: 'dropdown',
                    label: 'Signer en tant que',
                    options: BRAKING_BULLETIN_SIGNATURE_TYPES,
                    defaultValue: readDefaultSignatureType(),
                  },
                },
                actionText: 'Enregistrer et signer',
                action: updateBrakingBulletin({ ...formValues, sign: true }),
                actionClass: 'success',
              }),
            );
          } else {
            dispatch(updateBrakingBulletin({ ...formValues, sign: false })({}));
          }
        });

  const handleSecondarySign = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(
      openConfirmDialog({
        title: 'Ajouter votre signature au bulletin de freinage ?',
        fields: {
          signatureType: {
            type: 'dropdown',
            label: 'Signer en tant que',
            options: BRAKING_BULLETIN_SIGNATURE_TYPES,
            defaultValue: readDefaultSignatureType(),
          },
        },
        actionText: 'Signer',
        action: signBrakingBulletin,
        actionClass: 'success',
      }),
    );
  };

  const handleDelete = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(
      openConfirmDialog({
        title:
          brakingBulletin.status === 'DRAFT'
            ? 'Souhaitez-vous supprimer le brouillon du bulletin de freinage ?'
            : "Souhaitez-vous supprimer le bulletin de freinage de l'historique ?",
        actionText: 'Supprimer',
        action: deleteBrakingBulletin,
        actionClass: 'danger',
      }),
    );
  };

  return (
    <form className="overlay-form" autoComplete="off" onSubmit={doHandleSubmit(false)} noValidate>
      <div className="braking-bulletin-form">
        <Field
          component={Input}
          name="startLocation"
          labelText="À partir de"
          disabled={readOnly || brakingBulletin.revision === 1}
          maxLength={100}
        />
        <IndiceCompositionField compositionSummary={brakingBulletin.compositionSummary} formReadOnly={readOnly} />
        <FieldArray
          name="observations"
          component={BrakingBulletinObservations}
          readOnly={readOnly}
          compositionSummary={brakingBulletin.compositionSummary}
        />
        <CompositionSummarySection compositionSummary={brakingBulletin.compositionSummary} />
        <RequiredBrakingSection readOnly={readOnly} />
        <EffectiveBrakingSection compositionSummary={brakingBulletin.compositionSummary} readOnly={readOnly} />
        <BrakingBulletinMessages brakingBulletin={brakingBulletin} />
      </div>
      <div className="btn-group">
        {readOnly ? (
          <>
            <button className="btn btn-accent" onClick={handleCancel} type="button">
              Fermer
            </button>
            {userRoles.includes(COMPOSITION_WRITE) &&
              step.status === 'VALIDATED' &&
              brakingBulletin.status === 'SIGNED' &&
              brakingBulletin.signatures.length > 0 &&
              brakingBulletin.signatures.every(
                (signature) => !isOfflineSignature(signature) && signature.userEmail !== email,
              ) && (
                <button className="btn btn-success" onClick={handleSecondarySign} type="button">
                  Signer
                </button>
              )}
            {userRoles.includes(COMPOSITION_WRITE) &&
              brakingBulletin.signatures.every(
                (signature) => isOfflineSignature(signature) || signature.userEmail === email,
              ) && (
                <button className="btn btn-link remove" onClick={handleDelete} type="button">
                  Supprimer
                </button>
              )}
          </>
        ) : (
          <>
            <button className="btn btn-accent" type="submit" onMouseDown={toggleSign(false)}>
              Enregistrer
            </button>
            <button
              className="btn btn-success"
              onMouseDown={toggleSign(true)}
              onClick={doHandleSubmit(true)}
              disabled={step.status !== 'VALIDATED'}
              type="button"
            >
              Enregistrer et signer
            </button>
            <button className="btn btn-link" onClick={handleCancel} type="button">
              Annuler
            </button>
            <button className="btn btn-link remove" onClick={handleDelete} type="button">
              Supprimer
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default reduxForm<BrakingBulletinFormData, BrakingBulletinFormProps>({
  form: brakingBulletinFormName,
  validate: validateBrakingBulletin,
})(BrakingBulletinForm as any);
