import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Overlay from '../../../commons/components/overlay/Overlay';
import Input from '../../../commons/components/input/Input';
import StationField from '../../../commons/components/input/StationField';
import MarketSelect from '../../../commons/components/market-select/MarketSelect';
import { getMailingList } from '../../../commons/selectors/selectors';
import MailingListEntity from '../../../commons/entity/MailingListEntity';
import * as overlayActions from '../../../commons/components/overlay/overlayDucks';
import * as confirmModalActions from '../../../commons/components/modal/confirmModalDucks';
import * as mailingListActions from '../mailingListDucks';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { asyncValidateStation, asyncValidation } from '../../../commons/components/input/asyncValidation';

const MailingListDetails = ({
  mailingList,
  handleSubmit,
  shouldDisplayMarketsAndMissionType,
  startCreateMailingList,
  startUpdateMailingList,
  cancelEditMailingList,
  openConfirmDialog,
  submitFailed,
  error,
}) => (
  <Overlay
    name="mailing-list"
    className="mailing-list-details"
    title={(!mailingList.isNew ? 'Modifier la' : 'Ajouter une') + ' liste de diffusion'}
    onClosed={cancelEditMailingList}
    closeButton
  >
    <form
      className="overlay-form"
      autoComplete="off"
      onSubmit={handleSubmit((updatedMailingList) =>
        !mailingList.isNew ? startUpdateMailingList(updatedMailingList) : startCreateMailingList(updatedMailingList),
      )}
    >
      {submitFailed && error && (
        <div className="error-message">
          <strong>{error}</strong>
        </div>
      )}
      <Field
        component={Input}
        type="text"
        name="name"
        labelText="Nom de la liste"
        placeholder="Donnez un nom"
        inputMode="text"
      />
      <Field
        component={Dropdown}
        labelText="Type"
        name="type"
        options={{
          COMPOSITION: 'Composition',
          PRE_ADVICE: 'Pré-annonce',
        }}
        placeholder="Type"
      />
      <StationField
        name="startStation.label"
        className="icon route-from"
        labelText="Gare d'origine"
        placeholder="Choisissez une gare"
      />
      <StationField
        name="endStation.label"
        className="icon route-to"
        labelText="Gare de destination"
        placeholder="Choisissez une gare"
      />
      {shouldDisplayMarketsAndMissionType && (
        <Field
          component={MarketSelect}
          name="markets"
          className="icon star"
          labelText="Marchés"
          placeholder="Choisir les marchés"
        />
      )}
      {shouldDisplayMarketsAndMissionType && (
        <Field
          component={Input}
          type="text"
          name="missionType"
          labelText="Type de mission"
          placeholder="Préfixe de la mission"
          inputMode="text"
        />
      )}
      <Field
        component={Input}
        type="email"
        multiple={true}
        name="emailsProxy"
        labelText="Emails destinataires"
        placeholder="Saisissez un ou plusieurs emails séparés par une virgule"
        inputMode="email"
        autoCapitalize="none"
      />
      <div className="checkboxes">
        <div className="label">Relevé de véhicules</div>

        <Field component={CheckboxFormComponent} name="sendPdf" label="Envoyer le PDF" />
        <Field component={CheckboxFormComponent} name="sendExcel" label="Envoyer l'Excel" />
      </div>
      <div className="btn-group">
        <button className="btn btn-success" type="submit">
          {!mailingList.isNew ? 'Enregistrer' : 'Ajouter'}
        </button>
        <button className="btn btn-link" type="button" onClick={cancelEditMailingList}>
          Annuler
        </button>
        {!mailingList.isNew && (
          <button
            className="btn btn-icon remove"
            type="button"
            onClick={() =>
              openConfirmDialog({
                title: 'Supprimer la liste de diffusion ?',
                actionText: 'Oui, supprimer',
                action: mailingListActions.startDeleteMailingList(mailingList.data),
                actionClass: 'danger',
              })
            }
          >
            Supprimer
          </button>
        )}
      </div>
    </form>
  </Overlay>
);

const FORM_NAME = 'mailingListForm';
const MailingListDetailsForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: MailingListEntity.validate,
  ...asyncValidation({
    'startStation.label': asyncValidateStation,
    'endStation.label': asyncValidateStation,
  }),
})(MailingListDetails);

const selector = (state) => ({
  initialValues: getMailingList(state).data,
  mailingList: getMailingList(state),
  shouldDisplayMarketsAndMissionType:
    formValueSelector(FORM_NAME)(state, 'type') === MailingListEntity.TYPES.COMPOSITION,
});

export default connect(selector, {
  ...overlayActions,
  ...confirmModalActions,
  ...mailingListActions,
})(MailingListDetailsForm);
