import offlineUtils from '../../commons/offline/offlineUtils';
import { AnyAction } from 'redux';

import { AppDispatch } from '../../commons/store/store';
import { ServerUser, User } from '../../commons/model/userProfile';
import apiHelper from '../../api/apiHelper';
import { Sort, SortDirection } from '../../commons/model/sort';
import { sortList } from '../../commons/model/sortUtils';

type UserSort = Sort<User, 'fullName' | 'email'>;

export type UsersState = {
  data: readonly User[];
  sort: UserSort;
  loading: boolean;
  error: boolean;
};

// ACTIONS
const START_GET_USERS = 'START_GET_USERS';
const SUCCESS_GET_USERS = 'SUCCESS_GET_USERS';
const FAILURE_GET_USERS = 'FAILURE_GET_USERS';
const SORT_USERS = 'SORT_USERS';

// REDUCER

const defaultSort: UserSort = {
  field: 'fullName',
  direction: SortDirection.ASC,
};

const initialState: UsersState = {
  data: [],
  sort: defaultSort,
  loading: false,
  error: false,
};

export default (state: UsersState = initialState, action: AnyAction): UsersState => {
  switch (action.type) {
    case START_GET_USERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SUCCESS_GET_USERS:
      return {
        data: sortList(action.payload, state.sort),
        sort: defaultSort,
        loading: false,
        error: false,
      };
    case FAILURE_GET_USERS:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SORT_USERS:
      return {
        ...state,
        data: sortList(state.data, action.payload),
        sort: action.payload,
      };
    default:
      return state;
  }
};

// ACTIONS
export const failureGetUsers = () => ({
  type: FAILURE_GET_USERS,
});

export const successGetUsers = (users: User[]) => ({
  type: SUCCESS_GET_USERS,
  payload: users,
});

const toUser = (serverUsers: ServerUser[]): User[] => {
  return serverUsers.map((serverUser: ServerUser) => ({
    ...serverUser,
    fullName: `${serverUser.lastName} ${serverUser.firstName}`,
  }));
};

export const startGetUsers = () => async (dispatch: AppDispatch) => {
  dispatch({ type: START_GET_USERS });

  if (offlineUtils.isOnline()) {
    try {
      const users: ServerUser[] = await apiHelper.get('/api/users');
      dispatch(successGetUsers(toUser(users)));
    } catch {
      dispatch(failureGetUsers());
    }
  }
  return null;
};

export const sortUsers = (sort: Sort<User>) => ({
  type: SORT_USERS,
  payload: sort,
});
