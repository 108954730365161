import React from 'react';
import { useDispatch } from 'react-redux';
import PreAdviceHazardousMaterialEntity from '../../../commons/entity/PreAdviceHazardousMaterialEntity';
import { requestUpdatePreAdviceWagon } from '../preAdviceWagonDucks';
import PreAdviceHazardousMaterial from './PreAdviceHazardousMaterial';

const PreAdviceHazardousMaterials = ({ fields: hazardousMaterialFields, readOnly, wagon }) => {
  const dispatch = useDispatch();
  const addHazardousMaterial = () => {
    const hazardousMaterial = PreAdviceHazardousMaterialEntity.newEmptyHazardousMaterial();
    const hazardousMaterials = [...wagon.hazardousMaterials].concat([hazardousMaterial]);
    dispatch(requestUpdatePreAdviceWagon(wagon, { hazardousMaterials }));
    hazardousMaterialFields.push(hazardousMaterial);
  };
  return (
    <div className="hazmats">
      {hazardousMaterialFields.map((hazardousMaterialFieldName, index) => (
        <PreAdviceHazardousMaterial
          fieldName={hazardousMaterialFieldName}
          hazardousMaterialFields={hazardousMaterialFields}
          key={`hazardousMaterial.${index}`}
          position={index}
          readOnly={readOnly}
          wagon={wagon}
        />
      ))}

      {!readOnly && (
        <button type="button" className="btn btn-link btn-icon plus" onClick={addHazardousMaterial}>
          Ajouter une matière dangereuse
        </button>
      )}
    </div>
  );
};

export default PreAdviceHazardousMaterials;
