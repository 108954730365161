import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestGetPreAdviceSummaries, requestUpdateSortDefinition } from './preAdvicesDucks';
import PreAdvicesTopBar from './pre-advices-topbar/PreAdvicesTopBar';
import {
  getPreAdviceSummaries,
  getPreAdviceSummariesIsError,
  getPreAdviceSummariesIsLoading,
  getPreAdviceSummariesSortDefinition,
  selectSecurityContextRoles,
} from '../commons/selectors/selectors';
import PreAdvicesTable from './pre-advices-table/PreAdvicesTable';
import { PRE_ADVICE_WRITE } from '../commons/security/userRoles';
import { usePageTitle } from '../commons/components/hooks';

const PreAdvices = () => {
  usePageTitle('Pré-annonces');

  const dispatch = useDispatch();
  const error = useSelector(getPreAdviceSummariesIsError);
  const loading = useSelector(getPreAdviceSummariesIsLoading);
  const preAdviceSummaries = useSelector(getPreAdviceSummaries);
  const sortDefinition = useSelector(getPreAdviceSummariesSortDefinition);

  const canCreatePreAdvices = useSelector(selectSecurityContextRoles).includes(PRE_ADVICE_WRITE);
  const updateSortDefinition = (newSortDefinition) => dispatch(requestUpdateSortDefinition(newSortDefinition));
  const loadPreAdviceSummaries = () => dispatch(requestGetPreAdviceSummaries());
  useEffect(() => {
    dispatch(requestGetPreAdviceSummaries());
  }, [dispatch]);
  return (
    <div className="content-parent table-page" onClick={() => false}>
      <PreAdvicesTopBar
        canCreatePreAdvices={canCreatePreAdvices}
        loading={loading}
        loadPreAdviceSummaries={loadPreAdviceSummaries}
      />
      <PreAdvicesTable
        error={error}
        loading={loading}
        preAdviceSummaries={preAdviceSummaries}
        sortDefinition={sortDefinition}
        updateSortDefinition={updateSortDefinition}
      />
    </div>
  );
};

PreAdvices.propTypes = {};

export default PreAdvices;
