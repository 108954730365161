export type NestedKeyOf<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${NestedKeyOf<T[K]>}`}`;
    }[keyof T]
  : never;

export const enum SortDirection {
  ASC = 1,
  DESC = -1,
  NONE = 0,
}

export type Sort<E, F = NestedKeyOf<E>> = Readonly<{
  field: F;
  direction: SortDirection;
}>;
