import React from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { AppStore } from '../store/store';
import { browserHistory } from '../routes/routerHistoryConfig';
import Main from './layout/Main';

type RootProps = {
  store: AppStore;
};

const Root = ({ store }: RootProps) => (
  <Provider store={store}>
    <HistoryRouter history={browserHistory}>
      <Main />
    </HistoryRouter>
  </Provider>
);

export default Root;
