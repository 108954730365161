import { AppDispatch } from '../../../commons/store/store';
import { RootState } from '../../../commons/reducers/rootReducer';
import { selectTrainDiffs, selectTrainStep } from '../../../commons/selectors/selectors';
import { trainStepUpdated } from '../trainStepDucks';
import {
  applyTrainDiffs,
  TraceabilityInvalidateCommand,
  TraceabilitySignCommand,
} from '../../../commons/model/TrainDiff';
import { offlineUpdateTraceability, persistTrainsIfNeeded } from '../../offline/offlineTrainsDucks';
import { BrakeTestType } from '../../../commons/model/Traceability';
import moment from 'moment/moment';
import { DateString } from '../../../commons/model/common';

const doUpdateTraceability =
  ({ sign, invalidate }: { sign?: TraceabilitySignCommand; invalidate?: TraceabilityInvalidateCommand }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;
    dispatch(
      offlineUpdateTraceability({
        stepId: step.id,
        sign,
        invalidate,
      }),
    );
    const diffs = selectTrainDiffs(step.id)(getState());
    dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
    dispatch(persistTrainsIfNeeded());
  };

export const signBrakeTest = (type: BrakeTestType, brakeTestDateTime: DateString) =>
  doUpdateTraceability({
    sign: {
      type: 'BRAKE_TEST',
      date: moment().toISOString(),
      brakeTestType: type,
      brakeTestDateTime,
    },
  });

export const invalidateBrakeTest = (
  userId: number | null,
  brakeTestType: BrakeTestType,
  brakeTestDateTime: DateString | null,
) =>
  doUpdateTraceability({
    invalidate: {
      type: 'BRAKE_TEST',
      userId,
      brakeTestType,
      brakeTestDateTime,
    },
  });

export const signRearSignaling = () =>
  doUpdateTraceability({
    sign: { type: 'REAR_SIGNALING', date: moment().toISOString() },
  });

export const invalidateRearSignaling = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'REAR_SIGNALING', userId },
  });

export const signFirstLast = (firstNotBraked: boolean, lastNotBraked: boolean) =>
  doUpdateTraceability({
    sign: {
      type: 'FIRST_LAST',
      date: moment().toISOString(),
      firstNotBraked,
      lastNotBraked,
    },
  });

export const invalidateFirstLast = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'FIRST_LAST', userId },
  });

export const signAteAgreement = (exempt: boolean) =>
  doUpdateTraceability({
    sign: { type: 'ATE_AGREEMENT', date: moment().toISOString(), exempt },
  });

export const invalidateAteAgreement = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'ATE_AGREEMENT', userId },
  });

export const signAteBrakingBulletin = () =>
  doUpdateTraceability({
    sign: { type: 'ATE_BRAKING_BULLETIN', date: moment().toISOString() },
  });

export const invalidateAteBrakingBulletin = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'ATE_BRAKING_BULLETIN', userId },
  });

export const signAteTransmission = (noRestriction: boolean) =>
  doUpdateTraceability({
    sign: { type: 'ATE_TRANSMISSION', date: moment().toISOString(), noRestriction },
  });

export const invalidateAteTransmission = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'ATE_TRANSMISSION', userId },
  });

export const signVisit = () =>
  doUpdateTraceability({
    sign: { type: 'VISIT', date: moment().toISOString() },
  });

export const invalidateVisit = (userId: number | null) =>
  doUpdateTraceability({
    invalidate: { type: 'VISIT', userId },
  });
