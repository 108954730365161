import React from 'react';
import Checkbox, { CheckboxProps } from './Checkbox';
import { WrappedFieldProps } from 'redux-form';
import { ActionCreator } from 'redux';

type CheckboxFormComponentProps<T> = Omit<CheckboxProps, 'on' | 'onClick'> & {
  saveAction?: ActionCreator<any>;
  onCheck?: (checked: boolean) => void;
  data: T;
};

const CheckboxFormComponent = <T,>({
  input: { value: checked, onChange, name },
  saveAction,
  onCheck,
  data,
  meta,
  disabled,
  ...props
}: CheckboxFormComponentProps<T> & WrappedFieldProps) => (
  <Checkbox
    on={checked}
    disabled={disabled}
    onClick={() => {
      if (!disabled) {
        onChange(!checked);
        onCheck?.(!checked);
        if (saveAction) {
          meta.dispatch(saveAction(data, { [name]: !checked }));
        }
      }
    }}
    {...props}
  />
);

export default CheckboxFormComponent;
