import { TrainStep } from '../../../commons/model/Train';
import React, { MouseEventHandler } from 'react';
import TrainStepSummaryList from './TrainStepSummaryList';
import TrainUtils from '../../../commons/model/TrainUtils';
import FormSection from '../../../commons/components/form/FormSection';
import classNames from 'classnames';

type CompositionSummaryProps = {
  step: TrainStep;
  onClick?: MouseEventHandler;
};

const CompositionSummary = ({ step, onClick }: CompositionSummaryProps) => (
  <FormSection
    title="Composition"
    collapsible={true}
    className={classNames('composition-summary', {
      validated: TrainUtils.isStatusValidated(step),
      partial: TrainUtils.isStatusNotValidated(step),
    })}
    onClick={onClick}
  >
    {TrainUtils.isStatusValidated(step) && !step.manuallyUpdated && step.validatedBy != null && (
      <div className="copied-message">Copiée de l'étape précédente</div>
    )}
    <TrainStepSummaryList step={step} parts={['validation', 'composition', 'vehicles']} />
  </FormSection>
);

export default CompositionSummary;
