import { persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import { AppStore } from '../store/store';

let persistor: Persistor;

const persistStoreCreator = (store: AppStore, callback: () => any) => {
  persistor = persistStore(store, null, callback);
  return persistor;
};
export default persistStoreCreator;

export const purgePersistStore = () => persistor.purge();
