/**
 * Custom field component
 */
import React from 'react';
import { Field } from 'redux-form';
import Input, { InputProps } from './Input';
import helpers from '../../helpers/helpers';
import './inputStyles.scss';
import { isFieldReadOnly } from '../vehicles/edit/vehicleEditUtils';
import { useAppSelector } from '../../store/hooks';
import { BaseFieldProps } from 'redux-form/lib/Field';
import { selectSecurityContextRoles } from '../../selectors/selectors';

type VehicleFieldProps<P> = {
  name: string;
  numeric?: boolean;
  data?: any;
} & BaseFieldProps<P> &
  P;

const VehicleField = <T extends any = string, P extends React.InputHTMLAttributes<HTMLInputElement> = InputProps<T>>({
  disabled,
  numeric,
  component,
  ...props
}: VehicleFieldProps<P>) => {
  const roles = useAppSelector(selectSecurityContextRoles);
  const readOnly = props.data && isFieldReadOnly(props.data, roles, disabled);
  const childProps = { ...props };
  if (numeric) {
    childProps.pattern = '[0-9]*'; // Make keyboard input appear on iPad
    childProps.inputMode = 'numeric';
  }
  const component1 = component || Input<T>;
  return (
    <Field
      component={component1}
      id={`vehicle-${helpers.camelCaseToHyphen(props.name)}`}
      type="text"
      disabled={readOnly}
      {...childProps}
    />
  );
};

export default VehicleField;
