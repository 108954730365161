import { AdUser } from '../model/userProfile';
import { Autocompleter } from './Autocompleter';
import apiHelper from '../../api/apiHelper';

class AdUserAutocompleter implements Autocompleter<AdUser> {
  autocomplete = async (inputValue: string | null): Promise<AdUser[]> => {
    if (!inputValue || inputValue.length < 3) {
      return [];
    }
    return apiHelper.get('/api/users/autocomplete', { text: inputValue });
  };

  autocompleteWithFilter = () => this.autocomplete;

  formatItem = ({ firstName, lastName, email = 'email inconnu' }: AdUser) => `${firstName} ${lastName} (${email})`;
}

export default new AdUserAutocompleter();
