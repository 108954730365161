import AbstractTemplateCache from './AbstractTemplateCache';
import helpers from '../helpers/helpers';
import { CachedEngineTemplate, EngineTemplate } from '../model/templates';
import { Tx } from './AbstractDbCache';

class EngineCache extends AbstractTemplateCache<'engine', EngineTemplate> {
  protected getStoreName(): 'engine' {
    return 'engine';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 3;
  }

  protected addItemMetadata(item: EngineTemplate): CachedEngineTemplate {
    return { ...item, reversedNumber: helpers.reverseString(item.number) };
  }

  protected removeItemMetadata({ reversedNumber, ...item }: CachedEngineTemplate): EngineTemplate {
    return item;
  }

  protected async findTemplates(inputValue: string, tx: Tx<'engine'>) {
    const [numberMatches, reversedNumberMatches] = await Promise.all([
      this.findItemsByIndexPrefix(inputValue, tx, 'by-number'),
      this.findItemsByIndexPrefix(helpers.reverseString(inputValue), tx, 'by-reversedNumber'),
    ]);
    return helpers.deduplicateArray([...numberMatches, ...reversedNumberMatches], 'id');
  }

  public formatItem(item: EngineTemplate) {
    return item.number;
  }
}

export default new EngineCache();
