import moment from 'moment';
import intl from 'intl';
import 'intl/locale-data/jsonp/fr-FR';
import debugFactory from 'debug';
import lodashSet from 'lodash/fp/set';

const debug = debugFactory('helpers');

/**
 * Number formatting.
 */
if (!global.Intl) {
  // No `Intl`, so use and load the polyfill.
  global.Intl = intl;
}

const INTEGER_FORMAT = new global.Intl.NumberFormat('fr', { maximumFractionDigits: 0 });

const DECIMAL_FORMAT = new global.Intl.NumberFormat('fr', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

const DEFAULT_DATE_FORMAT = 'DD/MM';

export const toPath = (path: string) =>
  path
    .replace(/\[(\d+)]/g, '.$1') // a[0].b.c => a.0.b.c
    .split('.'); // a.0.b.c => ['a', '0', 'b', 'c']

export const APP_BUILD_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const SIDEBAR_DATE_TIME_FORMAT = 'D MMM HH:mm:ss';

export const registrationRegexp = /^(\d{2})(\d{2})(\d{3})(\d{4})(\d)$/;

function trimBadSpaces(str: string): string;
function trimBadSpaces(str: string | null): string | null {
  if (str == null) return str;
  return str.trim().replace(/\s\s+/g, ' ');
}

/**
 * Contains helpers methods
 * @module helper
 */
export default {
  /**
   * Return user initials
   *
   * @param name and surname
   * @return initials
   */
  getInitials(name: string): string {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  },

  /**
   * Test if element has CSS class
   *
   * @param element to test
   * @param className name
   * @return true if element has a class
   */
  hasClass(element: { className?: string }, className: string): boolean {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(element.className ?? '');
  },

  /**
   * Transform a string property to kebab-case 'className' like string
   *
   * @param str in camelCase
   * @return an hyphenated string
   */
  camelCaseToHyphen(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  },

  /**
   * Test email
   *
   * @param email
   * @return true if string is valid email
   */
  isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },

  /**
   * Make a string non-breakable by replacing spaces by non-breakable space utf-8 code (\u00a0)
   *
   * @param str
   * @return a non-breakable string
   */
  makeInsecable(str: string): string {
    return str.replace(' ', '\u00a0');
  },

  /**
   * Formats an integer number in French Locale
   *
   * @param n is the integer number to format
   * @returns the formatted number in French Locale
   */
  integerFormat(n: number): string {
    return INTEGER_FORMAT.format(n);
  },

  /**
   * Formats a decimal number in French Locale with exactly 2 decimal digits
   *
   * @param n is the decimal number to format
   * @returns the formatted number in French Locale
   */
  decimalFormat(n: number) {
    return DECIMAL_FORMAT.format(n);
  },

  /**
   * Formats a date
   *
   * @param date to format
   * @param format used to stringify the date
   * @returns a formatted date
   */
  dateTimeFormat(date: moment.MomentInput | string, format: string = DEFAULT_DATE_TIME_FORMAT): string {
    if (date) {
      const momentDateTime = moment(date);
      if (momentDateTime.isValid()) {
        return momentDateTime.format(format || DEFAULT_DATE_TIME_FORMAT);
      }
      return '-';
    }
    return '';
  },

  /**
   * Formats a date
   *
   * @param date to format
   * @param format used to stringify the date
   * @returns a formatted date
   */
  dateFormat(date: moment.MomentInput, format: string = DEFAULT_DATE_FORMAT): string {
    return date ? moment(date).format(format || DEFAULT_DATE_FORMAT) : '';
  },

  /**
   * Formats a registration
   *
   * @param registration to format
   * @returns the formatted registration
   */
  registrationFormat(registration: string): string {
    if (registration && !registration.match(registrationRegexp)) {
      debug('Helper method registrationFormat: registration should match \\d{12} only');
    }
    return registration.replace(registrationRegexp, '$1\u00a0$2\u00a0$3\u00a0$4\u2011$5');
  },

  /**
   * Transforms a formatted registration into a series of digits
   *
   * @param registration to unformat
   * @returns the unformatted registration
   */
  registrationUnformat(registration: string): string {
    return registration.replace(/\D*/g, '');
  },

  /**
   * Transforms a formatted number with thousand separator to a number without thousand separator.
   *
   * @param numberAsString to unformat
   * @returns the unformatted number
   */
  thousandSeparatorUnformat(numberAsString: string | number): string {
    if (typeof numberAsString === 'number') {
      return numberAsString.toString();
    }
    return numberAsString.replace(/\s/g, '');
  },

  /**
   * Get path property from in an Object literal.
   *
   * @param obj the Object literal. ex: { a: [{ foo: bar }] }
   * @param path path to the property. ex: a[0].foo => bar
   * @returns the property in Object literal corresponding to path
   */
  getIn(obj: object, path: string): any {
    let i;
    let ref: any = obj;
    const keys = toPath(path);
    for (i = 0; i < keys.length; i += 1) {
      ref = ref[keys[i]];
    }
    return ref;
  },

  /**
   * Set property in path for an Object literal.
   *
   * @param obj the Object literal.
   *        ex: { a: [{ foo: "bar" }] }
   * @param propertiesToUpdate the Object with key = path, value = property value to set in Object.
   *        ex: { "a[0].foo": "bar-bar"}
   * @returns the Object literal mutated with propertiesToUpdate
   *              ex: { a: [{ foo: "bar-bar" }] }
   */
  setIn(obj: object, propertiesToUpdate: object): object {
    const updatedObj: object = obj ?? {};
    let i;
    if (propertiesToUpdate) {
      if (typeof propertiesToUpdate !== 'object') {
        throw new Error('Helper method setIn should mutate with propertiesToUpdate as object only');
      }
      Object.keys(propertiesToUpdate).forEach((path) => {
        let ref: any = updatedObj;
        const keys = toPath(path);
        for (i = 0; i < keys.length - 1; i += 1) {
          if (!ref[keys[i]]) {
            ref[keys[i]] = {};
          }
          ref = ref[keys[i]];
        }
        // @ts-ignore
        ref[keys[i]] = propertiesToUpdate[path];
      });
    }
    return updatedObj;
  },

  /**
   * Copy of an object with an updated value.
   *
   * @param obj the Object literal.
   *        ex: { a: [{ foo: "bar" }] }
   * @param propertiesToUpdate the Object(s) with key = path, value = property value to set in Object.
   *        ex: { "a[0].foo": "bar-bar"}
   * @returns A copy of the Object literal with propertiesToUpdate applied
   *              ex: { a: [{ foo: "bar-bar" }] }
   */
  with<T extends object, R extends T = T>(obj: T, ...propertiesToUpdate: object[]): R {
    let updatedObj = obj as R;
    if (propertiesToUpdate) {
      propertiesToUpdate.forEach((propsToUpdate) =>
        Object.entries(propsToUpdate).forEach(([path, value]) => {
          updatedObj = lodashSet(path, value, updatedObj);
        }),
      );
    }
    return updatedObj;
  },

  /**
   * Remove unwanted spaces from a string (at position 0 or when 2 or more spaces next to each other).
   *
   * @param {string} str the string to clean up.
   *        ex: { " hello  world " }
   * @returns {string} the cleaned up string.
   *          ex: { "hello world" }
   */
  trimBadSpaces,

  dataURLtoBlob(dataURL: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    const byteString = atob(dataURL.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  },

  reverseString(str: string): string {
    return str.split('').reverse().join('');
  },

  /**
   * Filter an array by keeping a single item for each value of the given field (or for the item value itself).
   */
  deduplicateArray<T, F extends keyof T>(array: T[], fieldName?: F): T[] {
    const seen = new Set<T[F] | T>();
    return array.filter((item) => {
      const fieldValue = fieldName ? item[fieldName] : item;
      if (seen.has(fieldValue)) {
        return false;
      }
      seen.add(fieldValue);
      return true;
    });
  },

  arrayIntersection<T, F extends keyof T>(fieldName: F, ...lists: T[][]) {
    if (!lists.length) {
      return [];
    }
    let res = lists[0];
    for (const otherList of lists.slice(1)) {
      res = res.filter((item1) => otherList.some((item2) => item2[fieldName] === item1[fieldName]));
    }
    return res;
  },
};
