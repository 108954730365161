import {
  hasMaxLength,
  hasMaxOrEqualValue,
  hasMinLength,
  hasMinOrEqualValue,
  hasMinValue,
  isFieldEmpty,
  isRegistration,
  isRegistrationIgnoringChecksum,
  isRequiredFieldNotEmpty,
} from '../redux-form/valueValidator';
import lodashMerge from 'lodash/fp/merge';
import { Engine, Wagon, WagonHazardousMaterial } from '../model/Vehicle';
import { HazardousMaterial } from '../model/templates';
import { Damage } from '../model/DamageReport';
import { EngineFormValues } from '../components/vehicles/edit/engine/EngineForm';
import { WagonFormValues, WagonHazardousMaterialFormValues } from '../components/vehicles/edit/wagon/WagonForm';
import { FormErrors } from 'redux-form';
import VehicleUtils from '../model/VehicleUtils';
import {
  BrakingBulletinFormData,
  BrakingBulletinObservationFormData,
} from '../../trains/step/braking-bulletin/BrakingBulletinForm';
import { ReactElement } from 'react';
import helpers from '../helpers/helpers';
import { BrakeTestFormData } from '../../trains/step/traceability/BrakeTestSection';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { FirstLastFormData } from '../../trains/step/traceability/FirstLastSection';
import { AteAgreementFormData } from '../../trains/step/traceability/AteAgreementSubsection';
import { AteTransmissionFormData } from '../../trains/step/traceability/AteTransmissionSubsection';

export type ValidationErrors<F extends string> = { [K in F]?: string };

/**
 * Return a boolean indicating whether the given object contains some validation errors.
 *
 * The input object is an object returned by a Redux-form object. This
 * object may contain some sub-objects, but it does not mean that there
 * is validation error.
 *
 * There is an error if there is at least one object tree leaf whose
 * value is a string.
 *
 * @param errors The Redux-form errors object.
 * @returns {boolean} {@code true} if the input object contains some real
 * errors. Otherwise {@code false} is returned.
 */
const containsErrors = (errors: any): boolean =>
  Boolean(errors) &&
  Object.keys(errors).some((key) => {
    if ({}.hasOwnProperty.call(errors, key)) {
      const error = errors[key];
      return typeof error === 'string' || containsErrors(error);
    }
    return false;
  });

export const firstErrorPath = (errors: any, isRoot: boolean = true): string => {
  if (!errors) {
    return '';
  }
  if (Array.isArray(errors)) {
    const firstErrorIndex = errors.findIndex((childErrors) => containsErrors(childErrors));
    if (firstErrorIndex >= 0) {
      return `[${firstErrorIndex}]${firstErrorPath(errors[firstErrorIndex], false)}`;
    }
  } else if (typeof errors === 'object') {
    const entry = Object.entries(errors).find(([_key, childErrors]) => containsErrors(childErrors));
    if (entry) {
      const [key, childErrors] = entry;
      return `${isRoot ? '' : '.'}${key}${firstErrorPath(childErrors, false)}`;
    }
  }
  return '';
};

export const scrollToFirstError = (errors: any) => {
  const inputName = firstErrorPath(errors);
  const inputElement = inputName ? document.querySelector(`[name='${inputName}']`) : null;
  const inputParent = inputElement?.closest('.input,.dropdown,.checkbox');
  const elementToFocus = inputParent ?? inputElement;
  if (elementToFocus) {
    scrollIntoView(elementToFocus, { block: 'nearest', scrollMode: 'always', behavior: 'smooth' }).then();
  }
};

export const validateRegistration = (registration: string | null): ValidationErrors<'registration'> => {
  if (isFieldEmpty(registration)) {
    return { registration: 'Immatriculation obligatoire' };
  } else if (!isRegistration(registration)) {
    return { registration: "L'immatriculation n'est pas conforme" };
  }
  return {};
};

export const validateWagonCharge = (charge: string | null): string | undefined => {
  if (isFieldEmpty(charge)) {
    return 'Charge obligatoire';
  }
};

export const validateNbAxles = (nbAxles: number | null): ValidationErrors<'nbAxles'> => {
  if (isFieldEmpty(nbAxles)) {
    return { nbAxles: "Nombre d'essieux obligatoire" };
  } else if (!hasMinOrEqualValue(nbAxles, 2)) {
    return { nbAxles: "Le nombre d'essieux doit être supérieur à 2" };
  } else if (!hasMaxOrEqualValue(nbAxles, 99)) {
    return { nbAxles: "Le nombre d'essieux doit inférieur à 99" };
  }
  return {};
};

const getTotalNumberOfUti = (wagon: Wagon | WagonFormValues): number =>
  (wagon.nbUtiContainer ?? 0) + (wagon.nbUtiTrailer ?? 0) + (wagon.nbUtiVehicle ?? 0) + (wagon.nbUtiOther ?? 0);

const validateManualEvp = (wagon: Wagon | WagonFormValues): string | undefined => {
  const numberOfUti = getTotalNumberOfUti(wagon);
  const loadWeight = wagon.loadWeight ?? 0;
  if (numberOfUti > 0 && loadWeight === 0) {
    return "Un wagon non chargé ne peut pas avoir d'UTI";
  }
  if (numberOfUti === 0 && isRequiredFieldNotEmpty(wagon.manualEvp)) {
    return "Impossible de surcharger l'EVP sans ajouter d'UTI";
  }
};

type NumberOfUtiValidationError = ValidationErrors<
  'noUti' | 'nbUtiContainer' | 'nbUtiVehicle' | 'nbUtiTrailer' | 'nbUtiOther'
>;
const validateTotalNumberOfUti = (wagon: Wagon | WagonFormValues): NumberOfUtiValidationError | undefined => {
  const numberOfUti = getTotalNumberOfUti(wagon);
  const loadWeight = wagon.loadWeight ?? 0;
  const noUti = wagon.noUti ?? undefined;

  if (numberOfUti === 0 && loadWeight > 0 && !noUti) {
    return {
      noUti: "Indiquez le nombre d'UTI ou indiquez que le wagon n'en comporte pas",
    };
  }
  if (loadWeight === 0 && noUti !== undefined && !noUti) {
    return {
      noUti: "Un wagon non chargé doit indiquer ne pas contenir d'UTI",
    };
  }

  if (numberOfUti > 0 && loadWeight === 0) {
    return {
      nbUtiContainer: "Un wagon non chargé ne peut pas avoir d'UTI",
      nbUtiVehicle: "Un wagon non chargé ne peut pas avoir d'UTI",
      nbUtiTrailer: "Un wagon non chargé ne peut pas avoir d'UTI",
      nbUtiOther: "Un wagon non chargé ne peut pas avoir d'UTI",
      noUti: "Un wagon non chargé doit indiquer ne pas contenir d'UTI",
    };
  }

  if (numberOfUti > 0 && noUti) {
    return {
      nbUtiContainer: "Un wagon sans UTI ne peut pas avoir d'UTI",
      nbUtiVehicle: "Un wagon sans UTI ne peut pas avoir d'UTI",
      nbUtiTrailer: "Un wagon sans UTI ne peut pas avoir d'UTI",
      nbUtiOther: "Un wagon sans UTI ne peut pas avoir d'UTI",
      noUti: "Un wagon sans UTI doit indiquer ne pas contenir d'UTI",
    };
  }
  return undefined;
};

export const validateUtis = (
  wagon: Wagon | WagonFormValues,
  isMarketCombined: boolean,
): (NumberOfUtiValidationError & ValidationErrors<'manualEvp'>) | undefined => {
  if (!isMarketCombined) {
    return {};
  }
  const totalNumberOfUtiErrors = validateTotalNumberOfUti(wagon);
  if (totalNumberOfUtiErrors) {
    return {
      manualEvp: validateManualEvp(wagon),
      ...totalNumberOfUtiErrors,
    };
  }
  const toMsg = (val: number | null) => {
    if (isRequiredFieldNotEmpty(val) && !hasMinOrEqualValue(val, 0)) {
      return "Le nombre d'UTI ne peut pas être négatif";
    } else {
      return undefined;
    }
  };
  return {
    nbUtiContainer: toMsg(wagon.nbUtiContainer),
    nbUtiVehicle: toMsg(wagon.nbUtiVehicle),
    nbUtiTrailer: toMsg(wagon.nbUtiTrailer),
    nbUtiOther: toMsg(wagon.nbUtiOther),
    manualEvp: validateManualEvp(wagon),
    noUti: validateTotalNumberOfUti(wagon)?.noUti,
  };
};

export const validateWagonTare = (tare: number | null): ValidationErrors<'tare'> => {
  if (isFieldEmpty(tare)) {
    return { tare: 'Tare obligatoire' };
  } else if (!hasMinValue(tare, 0)) {
    return { tare: 'La tare doit être positive' };
  } else if (!hasMaxOrEqualValue(tare, 999999)) {
    return { tare: 'La tare doit être inférieure à 999 999 kg' };
  }
  return {};
};

export const validateWagonLength = (length: number | null): ValidationErrors<'length'> => {
  if (isFieldEmpty(length)) {
    return { length: 'Longueur obligatoire' };
  } else if (!hasMinValue(length, 0)) {
    return { length: 'La longueur doit être positive' };
  } else if (!hasMaxOrEqualValue(length, 300)) {
    return { length: 'La longueur doit être inférieure à 300 m' };
  }
  return {};
};

export const validateEffectiveBrakedWeight = (
  effectiveBrakedWeight: number | null,
): ValidationErrors<'effectiveBrakedWeight'> => {
  const errors: ValidationErrors<'effectiveBrakedWeight'> = {};
  validateBrakedWeight(errors, { effectiveBrakedWeight }, 'effectiveBrakedWeight', 'Masse freinée réalisée');
  return errors;
};

export const validateWagonLoadWeight = (
  loadWeight: number | null,
  calculatedHazmatsLoadWeight: number | null,
): ValidationErrors<'loadWeight'> => {
  if (isFieldEmpty(loadWeight)) {
    return { loadWeight: 'Masse de chargement obligatoire' };
  } else if (calculatedHazmatsLoadWeight && !hasMinOrEqualValue(loadWeight, calculatedHazmatsLoadWeight)) {
    return {
      loadWeight: 'La masse de chargement doit être supérieure ou égale à la somme des masses des matières dangereuses',
    };
  } else if (!hasMaxOrEqualValue(loadWeight, 999999)) {
    return { loadWeight: 'La masse de chargement doit être inférieure à 999 999 kg' };
  }
  return {};
};

export const validateWagonPosition = <F extends string>(
  nbWagons: number,
  firstPosition: number,
  position: number,
  fieldName: F,
): ValidationErrors<F> => {
  const errors: ValidationErrors<F> = {};
  if (isFieldEmpty(position)) {
    errors[fieldName] = 'Position obligatoire';
  } else if (position < firstPosition) {
    errors[fieldName] = 'La position doit être positive';
  } else if (position >= nbWagons + firstPosition) {
    errors[fieldName] = 'La position ne peut pas être supérieure au nombre de wagons';
  }
  return errors;
};

export const validateWagonSpeedLimit = (speedLimit: number | string | null): ValidationErrors<'speedLimit'> => {
  return { speedLimit: getSpeedLimitError(speedLimit) };
};

export const getSpeedLimitError = (speedLimit: number | string | null): string | undefined => {
  if (isFieldEmpty(speedLimit)) {
    return 'Limite de vitesse obligatoire';
  } else if (!hasMinValue(speedLimit, 0)) {
    return 'La vitesse doit être positive';
  } else if (!hasMaxOrEqualValue(speedLimit, 140)) {
    return 'La vitesse doit être inférieure à 140 km/h';
  }
  return undefined;
};

type WagonHazardousMaterialValidationErrors = {
  hazardousMaterial: ValidationErrors<keyof HazardousMaterial>;
} & ValidationErrors<'weight' | 'volume'>;
export const validateHazardousMaterial = (
  hazmat: WagonHazardousMaterial | WagonHazardousMaterialFormValues | null,
): WagonHazardousMaterialValidationErrors => {
  const { hazardousMaterial, volume, weight } = hazmat ?? {};
  const errors: WagonHazardousMaterialValidationErrors = { hazardousMaterial: {} };

  if (isFieldEmpty(hazardousMaterial?.id)) {
    errors.hazardousMaterial.id = 'Aucune matière dangereuse connue ne correspond à ces valeurs';
  }

  if (isFieldEmpty(hazardousMaterial?.unitedNationsCode)) {
    errors.hazardousMaterial.unitedNationsCode = 'ONU obligatoire';
  }

  if (isFieldEmpty(hazardousMaterial?.packingGroup)) {
    errors.hazardousMaterial.packingGroup = 'Groupe obligatoire';
  }

  if (isFieldEmpty(hazardousMaterial?.rid)) {
    errors.hazardousMaterial.rid = 'RID obligatoire';
  }

  if (isFieldEmpty(weight)) {
    errors.weight = 'Masse de MD obligatoire';
  } else if (!hasMinOrEqualValue(weight, 0)) {
    errors.weight = 'La masse de MD doit être positive';
  } else if (!hasMaxOrEqualValue(weight, 999999)) {
    errors.weight = 'La masse de MD doit être inférieure à 999 999 kg';
  }

  if (isRequiredFieldNotEmpty(volume)) {
    if (!hasMinOrEqualValue(volume, 0)) {
      errors.volume = 'Le volume de MD doit être positif';
    } else if (!hasMaxOrEqualValue(volume, 9999)) {
      errors.volume = 'Le volume de MD doit être inférieur à 9 999';
    }
  }
  return errors;
};

export const validateWagonDamage = (damageTemplate: Damage): ValidationErrors<'damageTemplateId'> => {
  if (isFieldEmpty(damageTemplate?.damageTemplateId)) {
    return { damageTemplateId: "Code d'avarie obligatoire" };
  }
  return {};
};

export const validateWagon = (
  wagon: Wagon | WagonFormValues,
  isMarketCombined: boolean,
  nbWagons: number,
): FormErrors<WagonFormValues> => {
  if (!wagon) {
    return {};
  }

  const errors: FormErrors<WagonFormValues> = {
    ...validateRegistration(wagon.registration),
    ...validateWagonPosition(nbWagons, 1, wagon.index, 'index'),
    ...validateWagonLength(wagon.length),
    ...validateWagonTare(wagon.tare),
    ...validateWagonLoadWeight(wagon.loadWeight, VehicleUtils.computeWagonHazmatsLoadWeight(wagon)),
    ...validateEffectiveBrakedWeight(wagon.effectiveBrakedWeight),
    ...validateNbAxles(wagon.nbAxles),
    ...validateUtis(wagon, isMarketCombined),
  };

  if (isFieldEmpty(wagon.owner?.name)) {
    errors.owner = {
      // The type from redux-form is too restrictive
      // @ts-ignore
      name: 'Détenteur obligatoire',
    };
  }

  if (wagon.ateFileNumber && !hasMaxLength(wagon.ateFileNumber, 24)) {
    errors.ateFileNumber = "L'ATE doit contenir au maximum 24 caractères";
  }

  errors.charge = validateWagonCharge(wagon.charge);

  // Validate hazardous materials
  const hazmats = wagon.hazardousMaterials;
  if (wagon.hazardousMaterials) {
    const hazmatErrors = { hazardousMaterials: hazmats.map(validateHazardousMaterial) };
    Object.assign(errors, hazmatErrors);
  }

  return errors;
};

const validateBrakedWeight = <T>(
  errors: { [P in keyof T]?: ReactElement | string },
  values: T,
  fieldName: keyof T,
  fieldLabel: string,
  max: number = 999,
  required: boolean = true,
) => {
  const value = values ? (values[fieldName] as unknown as number | null) : null;
  if (isFieldEmpty(value)) {
    if (required) {
      errors[fieldName] = `${fieldLabel} obligatoire`;
    }
  } else if (!hasMaxOrEqualValue(value, max)) {
    errors[fieldName] = `${fieldLabel} doit être inférieur à ${helpers.integerFormat(max)} t`;
  }
};

export const validateEngine = (engine: Engine | EngineFormValues): FormErrors<EngineFormValues> => {
  const errors: ValidationErrors<keyof Engine> = {};
  if (!engine) {
    return errors;
  }

  if (isFieldEmpty(engine.number)) {
    errors.number = 'Numéro obligatoire';
  }

  if (isFieldEmpty(engine.seriesNumber)) {
    errors.seriesNumber = 'N° de série obligatoire';
  } else if (!hasMinLength(engine.seriesNumber, 5)) {
    errors.seriesNumber = 'Le n° de série doit contenir au moins 5 caractères';
  } else if (!hasMaxLength(engine.seriesNumber, 12)) {
    errors.seriesNumber = "Le n° de série doit contenir jusqu'à 12 caractères";
  }

  if (isFieldEmpty(engine.registration)) {
    errors.registration = 'Immatriculation obligatoire';
  } else if (!isRegistrationIgnoringChecksum(engine.registration)) {
    errors.registration = "L'immatriculation n'est pas conforme";
  }

  if (isFieldEmpty(engine.length)) {
    errors.length = 'Longueur obligatoire';
  } else if (!hasMinValue(engine.length, 0)) {
    errors.length = 'La longueur doit être positive';
  } else if (!hasMaxOrEqualValue(engine.length, 99)) {
    errors.length = 'La longueur doit être inférieure à 99 m';
  }

  if (isFieldEmpty(engine.tare)) {
    errors.tare = 'Tare obligatoire';
  } else if (!hasMinValue(engine.tare, 0)) {
    errors.tare = 'La tare doit être positive';
  } else if (!hasMaxOrEqualValue(engine.tare, 999000)) {
    // En kg dans le modèle, en t à l'affichage
    errors.tare = 'La tare doit être inférieure à 999 t';
  }

  validateBrakedWeight(errors, engine, 'effectiveBrakedWeight', 'Masse freinée réalisée');

  Object.assign(errors, validateNbAxles(engine.nbAxles));

  if (isFieldEmpty(engine.enginePosition)) {
    errors.enginePosition = 'Position dans le train obligatoire';
  }

  if (isFieldEmpty(engine.tractionMode)) {
    errors.tractionMode = 'Mode de traction obligatoire';
  }

  return errors;
};

function validateBrakingBulletinObservation(
  observationData: BrakingBulletinObservationFormData,
  sign: boolean,
): FormErrors<BrakingBulletinObservationFormData> {
  const observationErrors: FormErrors<BrakingBulletinObservationFormData> = {};

  if (
    sign &&
    [
      'VEHICLE_ENGINE',
      'WAGON_SPEED_LIMIT',
      'BRAKING_INSUFFICIENT',
      'ISOLATED_VEHICLE',
      'QUEUED_ENGINE',
      'QUEUED_WAGON',
      'QUEUED_WAGON_NO_MAIN_LINE',
      'OTHER',
    ].includes(observationData.type) &&
    isFieldEmpty(observationData.text1)
  ) {
    observationErrors.text1 = 'Champ obligatoire';
  }
  if (
    sign &&
    ['ATE', 'BRAKING_INSUFFICIENT', 'ISOLATED_VEHICLE'].includes(observationData.type) &&
    isFieldEmpty(observationData.text2)
  ) {
    observationErrors.text2 = 'Champ obligatoire';
  }

  if (observationData.type === 'PNEUMATIC_VEHICLE') {
    if (isFieldEmpty(observationData.number1)) {
      if (sign) {
        observationErrors.number1 = 'Quantité obligatoire';
      }
    } else if (!hasMinValue(observationData.number1, 0)) {
      observationErrors.number1 = 'La quantité être positive';
    } else if (!hasMaxOrEqualValue(observationData.number1, 99)) {
      observationErrors.number1 = 'La quantité être inférieure à 99';
    }
  }

  if (['VEHICLE_ENGINE', 'WAGON_SPEED_LIMIT'].includes(observationData.type)) {
    if (isFieldEmpty(observationData.number1)) {
      if (sign) {
        observationErrors.number1 = 'Vitesse obligatoire';
      }
    } else if (!hasMinValue(observationData.number1, 0)) {
      observationErrors.number1 = 'La vitesse être positive';
    } else if (!hasMaxOrEqualValue(observationData.number1, 140)) {
      observationErrors.number1 = 'La vitesse être inférieure à 140 km/h';
    }
  }

  if (observationData.type === 'WAGON_SPEED_LIMIT') {
    if (isFieldEmpty(observationData.number2)) {
      if (sign) {
        observationErrors.number2 = 'Vitesse obligatoire';
      }
    } else if (!hasMinValue(observationData.number2, 0)) {
      observationErrors.number2 = 'La vitesse être positive';
    } else if (!hasMaxOrEqualValue(observationData.number2, 140)) {
      observationErrors.number2 = 'La vitesse être inférieure à 140 km/h';
    }
  }

  if (observationData.type === 'FCV_VEHICLES') {
    validateBrakedWeight(observationErrors, observationData, 'number1', 'Masse freinée voyageurs', 99999, sign);
  }

  return observationErrors;
}

export const validateBrakingBulletin = (formData: BrakingBulletinFormData): FormErrors<BrakingBulletinFormData> => {
  const sign = formData.sign === true;

  const errors: FormErrors<BrakingBulletinFormData> = {};

  if (isFieldEmpty(formData.startLocation)) {
    if (sign) {
      errors.startLocation = 'Champ obligatoire';
    }
  } else if (!hasMaxLength(formData.startLocation, 100)) {
    errors.startLocation = 'Longueur maximum 100 caractères';
  }

  errors.observations = (formData.observations ?? []).map((observationData) =>
    validateBrakingBulletinObservation(observationData, sign),
  ) as any;

  const brakedWeightDetailsErrors: FormErrors<BrakingBulletinFormData['brakedWeightDetails']> = {};
  validateBrakedWeight(
    brakedWeightDetailsErrors,
    formData.brakedWeightDetails,
    'stopping',
    "Freinage d'arrêt",
    99999,
    sign && !formData.brakedWeightDetails?.fixed,
  );
  validateBrakedWeight(
    brakedWeightDetailsErrors,
    formData.brakedWeightDetails,
    'drift',
    'Freinage de dérive',
    99999,
    false,
  );
  validateBrakedWeight(
    brakedWeightDetailsErrors,
    formData.brakedWeightDetails,
    'driftSecondHalf',
    'Freinage de la 2e moitié du train',
    99999,
    false,
  );
  if (Object.keys(brakedWeightDetailsErrors).length > 0) {
    errors.brakedWeightDetails = brakedWeightDetailsErrors as any;
  }

  const effectiveBrakedWeightDetailsErrors: FormErrors<BrakingBulletinFormData['effectiveBrakedWeightDetails']> = {};
  validateBrakedWeight(
    effectiveBrakedWeightDetailsErrors,
    formData.effectiveBrakedWeightDetails,
    'driftSecondHalf',
    'Freinage de la 2e moitié du train',
    99999,
    false,
  );
  if (Object.keys(effectiveBrakedWeightDetailsErrors).length > 0) {
    errors.effectiveBrakedWeightDetails = effectiveBrakedWeightDetailsErrors as any;
  }

  return errors;
};

export const validateBrakeTestForm = (formData: BrakeTestFormData): FormErrors<BrakeTestFormData> => {
  const errors: FormErrors<BrakeTestFormData> = {};
  if (isFieldEmpty(formData.type)) {
    errors.type = 'Type obligatoire';
  }
  if (isFieldEmpty(formData.dateTime)) {
    errors.dateTime = 'Date et heure obligatoires';
  }
  return errors;
};

export const validateFirstLastForm = (formData: FirstLastFormData): FormErrors<FirstLastFormData> => {
  const errors: FormErrors<FirstLastFormData> = {};
  if (!formData.allBraked && !formData.firstNotBraked && !formData.lastNotBraked) {
    errors._error = 'Détail obligatoire';
  } else if (formData.allBraked && (formData.firstNotBraked || formData.lastNotBraked)) {
    errors._error = 'Données incohérentes';
  }
  return errors;
};

export const validateAteAgreementForm = (formData: AteAgreementFormData): FormErrors<AteAgreementFormData> => {
  const errors: FormErrors<AteAgreementFormData> = {};
  if (!formData.received && !formData.exempt) {
    errors._error = 'Détail obligatoire';
  } else if (formData.received && formData.exempt) {
    errors._error = 'Données incohérentes';
  }
  return errors;
};

export const validateAteTransmissionForm = (formData: AteTransmissionFormData): FormErrors<AteTransmissionFormData> => {
  const errors: FormErrors<AteTransmissionFormData> = {};
  if (!formData.transmitted && !formData.noRestriction) {
    errors._error = 'Détail obligatoire';
  } else if (formData.transmitted && formData.noRestriction) {
    errors._error = 'Données incohérentes';
  }
  return errors;
};

export const getValidationMessage = (validationErrors: ValidationErrors<string> | undefined) => {
  let message = '';
  if (validationErrors) {
    Object.keys(validationErrors).forEach((key) => {
      if ({}.hasOwnProperty.call(validationErrors, key)) {
        const error = validationErrors[key];
        if (typeof error === 'string') {
          message += '\n' + error;
        } else {
          message += getValidationMessage(error);
        }
      }
    });
  }
  return message;
};

export const mergeValidationErrors = <T>(formErrors: T, entityErrors: T): T => {
  return lodashMerge(formErrors, entityErrors);
};

export default containsErrors;
