import { hasMinLength, isEmailsList, isFieldEmpty, isRequiredFieldNotEmpty } from '../redux-form/valueValidator';

export default class MailingListEntity {
  static TYPES = {
    COMPOSITION: 'COMPOSITION',
    PRE_ADVICE: 'PRE_ADVICE',
  };

  static validate(mailingList) {
    let errors = {};

    if (!mailingList) {
      return errors;
    }

    if (isFieldEmpty(mailingList.name)) {
      errors.name = 'Nom obligatoire';
    }

    if (isFieldEmpty(mailingList.type)) {
      errors.type = 'Type obligatoire';
    } else if (!Object.keys(MailingListEntity.TYPES).includes(mailingList.type)) {
      errors.type = 'Type invalide';
    }

    const hasStartStationLabel = mailingList.startStation && isRequiredFieldNotEmpty(mailingList.startStation.label);
    const hasEndStationLabel = mailingList.endStation && isRequiredFieldNotEmpty(mailingList.endStation.label);

    if (mailingList.type === MailingListEntity.TYPES.PRE_ADVICE) {
      if (!hasStartStationLabel && !hasEndStationLabel) {
        errors = {
          ...errors,
          _error: 'Choisissez au moins une gare',
          startStation: {
            label: true,
          },
          endStation: {
            label: true,
          },
        };
      }
    }

    if (mailingList.type === MailingListEntity.TYPES.COMPOSITION) {
      if (
        !hasStartStationLabel &&
        !hasEndStationLabel &&
        !hasMinLength(mailingList.markets, 1) &&
        !mailingList.missionType
      ) {
        errors = {
          ...errors,
          _error: 'Choisissez au moins une gare, un marché, ou un type de mission',
          startStation: {
            label: true,
          },
          endStation: {
            label: true,
          },
          markets: true,
          missionType: true,
        };
      }
    }

    if (isFieldEmpty(mailingList.emailsProxy)) {
      errors.emailsProxy = 'Ajoutez au moins un email';
    } else if (!isEmailsList(mailingList.emailsProxy)) {
      errors.emailsProxy = 'Au moins un email est invalide';
    }

    return errors;
  }
}
