/**
 * Vehicle edition component
 */
import React, { useEffect } from 'react';
import { initialize } from 'redux-form';
import WagonForm, { initializeWagonFormValues } from '../wagon/WagonForm';
import EngineForm from '../engine/EngineForm';
import Switch2 from '../../../switch2/Switch2';
import { deleteEngine, deleteWagon, verifyOrUnverifyVehicle } from '../../../../../trains/step/vehicleActions';
import { COMPOSITION_WRITE } from '../../../../security/userRoles';
import { openConfirmDialog } from '../../../modal/confirmModalDucks';
import { startToast } from '../../../toast/toastDucks';
import WagonHeaderAdditionalActions from './WagonHeaderAdditionalActions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import VehiclesUtils from '../../../../model/VehiclesUtils';
import { isEngine, isWagon, Vehicle } from '../../../../model/Vehicle';
import TrainUtils from '../../../../model/TrainUtils';
import VehicleUtils from '../../../../model/VehicleUtils';
import { selectSecurityContextRoles, selectTrainStep } from '../../../../selectors/selectors';
import { useViewportWidth } from '../../../../responsive/hooks';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { usePageTitle } from '../../../hooks';
import { trainStepTitle } from '../../../../../trains/step/TrainStepPage';

type VehicleEditProps = {
  vehicleId: string;
};

const vehicleTitle = (vehicle: Vehicle) => {
  if (isWagon(vehicle)) {
    return `Wagon ${vehicle.position}`;
  } else {
    return `EM ${vehicle.enginePosition}`;
  }
};

const VehicleEdit = ({ vehicleId }: VehicleEditProps) => {
  const dispatch = useAppDispatch();
  const { data: step, loading } = useAppSelector(selectTrainStep);
  const vehicle = step && VehiclesUtils.findVehicle(step.vehicles, vehicleId);
  const roles = useAppSelector(selectSecurityContextRoles);
  const { isMobile } = useViewportWidth();

  usePageTitle(vehicle && vehicle.id === vehicleId ? `${vehicleTitle(vehicle)} - ${trainStepTitle(step)}` : 'Véhicule');

  useEffect(() => {
    if (vehicle?.id) {
      dispatch(initialize(`${vehicle.type}Form`, vehicle));
    }
  }, [dispatch, vehicle, vehicle?.id]);

  const handleDelete = () => {
    if (TrainUtils.isStatusValidated(step!)) {
      dispatch(
        startToast({
          text: 'Pour supprimer un véhicule, veuillez au préalable déverrouiller la composition',
          className: 'error',
        }),
      );
    } else if (VehicleUtils.isStatusValidated(vehicle!)) {
      dispatch(
        startToast({
          text: 'Pour supprimer un véhicule, veuillez au préalable le déverrouiller',
          className: 'error',
        }),
      );
    } else if (isWagon(vehicle!)) {
      dispatch(
        openConfirmDialog({
          title: `Supprimer le wagon ${VehicleUtils.formatRegistration(vehicle)} ?`,
          actionText: 'Oui, supprimer',
          action: deleteWagon(vehicle, true),
          actionClass: 'danger',
        }),
      );
    } else if (isEngine(vehicle!)) {
      dispatch(
        openConfirmDialog({
          title: `Supprimer la locomotive ${VehicleUtils.formatEngineNumber(vehicle)}?`,
          actionText: 'Oui, supprimer',
          action: deleteEngine(vehicle, true),
          actionClass: 'danger',
        }),
      );
    }
  };

  return (
    <div className="vehicle-edit" onClick={() => false}>
      {!vehicle ? (
        <div>Ce vehicle n'existe pas.</div>
      ) : (
        <div className={classNames('vehicle-edit-form', isWagon(vehicle) ? 'wagon-edit' : 'engine-edit')}>
          <div className="vehicle-edit-header">
            {isMobile && (
              <Link className="btn btn-icon btn-basic wagon-empty" to={TrainUtils.linkToTrainStep(step.id, 'vehicles')}>
                <strong>&lt;</strong>
              </Link>
            )}
            <h1 className="title">{isWagon(vehicle) ? 'Wagon' : 'Locomotive'}</h1>
            {loading && <div className="loader" />}
            {isWagon(vehicle) && <WagonHeaderAdditionalActions step={step} wagon={vehicle} />}
            <Switch2
              id="switch-validate"
              className="switch-validate"
              showLock={true}
              labelOff="À vérifier"
              labelOn="OK"
              value={VehicleUtils.isStatusValidated(vehicle)}
              onChange={() => {
                // Small delay to give the Blur field handlers time to save the vehicle
                setTimeout(() => dispatch(verifyOrUnverifyVehicle(vehicleId)), 50);
              }}
              disabled={!roles.includes(COMPOSITION_WRITE)}
            />
            {roles.includes(COMPOSITION_WRITE) && (
              <button className="btn btn-round remove" onClick={handleDelete} type="button" />
            )}
          </div>
          {isWagon(vehicle) ? (
            <WagonForm step={step} wagon={vehicle} initialValues={initializeWagonFormValues(vehicle)} />
          ) : (
            <EngineForm engine={vehicle} initialValues={vehicle} />
          )}
        </div>
      )}
    </div>
  );
};

export default VehicleEdit;
