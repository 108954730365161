import localForage from 'localforage';

const storageName = 'iCompo';
const storageVersion = 1.0;
const storageDescription = 'Storage for iCompo';

localForage.config({
  name: storageName,
  version: storageVersion,
  storeName: storageName, // Should be alphanumeric, with underscores.
  description: storageDescription,
});

export default localForage;
