import { SubmissionError, reset } from 'redux-form';
import { showOverlay, hideOverlay } from '../../commons/components/overlay/overlayDucks';
import apiHelper from '../../api/apiHelper';
import { startToast } from '../../commons/components/toast/toastDucks';
import { startGetProfiles } from './profilesDucks';
import { AppDispatch } from '../../commons/store/store';
import { AnyAction } from 'redux';
import { Profile } from '../../commons/model/userProfile';
import ApiError from '../../api/ApiError';

// ACTIONS
const LOAD_PROFILE = 'LOAD_PROFILE';
const CREATE_PROFILE = 'CREATE_PROFILE';
const PERSIST_PROFILE_START = 'PERSIST_PROFILE_START';
const PERSIST_PROFILE_SUCCESS = 'PERSIST_PROFILE_SUCCESS';
const PERSIST_PROFILE_ERROR = 'PERSIST_PROFILE_ERROR';

// REDUCER
const initialState: ProfileState = {
  data: null,
  loading: false,
  error: false,
};

export type ProfileState = {
  data: Profile | null;
  loading: boolean;
  error: boolean;
};

export default (state: ProfileState = initialState, action: AnyAction): ProfileState => {
  switch (action.type) {
    case LOAD_PROFILE:
      return {
        data: { ...action.payload },
        loading: false,
        error: false,
      };
    case CREATE_PROFILE:
      return {
        data: null,
        loading: false,
        error: false,
      };
    case PERSIST_PROFILE_START:
      return {
        ...state,
        error: false,
      };
    case PERSIST_PROFILE_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

// ACTIONS
export const loadProfile = (profile: Profile) => (dispatch: AppDispatch) => {
  dispatch({ type: LOAD_PROFILE, payload: profile });
  dispatch(reset('profileForm')); // If we load the same profile twice, the form is not reinitialized.
  dispatch(showOverlay('profile'));
};

export const createProfile = () => (dispatch: AppDispatch) => {
  dispatch({ type: CREATE_PROFILE });
  dispatch(reset('profileForm')); // If we load the same profile twice, the form is not reinitialized.
  dispatch(showOverlay('profile'));
};

const doPersist = async (
  profile: Profile,
  persistRequest: Promise<void>,
  actionVerb: string,
  dispatch: AppDispatch,
) => {
  dispatch({ type: PERSIST_PROFILE_START, payload: profile });

  try {
    await persistRequest;
    dispatch({ type: PERSIST_PROFILE_SUCCESS, payload: profile });
    dispatch(startGetProfiles());
    dispatch(startToast({ text: `Le profil a été ${actionVerb}.`, className: 'success' }));
    dispatch(hideOverlay());
  } catch (err) {
    dispatch({ type: PERSIST_PROFILE_ERROR, payload: profile });

    if (err instanceof ApiError && err.httpStatus === 400) {
      if (err.errors) {
        throw new SubmissionError(err.errors);
      } else {
        dispatch(
          startToast({
            text: `Erreur : le profil n'a pas été ${actionVerb}.\n${err.message ?? ''}`,
            className: 'error',
          }),
        );
      }
    } else {
      dispatch(startToast({ text: `Erreur : le profil n'a pas été ${actionVerb}.`, className: 'error' }));
    }
  }
};

export const persistProfileStart = (profile: Profile, id: string | undefined) => (dispatch: AppDispatch) => {
  const persistRequest = id ? apiHelper.put(`/api/profiles/${id}`, profile) : apiHelper.post('/api/profiles', profile);
  const actionVerb = id ? 'mis à jour' : 'ajouté';
  return doPersist(profile, persistRequest, actionVerb, dispatch);
  // TODO update profile templates
};

export const deleteProfile = (profile: Profile) => () => (dispatch: AppDispatch) => {
  const persistRequest = apiHelper.delete(`/api/profiles/${profile.id}`);
  const actionVerb = 'supprimé';
  return doPersist(profile, persistRequest, actionVerb, dispatch);
};
