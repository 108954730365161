import { v4 as uuid } from 'uuid';
import { EntityBase, Labelled, TractionMode, ValidationStatus } from './common';
import { HazardousMaterial } from './templates';
import { TrainStep } from './Train';
import { NullableFields } from './typeUtils';

export type VehicleType = 'engine' | 'wagon';

export type Charge = 'C' | 'D';

export type WagonOwner = Readonly<{
  id: number;
  name: string;
}>;

export type EnginePosition = 'US' | 'UM' | 'DT' | 'VEHICLE' | 'P';

export const ENGINE_POSITIONS: Record<EnginePosition, Labelled & { shortLabel: string }> = {
  US: { label: 'US', shortLabel: 'US' },
  UM: { label: 'UM', shortLabel: 'UM' },
  DT: { label: 'DT', shortLabel: 'DT' },
  VEHICLE: { label: 'Véhicule', shortLabel: 'CV' },
  P: { label: 'P', shortLabel: 'P' },
};

export const TRACTION_MODES: Record<TractionMode, Labelled> = {
  DIESEL: { label: 'Diesel' },
  ELECTRIC: { label: 'Électrique' },
};

export const CHARGES: Record<Charge, Labelled> = {
  C: { label: 'C' },
  D: { label: 'D' },
};

export type WagonHazardousMaterial = Readonly<{
  hazardousMaterial: NullableFields<HazardousMaterial>;
  weight: number | null;
  volume: number | null;
}>;

export type VehicleBase = EntityBase<string> &
  Readonly<{
    stepId: string;
    type: VehicleType;
    /**
     * Index among all vehicles, 0-based
     */
    position: number;
    status: ValidationStatus;
    registration: string | null;
    length: number | null;
    tare: number | null;
    effectiveBrakedWeight: number | null;
    nbAxles: number | null;
  }>;

export type Wagon = VehicleBase &
  Readonly<{
    type: 'wagon';
    rat: boolean | null;
    loadWeight: number | null;
    ateFileNumber: string | null;
    gbGauge: boolean;
    charge: Charge | null;
    owner: WagonOwner | null;
    nbUtiContainer: number | null;
    nbUtiTrailer: number | null;
    nbUtiVehicle: number | null;
    nbUtiOther: number | null;
    noUti: boolean | null;
    manualEvp: number | null;
    hazardousMaterials: readonly WagonHazardousMaterial[];
    doubleWagon: boolean;
    /**
     * Index among wagons, 1-based
     */
    index: number;
  }>;

export type Engine = VehicleBase &
  Readonly<{
    type: 'engine';
    enginePosition: EnginePosition | null;
    tractionMode: TractionMode | null;
    number: string | null;
    seriesNumber: string | null;
    operationalLength: number | null;
    rollingGoods: boolean | null;
  }>;

export type Vehicle = Wagon | Engine;

export const isWagon = (vehicle: Vehicle): vehicle is Wagon => {
  return vehicle.type === 'wagon';
};

export const isEngine = (vehicle: Vehicle): vehicle is Engine => {
  return vehicle.type === 'engine';
};

export const createNewWagon = (step: TrainStep, position: number, index: number): Wagon => {
  const date = new Date().toISOString();
  return {
    id: uuid(),
    stepId: step.id,
    type: 'wagon',
    position,
    index,
    registration: null,
    hazardousMaterials: [],
    status: 'EMPTY',
    nbUtiVehicle: step.combinedMarket ? 0 : null,
    nbUtiOther: step.combinedMarket ? 0 : null,
    nbUtiTrailer: step.combinedMarket ? 0 : null,
    nbUtiContainer: step.combinedMarket ? 0 : null,
    noUti: step.combinedMarket ? true : null,
    rat: false,
    creationDate: date,
    updateDate: date,
    length: null,
    tare: null,
    effectiveBrakedWeight: null,
    nbAxles: null,
    ateFileNumber: null,
    gbGauge: false,
    charge: null,
    loadWeight: null,
    manualEvp: null,
    owner: null,
    doubleWagon: false,
  };
};

export const createNewEngine = (step: TrainStep, position: number): Engine => {
  const date = new Date().toISOString();
  return {
    id: uuid(),
    stepId: step.id,
    type: 'engine',
    position,
    registration: null,
    status: 'EMPTY',
    nbAxles: null,
    effectiveBrakedWeight: null,
    length: null,
    tare: null,
    enginePosition: null,
    number: null,
    updateDate: date,
    creationDate: date,
    operationalLength: null,
    seriesNumber: null,
    tractionMode: null,
    rollingGoods: false,
  };
};

export const createEmptyHazardousMaterial = () => ({
  id: null,
  dangerIdentificationNumber: null,
  unitedNationsCode: null,
  label: null,
  rid: null,
  packingGroup: null,
  dangerLabels: null,
  limitedQuantity: null,
});

export const createNewWagonHazardousMaterial = (): WagonHazardousMaterial => ({
  hazardousMaterial: createEmptyHazardousMaterial(),
  weight: null,
  volume: null,
});
