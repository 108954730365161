import { TrainStep } from './Train';
import { Vehicle } from './Vehicle';
import { Draft } from '@reduxjs/toolkit';
import VehiclesUtils from './VehiclesUtils';
import { BrakingBulletin, BrakingBulletinSignature, BrakingBulletinSignatureType } from './BrakingBulletin';
import {
  AgreementSignature,
  BrakeTestSignature,
  BrakeTestType,
  FirstLastSignature,
  Traceability,
  TransmissionSignature,
} from './Traceability';
import { createOfflineSignature, DateString, isOfflineSignature, SignatureOrOffline } from './common';
import { createEmptyTraceabilityDiff } from './TraceabilityUtils';
import { ReadyForDispatchSignature } from './ReadyForDispatch';
import moment from 'moment';
import { SetRequired, Writable } from 'type-fest';

export type CompositionDiff = Pick<TrainStep, 'status' | 'version'>;

export type VehicleDiff = Vehicle;

export type BrakingBulletinContent = Omit<BrakingBulletin, 'id' | 'compositionId' | 'status' | 'signatures'>;

type BrakingBulletinHeader = Pick<BrakingBulletin, 'compositionId' | 'status' | 'signatures'>;

export type BrakingBulletinDiff = {
  id: string;
  brakingBulletin?: BrakingBulletinContent;
  sign?: DateString;
  signatureType?: BrakingBulletinSignatureType;
  invalidate?: true;
  delete?: true;
};

export type BrakingBulletinDiffMap = { [id: string]: BrakingBulletinDiff };

export type NewBrakingBulletinDiff = SetRequired<BrakingBulletinDiff, 'brakingBulletin'>;

type TraceabilitySignatureType =
  | 'BRAKE_TEST'
  | 'REAR_SIGNALING'
  | 'FIRST_LAST'
  | 'ATE_AGREEMENT'
  | 'ATE_BRAKING_BULLETIN'
  | 'ATE_TRANSMISSION'
  | 'VISIT';

type TraceabilityGenericSignCommand = {
  type: Exclude<TraceabilitySignatureType, 'BRAKE_TEST' | 'FIRST_LAST' | 'ATE_AGREEMENT' | 'ATE_TRANSMISSION'>;
  date: DateString;
};

type TraceabilityBrakeTestSignCommand = {
  type: 'BRAKE_TEST';
  date: DateString;
  brakeTestType: BrakeTestType;
  brakeTestDateTime: DateString;
};

type TraceabilityFirstLastSignCommand = {
  type: 'FIRST_LAST';
  date: DateString;
  firstNotBraked: boolean;
  lastNotBraked: boolean;
};

type TraceabilityAgreementSignCommand = {
  type: 'ATE_AGREEMENT';
  date: DateString;
  exempt: boolean;
};

type TraceabilityTransmissionSignCommand = {
  type: 'ATE_TRANSMISSION';
  date: DateString;
  noRestriction: boolean;
};

export type TraceabilitySignCommand =
  | TraceabilityGenericSignCommand
  | TraceabilityBrakeTestSignCommand
  | TraceabilityFirstLastSignCommand
  | TraceabilityAgreementSignCommand
  | TraceabilityTransmissionSignCommand;

export const isTraceabilityBrakeTestSignCommand = (
  command: TraceabilitySignCommand,
): command is TraceabilityBrakeTestSignCommand => command.type === 'BRAKE_TEST';

const isTraceabilityFirstLastSignCommand = (
  command: TraceabilitySignCommand,
): command is TraceabilityFirstLastSignCommand => command.type === 'FIRST_LAST';

const isTraceabilityAgreementSignCommand = (
  command: TraceabilitySignCommand,
): command is TraceabilityAgreementSignCommand => command.type === 'ATE_AGREEMENT';

const isTraceabilityTransmissionSignCommand = (
  command: TraceabilitySignCommand,
): command is TraceabilityTransmissionSignCommand => command.type === 'ATE_TRANSMISSION';

export type UserInvalidateCommand = {
  // null represents an offline signature
  userId: number | null;
};

type TraceabilityGenericInvalidateCommand = UserInvalidateCommand & {
  type: Exclude<TraceabilitySignatureType, 'BRAKE_TEST'>;
};

type TraceabilityBrakeTestInvalidateCommand = UserInvalidateCommand & {
  type: 'BRAKE_TEST';
  brakeTestType: BrakeTestType;
  brakeTestDateTime: DateString | null;
};

export type TraceabilityInvalidateCommand =
  | TraceabilityGenericInvalidateCommand
  | TraceabilityBrakeTestInvalidateCommand;

export const isTraceabilityBrakeTestInvalidateCommand = (
  command: TraceabilityInvalidateCommand,
): command is TraceabilityBrakeTestInvalidateCommand => command.type === 'BRAKE_TEST';

export type TraceabilityDiff = {
  sign: TraceabilitySignCommand[];
  invalidate: TraceabilityInvalidateCommand[];
};

export type ReadyForDispatchSignCommand = {
  date: DateString;
  trackNumber: string | null;
};

export type ReadyForDispatchInvalidateCommand = {
  date: DateString | null;
};

export type ReadyForDispatchUpdateCommand = {
  sign?: ReadyForDispatchSignCommand;
  invalidate: ReadyForDispatchInvalidateCommand[];
};

export type MissionEndUpdateCommand = {
  sign?: DateString;
  invalidate: UserInvalidateCommand[];
};

export type TrainStepDiff = {
  stepId: string;
  updatedComposition?: CompositionDiff;
  updatedVehicles: { [id: string]: VehicleDiff };
  deletedVehicles: string[];
  updatedBrakingBulletins: BrakingBulletinDiffMap;
  updatedTraceability?: TraceabilityDiff;
  updatedReadyForDispatch?: ReadyForDispatchUpdateCommand;
  updatedMissionEnd?: MissionEndUpdateCommand;
};

export type TrainDiffMap = { [id: string]: TrainStepDiff };

export const createTrainDiff = (stepId: string): Draft<TrainStepDiff> => ({
  stepId,
  updatedVehicles: {},
  deletedVehicles: [],
  updatedBrakingBulletins: {},
});

/**
 * Apply diff2 on top of diff1
 */
export const mergeDiffs = (diffs1: TrainDiffMap, diffs2: TrainDiffMap): TrainDiffMap => {
  const diffs1Ids = new Set(Object.keys(diffs1));
  const res: TrainDiffMap = {};
  for (const diff of Object.values(diffs1)) {
    res[diff.stepId] = mergeDiff(diff, diffs2[diff.stepId]);
  }
  for (const diff of Object.values(diffs2)) {
    if (!diffs1Ids.has(diff.stepId)) {
      res[diff.stepId] = diff;
    }
  }
  return res;
};

export const mergeDiff = (diff1: TrainStepDiff, diff2?: TrainStepDiff): TrainStepDiff => {
  if (!diff2) {
    return diff1;
  }
  return {
    stepId: diff1.stepId,
    updatedComposition: diff2.updatedComposition ?? diff1.updatedComposition,
    updatedVehicles: Object.fromEntries(
      [...Object.values(diff1.updatedVehicles), ...Object.values(diff2.updatedVehicles)].map((vehicleDiff) => [
        vehicleDiff.id,
        vehicleDiff,
      ]),
    ),
    deletedVehicles: [...diff1.deletedVehicles, ...diff2.deletedVehicles],
    updatedBrakingBulletins: mergeBrakingBulletinDiffMaps(diff1.updatedBrakingBulletins, diff2.updatedBrakingBulletins),
    updatedTraceability:
      diff1.updatedTraceability || diff2.updatedTraceability
        ? {
            sign: [...(diff1.updatedTraceability?.sign ?? []), ...(diff2.updatedTraceability?.sign ?? [])],
            invalidate: [
              ...(diff1.updatedTraceability?.invalidate ?? []),
              ...(diff2.updatedTraceability?.invalidate ?? []),
            ],
          }
        : undefined,
    updatedReadyForDispatch:
      diff1.updatedReadyForDispatch || diff2.updatedReadyForDispatch
        ? {
            // There should only be at most one offline sign command at a time
            sign: diff2.updatedReadyForDispatch?.sign ?? diff1.updatedReadyForDispatch?.sign,
            invalidate: [
              ...(diff1.updatedReadyForDispatch?.invalidate ?? []),
              ...(diff2.updatedReadyForDispatch?.invalidate ?? []),
            ],
          }
        : undefined,
    updatedMissionEnd:
      diff1.updatedMissionEnd || diff2.updatedMissionEnd
        ? {
            // There should only be at most one offline sign command at a time
            sign: diff2.updatedMissionEnd?.sign ?? diff1.updatedMissionEnd?.sign,
            invalidate: [
              ...(diff1.updatedMissionEnd?.invalidate ?? []),
              ...(diff2.updatedMissionEnd?.invalidate ?? []),
            ],
          }
        : undefined,
  };
};

const mergeBrakingBulletinDiffMaps = (
  bbDiffs1: BrakingBulletinDiffMap,
  bbDiffs2: BrakingBulletinDiffMap,
): BrakingBulletinDiffMap => {
  const res = { ...bbDiffs1 };
  for (const bbDiff of Object.values(bbDiffs2)) {
    res[bbDiff.id] = mergeBrakingBulletinDiffs(res[bbDiff.id], bbDiff);
  }
  return res;
};

export const mergeBrakingBulletinDiffs = (
  bbDiff1: BrakingBulletinDiff | undefined,
  bbDiff2: BrakingBulletinDiff,
): BrakingBulletinDiff => {
  return {
    ...bbDiff1,
    ...bbDiff2,
  };
};

export const applyNewBrakingBulletinDiff = (
  compositionId: string,
  bbDiff: NewBrakingBulletinDiff,
): [BrakingBulletin] | [] =>
  doApplyBrakingBulletinDiff({ compositionId, status: 'DRAFT', signatures: [] }, bbDiff.brakingBulletin, bbDiff);

export const applyBrakingBulletinDiff = (bb: BrakingBulletin, bbDiff: BrakingBulletinDiff): [BrakingBulletin] | [] =>
  doApplyBrakingBulletinDiff(bb, bb, bbDiff);

const doApplyBrakingBulletinDiff = (
  bbHeader: BrakingBulletinHeader,
  bbContent: BrakingBulletinContent,
  bbDiff: BrakingBulletinDiff,
): [BrakingBulletin] | [] => {
  if (bbDiff.delete) {
    return [];
  }

  const updatedBB: Writable<BrakingBulletin> = {
    id: bbDiff.id,
    ...bbHeader,
    ...bbContent,
    ...bbDiff.brakingBulletin,
  };
  if (bbDiff.sign && !updatedBB.signatures.some(({ date }) => date === bbDiff.sign)) {
    updatedBB.signatures = [
      ...bbHeader.signatures,
      createOfflineSignature<BrakingBulletinSignature>({ date: bbDiff.sign, type: bbDiff.signatureType }),
    ];
    updatedBB.status = 'SIGNED';
  }
  if (bbDiff.invalidate) {
    updatedBB.status = 'INVALIDATED';
  }
  return [updatedBB];
};

const applySimpleTraceabilitySignaturesDiff = (
  signatures: SignatureOrOffline[] | undefined,
  diff: TraceabilityDiff,
  type: TraceabilitySignatureType,
): SignatureOrOffline[] => [
  ...(signatures ?? []).filter(
    (signature) =>
      // Remove all offline signatures - the ones that were not deleted will be reapplied in the second part
      !isOfflineSignature(signature) &&
      // Remove all online signatures that were invalidated
      diff.invalidate.every((command) => command.type !== type || command.userId !== signature.userId),
  ),
  // Add offline signatures
  ...diff.sign.filter((command) => command.type === type).map(({ date }) => createOfflineSignature({ date })),
];

const applyBrakeTestSignaturesDiff = (
  signatures: SignatureOrOffline<BrakeTestSignature>[] | undefined,
  diff: TraceabilityDiff,
): SignatureOrOffline<BrakeTestSignature>[] => [
  ...(signatures ?? []).filter(
    (signature) =>
      !isOfflineSignature(signature) &&
      diff.invalidate.every(
        (command) =>
          !isTraceabilityBrakeTestInvalidateCommand(command) ||
          command.brakeTestType !== signature.type ||
          command.brakeTestDateTime !== signature.testDateTime ||
          command.userId !== signature.userId,
      ),
  ),
  ...diff.sign.filter(isTraceabilityBrakeTestSignCommand).map(({ date, brakeTestType, brakeTestDateTime }) =>
    createOfflineSignature<BrakeTestSignature>({
      date,
      type: brakeTestType,
      testDateTime: brakeTestDateTime,
    }),
  ),
];

const applyFirstLastSignaturesDiff = (
  signatures: SignatureOrOffline<FirstLastSignature>[] | undefined,
  diff: TraceabilityDiff,
): SignatureOrOffline<FirstLastSignature>[] => [
  ...(signatures ?? []).filter(
    (signature) =>
      !isOfflineSignature(signature) &&
      diff.invalidate.every((command) => command.type !== 'FIRST_LAST' || command.userId !== signature.userId),
  ),
  ...diff.sign
    .filter(isTraceabilityFirstLastSignCommand)
    .map(({ date, firstNotBraked, lastNotBraked }) =>
      createOfflineSignature<FirstLastSignature>({ date, firstNotBraked, lastNotBraked }),
    ),
];

const applyAgreementSignaturesDiff = (
  signatures: SignatureOrOffline<AgreementSignature>[] | undefined,
  diff: TraceabilityDiff,
): SignatureOrOffline<AgreementSignature>[] => [
  ...(signatures ?? []).filter(
    (signature) =>
      !isOfflineSignature(signature) &&
      diff.invalidate.every((command) => command.type !== 'ATE_AGREEMENT' || command.userId !== signature.userId),
  ),
  ...diff.sign
    .filter(isTraceabilityAgreementSignCommand)
    .map(({ date, exempt }) => createOfflineSignature<AgreementSignature>({ date, exempt })),
];

const applyTransmissionSignaturesDiff = (
  signatures: SignatureOrOffline<TransmissionSignature>[] | undefined,
  diff: TraceabilityDiff,
): SignatureOrOffline<TransmissionSignature>[] => [
  ...(signatures ?? []).filter(
    (signature) =>
      !isOfflineSignature(signature) &&
      diff.invalidate.every((command) => command.type !== 'ATE_TRANSMISSION' || command.userId !== signature.userId),
  ),
  ...diff.sign
    .filter(isTraceabilityTransmissionSignCommand)
    .map(({ date, noRestriction }) => createOfflineSignature<TransmissionSignature>({ date, noRestriction })),
];

const applyTraceabilityDiff = (
  traceability: Traceability | undefined,
  vehicles: Vehicle[],
  diff: TraceabilityDiff | undefined = createEmptyTraceabilityDiff(),
): Traceability => ({
  ratSignature: VehiclesUtils.hasAllRAT(vehicles)
    ? traceability?.ratSignature ?? createOfflineSignature({ date: moment().toISOString() })
    : undefined,
  brakeTestSignatures: applyBrakeTestSignaturesDiff(traceability?.brakeTestSignatures, diff),
  rearSignalingSignatures: applySimpleTraceabilitySignaturesDiff(
    traceability?.rearSignalingSignatures,
    diff,
    'REAR_SIGNALING',
  ),
  firstLastSignatures: applyFirstLastSignaturesDiff(traceability?.firstLastSignatures, diff),
  ateAgreementSignatures: applyAgreementSignaturesDiff(traceability?.ateAgreementSignatures, diff),
  ateBrakingBulletinSignatures: applySimpleTraceabilitySignaturesDiff(
    traceability?.ateBrakingBulletinSignatures,
    diff,
    'ATE_BRAKING_BULLETIN',
  ),
  ateTransmissionSignatures: applyTransmissionSignaturesDiff(traceability?.ateTransmissionSignatures, diff),
  visitSignatures: applySimpleTraceabilitySignaturesDiff(traceability?.visitSignatures, diff, 'VISIT'),
});

const applyReadyForDispatchDiff = (
  signatures: readonly SignatureOrOffline<ReadyForDispatchSignature>[],
  diff: ReadyForDispatchUpdateCommand | undefined,
): readonly SignatureOrOffline<ReadyForDispatchSignature>[] => {
  if (!diff) {
    return signatures;
  }
  return [
    ...signatures.filter(
      (signature) =>
        !isOfflineSignature(signature) && diff.invalidate.every((command) => command.date !== signature.date),
    ),
    ...(diff.sign
      ? [
          createOfflineSignature<ReadyForDispatchSignature>({
            date: diff.sign.date,
            trackNumber: diff.sign.trackNumber,
          }),
        ]
      : []),
  ];
};

const applyMissionEndDiff = (
  signatures: readonly SignatureOrOffline[],
  diff: MissionEndUpdateCommand | undefined,
): readonly SignatureOrOffline[] => {
  if (!diff) {
    return signatures;
  }
  return [
    ...signatures.filter(
      (signature) =>
        !isOfflineSignature(signature) && diff.invalidate.every((command) => command.userId !== signature.userId),
    ),
    ...(diff.sign ? [createOfflineSignature({ date: diff.sign })] : []),
  ];
};

export const applyTrainDiff = (step: TrainStep, diff: TrainStepDiff): TrainStep => {
  const newVehicles = Object.values(diff.updatedVehicles).filter(
    (vehicle) => step.vehicles.every((cv) => cv.id !== vehicle.id) && !diff.deletedVehicles.includes(vehicle.id),
  );
  const vehicles = step.vehicles
    .map((vehicle) => ({ ...vehicle, ...diff.updatedVehicles[vehicle.id] }))
    .filter((vehicle) => !diff.deletedVehicles.includes(vehicle.id));
  vehicles.push(...newVehicles);
  vehicles.sort((v1, v2) => v1.position - v2.position);

  const newBrakingBulletins: BrakingBulletin[] = Object.values(diff.updatedBrakingBulletins)
    .filter((bbDiff) => bbDiff.brakingBulletin && step.brakingBulletins.every((bb) => bb.id !== bbDiff.id))
    .flatMap((bbDiff) => applyNewBrakingBulletinDiff(step.id, bbDiff as NewBrakingBulletinDiff));
  const updatedBrakingBulletins: BrakingBulletin[] = step.brakingBulletins.flatMap((bb) =>
    applyBrakingBulletinDiff(bb, diff.updatedBrakingBulletins[bb.id] ?? {}),
  );
  const brakingBulletins = [...updatedBrakingBulletins, ...newBrakingBulletins];
  brakingBulletins.sort((v1, v2) => v1.revision - v2.revision);

  return {
    ...step,
    ...diff.updatedComposition,
    vehicles,
    brakingBulletins,
    traceability: applyTraceabilityDiff(step.traceability, vehicles, diff.updatedTraceability),
    readyForDispatchSignatures: applyReadyForDispatchDiff(
      step.readyForDispatchSignatures,
      diff.updatedReadyForDispatch,
    ),
    missionEndSignatures: applyMissionEndDiff(step.missionEndSignatures, diff.updatedMissionEnd),
    manuallyUpdated:
      step.manuallyUpdated || Boolean(diff.updatedComposition) || Object.keys(diff.updatedVehicles).length > 0,
    manualBrakingBulletin: step.manualBrakingBulletin || Object.keys(diff.updatedBrakingBulletins).length > 0,
    manualTraceability: step.manualTraceability || Boolean(diff.updatedTraceability),
    manualReadyForDispatch: step.manualReadyForDispatch || Boolean(diff.updatedReadyForDispatch),
  };
};

export const applyTrainDiffs = (composition: TrainStep, diffs: TrainStepDiff[]): TrainStep => {
  let res = composition;
  for (const diff of diffs) {
    res = applyTrainDiff(res, diff);
  }
  return res;
};
