import React from 'react';
import PropTypes from 'prop-types';
import { ICMessageStatuses } from '../sentMessagesUtils';
import { Link } from 'react-router-dom';
import helpers from '../../../commons/helpers/helpers';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import Checkbox from '../../../commons/components/checkbox/Checkbox';
import warningImg from '../../../commons/assets/images/warning.svg';
import { TRAIN_PROBLEM_STATUSES } from '../../../commons/model/Train';
import { VALIDATION_STATUSES } from '../../../commons/model/common';
import TrainUtils from '../../../commons/model/TrainUtils';

const noResendStatuses = [ICMessageStatuses.CREATING, ICMessageStatuses.SENDING, ICMessageStatuses.SUCCESS_IC];
export const shouldDisplayResendCheckbox = (status, resent) => {
  return !resent && !noResendStatuses.includes(status);
};

const getClassNameForStatus = (item) => {
  switch (item.icMessageStatus) {
    case ICMessageStatuses.CREATING:
      return 'creating';
    case ICMessageStatuses.SENDING:
      return 'sending';
    case ICMessageStatuses.SUCCESS:
    case ICMessageStatuses.SUCCESS_IC: {
      if (item.compositionStatus === VALIDATION_STATUSES.VALIDATED) {
        return 'sent';
      }
      return 'sent-incomplete';
    }
    default:
      return 'error';
  }
};

const getClassNames = (item) => {
  const classes = [];
  if (item.compositionStatus === VALIDATION_STATUSES.VALIDATED) {
    classes.push('validated');
  } else if (item.compositionStatus === VALIDATION_STATUSES.NOT_VALIDATED) {
    classes.push('not-validated');
  } else {
    classes.push('composition-empty');
  }

  switch (item.icMessageStatus) {
    case ICMessageStatuses.ERROR_NO_ENGINE:
    case ICMessageStatuses.ERROR_NO_SILLON:
    case ICMessageStatuses.ERROR_PREV_STEP:
    case ICMessageStatuses.ERROR_TRAIN_NB:
    case ICMessageStatuses.ERROR_SEND:
    case ICMessageStatuses.ERROR_CREATION:
      classes.push('message-sending-error');
      break;
    case ICMessageStatuses.CREATING:
      classes.push('message-sending-in-progress');
      break;
    case ICMessageStatuses.SUCCESS:
      classes.push('message-sending-success');
      classes.push('message-response-waiting');
      break;
    case ICMessageStatuses.SUCCESS_IC:
      classes.push('message-sending-success');
      classes.push('message-response-success');
      break;
    case ICMessageStatuses.ERROR_SNCF_RESEAU:
      classes.push('message-sending-success');
      classes.push('message-response-error');
      break;
    default:
      break;
  }
  return classes;
};

const getTooltipContent = (message) => {
  let content;
  switch (message.compositionStatus) {
    case VALIDATION_STATUSES.VALIDATED:
      content = 'Composition validée.';
      break;
    case VALIDATION_STATUSES.NOT_VALIDATED:
      content = 'Composition non validée. ';
      break;
    default:
      content = 'Composition vide. ';
  }

  switch (message.icMessageStatus) {
    case ICMessageStatuses.ERROR_NO_ENGINE:
    case ICMessageStatuses.ERROR_NO_SILLON:
    case ICMessageStatuses.ERROR_PREV_STEP:
    case ICMessageStatuses.ERROR_TRAIN_NB:
    case ICMessageStatuses.ERROR_SEND:
    case ICMessageStatuses.ERROR_CREATION:
      content += "Erreur lors de l'envoi du message.";
      break;
    case ICMessageStatuses.CREATING:
      content += 'Envoi du message en cours.';
      break;
    case ICMessageStatuses.SUCCESS:
      content += 'Message envoyé. En attente de la réponse.';
      break;
    case ICMessageStatuses.SUCCESS_IC:
      content += 'Message envoyé. Message traité en succès par SNCF Réseau.';
      break;
    case ICMessageStatuses.ERROR_SNCF_RESEAU:
      content += 'Message envoyé. Erreur lors du traitement par SNCF Réseau.';
      break;
    default:
      break;
  }

  return content;
};

export const statusMessage = (item) => {
  const sendDate = helpers.dateTimeFormat(item.sendDate);
  switch (item.icMessageStatus) {
    case ICMessageStatuses.ERROR_NO_ENGINE:
      return `${sendDate} - Message non envoyé - Composition sans EM`;
    case ICMessageStatuses.ERROR_NO_SILLON:
      return `${sendDate} - Message non envoyé - Étape sans sillon`;
    case ICMessageStatuses.ERROR_PREV_STEP:
      return `${sendDate} - Message non envoyé - Étape précédente non saisie`;
    case ICMessageStatuses.ERROR_TRAIN_NB:
      return `${sendDate} - Message non envoyé - Numéro de train invalide`;
    case ICMessageStatuses.ERROR_SEND:
      return `${sendDate} - Message non envoyé - Erreur lors de l'envoi à l'IC`;
    case ICMessageStatuses.ERROR_CREATION:
      return `${sendDate} - Message non envoyé - Erreur lors de la création du message`;
    case ICMessageStatuses.CREATING: {
      if (item.compositionStatus === VALIDATION_STATUSES.VALIDATED) {
        return `${sendDate} - Composition validée, message en cours de constitution`;
      }
      return `${sendDate} - Composition non validée, message en cours de constitution`;
    }
    case ICMessageStatuses.SENDING: {
      if (item.compositionStatus === VALIDATION_STATUSES.VALIDATED) {
        return `${sendDate} - Composition validée, message en attente d'envoi`;
      }
      return `${sendDate} - Composition non validée, message en attente d'envoi`;
    }
    case ICMessageStatuses.SUCCESS: {
      if (item.compositionStatus === VALIDATION_STATUSES.VALIDATED) {
        return `${sendDate} - Message envoyé avec succès`;
      }
      return `${sendDate} - Message envoyé mais composition non validée`;
    }

    case ICMessageStatuses.SUCCESS_IC:
      return `${sendDate} - Message traité en succès par SNCF Réseau`;

    case ICMessageStatuses.ERROR_SNCF_RESEAU: {
      if (item.errorCode) {
        return `${sendDate} - SNCF Réseau : ${item.errorCode} - ${item.errorMessage}`;
      }
      return `${sendDate} - SNCF Réseau : Autre`;
    }

    default:
      throw new Error(`Aucun message pour le statut : ${item.icMessageStatus}`);
  }
};

const shouldDisplayDownloadLink = ({ hasS3Key, icMessageStatus }) =>
  hasS3Key && icMessageStatus && icMessageStatus.includes('ERROR');

const downloadLink = ({ id }) => `/api/sentMessages/${id}/response.xml`;

const getSingularOrPluralMessage = (number, singular, plural) =>
  number === 1 ? `1 ${singular}` : `${number} ${plural}`;

const MessagesTableRow = ({ checked, message, onCheckBoxClick, resent }) => {
  return (
    <div className={`table-body-row row-${getClassNameForStatus(message)}`}>
      <div className="train-number-date-market-statut-locations">
        <div className="train-number-date-market-statut">
          <Link className="cell-train-no" to={TrainUtils.linkToTrainStep(message.compositionId)}>
            {message.trainNumber}
          </Link>
          <div className="cell-date">{helpers.dateTimeFormat(message.startDate)}</div>
          <div className="cell-market">{message.marketName}</div>
          <div className={classNames('cell-status', getClassNames(message))}>
            <Tooltip overlay={getTooltipContent(message)} placement="top" trigger={['hover', 'click']}>
              <span>{statusMessage(message)}</span>
            </Tooltip>
            &nbsp;
            {shouldDisplayDownloadLink(message) && (
              <a href={downloadLink(message)} target="_blank" rel="noopener noreferrer">
                (Télécharger)
              </a>
            )}
          </div>
        </div>
        <div className="cell-route">
          <div className="cell-route-from">{message.startLocationLabel}</div>
          <div className="cell-route-to">{message.endLocationLabel}</div>
        </div>
        {message.stepProblemStatus !== 'OK' && (
          <div className="cell-problem">
            <img src={warningImg} alt="" /> {TRAIN_PROBLEM_STATUSES[message.stepProblemStatus]}
          </div>
        )}
      </div>
      <div className="cell-attempts">
        <div className="success">
          {message.numberOfSuccess ? getSingularOrPluralMessage(message.numberOfSuccess, 'succès', 'succès') : ''}
        </div>
        <div className="fail">
          {message.numberOfError ? getSingularOrPluralMessage(message.numberOfError, 'échec', 'échecs') : ''}
        </div>
      </div>
      <div className="cell-action">
        {shouldDisplayResendCheckbox(message.icMessageStatus, resent) && (
          <Checkbox on={checked} onClick={() => onCheckBoxClick(message.compositionId)} />
        )}
        {resent && <div className="message-resent" />}
      </div>
    </div>
  );
};

MessagesTableRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    compositionId: PropTypes.string.isRequired,
    compositionStatus: PropTypes.oneOf(Object.values(VALIDATION_STATUSES)).isRequired,
    endLocationLabel: PropTypes.string.isRequired,
    icMessageStatus: PropTypes.oneOf(Object.keys(ICMessageStatuses)).isRequired,
    hasS3Key: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    marketName: PropTypes.string.isRequired,
    numberOfError: PropTypes.number.isRequired,
    numberOfSuccess: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    startLocationLabel: PropTypes.string.isRequired,
    trainNumber: PropTypes.string,
  }).isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  resent: PropTypes.bool.isRequired,
};

export default MessagesTableRow;
