import { change, getFormValues } from 'redux-form';
import helpers from '../../commons/helpers/helpers';
import { parseToFloat } from '../../commons/redux-form/valueParser';
import { wagonFormName } from '../../commons/components/vehicles/edit/vehicleEditUtils';
import hazmatCache from '../../commons/templates/hazmatCache';
import { createEmptyHazardousMaterial, createNewWagonHazardousMaterial, Wagon } from '../../commons/model/Vehicle';
import { AppDispatch } from '../../commons/store/store';
import { updateWagon } from './vehicleActions';
import { RootState } from '../../commons/reducers/rootReducer';
import { startToast } from '../../commons/components/toast/toastDucks';
import { WagonFormValues } from '../../commons/components/vehicles/edit/wagon/WagonForm';
import VehicleUtils from '../../commons/model/VehicleUtils';

export const addHazmats = (wagon: Wagon) => (dispatch: AppDispatch) => {
  const hazardousMaterials = [...wagon.hazardousMaterials, createNewWagonHazardousMaterial()];
  dispatch(updateWagon(wagon, { hazardousMaterials }));
};

export const updateHazmat =
  (index: number) => (wagon: Wagon, propertyToUpdate: Partial<Wagon>) => async (dispatch: AppDispatch) => {
    let hazardousMaterial = wagon.hazardousMaterials[index];
    const hazmatIdBeforeUpdate = hazardousMaterial.hazardousMaterial?.id;

    const updatedWagon = helpers.with(wagon, propertyToUpdate);

    // If id of embedded hazardousMaterial changed, propagate to hazardousMaterial
    const hazmatIdAfterUpdate = hazardousMaterial.hazardousMaterial?.id;
    if ((hazmatIdBeforeUpdate || null) !== (hazmatIdAfterUpdate || null)) {
      const hazmatTemplate = hazmatIdAfterUpdate ? await hazmatCache.findItemById(hazmatIdAfterUpdate) : null;
      hazardousMaterial = { ...hazardousMaterial, hazardousMaterial: hazmatTemplate ?? createEmptyHazardousMaterial() };

      const fieldName = `hazardousMaterials[${index}].hazardousMaterial`;
      dispatch(change(wagonFormName, fieldName, hazardousMaterial.hazardousMaterial));
      dispatch(updateWagon(updatedWagon, { [fieldName]: hazardousMaterial.hazardousMaterial }));
    } else {
      dispatch(updateWagon(updatedWagon, propertyToUpdate));
    }
  };

const changeFormWeight = (wagon: Wagon, dispatch: AppDispatch, getState: () => RootState) => {
  const formValues = getFormValues(wagonFormName)(getState()) as WagonFormValues;

  const previousCalculatedHazmatsLoadWeight = VehicleUtils.computeWagonHazmatsLoadWeight(wagon);
  const calculatedHazmatsLoadWeight = VehicleUtils.computeWagonHazmatsLoadWeight(formValues);

  dispatch(change(wagonFormName, 'calculatedHazmatsLoadWeight', calculatedHazmatsLoadWeight));

  // Tell the form that wagon load weight changed if not touched or equal to actual total weight
  if (!formValues.loadWeight || parseToFloat(formValues.loadWeight) === previousCalculatedHazmatsLoadWeight) {
    dispatch(change(wagonFormName, 'loadWeight', calculatedHazmatsLoadWeight));
    return { loadWeight: calculatedHazmatsLoadWeight };
  }
  return {};
};

export const autofillLoadWeight =
  (wagon: Wagon, propertyToUpdate: Partial<Wagon>) => (dispatch: AppDispatch, getState: () => RootState) => {
    const weightPropertyToUpdate = changeFormWeight(wagon, dispatch, getState);
    dispatch(updateWagon(wagon, { ...propertyToUpdate, ...weightPropertyToUpdate }));
  };

export const removeHazmat = (wagon: Wagon, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  const weightPropertyToUpdate = changeFormWeight(wagon, dispatch, getState);
  dispatch(
    updateWagon(wagon, {
      hazardousMaterials: wagon.hazardousMaterials.filter((_, i) => i !== index),
      ...weightPropertyToUpdate,
    }),
  );
  dispatch(startToast({ text: 'La matière dangereuse a été supprimée', className: 'success' }));
};
