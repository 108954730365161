export const errorTypes = {
  HAZARDOUS_MATERIAL: 'HAZARDOUS_MATERIAL',
  DAMAGE: 'DAMAGE',
  STATION: 'STATION',
  AMBIGUOUS_DAMAGE: 'AMBIGUOUS_DAMAGE',
};

export const severities = {
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export default class PreAdviceWagonImportErrorEntity {
  constructor(serverError) {
    this.id = serverError.id;
    this.type = serverError.type;
    this.code = serverError.code;
    this.name = serverError.name;
    this.stationType = serverError.stationType;
    this.dangerIdentificationNumber = serverError.dangerIdentificationNumber;
    this.unitedNationsCode = serverError.unitedNationsCode;
    this.packingGroup = serverError.packingGroup;
    this.possibleDamageTemplateIds = serverError.possibleDamageTemplateIds;
  }

  getSeverity() {
    const warningErrorTypes = [errorTypes.DAMAGE, errorTypes.HAZARDOUS_MATERIAL, errorTypes.AMBIGUOUS_DAMAGE];
    if (warningErrorTypes.includes(this.type)) {
      return severities.WARNING;
    }
    return severities.INFO;
  }
}
