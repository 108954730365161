/**
 * Dropdown component
 */
import React, { Component } from 'react';
import reactClickOutside from 'react-click-outside';
import classNames from 'classnames';

import helpers from '../../helpers/helpers';
import './dropdownStyles.scss';
import { ActionCreator } from 'redux';
import { AppAction } from '../../store/store';
import { WrappedFieldProps } from 'redux-form';

export type DropdownProps<T> = {
  className?: string;
  /**
   * Action to dispatch when the field value was updated.
   */
  saveAction?: ActionCreator<AppAction>;
  /**
   * Allow error message display without display the dropdown itself
   */
  displayDropDownElement?: boolean;
  /**
   * Field text "icon"
   */
  iconText?: React.ReactNode;
  options: { [key: string]: T };
  formatOption?: (option: T) => string;
  /**
   * Field label.
   */
  labelText?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  /**
   * The full entity this input is a part of.
   */
  data?: any;
};

const Dropdown = <T extends object>({
  className,
  displayDropDownElement = true,
  iconText,
  options,
  formatOption = (option: T) => option.toString(),
  labelText,
  input,
  meta,
  disabled,
  placeholder = '',
}: DropdownProps<T> & WrappedFieldProps) => {
  const id = helpers.camelCaseToHyphen(input.name);

  let selectedValue = '';
  if (input.value) {
    const selectedOption = options?.[input.value];
    if (selectedOption) {
      selectedValue = formatOption(selectedOption);
    } else {
      // Fallback to the raw value for unknown options
      selectedValue = input.value;
    }
  } else if (!disabled) {
    // Only show the placeholder for empty enabled fields
    selectedValue = placeholder;
  }

  /**
   * Handles click on dropdown element
   */
  const handleClick = (key: string) => {
    input.onBlur(key);
  };

  return (
    <div
      className={classNames('dropdown', {
        'dropdown-label': labelText,
        on: meta.active,
        'non-selection': !input.value,
        'dropdown-error': meta.touched && meta.invalid,
      })}
    >
      {labelText ? <label htmlFor={id}>{labelText}</label> : null}
      {displayDropDownElement && (
        <>
          {iconText && <div className="railway-company-label">{iconText}</div>}
          <button
            id={id}
            type="button"
            className={classNames(
              'dropdown-button',
              {
                icon: iconText,
              },
              className,
            )}
            disabled={disabled}
            onClick={input.onFocus}
          >
            {selectedValue}
          </button>
          {meta.active && (
            <div className="dropdown-container">
              <div className="dropdown-body">
                {options &&
                  Object.keys(options).map((key) => (
                    <div key={key} className="dropdown-option" onClick={() => handleClick(key)}>
                      {formatOption(options[key])}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
      {meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
    </div>
  );
};

Dropdown.defaultProps = {
  // Allow error message display without display the drop down itself
  displayDropDownElement: true,
  placeholder: 'Sélectionner…',
};

class DropdownComponent<T extends object> extends Component<DropdownProps<T> & WrappedFieldProps> {
  componentDidUpdate(prevProps: DropdownProps<T> & WrappedFieldProps, prevState: DropdownProps<T> & WrappedFieldProps) {
    const {
      meta: { active: prevActive },
    } = prevProps;
    const {
      meta: { valid, active, dispatch },
      saveAction,
      data,
      input,
    } = this.props;
    if (valid && !active && prevActive && saveAction) {
      dispatch(saveAction(data, { [input.name]: input.value }));
    }
  }

  handleClickOutside = () => {
    const { input, meta } = this.props;
    if (meta.active) {
      input.onBlur(input.value);
    }
  };

  render = () => <Dropdown {...this.props} />;
}

export default reactClickOutside(DropdownComponent);
