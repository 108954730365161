import { BrakingBulletinSignatureType, isBrakingBulletinSignatureType } from '../../../commons/model/BrakingBulletin';

const DEFAULT_SIGNATURE_TYPE_KEY = 'defaultBrakingBulletinSignatureType';

export const writeDefaultSignatureType = (signatureType: BrakingBulletinSignatureType) => {
  localStorage.setItem(DEFAULT_SIGNATURE_TYPE_KEY, signatureType);
};

export const readDefaultSignatureType = (): BrakingBulletinSignatureType | undefined => {
  const storedValue = localStorage.getItem(DEFAULT_SIGNATURE_TYPE_KEY);
  return isBrakingBulletinSignatureType(storedValue) ? storedValue : undefined;
};
