import React, { ChangeEvent, useMemo, useState } from 'react';
import classNames from 'classnames';
import Autocomplete from '../autocomplete/Autocomplete';
import InputTags from '../input-tags/InputTags';
import { BaseFieldProps, WrappedFieldProps } from 'redux-form';
import marketCache from '../../templates/marketCache';
import { Market } from '../../model/templates';
import { AppAction } from '../../store/store';

type MarketSelectProps<T> = BaseFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    labelText?: React.ReactNode;
    data: T;
    saveAction?: (data: T, propertyToUpdate: any) => AppAction;
  };

const MarketSelect = <T,>({
  id,
  labelText,
  input: { name, value, onFocus, onBlur, onChange },
  meta,
  data,
  saveAction,
  ...inputProps
}: MarketSelectProps<T> & WrappedFieldProps) => {
  const selectedMarkets = value as Market[];
  const [inputValue, setInputValue] = useState('');
  const autocomplete = useMemo(
    () => marketCache.autocompleteWithBlacklist(selectedMarkets.map((market) => market.id)),
    [selectedMarkets],
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onAddMarket = (market: Market) => {
    setInputValue('');

    const newMarkets: Market[] = [];
    if (selectedMarkets) {
      Array.prototype.push.apply(newMarkets, selectedMarkets);
    }
    if (newMarkets.every((m) => m.id !== market.id)) {
      newMarkets.push({ ...market });
    }
    onChange(newMarkets);
    if (saveAction) {
      meta.dispatch(saveAction(data, { [name]: newMarkets }));
    }
  };

  const onRemoveMarket = (market: Market) => {
    const newMarkets = selectedMarkets.filter((m) => m.id !== market.id);
    onChange(newMarkets);
    if (saveAction) {
      meta.dispatch(saveAction(data, { [name]: newMarkets }));
    }
  };

  return (
    <div>
      <div
        className={classNames('input', {
          'input-label': labelText,
          'input-error': meta?.touched && meta?.invalid,
        })}
      >
        {labelText && <label htmlFor={id}>{labelText}</label>}

        <input
          id={id}
          placeholder="Saisir"
          {...inputProps}
          onFocus={onFocus}
          onBlur={() => onBlur(undefined)}
          value={inputValue}
          onChange={onInputChange}
        />

        {autocomplete && (
          <Autocomplete
            value={inputValue}
            autocomplete={autocomplete}
            formatItem={(market: Market) => market.name}
            onClick={onAddMarket}
            active={meta?.active}
          />
        )}

        {meta?.touched && meta?.error ? <div className="error-message">{meta.error}</div> : null}
      </div>
      <InputTags
        tags={selectedMarkets}
        disabled={Boolean(inputProps.disabled)}
        onRemove={onRemoveMarket}
        getClassNames={(market) => ({ inactive: market && !market.active })}
        getLabel={(market) => market.name}
      />
    </div>
  );
};

export default MarketSelect;
