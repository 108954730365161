import React, { useEffect } from 'react';
import ProfilesTable from './profiles-table/ProfilesTable';
import ProfileDetails from './profile-details/ProfileDetails';
import { startGetProfiles } from './profilesDucks';
import { createProfile, loadProfile } from './profileDucks';
import { getProfiles, selectOverlay, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { PROFILE_WRITE } from '../../commons/security/userRoles';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { useViewportWidth } from '../../commons/responsive/hooks';
import classNames from 'classnames';
import { usePageTitle } from '../../commons/components/hooks';
import { Profile } from '../../commons/model/userProfile';

const Profiles = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectSecurityContextRoles);
  const profiles = useAppSelector(getProfiles);
  const overlay = useAppSelector(selectOverlay);
  const { isMobile } = useViewportWidth();

  usePageTitle('Profils & rôles');

  useEffect(() => {
    dispatch(startGetProfiles());
  }, [dispatch]);

  const handleLoadProfiles = (profile: Profile) => dispatch(loadProfile(profile));

  const handleCreateProfile = () => dispatch(createProfile());

  return (
    <div className="content-parent table-page">
      <div className="topbar">
        <h1 className="title">Profils & rôles</h1>
        {profiles.loading && <div className="loader" />}
        {roles.includes(PROFILE_WRITE) && (
          <button
            className={classNames('btn btn-success', isMobile && 'btn-round plus')}
            onClick={handleCreateProfile}
            type="button"
          >
            Ajouter
          </button>
        )}
      </div>
      <ProfilesTable profiles={profiles} onRowClick={handleLoadProfiles} readOnly={!roles.includes(PROFILE_WRITE)} />
      {overlay.active === 'profile' && <ProfileDetails />}
    </div>
  );
};

export default Profiles;
