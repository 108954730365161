import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import DateTimeField from '../../../commons/components/input/DateTimeField';
import { requestUpdatePreAdvice } from '../preAdviceDucks';

const PRE_ADVICE_FORM = 'preAdviceForm';
const formSelector = formValueSelector(PRE_ADVICE_FORM);

const PreAdviceBorderCrossingDateFields = ({ preAdvice, touch }) => {
  const dispatch = useDispatch();
  const initialEstimatedDateTime = useSelector((state) =>
    formSelector(state, 'borderCrossing.initialEstimatedDateTime'),
  );
  const estimatedDateTime = useSelector((state) => formSelector(state, 'borderCrossing.estimatedDateTime'));

  return (
    <>
      <DateTimeField
        className="icon"
        data={preAdvice}
        disabled={false}
        inputReadOnly={true}
        labelText="Prévu initialement"
        name="borderCrossing.initialEstimatedDateTime"
        placeholder="Prévu initialement"
        saveAction={
          estimatedDateTime
            ? requestUpdatePreAdvice
            : (data, propertyToUpdate) => {
                const newValue = propertyToUpdate['borderCrossing.initialEstimatedDateTime'];
                dispatch(change(PRE_ADVICE_FORM, 'borderCrossing.estimatedDateTime', newValue));
                return requestUpdatePreAdvice(data, {
                  ...propertyToUpdate,
                  'borderCrossing.estimatedDateTime': newValue,
                });
              }
        }
        timeFormat="HH:mm"
        touch={touch}
      />
      <DateTimeField
        className="icon"
        data={preAdvice}
        disabled={false}
        inputReadOnly={true}
        labelText="Estimé"
        name="borderCrossing.estimatedDateTime"
        placeholder="Estimé"
        saveAction={
          initialEstimatedDateTime
            ? requestUpdatePreAdvice
            : (data, propertyToUpdate) => {
                const newValue = propertyToUpdate['borderCrossing.estimatedDateTime'];
                dispatch(change(PRE_ADVICE_FORM, 'borderCrossing.initialEstimatedDateTime', newValue));
                return requestUpdatePreAdvice(data, {
                  ...propertyToUpdate,
                  'borderCrossing.initialEstimatedDateTime': newValue,
                });
              }
        }
        timeFormat="HH:mm"
        touch={touch}
      />
    </>
  );
};

export default PreAdviceBorderCrossingDateFields;
