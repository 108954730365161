import AbstractTemplateCache from './AbstractTemplateCache';
import { Market } from '../model/templates';
import { Tx } from './AbstractDbCache';

class MarketCache extends AbstractTemplateCache<'market'> {
  protected getStoreName(): 'market' {
    return 'market';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  protected findTemplates(inputValue: string, tx: Tx<'market'>) {
    const normalizedInputValue = this.normalizeInput(inputValue);
    return this.findItemsByMatcher(tx, (market) => market.name.toUpperCase().includes(normalizedInputValue));
  }

  public findByName(name: string): Promise<Market | undefined> {
    return this.findItemByIndex(name, 'by-name');
  }

  public formatItem(item: Market): string {
    return item.name;
  }
}

export default new MarketCache();
