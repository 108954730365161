/**
 * Trains top bar component
 */
import React from 'react';
import { loadTrainSummaries } from '../trains-table/trainSummariesDucks';
import TrainFilters from '../../commons/components/filters/TrainFilters';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { loadTrains } from '../offline/offlineTrainsDucks';
import { selectTrainSummaries } from '../../commons/selectors/selectors';

const TrainsTopBar = () => {
  const trainSummary = useAppSelector(selectTrainSummaries);
  const dispatch = useAppDispatch();

  const loading = trainSummary.loading && trainSummary.data && trainSummary.data.length > 0;

  return (
    <div className="topbar">
      <h1 className="title">Trains</h1>
      {loading ? (
        <div className="loader" />
      ) : (
        <button
          className="btn btn-round refresh"
          onClick={() => {
            dispatch(loadTrainSummaries());
            dispatch(loadTrains());
          }}
          type="button"
        >
          Rafraîchir
        </button>
      )}
      <TrainFilters />
    </div>
  );
};

export default TrainsTopBar;
