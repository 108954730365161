import { TrainStep } from '../../../commons/model/Train';

import './brakingBulletinStyles.scss';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { addBrakingBulletin, openBrakingBulletinAndDenormalize, pdfBrakingBulletinUrl } from './brakingBulletinActions';
import classNames from 'classnames';
import List from '../../../commons/components/list/List';
import { BRAKING_BULLETIN_SIGNATURE_TYPES, BrakingBulletin } from '../../../commons/model/BrakingBulletin';
import FormSection from '../../../commons/components/form/FormSection';
import { getOfflineState, selectSecurityContext } from '../../../commons/selectors/selectors';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';

type BrakingBulletinListProps = {
  step: TrainStep;
  editable: boolean;
};

const getGlobalClassName = (step: TrainStep): string => {
  if (BrakingBulletinUtils.stepHasSignedBrakingBulletin(step)) {
    return 'signed';
  }
  return step.brakingBulletins.length > 0 ? 'partial' : 'empty';
};

const BrakingBulletinList = ({ step, editable }: BrakingBulletinListProps) => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector(selectSecurityContext);
  const offline = useAppSelector(getOfflineState);

  const onOpen = (brakingBulletin: BrakingBulletin) => () => {
    dispatch(openBrakingBulletinAndDenormalize(brakingBulletin));
  };

  return (
    <FormSection
      className="braking-bulletin-list"
      title="Bulletin de freinage"
      collapsible={true}
      collapsedClassName={getGlobalClassName(step)}
    >
      {!step.manualBrakingBulletin && step.brakingBulletins.length > 0 && (
        <div className="copied-message">Copié de l'étape précédente</div>
      )}
      {step.brakingBulletins.length > 0 && (
        <ul>
          {step.brakingBulletins.map((brakingBulletin) => (
            <li
              key={brakingBulletin.id}
              className={classNames('braking-bulletin-item', brakingBulletin.status.toLowerCase())}
              onClick={onOpen(brakingBulletin)}
            >
              <div className="version">V{brakingBulletin.revision}</div>
              <div className="details">
                {brakingBulletin.status === 'DRAFT'
                  ? 'En cours de saisie'
                  : brakingBulletin.signatures.map((signature, index) => (
                      <List
                        key={index}
                        items={[
                          ...SignatureUtils.basicSignatureFields(signature, username),
                          ...(signature.type
                            ? [{ key: 'En tant que', value: BRAKING_BULLETIN_SIGNATURE_TYPES[signature.type] }]
                            : []),
                        ]}
                      />
                    ))}
              </div>
              <button
                className={classNames(
                  'btn btn-round',
                  brakingBulletin.status === 'DRAFT' && editable ? 'edit' : 'view',
                )}
                type="button"
              >
                {editable ? 'Modifier' : 'Voir'}
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="buttons">
        <div>
          {step.brakingBulletins[step.brakingBulletins.length - 1]?.status !== 'DRAFT' && editable && (
            <button className="btn btn-accent" onClick={() => dispatch(addBrakingBulletin())} type="button">
              Nouvelle version
            </button>
          )}
        </div>
        {!offline && step.brakingBulletins.some((bb) => bb.status !== 'DRAFT') && (
          <a className="btn btn-round pdf" href={pdfBrakingBulletinUrl(step)} target="_blank" rel="noopener noreferrer">
            Télécharger
          </a>
        )}
      </div>
    </FormSection>
  );
};

export default BrakingBulletinList;
