import { Train } from '../../commons/model/Train';
import { createAction, createAsyncThunk, createSlice, Draft } from '@reduxjs/toolkit';
import { RootState } from '../../commons/reducers/rootReducer';
import offlineUtils from '../../commons/offline/offlineUtils';
import apiHelper from '../../api/apiHelper';
import trainStepCache from '../../commons/templates/trainStepCache';
import { selectTrainDiffs } from '../../commons/selectors/selectors';
import { applyTrainDiffs } from '../../commons/model/TrainDiff';
import trainCache from '../../commons/templates/trainCache';
import ApiError from '../../api/ApiError';
import { AppDispatch } from '../../commons/store/store';

export type TrainState = {
  data: Train | null;
  loading: boolean;
  /**
   * Error status code, or 0 for no error
   */
  error: number;
};

const initialState: TrainState = {
  data: null,
  loading: false,
  error: 0,
};

/*
 * Utilities
 */

const loadTrainFromCache = async (trainId: string, state: RootState): Promise<Train | null> => {
  const cachedTrain = await trainCache.findItemById(trainId);
  if (!cachedTrain) {
    return null;
  }

  const steps = await trainStepCache.findItemsByIds(cachedTrain.stepIds);
  return loadAndApplyTrainDiffs({ id: cachedTrain.id, steps }, state);
};

const loadAndApplyTrainDiffs = (cachedTrain: Train, state: RootState) => ({
  id: cachedTrain.id,
  steps: cachedTrain.steps.map((rawCachedStep) => {
    const diffs = selectTrainDiffs(rawCachedStep.id)(state);
    return applyTrainDiffs(rawCachedStep, diffs);
  }),
});

/*
 * Actions
 */

const preloadTrain = createAction<Train>('train/preload');

export const loadTrain = createAsyncThunk<
  Train,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('train/load', async (id, { getState, dispatch, rejectWithValue }) => {
  const cachedTrain = await loadTrainFromCache(id, getState());
  if (cachedTrain) {
    if (!offlineUtils.isOnline()) {
      // If offline, do not try to load the train from the API
      return cachedTrain;
    } else {
      // Preload the cached train while we load from the API
      dispatch(preloadTrain(cachedTrain));
    }
  }

  try {
    const train: Train = await apiHelper.get(`/api/trains/${encodeURIComponent(id)}`);
    await trainCache.saveTrains([train], { clear: false });
    // Apply the latest diff to the remote train
    return loadAndApplyTrainDiffs(train, getState());
  } catch (e) {
    if (!offlineUtils.isOnline() && cachedTrain) {
      // If the connection was lost, use the cached train
      return cachedTrain;
    }
    if (e instanceof ApiError) {
      return rejectWithValue(e);
    }
    throw e;
  }
});

const trainSlice = createSlice({
  name: 'train',
  initialState,
  reducers: {
    unloadTrain: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTrain.pending, (state, { meta: { arg } }) => {
        if (state.data && state.data.id !== arg) {
          // Clear the currently loaded train if it is different from the requested one.
          state.data = null;
        }
        state.loading = true;
        state.error = 0;
      })
      .addCase(loadTrain.fulfilled, (state, { payload: train }) => {
        state.loading = false;
        state.error = 0;
        state.data = train as Draft<Train>;
      })
      .addCase(preloadTrain, (state, { payload: train }) => {
        // Keep the "loading" flag unchanged
        state.data = train as Draft<Train>;
      })
      .addCase(loadTrain.rejected, (state, { payload, error }) => {
        console.error('Error loading the train', payload, error);
        state.loading = false;
        // noinspection SuspiciousTypeOfGuard
        if (payload instanceof ApiError) {
          state.error = payload.httpStatus;
        } else {
          state.error = -1;
        }
      });
  },
});
export const { unloadTrain } = trainSlice.actions;
export default trainSlice.reducer;
