import React, { ChangeEvent, FocusEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Wagon } from '../../model/Vehicle';
import { AppAction } from '../../store/store';

type HazardousMaterialPlateInputProps = {
  data: Wagon;
  saveAction: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const HazardousMaterialPlateInput = ({
  input,
  meta,
  saveAction,
  data,
  inputProps,
  ...otherProps
}: HazardousMaterialPlateInputProps & WrappedFieldProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (meta.valid && saveAction) {
      meta.dispatch(saveAction(data, { [input.name]: event.target.value }));
    }
    // Call native redux form event
    input.onChange(event);
  };
  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (meta.valid && saveAction) {
      meta.dispatch(saveAction(data, { [input.name]: event.target.value }));
    }
    // Call native redux form event
    input.onBlur(event);
  };
  return (
    <input {...input} {...otherProps} {...inputProps} onBlur={onBlur} onChange={onChange} value={input.value ?? ''} />
  );
};

export default HazardousMaterialPlateInput;
