class AbstractDataCache {
  constructor(cache = new Map()) {
    this.cache = cache;
  }

  getKeys() {
    return Array.from(this.cache.keys());
  }

  loadFromCache() {
    return Array.from(this.cache.values());
  }

  loadByKeyFromCache(key) {
    return this.cache.get(key);
  }

  createNewCache(items) {
    const newMap = new Map();

    if (items) {
      items.forEach((item) => {
        newMap.set(this.getId(item), this.createEntity(item));
      });
    }

    return this.createCache(newMap);
  }

  createUpdatedCache(item) {
    const newMap = new Map(this.cache.entries());

    if (item) {
      newMap.set(this.getId(item), this.createEntity(item));
    }

    return this.createCache(newMap);
  }

  createUpdatedCacheWithout(item) {
    const newMap = new Map(this.cache.entries());

    newMap.delete(this.getId(item));

    return this.createCache(newMap);
  }

  restoreFromStorage(cacheFromStorage) {
    if (cacheFromStorage?.cache?.forEach) {
      cacheFromStorage.cache.forEach((data, id) => this.cache.set(id, this.createEntityAndRecover(data)));
    }
  }

  set(id, entity) {
    this.cache.set(id, entity);
  }

  createEntity(data) {
    throw new TypeError(`Do not call abstract method createEntity from child. param : ${data}, cache : ${this.cache}`);
  }

  createEntityAndRecover(data) {
    throw new TypeError(
      `Do not call abstract method createEntityAndRecover from child. param : ${data}, cache : ${this.cache}`,
    );
  }

  getId(data) {
    throw new TypeError(`Do not call abstract method getId from child. param : ${data}, cache : ${this.cache}`);
  }

  createCache(map) {
    throw new TypeError(`Do not call abstract method createCache from child. param : ${map}, cache : ${this.cache}`);
  }
}

export default AbstractDataCache;
