import PropTypes from 'prop-types';
import PreAdviceEntity from '../commons/entity/PreAdviceEntity';
import { SortDirection } from '../commons/model/sort';

export const preAdviceSummaryShape = {
  id: PropTypes.string.isRequired,
  trainNumber: (props, propName, componentName) => {
    if (!/^\d{5,6}$/.test(props[propName])) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}: ${props[propName]} does not match "^\\d{5,6}$"`,
      );
    }
  },
  status: PropTypes.oneOf(Object.keys(PreAdviceEntity.STATUSES)).isRequired,
  senderId: PropTypes.string.isRequired,
  receiverId: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired, // Format: yyyy-MM-dd'T'HH:mm:SSZ
  startStationId: PropTypes.string.isRequired,
  startStationName: PropTypes.string.isRequired,
  endStationId: PropTypes.string.isRequired,
  endStationName: PropTypes.string.isRequired,
  creationDateTime: PropTypes.string.isRequired, // Format: yyyy-MM-dd'T'HH:mm:SSZ
  usedInCompositionStatus: PropTypes.oneOf(['USED', 'NOT_USED']).isRequired,
  importErrorStatus: PropTypes.oneOf([
    PreAdviceEntity.IMPORT_ERROR_STATUSES.WITH_ERRORS,
    PreAdviceEntity.IMPORT_ERROR_STATUSES.WITH_WARNINGS,
    PreAdviceEntity.IMPORT_ERROR_STATUSES.NO_ERROR,
  ]).isRequired,
};

export const sortDefinitionShape = {
  field: PropTypes.oneOf(['trainNumber', 'startDateTime', 'status']).isRequired,
  direction: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC]).isRequired,
};
