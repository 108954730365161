/**
 * Segmented control component
 */
import React from 'react';
import classNames from 'classnames';
import './segmentedControlStyles.scss';
import { Labelled } from '../../model/common';
import { WrappedFieldProps } from 'redux-form';
import { ActionCreator } from 'redux';

export type SegmentedControlProps<T extends keyof any> = React.InputHTMLAttributes<HTMLInputElement> & {
  active: T | null;
  buttons: Record<T, Labelled>;
  data?: any;
  saveAction?: ActionCreator<any>;
  labelText?: React.ReactNode;
};

const SegmentedControl = <T extends keyof any>({
  saveAction,
  data,
  input,
  meta,
  readOnly,
  disabled,
  className,
  labelText,
  active,
  buttons,
}: SegmentedControlProps<T> & WrappedFieldProps) => (
  <div
    className={classNames('segmented-control', className, {
      disabled,
    })}
  >
    {labelText && <div className="segmented-control-label">{labelText}</div>}

    <div className="segmented-control-btn-group">
      {(Object.keys(buttons) as T[]).map((item, i) => (
        <button
          key={`segment-${i}`}
          type="button"
          className={classNames('segmented-control-btn', { on: item === active })}
          onClick={() => {
            if (saveAction && !readOnly) {
              const propertyToUpdate = { [input.name]: item };
              if (propertyToUpdate[input.name] !== input.value) {
                meta.dispatch(saveAction(data, propertyToUpdate));
              }
            }
            input.onBlur(item);
          }}
        >
          {buttons[item].label}
        </button>
      ))}
    </div>

    {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
  </div>
);

export default SegmentedControl;
