import AbstractTemplateCache from './AbstractTemplateCache';
import { DamageTemplate } from '../model/templates';
import { Tx } from './AbstractDbCache';

class DamageCache extends AbstractTemplateCache<'damage'> {
  protected getStoreName(): 'damage' {
    return 'damage';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  protected findTemplates(inputValue: string, tx: Tx<'damage'>) {
    return this.findItemsByIdPrefix(inputValue, tx);
  }

  public formatItem(item: DamageTemplate): string {
    return item.id;
  }
}

export default new DamageCache();
