/**
 * Overlay component
 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import config from '../../config/config';
import { selectOverlay } from '../../selectors/selectors';
import { hideOverlay, OverlayName } from './overlayDucks';
import './overlayStyles.scss';
import { useAppDispatch } from '../../store/hooks';
import { useViewportWidth } from '../../responsive/hooks';

type OverlayProps = {
  name: OverlayName;
  title: ReactNode;
  /**
   * Called when the overlay is shown.
   */
  onShow?: () => any;
  /**
   * Called when the user requests to close the overlay, by clicking outside, or on the close button.
   * If false is returned, the close request is cancelled.
   */
  onRequestClose?: () => Promise<boolean> | undefined;
  /**
   * Called after the overlay has been manually closed.
   */
  onClosed?: () => any;
  className?: string;
  closeButton?: boolean;
  children?: ReactNode;
};

const Overlay = ({ name, title, onShow, onRequestClose, onClosed, className, closeButton, children }: OverlayProps) => {
  const [hiding, setHiding] = useState(false);
  const dispatch = useAppDispatch();
  const { isMobile } = useViewportWidth();
  const { active } = useSelector(selectOverlay);
  const open = active === name;

  const handleClose = useCallback(() => {
    (async () => {
      if (onRequestClose) {
        const requestCloseResult = await onRequestClose();
        if (requestCloseResult === false) {
          return;
        }
      }
      setHiding(true);
      setTimeout(() => {
        setHiding(false);
        dispatch(hideOverlay());
        if (onClosed) {
          onClosed();
        }
      }, config.baseAnimationTime);
    })();
  }, [dispatch, onClosed, onRequestClose]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (open && onShow) {
      onShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div className={classNames('overlay', `overlay-${name}`, { hide: hiding })}>
      <div className="overlay-fade" onClick={handleClose} />
      <div className={classNames('overlay-body', className)}>
        {isMobile && closeButton && (
          <button className="btn btn-round close" onClick={handleClose} type="button">
            Fermer
          </button>
        )}
        <h1 className="overlay-title">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default Overlay;
