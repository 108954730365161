import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'rc-tooltip/assets/bootstrap.css';
import GlobalError from '../../../commons/components/GlobalError';
import MessagesTableRow from './MessagesTableRow';
import './messagesTableStyles.scss';
import Checkbox from '../../../commons/components/checkbox/Checkbox';
import { resendStart } from '../sentMessagesDucks';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import ScrollableTableBody from '../../../commons/components/table/ScrollableTableBody';

const MessagesTable = ({ sentMessages }) => {
  const dispatch = useDispatch();
  const [selectedCompositionIds, setSelectedCompositionIds] = useState([]);
  const [resentCompositionIds, setResentCompositionIds] = useState([]);
  useEffect(() => {
    // Reset selected composition ids when list is reloaded
    setSelectedCompositionIds([]);
    // Reset resent composition ids when list is reloaded
    setResentCompositionIds([]);
  }, [sentMessages.data]);

  if (sentMessages.error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des messages train.
      </GlobalError>
    );
  }

  if (!sentMessages.data || sentMessages.data.length === 0) {
    return <div className="maincontent">{!sentMessages.loading && <span>Aucun message envoyé.</span>}</div>;
  }

  const onRowCheckBoxClick = (compositionId) => {
    if (selectedCompositionIds.includes(compositionId)) {
      setSelectedCompositionIds(selectedCompositionIds.filter((id) => id !== compositionId));
    } else {
      setSelectedCompositionIds([...selectedCompositionIds, compositionId]);
    }
  };

  const onResendButtonClick = () => {
    const doResend = () => {
      setResentCompositionIds([...resentCompositionIds, ...selectedCompositionIds]);
      setSelectedCompositionIds([]);
      return resendStart(selectedCompositionIds);
    };
    if (selectedCompositionIds.length === 1) {
      dispatch(doResend());
    } else {
      dispatch(
        openConfirmDialog({
          title: `Souhaitez-vous renvoyer les ${selectedCompositionIds.length} messages ?`,
          actionText: 'Renvoyer',
          action: doResend,
        }),
      );
    }
  };

  const onGlobalCheckBoxClick = () => {
    if (selectedCompositionIds.length === sentMessages.data.length) {
      setSelectedCompositionIds([]);
    } else {
      setSelectedCompositionIds(sentMessages.data.map((message) => message.compositionId));
    }
  };

  return (
    <div className="table table-sent-messages">
      <div className="table-header">
        <div className="cell-train-no">N° de train</div>
        <div className="cell-date">Date du train</div>
        <div className="cell-market">Marché</div>
        <div className="cell-status">Statuts</div>
        <div className="cell-attempts">Tentatives</div>
        <div className="cell-action">
          <Checkbox on={selectedCompositionIds.length === sentMessages.data.length} onClick={onGlobalCheckBoxClick} />
        </div>
      </div>
      <ScrollableTableBody messages={sentMessages.data}>
        {sentMessages.data.map((item) => () => (
          <MessagesTableRow
            key={item.id}
            checked={selectedCompositionIds.includes(item.compositionId)}
            message={item}
            onCheckBoxClick={onRowCheckBoxClick}
            resent={resentCompositionIds.includes(item.compositionId)}
          />
        ))}
      </ScrollableTableBody>
      {selectedCompositionIds.length > 0 && (
        <button className="resend-multiple-messages-btn btn btn-success" onClick={onResendButtonClick} type="button">
          Renvoyer
        </button>
      )}
    </div>
  );
};
export default MessagesTable;
