import React from 'react';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const ResetFiltersButton = ({ disabled = false, onClick }: Props) => {
  return (
    <button className="btn btn-link btn-icon filter-reset" type="button" disabled={disabled} onClick={onClick}>
      Réinitialiser les filtres
    </button>
  );
};

export default ResetFiltersButton;
