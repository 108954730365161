import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';
import {
  copyCompositionWagonIntoComposition,
  copyPreAdviceWagonIntoComposition,
  shouldTryToValidateWagons,
} from '../../commons/components/copy-wagons/copyWagonsUtils';
import { hideOverlay } from '../../commons/components/overlay/overlayDucks';
import { startToast } from '../../commons/components/toast/toastDucks';
import { afterVehiclesUpdated } from './vehicleActions';
import { errorTypes } from '../../commons/entity/PreAdviceWagonImportErrorEntity';
import { offlineUpdateVehicles, onUpdatedSteps } from '../offline/offlineTrainsDucks';
import PreAdviceWagonEntity from '../../commons/entity/PreAdviceWagonEntity';
import { AppDispatch } from '../../commons/store/store';
import { Wagon } from '../../commons/model/Vehicle';
import { ImportSourceCandidate, ImportSourceType, PRE_ADVICE_TYPE, TRAIN_TYPE } from '../../commons/model/importSource';
import { RootState } from '../../commons/reducers/rootReducer';
import { ConfirmModalFormValues } from '../../commons/components/modal/confirmModalDucks';
import apiHelper from '../../api/apiHelper';
import { TrainStep } from '../../commons/model/Train';
import VehiclesUtils from '../../commons/model/VehiclesUtils';
import { selectSecurityContext, selectTrainStep } from '../../commons/selectors/selectors';
import { COMPOSITION_VALIDATE_WAGONS_ON_COPY } from '../../commons/security/userRoles';

/**
 * Return a boolean indicating whether at least one of the given wagons
 * has unknown hazardous materials.
 */
const haveHavePreAdviceWagonsUnknownHazardousMaterials = (preAdviceWagons: PreAdviceWagonEntity[]): boolean =>
  preAdviceWagons.flatMap((wagon) => wagon.errors).some((error) => error.type === errorTypes.HAZARDOUS_MATERIAL);

const copyWagonsIntoTrain = async (
  sourceId: string,
  sourceType: ImportSourceType,
  targetStep: TrainStep,
  copyWagonsLoad: boolean,
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const securityContext = selectSecurityContext(getState());
  const isWagonToBeLoaded = copyWagonsLoad || securityContext.roles.includes(COMPOSITION_VALIDATE_WAGONS_ON_COPY);
  const vehicles = [...targetStep.vehicles];
  const insertionPositionData = VehiclesUtils.getWagonInsertionPositionData(vehicles);

  let newWagons: Wagon[];
  if (sourceType === TRAIN_TYPE) {
    // Load the source train and then copy its wagons into the targeted train
    const sourceStep: TrainStep = await apiHelper.get(`/api/trains/steps/${sourceId}`);
    newWagons = VehiclesUtils.getWagons(sourceStep.vehicles).map((wagon, i) =>
      copyCompositionWagonIntoComposition({
        inputWagon: wagon,
        stepId: targetStep.id,
        position: insertionPositionData.position + i,
        index: insertionPositionData.index + i,
        isWagonToBeLoaded,
        isTargetTrainCombined: targetStep.combinedMarket,
      }),
    );
    vehicles.splice(insertionPositionData.indexInVehiclesArray, 0, ...newWagons);
  } else {
    // Load the source pre-advice and then copy its wagons into the targeted train
    const tryToValidateWagons = shouldTryToValidateWagons(securityContext, PRE_ADVICE_TYPE, TRAIN_TYPE);
    const preAdviceBody = await apiHelper.get(`/api/pre-advices/${sourceId}`);
    const preAdviceSource = new PreAdviceEntity(preAdviceBody);
    newWagons = await Promise.all(
      preAdviceSource.wagons.map((wagon: PreAdviceWagonEntity, i: number) =>
        copyPreAdviceWagonIntoComposition({
          inputWagon: wagon,
          stepId: targetStep.id,
          position: insertionPositionData.position + i,
          index: insertionPositionData.index + i,
          shouldTryToValidateWagon: tryToValidateWagons,
          isWagonToBeLoaded,
          isTargetTrainCombined: targetStep.combinedMarket,
        }),
      ),
    );
    vehicles.splice(insertionPositionData.indexInVehiclesArray, 0, ...newWagons);
    copyPreAdviceWagonsIntoTrainPostAction(dispatch, preAdviceSource.wagons, newWagons, tryToValidateWagons);
  }

  // Persist server-side the target train with its new wagons and hazardous materials
  const vehiclesToUpdate = VehiclesUtils.computeVehiclesPositions(vehicles).concat(newWagons);
  dispatch(offlineUpdateVehicles(vehiclesToUpdate));
  dispatch(afterVehiclesUpdated(targetStep));

  // Notify import
  const importSourceType = sourceType === PRE_ADVICE_TYPE ? PRE_ADVICE_TYPE : 'COMPOSITION';
  await apiHelper.post(`/api/trains/steps/${targetStep.id}/notify-import`, {
    sourceId,
    sourceType: importSourceType,
  });
};

export const copyPreAdviceWagonsIntoTrainPostAction = (
  dispatch: AppDispatch,
  inputPreAdviceWagons: PreAdviceWagonEntity[],
  outputTrainWagons: Wagon[],
  tryToValidateWagons: boolean,
) => {
  if (!tryToValidateWagons) {
    return;
  }
  if (outputTrainWagons.every((wagon) => wagon.status === 'VALIDATED')) {
    return;
  }

  let text = "Certains wagons n'ont pas été validés à cause de données manquantes";
  if (haveHavePreAdviceWagonsUnknownHazardousMaterials(inputPreAdviceWagons)) {
    text += " ou de matières dangereuses non reconnues. Pour plus d'informations, veuillez consulter la pré-annonce.";
  }
  dispatch(
    startToast({
      autoHide: false,
      text,
      className: 'warning',
    }),
  );
};

const copyFullStep = async (targetId: string, sourceId: string, dispatch: AppDispatch, getState: () => RootState) => {
  const updatedSteps: TrainStep[] = await apiHelper.post(`/api/trains/steps/${targetId}/full-import`, {
    sourceId,
  });
  await onUpdatedSteps(updatedSteps, dispatch, getState);
};

export const copyWagonsIntoComposition =
  ({ id: sourceId, type: sourceType }: ImportSourceCandidate) =>
  (values: ConfirmModalFormValues<{ copyWagonsLoad: 'checkbox'; fullCopy: 'checkbox' }>) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const targetStep = selectTrainStep(getState()).data!;

    try {
      if (values.fullCopy) {
        await copyFullStep(targetStep.id, sourceId, dispatch, getState);
      } else {
        await copyWagonsIntoTrain(sourceId, sourceType, targetStep, Boolean(values.copyWagonsLoad), dispatch, getState);
      }
      dispatch(hideOverlay());
    } catch (e) {
      dispatch(
        startToast({
          text: 'Une erreur technique est survenue lors de la copie des wagons',
          className: 'error',
        }),
      );
      throw e;
    }
  };
