import { brakingBulletinFormName, BrakingBulletinObservationFormData } from '../BrakingBulletinForm';
import { useAppSelector } from '../../../../commons/store/hooks';
import { formValueSelector } from 'redux-form';
import BrakingBulletinUtils from '../../../../commons/model/BrakingBulletinUtils';

export default function useObservations() {
  const observations: BrakingBulletinObservationFormData[] = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'observations'),
  );

  return {
    hasFCV: BrakingBulletinUtils.hasFullFCVObservation(observations),
    hasLL: BrakingBulletinUtils.hasLLObservation(observations),
  };
}
