import useObservations from '../observations/useObservations';
import {
  BrakingBulletinCompositionSummary,
  BrakingBulletinIndiceComposition,
} from '../../../../commons/model/BrakingBulletin';
import { useAppDispatch, useAppSelector } from '../../../../commons/store/hooks';
import BrakingBulletinUtils from '../../../../commons/model/BrakingBulletinUtils';
import { change, formValueSelector } from 'redux-form';
import { brakingBulletinFormName } from '../BrakingBulletinForm';

function useIndiceComposition(compositionSummary: BrakingBulletinCompositionSummary) {
  const dispatch = useAppDispatch();
  const { hasLL, hasFCV } = useObservations();
  const brakingBulletinIndiceComposition = useAppSelector((state) =>
    formValueSelector(brakingBulletinFormName)(state, 'indiceComposition'),
  );

  function updateFixedBrakedWeight(newIndiceComposition?: BrakingBulletinIndiceComposition) {
    const fixedBrakedWeight = BrakingBulletinUtils.computeFixedBrakedWeight(
      compositionSummary,
      hasLL,
      hasFCV,
      newIndiceComposition,
    );
    dispatch(change(brakingBulletinFormName, 'brakedWeightDetails.fixed', fixedBrakedWeight));
  }

  function indiceCompositionOfBrakingBulletin(): string | null {
    if (brakingBulletinIndiceComposition) {
      return brakingBulletinIndiceComposition;
    }
    return compositionSummary.compositionIndex;
  }

  return { updateFixedBrakedWeight, indiceCompositionOfBrakingBulletin };
}

export default useIndiceComposition;
