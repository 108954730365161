import React, { ChangeEvent, useMemo, useState } from 'react';
import classNames from 'classnames';
import Autocomplete from '../autocomplete/Autocomplete';
import InputTags from '../input-tags/InputTags';
import { BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { ActionCreator } from 'redux';
import { Owner } from '../../model/templates';
import ownerCache from '../../templates/ownerCache';

type OwnerSelectProps = BaseFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    labelText?: React.ReactNode;
    data: any;
    saveAction?: ActionCreator<any>;
  };

const OwnerSelect = ({
  id,
  labelText,
  input: { name, value, onFocus, onBlur, onChange },
  meta,
  data,
  saveAction,
  ...inputProps
}: OwnerSelectProps & WrappedFieldProps) => {
  const selectedOwners = value as Owner[];
  const [inputValue, setInputValue] = useState('');
  const autocomplete = useMemo(
    () => ownerCache.autocompleteWithBlacklist(selectedOwners.map((owner) => owner.id)),
    [selectedOwners],
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onAddOwner = (owner: Owner) => {
    setInputValue('');

    const newOwners: Owner[] = [];
    if (selectedOwners) {
      Array.prototype.push.apply(newOwners, selectedOwners);
    }
    if (newOwners.every((m) => m.id !== owner.id)) {
      newOwners.push({ ...owner });
    }
    onChange(newOwners);
    if (saveAction) {
      meta.dispatch(saveAction(data, { [name]: newOwners }));
    }
  };

  const onRemoveOwner = (owner: Owner) => {
    const newOwners = selectedOwners.filter((m) => m.id !== owner.id);
    onChange(newOwners);
    if (saveAction) {
      meta.dispatch(saveAction(data, { [name]: newOwners }));
    }
  };

  return (
    <div>
      <div
        className={classNames('input', {
          'input-label': labelText,
          'input-error': meta && meta.touched && meta.invalid,
        })}
      >
        {labelText && <label htmlFor={id}>{labelText}</label>}

        <input
          id={id}
          placeholder="Saisir"
          {...inputProps}
          onFocus={onFocus}
          onBlur={() => onBlur(undefined)}
          value={inputValue}
          onChange={onInputChange}
        />

        {autocomplete && (
          <Autocomplete
            value={inputValue}
            autocomplete={autocomplete}
            formatItem={(owner: Owner) => owner.name}
            onClick={onAddOwner}
            active={meta && meta.active}
          />
        )}

        {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
      </div>
      <InputTags
        tags={selectedOwners}
        disabled={Boolean(inputProps.disabled)}
        onRemove={onRemoveOwner}
        getClassNames={(owner) => ({ inactive: owner && !owner.active })}
        getLabel={(owner) => owner.name}
      />
    </div>
  );
};

export default OwnerSelect;
