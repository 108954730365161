/**
 * 3-state Switch component
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { touch } from 'redux-form';
import classNames from 'classnames';
import './switch3Styles.scss';

const height = 40;
const width = 96;
const handleDiameter = 34;
const leftPos = Math.max(0, (height - handleDiameter) / 2);
const rightPos = Math.max(width - height, width - (height + handleDiameter) / 2);
const undefPos = (leftPos + rightPos) / 2;

const posFromValue = (value, valueLeft, valueRight) => {
  switch (value) {
    case valueLeft:
      return leftPos;
    case valueRight:
      return rightPos;
    default:
      return undefPos;
  }
};

/**
 * 3-state Switch component.
 *
 * Copied and tweaked from react-switch (https://github.com/yogaboll/react-switch)
 */
class Switch3 extends Component {
  constructor(props) {
    super(props);
    const { input, value, valueLeft, valueRight } = props;
    this.state = {
      pos: posFromValue(value || (input && input.value), valueLeft, valueRight),
      startX: null,
      isDragging: false,
      hasOutline: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ input, value }) {
    const pos = this.posFromValue(value || (input && input.value));
    this.setState({ pos });
  }

  getValue() {
    const { input, value } = this.props;
    return value || (input && input.value);
  }

  posFromValue = (value) => {
    const { valueLeft, valueRight } = this.props;
    return posFromValue(value, valueLeft, valueRight);
  };

  valueFromPos = (pos) => {
    const { valueLeft, valueRight } = this.props;
    if (pos <= (leftPos + undefPos) / 2) {
      return valueLeft;
    } else if (pos >= (rightPos + undefPos) / 2) {
      return valueRight;
    }
    return '';
  };

  handleChange = (value, event) => {
    const { disabled, input, getError, meta, onChange, id, saveAction, data } = this.props;
    if (disabled) {
      return;
    }
    if (event) {
      event.preventDefault();
    }
    if (input && input.onChange) {
      input.onChange(value);
    }
    if (onChange) {
      onChange(value, event, id);
    }

    if (meta?.dispatch && input?.name) {
      meta.dispatch(touch(meta.form, input.name));
      if (saveAction && !getError(value)) {
        meta.dispatch(saveAction(data, { [input.name]: value }));
      }
    }
  };

  handleDragStart = (clientX) => {
    this.setState({ startX: clientX, hasOutline: true });
  };

  handleDrag = (clientX) => {
    const { startX } = this.state;
    const startPos = this.posFromValue(this.getValue());
    const newPos = startPos + clientX - startX;
    const pos = Math.min(rightPos, Math.max(leftPos, newPos));
    this.setState({ pos, isDragging: true });
  };

  handleDragStop = (event) => {
    const { pos, isDragging } = this.state;

    // Simulate clicking the handle: cancel the drag without changing the value
    if (!isDragging) {
      this.handleTouchCancel();
      return;
    }

    const oldValue = this.getValue();
    const newValue = this.valueFromPos(pos);

    if (newValue === oldValue) {
      this.setState({ pos: this.posFromValue(oldValue), startX: null, isDragging: false, hasOutline: false });
      return;
    }

    this.setState({ pos: this.posFromValue(newValue), startX: null, isDragging: false, hasOutline: false });
    this.handleChange(newValue, event);
  };

  handleMouseDown = (event) => {
    // Ignore right click and scroll
    if (typeof event.button === 'number' && event.button !== 0) {
      return;
    }

    this.handleDragStart(event.clientX);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (event) => {
    event.preventDefault();
    this.handleDrag(event.clientX);
  };

  handleMouseUp = (event) => {
    this.handleDragStop(event);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  handleTouchStart = (event) => {
    this.handleDragStart(event.touches[0].clientX);
  };

  handleTouchMove = (event) => {
    this.handleDrag(event.touches[0].clientX);
  };

  handleTouchEnd = (event) => {
    event.preventDefault();
    this.handleDragStop(event);
  };

  handleTouchCancel = () => {
    this.setState({ startX: null, hasOutline: false });
  };

  handleClickLeft = (event) => {
    this.handleChange(this.props.valueLeft, event);
  };

  handleClickMiddle = (event) => {
    this.handleChange('', event);
  };

  handleClickRight = (event) => {
    this.handleChange(this.props.valueRight, event);
  };

  render() {
    const { disabled, id, labelText, labelLeft, labelRight, className, meta } = this.props;

    const { pos, hasOutline } = this.state;
    const value = this.getValue();

    return (
      <div
        className={classNames('input', {
          'input-label': labelText,
          'input-error': meta && meta.touched && meta.invalid,
          'input-autofill': meta && meta.autofilled,
        })}
      >
        {labelText && <label>{labelText}</label>}

        <div
          className={classNames('switch3', {
            on: !!value,
            disabled,
            [className]: className,
          })}
        >
          {labelLeft && (
            <label className="switch3-label" htmlFor={`switch3-${id}-left`}>
              {labelLeft}
            </label>
          )}
          <div className="switch3-root">
            <div className="switch3-bg">
              <button
                id={`switch3-${id}-left`}
                className="switch3-button"
                onClick={this.handleClickLeft}
                disabled={disabled}
                type="button"
              />
              <button
                id={`switch3-${id}-undef`}
                className="switch3-button"
                onClick={this.handleClickMiddle}
                disabled={disabled}
                type="button"
              />
              <button
                id={`switch3-${id}-right`}
                className="switch3-button"
                onClick={this.handleClickRight}
                disabled={disabled}
                type="button"
              />
            </div>
            <div
              className={classNames('switch3-handle', hasOutline && 'outline')}
              tabIndex={disabled ? null : 0}
              onMouseDown={disabled ? null : this.handleMouseDown}
              onTouchStart={disabled ? null : this.handleTouchStart}
              onTouchMove={disabled ? null : this.handleTouchMove}
              onTouchEnd={disabled ? null : this.handleTouchEnd}
              onTouchCancel={disabled ? null : this.handleTouchCancel}
              onFocus={() => this.setState({ hasOutline: true })}
              onBlur={() => this.setState({ hasOutline: false })}
              style={{ transform: `translateX(${pos}px)` }}
              aria-disabled={disabled}
            />
          </div>
          {labelRight && (
            <label className="switch3-label" htmlFor={`switch3-${id}-right`}>
              {labelRight}
            </label>
          )}
        </div>

        {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
      </div>
    );
  }
}

Switch3.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  getError: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  valueLeft: PropTypes.string,
  valueRight: PropTypes.string,
  onChange: PropTypes.func,
};

Switch3.defaultProps = {
  disable: false,
  showLock: false,
  labelLeft: 'C',
  labelRight: 'D',
  valueLeft: 'C',
  valueRight: 'D',
};

export default Switch3;
