/**
 * Button with checked/unchecked status.
 */
import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import classNames from 'classnames';
import { WrappedFieldProps } from 'redux-form';
import { AppAction } from '../../store/store';

type CheckButtonProps<T> = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  data: T;
  saveAction?: (data: T, propertyToUpdate: any) => AppAction;
  onToggle?: (newValue: boolean) => any;
};

const CheckButton = <T,>({
  id,
  value,
  className,
  label,
  data,
  input,
  meta: { dispatch },
  onToggle,
  saveAction,
  ...childProps
}: CheckButtonProps<T> & WrappedFieldProps) => {
  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    const oldValue = value || (input && input.value);
    const newValue = !oldValue;
    if (input && input.onChange) {
      input.onChange(newValue);
    }
    if (onToggle) {
      onToggle(newValue);
    }
    if (saveAction && input && input.name) {
      dispatch(saveAction(data, { [input.name]: newValue }));
    }
  };

  return (
    <button
      id={id}
      className={classNames(className, { on: value || (input && input.value) })}
      type="button"
      onClick={onClick}
      {...childProps}
    >
      {label}
    </button>
  );
};

export default CheckButton;
