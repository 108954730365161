import { createSlice } from '@reduxjs/toolkit';

export type SidebarState = Readonly<{
  /**
   * The menu sidebar is opened.
   */
  expanded: boolean;
  /**
   * The sidebar is hidden (i.e. fullscreen content).
   */
  hidden: boolean;
}>;

const initialState: SidebarState = {
  expanded: false,
  hidden: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.expanded = !state.expanded;
    },
    collapseSidebar: (state) => {
      state.expanded = false;
    },
    expandSidebar: (state) => {
      state.expanded = true;
    },
    showSidebar: (state) => {
      state.hidden = false;
    },
    hideSidebar: (state) => {
      state.hidden = true;
    },
  },
});
export const { toggleSidebar, collapseSidebar, expandSidebar, showSidebar, hideSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
