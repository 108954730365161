import moment from 'moment';

import offlineUtils from '../../commons/offline/offlineUtils';
import { startToast } from '../../commons/components/toast/toastDucks';
import { getSentMessages } from '../../commons/selectors/selectors';
import { statusOptions } from './sentMessagesUtils';
import apiHelper from '../../api/apiHelper';

// ACTIONS
const START_GET_SENT_MESSAGES = 'START_GET_SENT_MESSAGES';
const SUCCESS_GET_SENT_MESSAGES = 'SUCCESS_GET_SENT_MESSAGES';
const FAILURE_GET_SENT_MESSAGES = 'FAILURE_GET_SENT_MESSAGES';

const UPDATE_FILTERS = 'MESSAGES/UPDATE_FILTERS';

const START_RESENT_MESSAGES = 'START_RESENT_MESSAGES';

// HELPERS
/**
 * Transforms the filters stored in the Redux state into an object
 * representing HTTP params.
 *
 * @param filters The filters to apply.
 */
const filtersToParams = (filters) => {
  const params = {};
  if (filters.trainNumber) {
    params.trainNumber = filters.trainNumber;
  }
  if (filters.markets.length > 0) {
    params.marketIds = filters.markets.map((market) => market.id);
  }
  params.lastStatuses = filters.lastStatuses;
  params.toStartDate = moment(filters.toStartDate || undefined)
    .startOf('day')
    .add(1, 'day')
    .local()
    .format();
  params.fromStartDate = filters.fromStartDate;
  if (filters.icError) {
    params.icError = filters.icError;
  }
  return params;
};

const updateReduxFilter = (filters, field, value) => {
  const newFilters = {
    ...filters,
  };
  newFilters[field] = value;
  return newFilters;
};

// REDUCER
const initialState = {
  data: [],
  loading: false,
  error: false,
  filters: {
    trainNumber: null,
    lastStatuses: statusOptions.map((statusOption) => statusOption.id),
    markets: [],
    fromStartDate: moment().startOf('day').subtract(2, 'day').local().format(),
    toStartDate: null,
    icError: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_GET_SENT_MESSAGES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SUCCESS_GET_SENT_MESSAGES:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILURE_GET_SENT_MESSAGES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: updateReduxFilter(state.filters || initialState.filters, action.payload.field, action.payload.value),
      };
    default:
      return state;
  }
};

// ACTIONS
export const failureGetSentMessages = () => ({
  type: FAILURE_GET_SENT_MESSAGES,
});

export const successGetSentMessages = (sentMessages) => ({
  type: SUCCESS_GET_SENT_MESSAGES,
  payload: sentMessages,
});

export const startGetSentMessages = () => async (dispatch, getState) => {
  dispatch({ type: START_GET_SENT_MESSAGES });
  const { filters } = getSentMessages(getState());
  if (offlineUtils.isOnline()) {
    try {
      const sentMessages = await apiHelper.get('/api/sentMessages', filtersToParams(filters));
      dispatch(successGetSentMessages(sentMessages));
    } catch {
      dispatch(failureGetSentMessages());
    }
  }
  return null;
};

export const resendStart = (compositionIds) => async (dispatch) => {
  dispatch({ type: START_RESENT_MESSAGES, payload: compositionIds });

  try {
    await apiHelper.post('/api/sentMessages', compositionIds);
    dispatch(
      startToast({
        text: "Demande prise en compte : une nouvelle tentative d'envoi des messages va être effectuée.",
        className: 'success',
      }),
    );
  } catch {
    dispatch(
      startToast({ text: "Erreur : la tentative d'envoi des messages n'a pas pu être effectuée.", className: 'error' }),
    );
  }
};

/**
 * Action creator to update filters.
 *
 * @param field The filter field.
 * @param value The filter value.
 */
export const updateFilters = (field, value) => ({
  type: UPDATE_FILTERS,
  payload: {
    field,
    value,
  },
});
