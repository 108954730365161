import React from 'react';
import { Field } from 'redux-form';

import './prefixedFieldStyles.scss';

const PrefixedField = ({ prefix, ...childrenProps }) => (
  <div className="prefixed-field">
    <div className="prefixed-field-prefix">{prefix}</div>
    <Field {...childrenProps} />
  </div>
);

export default PrefixedField;
