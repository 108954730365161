import React, { ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { startGetSentMessages, updateFilters } from '../sentMessagesDucks';
import Input from '../../../commons/components/input/Input';
import { getSentMessages } from '../../../commons/selectors/selectors';
import './sentMessagesFilters.scss';
import { RootState } from '../../../commons/reducers/rootReducer';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import FiltersPopup from '../../../commons/components/filters/FiltersPopup';
import DateIntervalFilters from '../../../commons/components/filters/DateIntervalFilters';
import MarketSelect from '../../../commons/components/market-select/MarketSelect';
import PrefixedField from '../../../commons/components/input/PrefixedField';
import { statusOptions } from '../sentMessagesUtils';
import Checkbox from '../../../commons/components/checkbox/Checkbox';
import moment from 'moment/moment';

/**
 * Component representing all the filters about messages.
 */
const SentMessagesFilters = () => {
  const dispatch = useAppDispatch();
  const filtersValues = useAppSelector(getFormValues('messagesFiltersForm')) as any;
  const { isMobile } = useViewportWidth();
  const selectedStatuses = filtersValues?.lastStatuses ?? [];

  const updateMessagesFilters = (field: string, value: any) => dispatch(updateFilters(field, value));

  const loadMessages = () => dispatch(startGetSentMessages());

  /**
   * When the "train number" filter changes, the Redux filters are updated
   * and messages are reloaded.
   */
  const trainNumberChangedHandler = (e?: React.ChangeEvent, value?: any) => {
    if (!value || value.length >= 5) {
      updateMessagesFilters('trainNumber', value);
      loadMessages();
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    // Do nothing
    // Message search is executed as soon as a filter value changes
    // There is a "reload" button if reload is required
    e.preventDefault();
  };

  const handleChangeDates = (e: ChangeEvent, propertiesToUpdate: any) => () => {
    if (propertiesToUpdate.fromStartDate) {
      updateMessagesFilters('fromStartDate', moment(propertiesToUpdate.fromStartDate).local().format());
    }
    if (propertiesToUpdate.toStartDate) {
      updateMessagesFilters('toStartDate', moment(propertiesToUpdate.toStartDate).local().format());
    }
    loadMessages();
  };

  const handleChangeIcErrorContent = (e: ChangeEvent, value: any) => {
    updateMessagesFilters('icError', value);
    if (!value || value.length >= 3) {
      loadMessages();
    }
  };

  /**
   * When the markets change, the filters are updated
   * and then messages are reloaded.
   */
  const marketsChangeHandler = (e?: ChangeEvent, value?: any) => {
    updateMessagesFilters('markets', value);
    loadMessages();
  };

  const statusCheckboxClickHandler = (status: any) => () => {
    if (selectedStatuses.includes(status)) {
      updateMessagesFilters(
        'lastStatuses',
        selectedStatuses.filter((st: any) => st !== status),
      );
    } else {
      updateMessagesFilters('lastStatuses', selectedStatuses.concat(status));
    }
    loadMessages();
  };

  const selectAllStatuses = () => {
    updateMessagesFilters(
      'lastStatuses',
      statusOptions.map((statusOption) => statusOption.id),
    );
    loadMessages();
  };

  const unselectAllStatuses = () => {
    updateMessagesFilters('lastStatuses', []);
    loadMessages();
  };

  return (
    <div className="filters sent-messages-filters">
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <Field
          component={Input}
          maxLength={6}
          name="trainNumber"
          placeholder={isMobile ? 'Numéro' : 'Filtrer par n°'}
          onChange={trainNumberChangedHandler}
          pattern="[0-9]*" // iPad numeric keyboard
          inputMode="numeric"
        />
        <FiltersPopup>
          <div className="filters-dropdown">
            <DateIntervalFilters
              changeFormValue={updateMessagesFilters}
              currentFromDateValue={filtersValues?.fromStartDate}
              currentToDateValue={filtersValues?.toStartDate}
              fromDateFieldName="fromStartDate"
              offline={false}
              reloadListAction={handleChangeDates}
              toDateFieldName="toStartDate"
            />
            <Field
              component={MarketSelect}
              id="user-markets"
              name="markets"
              className="icon star"
              placeholder="Marchés"
              onChange={marketsChangeHandler}
            />
            <PrefixedField
              component={Input}
              id="ic-error-filter"
              name="icError"
              placeholder="Code ou message"
              onChange={handleChangeIcErrorContent}
              prefix="IC"
            />
            <div className="checkboxes">
              <div className="label">
                Statut
                <span className="statuses-shortcuts">
                  (<span onClick={selectAllStatuses}>Sélectionner tous</span>/
                  <span onClick={unselectAllStatuses}>Dé-sélectionner tous</span>)
                </span>
              </div>
              {statusOptions.map((statusOption) => (
                <Checkbox
                  key={statusOption.id}
                  on={selectedStatuses.includes(statusOption.id)}
                  label={statusOption.label}
                  onClick={statusCheckboxClickHandler(statusOption.id)}
                />
              ))}
            </div>
          </div>
        </FiltersPopup>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: getSentMessages(state).filters,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'messagesFiltersForm',
    enableReinitialize: true,
  })(SentMessagesFilters as any),
);
