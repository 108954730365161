/**
 * Disabled Input component, without any data binding.
 */
import React from 'react';
import classNames from 'classnames';
import './inputStyles.scss';

export type DisabledInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'disabled'> & {
  unit?: string;
  labelText?: React.ReactNode;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'disabled'>;
};

const DisabledInput = ({ id, unit, labelText, inputProps, ...otherProps }: DisabledInputProps) => {
  return (
    <div
      className={classNames('input', {
        'input-label': labelText,
        'input-unit': unit,
      })}
    >
      {labelText && <label htmlFor={id}>{labelText}</label>}

      <input id={id} {...otherProps} {...inputProps} disabled={true} />

      {unit ? <div className="unit">{unit}</div> : null}
    </div>
  );
};

export default DisabledInput;
