import React from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';
import { appContainer } from './container/containerUtils';
import { AppStore } from '../store/store';

const containerInjector = (store: AppStore) => {
  ReactDOM.render(<Root store={store} />, appContainer);
};

export default containerInjector;
