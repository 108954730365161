import FormSection from '../../../commons/components/form/FormSection';
import { TrainStep } from '../../../commons/model/Train';
import List from '../../../commons/components/list/List';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import { isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import { invalidateMissionEnd, signMissionEnd } from './MissionEndActions';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';
import './missionEndStyles.scss';

type MissionEndSummaryProps = {
  step: TrainStep;
  editable: boolean;
};

const MissionEndSummary = ({ step, editable }: MissionEndSummaryProps) => {
  const dispatch = useAppDispatch();
  const { username, email } = useAppSelector(selectSecurityContext);
  const signed = Boolean(step.missionEndSignatures?.length);

  const handleSign = () => {
    dispatch(
      openConfirmDialog({
        title: `Souhaitez-vous valider en tant que ${username} que la mission de conduite est terminée ?`,
        actionText: 'Valider',
        action: signMissionEnd,
      }),
    );
  };

  const handleInvalidate = (signature: SignatureOrOffline) => () => {
    dispatch(
      openConfirmDialog({
        title: `Souhaitez-vous supprimer cette signature ?`,
        actionText: 'Supprimer',
        action: invalidateMissionEnd(isOfflineSignature(signature) ? null : signature.userId),
        actionClass: 'danger',
      }),
    );
  };
  return (
    <FormSection
      title="Fin de mission de conduite"
      collapsible={true}
      className={classNames('mission-end-summary', { signed })}
    >
      <div className="mission-end-summary-items">
        {step.missionEndSignatures?.map((signature, index) => (
          <div className="mission-end-summary-item" key={index}>
            <List items={SignatureUtils.basicSignatureFields(signature, username, true)} />
            {editable && (
              <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
            )}
          </div>
        ))}
      </div>
      {editable &&
        step.missionEndSignatures?.every(
          (signature) => !isOfflineSignature(signature) && signature.userEmail !== email,
        ) &&
        (BrakingBulletinUtils.stepHasSignedBrakingBulletin(step) ? (
          <button className="btn btn-accent" onClick={handleSign} type="button">
            Saisir
          </button>
        ) : (
          <div className="info-message">
            Veuillez valider le bulletin de freinage avant de saisir la fin de mission de conduite
          </div>
        ))}
    </FormSection>
  );
};

export default MissionEndSummary;
