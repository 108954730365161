export const foreignCountries = {
  GERMANY: 'Allemagne',
  BELGIUM: 'Belgique',
  SPAIN: 'Espagne',
  ITALY: 'Italie',
  LUXEMBOURG: 'Luxembourg',
  SWITZERLAND: 'Suisse',
  NETHERLANDS: 'Pays-Bas',
  AUSTRIA: 'Autriche',
  BULGARIA: 'Bulgarie',
  CROATIA: 'Croatie',
  DENMARK: 'Danemark',
  FINLAND: 'Finlande',
  GREECE: 'Grèce',
  HUNGARY: 'Hongrie',
  LATVIA: 'Lettonie',
  LITHUANIA: 'Lituanie',
  NORWAY: 'Norvège',
  POLAND: 'Pologne',
  PORTUGAL: 'Portugal',
  CZECH_REPUBLIC: 'Rep. Tchèque',
  ROMANIA: 'Roumanie',
  UNITED_KINGDOM: 'Royaume-Uni',
  SERBIA: 'Serbie',
  SLOVAKIA: 'Slovaquie',
  SLOVENIA: 'Slovénie',
  SWEDEN: 'Suède',
};

export const getCountryName = (country) => {
  if (!country) {
    return null;
  }
  if (country === 'FRANCE') {
    return 'France';
  }
  return foreignCountries[country] ?? country;
};
