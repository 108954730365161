import moment from 'moment';
import { initialize } from 'redux-form';
import getSortFunction from './sortPreAdvices';
import helpers from '../commons/helpers/helpers';
import apiHelper from '../api/apiHelper';
import { SortDirection } from '../commons/model/sort';
/*
 * ACTIONS
 */
const START_GET_PRE_ADVICE_SUMMARIES = 'START_GET_PRE_ADVICE_SUMMARIES';
const SUCCESS_GET_PRE_ADVICE_SUMMARIES = 'SUCCESS_GET_PRE_ADVICE_SUMMARIES';
const ERROR_GET_PRE_ADVICE_SUMMARIES = 'ERROR_GET_PRE_ADVICE_SUMMARIES';
const UPDATE_SORT_PRE_ADVICES = 'UPDATE_SORT_PRE_ADVICES';
const UPDATE_PRE_ADVICE_SUMMARIES_FILTERS = 'UPDATE_PRE_ADVICE_SUMMARIES_FILTERS';

/*
 * ACTION CREATORS
 */
const startGetPreAdviceSummaries = () => ({
  type: START_GET_PRE_ADVICE_SUMMARIES,
});

const successGetPreAdviceSummaries = (preAdviceSummaries) => ({
  type: SUCCESS_GET_PRE_ADVICE_SUMMARIES,
  payload: { preAdviceSummaries },
});

const errorGetPreAdviceSummaries = () => ({
  type: ERROR_GET_PRE_ADVICE_SUMMARIES,
});

export const requestGetPreAdviceSummaries = () => async (dispatch, getState) => {
  dispatch(startGetPreAdviceSummaries());
  const filters = getState().preAdviceSummaries.filters;
  try {
    const preAdvicesSummaries = await apiHelper.get('/api/pre-advices/summaries', filtersToQueryParams(filters));
    dispatch(successGetPreAdviceSummaries(preAdvicesSummaries));
  } catch {
    dispatch(errorGetPreAdviceSummaries());
  }
};

export const requestUpdateSortDefinition = (sortDefinition) => ({
  type: UPDATE_SORT_PRE_ADVICES,
  payload: { sortDefinition },
});

export const requestUpdateFilters = (data, propertyToUpdate) => (dispatch) => {
  dispatch({
    type: UPDATE_PRE_ADVICE_SUMMARIES_FILTERS,
    payload: { propertyToUpdate },
  });
  dispatch(requestGetPreAdviceSummaries());
};

export const requestResetFilters = () => (dispatch) => {
  dispatch(initialize('preAdviceSummariesFiltersForm', defaultFilters));
  dispatch(requestUpdateFilters(null, defaultFilters));
};

/*
 * REDUCER
 */
const defaultFilters = {
  fromStartDateTime: moment().add(-3, 'days').startOf('day').local(),
  toStartDateTime: moment().add(3, 'days').startOf('day').local(),
  emptyStatus: true,
  notValidatedStatus: true,
  validatedStatus: true,
  sender: null,
  receiver: null,
  startStation: null,
  endStation: null,
};
const initialState = {
  preAdviceSummaries: [],
  loading: false,
  error: false,
  sortDefinition: {
    field: 'startDateTime',
    direction: SortDirection.ASC,
  },
  filters: defaultFilters,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_GET_PRE_ADVICE_SUMMARIES:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ERROR_GET_PRE_ADVICE_SUMMARIES:
      return {
        ...initialState,
        filters: state.filters,
        sortDefinition: state.sortDefinition,
        error: true,
      };
    case SUCCESS_GET_PRE_ADVICE_SUMMARIES: {
      action.payload.preAdviceSummaries.sort(getSortFunction(state.sortDefinition));
      return {
        ...state,
        loading: false,
        error: false,
        preAdviceSummaries: action.payload.preAdviceSummaries,
      };
    }
    case UPDATE_SORT_PRE_ADVICES: {
      const summaries = [...state.preAdviceSummaries];
      summaries.sort(getSortFunction(action.payload.sortDefinition));
      return {
        ...state,
        preAdviceSummaries: summaries,
        sortDefinition: action.payload.sortDefinition,
      };
    }
    case UPDATE_PRE_ADVICE_SUMMARIES_FILTERS: {
      const newFilters = { ...state.filters };
      helpers.setIn(newFilters, action.payload.propertyToUpdate);
      return {
        ...state,
        filters: newFilters,
      };
    }
    default:
      return state;
  }
};

/*
 * Utilities
 */
const filtersToQueryParams = (filters) => {
  const params = {
    fromStartDateTime: moment(filters.fromStartDateTime).startOf('day').local().format(),
    toStartDateTime: moment(filters.toStartDateTime).startOf('day').add(1, 'day').local().format(),
    emptyStatus: filters.emptyStatus,
    notValidatedStatus: filters.notValidatedStatus,
    validatedStatus: filters.validatedStatus,
  };
  if (filters.trainNumber) {
    params.trainNumber = filters.trainNumber;
  }
  /*
   * For auto-completed fields, the label must be checked because, when the user
   * removes the input text, the label is reset but the id is not.
   */
  if (filters.startStation?.id && filters.startStation.label) {
    params.startStationId = filters.startStation.id;
  }
  if (filters.endStation?.id && filters.endStation.label) {
    params.endStationId = filters.endStation.id;
  }
  if (filters.sender?.id) {
    params.senderId = filters.sender.id;
  }
  if (filters.receiver?.id) {
    params.receiverId = filters.receiver.id;
  }
  return params;
};
