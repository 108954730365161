/**
 * Wagon details component
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, FormErrors, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { getVehiclesLibrary, selectSecurityContextRoles } from '../../../commons/selectors/selectors';
import Overlay from '../../../commons/components/overlay/Overlay';
import Input from '../../../commons/components/input/Input';
import { cancelEditWagon, createLibraryWagon, deleteLibraryWagon, updateLibraryWagon } from '../vehiclesLibraryDuck';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { addDamageReport } from '../../../damage-reports/damage-report/damageReportDucks';
import { parseToInt } from '../../../commons/redux-form/valueParser';
import {
  normalizePositiveFloatTwoDecimal,
  normalizePositiveNumber,
  normalizeRegistration,
} from '../../../commons/redux-form/valueNormalizer';
import Mask from '../../../commons/mask/Mask';
import { formatDecimalAndSeparateThousands, formatDecimalNumber } from '../../../commons/redux-form/valueFormatter';
import { DAMAGE_REPORT_WRITE } from '../../../commons/security/userRoles';
import { initialFormValuesForNewWagonTemplates, validate } from './wagonTemplateUtils';

import './WagonDetailsStyles.scss';
import wagonCache from '../../../commons/templates/wagonCache';
import ownerCache from '../../../commons/templates/ownerCache';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { RootState } from '../../../commons/reducers/rootReducer';
import { WagonTemplate } from '../../../commons/model/templates';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import { CompanyOptions } from '../../../commons/model/common';

type WagonTemplateFormData = WagonTemplate;

type WagonTemplateDetailsProps = InjectedFormProps<WagonTemplateFormData>;

const WagonTemplateDetails = ({ handleSubmit }: WagonTemplateDetailsProps) => {
  const vehiclesLibrary = useAppSelector(getVehiclesLibrary);
  const roles = useAppSelector(selectSecurityContextRoles);
  const dispatch = useAppDispatch();

  const [ownersOptions, setOwnersOptions] = useState({});

  const persistWagonTemplate = async (updatedWagon: WagonTemplate) => {
    const action = vehiclesLibrary.editedWagon ? updateLibraryWagon(updatedWagon) : createLibraryWagon(updatedWagon);
    const result = await dispatch(action);
    if (result.meta.requestStatus === 'rejected') {
      throw new SubmissionError(result.payload as FormErrors);
    }
  };

  useEffect(() => {
    (async () => {
      const owners = await ownerCache.findAllItems();
      setOwnersOptions(
        owners.reduce(
          (acc, owner) => ({
            ...acc,
            [owner.id]: owner.name,
          }),
          {},
        ),
      );
    })();
  }, []);

  return (
    <Overlay
      name="vehicle-library"
      title={vehiclesLibrary.editedWagon ? 'Modifier wagon' : 'Ajouter un wagon'}
      onClosed={() => dispatch(cancelEditWagon())}
      closeButton
    >
      <form noValidate={true} className="overlay-form" onSubmit={handleSubmit(persistWagonTemplate)}>
        <Field
          disabled={vehiclesLibrary.editedWagon}
          component={Input}
          name="registration"
          labelText="Immatriculation *"
          placeholder="Ex: 12 34 567 8901-2"
          format={(value: string) => value && Mask.REGISTRATION.format(value)}
          inputMode="numeric"
          pattern="[0-9]*"
          normalize={normalizeRegistration}
        />
        <div className="inputs-group">
          <Field
            component={Input}
            name="length"
            labelText="Longueur *"
            unit="m"
            format={formatDecimalNumber}
            inputMode="numeric"
            pattern="[0-9]*"
            normalize={normalizePositiveFloatTwoDecimal}
          />
          <Field
            component={Input}
            name="tare"
            labelText="Tare *"
            unit="kg"
            format={formatDecimalAndSeparateThousands}
            inputMode="numeric"
            pattern="[0-9]*"
            normalize={normalizePositiveNumber}
            parse={parseToInt}
          />
        </div>
        <Field
          component={Input}
          name="nbAxles"
          labelText="Nombre d’essieux *"
          inputMode="numeric"
          pattern="[0-9]*"
          normalize={normalizePositiveNumber}
          parse={parseToInt}
        />
        <Field component={Dropdown} labelText="Détenteur *" name="ownerId" options={ownersOptions} readOnly={false} />
        <Field component={CheckboxFormComponent} name="doubleWagon" label="Wagon double" />
        <Field component={Dropdown} labelText="Gestionnaire" name="manager" options={CompanyOptions} />
        <div className="btn-group wagon-template-form-actions">
          <button className="btn btn-success" type="submit">
            {vehiclesLibrary.editedWagon ? 'Modifier' : 'Ajouter'}
          </button>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => {
              dispatch(hideOverlay());
              dispatch(cancelEditWagon());
            }}
          >
            Annuler
          </button>
          {roles.includes(DAMAGE_REPORT_WRITE) && vehiclesLibrary.editedWagon?.registration && (
            <button
              className="btn btn-link"
              type="button"
              onClick={async () => {
                const wagonTemplate = await wagonCache.findItemById(vehiclesLibrary.editedWagon.registration);
                const ownerTemplate = wagonTemplate?.ownerId && (await ownerCache.findItemById(wagonTemplate.ownerId));
                dispatch(addDamageReport(true, vehiclesLibrary.editedWagon, ownerTemplate));
              }}
            >
              Ouvrir un PVCA
            </button>
          )}
          {vehiclesLibrary.editedWagon && (
            <button
              className="btn btn-icon remove"
              type="button"
              onClick={() =>
                dispatch(
                  openConfirmDialog({
                    title: 'Souhaitez-vous supprimer le modèle de wagon ?',
                    actionText: 'Supprimer',
                    action: deleteLibraryWagon(vehiclesLibrary.editedWagon),
                    actionClass: 'danger',
                  }),
                )
              }
            >
              Supprimer
            </button>
          )}
        </div>
      </form>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: getVehiclesLibrary(state).editedWagon ?? initialFormValuesForNewWagonTemplates(),
});

const WagonTemplateForm = reduxForm<WagonTemplateFormData>({
  form: 'wagonTemplateForm',
  validate,
})(WagonTemplateDetails);

export default connect(mapStateToProps)(WagonTemplateForm);
