import debugFactory from 'debug';
import { keepalive } from '../rest/securityAccessRest';
import initSentry from '../sentry/sentry';
import store from '../store/store';
import { browserOffline } from '../app-state/appStateActions';
import { loadTemplates } from '../templates/templatesDucks';
import { syncDirtyData } from '../../persist/backendSynchronizer';

const debug = debugFactory('offlineManager');

const oneMinute = 60 * 1000;

const oneHour = 60 * 60 * 1000;

export default () => {
  window.addEventListener(
    'offline',
    () => {
      debug('App is offline');
      store.dispatch(browserOffline(true));
    },
    false,
  );

  window.addEventListener(
    'online',
    () => {
      keepalive();
      initSentry();
      store.dispatch(browserOffline(false));

      store.dispatch(syncDirtyData());

      debug('App is online');
    },
    false,
  );

  setInterval(() => {
    store.dispatch(syncDirtyData());
  }, oneMinute);

  // Refresh templates every hour
  setInterval(() => {
    store.dispatch(loadTemplates());
  }, oneHour);
};
