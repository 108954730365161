import { RoleId } from '../../../../commons/security/userRoles';
import { BrakingBulletin, BrakingBulletinIndiceComposition } from '../../../../commons/model/BrakingBulletin';
import { TrainStep } from '../../../../commons/model/Train';

/**
 * Return a boolean indicating if the "braking bulletin indice composition" field
 * must be displayed.
 * The field is not hidden after validation if empty to keep the UI consistent:
 * - before and after validation;
 * - between versions of the braking bulletin.
 */
export const displayIndiceCompositionField = (
  roles: RoleId[],
  brakingBulletinIndiceComposition: BrakingBulletinIndiceComposition | null,
  stepIndiceComposition: string | null,
): boolean => {
  if (brakingBulletinIndiceComposition) {
    // If a value is defined, it should be displayed.
    return true;
  }
  if (stepIndiceComposition) {
    return false;
  }
  return roles.includes('OVERRIDE_INDICE_COMPOSITION');
};

/**
 * Return a boolean indicating whether the field, if displayed, must be
 * read-only.
 *
 * It does not determine if the field must be displayed.
 */
export const indiceCompositionFieldReadonly = (roles: RoleId[], formReadOnly: boolean): boolean => {
  return formReadOnly || !roles.includes('OVERRIDE_INDICE_COMPOSITION');
};

/**
 * Return the indice composition override to use in a new braking bulletin.
 * @param step The step.
 * @param previousVersion The previous version of the braking bulletin. If there is
 * no such version, a skeleton must be provided.
 */
export const indiceCompositionOverrideForNewBrakingBulletin = (
  step: TrainStep,
  previousVersion: BrakingBulletin,
): BrakingBulletinIndiceComposition | null => {
  if (step.indiceComposition) {
    // If the step has a defined indice composition, no override.
    return null;
  }
  return previousVersion.indiceComposition;
};

/**
 * The indices are sorted according to the displaying order.
 */
export const INDICE_COMPOSITION_OPTIONS: Readonly<Record<BrakingBulletinIndiceComposition, string>> = {
  MA80: 'MA80',
  MA90: 'MA90',
  MA100: 'MA100',
  ME100: 'ME100',
  ME120: 'ME120',
  ME140: 'ME140',
  MV160: 'MV160',
  MVGV: 'MVGV',
  HLP: 'HLP',
  AGC: 'AGC',
  AR160: 'AR160',
  AUTOM: 'AUTOM',
  AUTOR: 'AUTOR',
  B16C: 'B16C',
  E13N: 'E13N',
  E16N: 'E16N',
  E20C: 'E20C',
  E32C: 'E32C',
  ES2: 'ES2',
  EVO: 'EVO',
  R160: 'R160',
  TM: 'TM',
  V120: 'V120',
  V140: 'V140',
  V160: 'V160',
};
