/**
 * Train component
 */
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TrainStepForm from './TrainStepForm';
import Vehicles from '../../commons/components/vehicles/Vehicles';
import GlobalError from '../../commons/components/GlobalError';
import { loadTrainStep, unloadTrainStep } from './trainStepDucks';
import { showSidebar } from '../../commons/components/sidebar/sidebarDucks';
import './trainStepStyles.scss';
import CopyWagonsOverlay from '../../commons/components/copy-wagons/CopyWagonsOverlay';
import { PRE_ADVICE_READ } from '../../commons/security/userRoles';
import { copyWagonsIntoComposition } from './copyWagonsIntoTrainAction';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import TrainUtils from '../../commons/model/TrainUtils';
import { ImportSourceType, PRE_ADVICE_TYPE, TRAIN_TYPE } from '../../commons/model/importSource';
import { selectSecurityContextRoles, selectTrainStep } from '../../commons/selectors/selectors';
import BrakingBulletinOverlay from './braking-bulletin/BrakingBulletinOverlay';
import TraceabilityOverlay from './traceability/TraceabilityOverlay';
import { useViewportWidth } from '../../commons/responsive/hooks';
import SwipeableViews from 'react-swipeable-views';
import { TrainStep } from '../../commons/model/Train';
import helpers from '../../commons/helpers/helpers';
import { usePageTitle } from '../../commons/components/hooks';

export const trainStepTitle = (step: TrainStep) => {
  return `Train ${step.trainNumber} du ${helpers.dateFormat(step.startDate)} - Étape de ${step.startLocationLabel} à ${
    step.endLocationLabel
  }`;
};

const TrainStepPage = () => {
  const { stepId } = useParams();
  const { isMobile } = useViewportWidth();
  const [searchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(searchParams.get('tab') === 'vehicles' ? 1 : 0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showSidebar());
    dispatch(loadTrainStep(stepId!));
    return () => {
      // Free up the state when leaving the page
      unloadTrainStep();
    };
  }, [dispatch, stepId]);

  const step = useAppSelector(selectTrainStep);
  const canReadPreAdvices = useAppSelector(selectSecurityContextRoles).includes(PRE_ADVICE_READ);

  const importSourcesTypes: ImportSourceType[] = canReadPreAdvices ? [TRAIN_TYPE, PRE_ADVICE_TYPE] : [TRAIN_TYPE];

  usePageTitle(step.data && step.data.id === stepId ? trainStepTitle(step.data) : 'Train - Étape');

  if (step.error) {
    if (step.error === 404) {
      return <GlobalError title="Étape introuvable">Aucune étape de train trouvée pour ce numéro.</GlobalError>;
    } else if (step.error === 403) {
      return <GlobalError title="Accès refusé">Vous n'avez pas accès à ce train.</GlobalError>;
    } else if (!step.data) {
      return (
        <GlobalError title="Erreur">
          Une erreur inattendue s'est produite durant la récupération de l'étape.
        </GlobalError>
      );
    }
  }

  if (!step.data) {
    // Skeleton page
    return (
      <div className="composition">
        <div className="composition-form">
          <div className="composition-form-inner">
            <h1 className="title">
              Train &gt; Étape
              <div className="loader" />
            </h1>
          </div>
        </div>
        {!isMobile && <div className="vehicles-list"></div>}
      </div>
    );
  }

  /**
   * Renders component
   */
  return (
    <div className="composition">
      {isMobile ? (
        <SwipeableViews animateHeight index={tabIndex} onChangeIndex={(newIndex) => setTabIndex(newIndex)}>
          <TrainStepForm step={step.data} loading={step.loading} onChangeTab={(newIndex) => setTabIndex(newIndex)} />
          <Vehicles
            step={step.data}
            isCollapsed={false}
            isValidated={TrainUtils.isStatusValidated(step.data)}
            onChangeTab={(newIndex) => setTabIndex(newIndex)}
          />
        </SwipeableViews>
      ) : (
        <>
          <TrainStepForm step={step.data} loading={step.loading} />
          <Vehicles step={step.data} isCollapsed={false} isValidated={TrainUtils.isStatusValidated(step.data)} />
        </>
      )}
      <CopyWagonsOverlay
        authorizedTypes={importSourcesTypes}
        candidateIdToExclude={step.data.id}
        copyWagonsAction={copyWagonsIntoComposition}
        marketId={step.data.marketId}
        marketName={step.data.marketName}
        startDateTime={step.data.startDate}
        targetType={TRAIN_TYPE}
      />
      <BrakingBulletinOverlay />
      <TraceabilityOverlay />
    </div>
  );
};

export default TrainStepPage;
