import AbstractTemplateCache from './AbstractTemplateCache';
import { Owner } from '../model/templates';
import { Tx } from './AbstractDbCache';

class OwnerCache extends AbstractTemplateCache<'owner'> {
  protected getStoreName(): 'owner' {
    return 'owner';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  protected findTemplates(inputValue: string, tx: Tx<'owner'>) {
    const normalizedInputValue = this.normalizeInput(inputValue);
    return this.findItemsByMatcher(tx, (owner) => owner.name.toUpperCase().includes(normalizedInputValue));
  }

  public findByName(name: string): Promise<Owner | undefined> {
    return this.findItemByIndex(name, 'by-name');
  }

  public addOrUpdateOwner(owner: Owner) {
    return this.saveItems([owner], { clear: false });
  }

  public async delete(ownerId: number) {
    const { tx, store } = await this.connect('readwrite');
    await store.delete(ownerId);
    await tx.done;
  }

  public formatItem(item: Owner): string {
    return item.name;
  }
}

export default new OwnerCache();
