import React from 'react';
import Checkbox from '../checkbox/Checkbox';
import roles from '../../security/userRoles';

const removeRole = (roleId, oldRoles, onChange) => {
  const newRoles = oldRoles.filter((r) => r !== roleId);
  onChange(newRoles);
};

const addRole = (roleId, oldRoles, onChange) => {
  const newRoles = [...oldRoles];
  if (!newRoles.includes(roleId)) {
    newRoles.push(roleId);
  }
  onChange(newRoles);
};

const RoleCheckboxes = ({ labelText, input: { value, onChange }, meta }) => (
  <div className="checkboxes">
    <div className="label">{labelText}</div>
    {Object.keys(roles).map((roleId) => {
      const checked = value.includes(roleId);
      const role = roles[roleId];
      return (
        <Checkbox
          key={`role-${roleId}`}
          on={checked}
          label={role.name}
          onClick={() => {
            if (checked) {
              removeRole(roleId, value, onChange);
            } else {
              addRole(roleId, value, onChange);
            }
          }}
        />
      );
    })}
    {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
  </div>
);

export default RoleCheckboxes;
