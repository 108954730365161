class MaskDefinition {
  constructor(regexp, format) {
    this.regexp = regexp;
    this.format = format;
  }
}

const registrationMaskRegexps = [
  new MaskDefinition(/^(\d{2})\s*(\d{2})\s*(\d{3})\s*(\d{4})-*(\d).*$/, '$1 $2 $3 $4-$5'),
  new MaskDefinition(/^(\d{2})\s*(\d{2})\s*(\d{3})\s*(\d{4})-*(\d)$/, '$1 $2 $3 $4-$5'),
  new MaskDefinition(/^(\d{2})\s*(\d{2})\s*(\d{3})\s*(\d{1,4})$/, '$1 $2 $3 $4'),
  new MaskDefinition(/^(\d{2})\s*(\d{2})\s*(\d{1,3})$/, '$1 $2 $3'),
  new MaskDefinition(/^(\d{2})\s*(\d{1,2})$/, '$1 $2'),
  new MaskDefinition(/^(\d{1,2})$/, '$1'),
];

export default class Mask {
  static REGISTRATION = new Mask(registrationMaskRegexps);

  /**
   * The mask definitions (array of regexp and substitution)
   * @param {Array} maskDefinitions
   */
  constructor(maskDefinitions) {
    this.maskDefinitions = maskDefinitions;
  }

  format = (parsed) => {
    for (const maskDefinition of this.maskDefinitions) {
      if (maskDefinition.regexp.test(parsed)) {
        return parsed.replace(maskDefinition.regexp, maskDefinition.format);
      }
    }
    return parsed;
  };
}
