import moment from 'moment';

import DamageReportEntity from '../../commons/entity/DamageReportEntity';

const sortedStatuses = [
  DamageReportEntity.VALIDATION_STATUSES.EMPTY,
  DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS,
  DamageReportEntity.VALIDATION_STATUSES.SUBMITTED,
  DamageReportEntity.VALIDATION_STATUSES.VALIDATED,
];

const sortByCreationDate = (direction) => (damageReport1, damageReport2) =>
  direction * moment(damageReport1.creationDate).diff(moment(damageReport2.creationDate));

const sortByStatus = (direction) => (damageReport1, damageReport2) => {
  if (damageReport1.status === damageReport2.status) {
    return sortByCreationDate(direction)(damageReport1, damageReport2);
  }
  const index1 = sortedStatuses.indexOf(damageReport1.status);
  const index2 = sortedStatuses.indexOf(damageReport2.status);
  return index1 < index2 ? -direction : direction;
};

const sortByNumber = (direction) => (damageReport1, damageReport2) => {
  if (!damageReport1.number && !damageReport2.number) {
    return sortByCreationDate(direction)(damageReport1, damageReport2);
  }
  if (!damageReport1.number) {
    return direction;
  }
  if (!damageReport2.number) {
    return -direction;
  }
  if (damageReport1.number > damageReport2.number) {
    return direction;
  }
  if (damageReport1.number < damageReport2.number) {
    return -direction;
  }
  return sortByCreationDate(direction)(damageReport1, damageReport2);
};

const sortByWagon = (direction) => (damageReport1, damageReport2) => {
  if (!damageReport1.wagon.registration && !damageReport2.wagon.registration) {
    return sortByCreationDate(direction)(damageReport1, damageReport2);
  }
  if (!damageReport1.wagon.registration) {
    return direction;
  }
  if (!damageReport2.wagon.registration) {
    return -direction;
  }
  if (damageReport1.wagon.registration > damageReport2.wagon.registration) {
    return direction;
  }
  if (damageReport1.wagon.registration < damageReport2.wagon.registration) {
    return -direction;
  }
  return sortByCreationDate(direction)(damageReport1, damageReport2);
};

const sortByValidationDate = (direction) => (damageReport1, damageReport2) => {
  if (!damageReport1.validatedDate && !damageReport2.validatedDate) {
    return sortByCreationDate(direction)(damageReport1, damageReport2);
  }
  if (!damageReport1.validatedDate) {
    return direction;
  }
  if (!damageReport2.validatedDate) {
    return -direction;
  }
  return direction * moment(damageReport1.validatedDate).diff(moment(damageReport2.validatedDate));
};

const getSortFunction = (sortDefinition) => {
  switch (sortDefinition.field) {
    case 'number':
      return sortByNumber(sortDefinition.direction);
    case 'wagon.registration':
      return sortByWagon(sortDefinition.direction);
    case 'creationDate':
      return sortByCreationDate(sortDefinition.direction);
    case 'validatedDate':
      return sortByValidationDate(sortDefinition.direction);
    case 'status':
      return sortByStatus(sortDefinition.direction);
    default:
      return sortByCreationDate(sortDefinition.direction);
  }
};

export default getSortFunction;
