import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';

import Input from '../../commons/components/input/Input';
import { requestResetFilters, requestUpdateFilters } from '../preAdvicesDucks';
import { getPreAdviceSummariesState } from '../../commons/selectors/selectors';
import DateIntervalFilters from '../../commons/components/filters/DateIntervalFilters';
import StationField from '../../commons/components/input/StationField';
import CheckButton from '../../commons/components/check-button/CheckButton';
import './preAdviceSummariesFiltersStyles.scss';
import { asyncValidateStation, asyncValidation } from '../../commons/components/input/asyncValidation';
import classNames from 'classnames';
import { useViewportWidth } from '../../commons/responsive/hooks';
import RailwayCompanyField from '../../commons/components/input/RailwayCompanyField';
import ResetFiltersButton from '../../commons/components/filters/ResetFiltersButton';

const PreAdviceFilters = ({
  change,
  filtersValues,
  handleSubmit,
  loadPreAdviceSummaries,
  resetFilters,
  updateFilters,
}) => {
  const [isOpened, setOpenStatus] = useState(false);
  const { isMobile } = useViewportWidth();
  return (
    <div className="filters">
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          updateFilters(data);
        })}
      >
        <Field
          component={Input}
          name="trainNumber"
          placeholder={isMobile ? 'Numéro' : 'Filtrer par n°'}
          maxLength={6}
          pattern="[0-9]*" // iPad numeric keyboard
          inputMode="numeric"
          saveAction={requestUpdateFilters}
          onChange={(event, trainNumber) => {
            if (!trainNumber || trainNumber.length >= 5) {
              updateFilters(null, { trainNumber });
              loadPreAdviceSummaries();
            }
          }}
        />
        <div>
          <button
            className={classNames('btn filter', isMobile ? 'btn-round' : 'btn-link btn-icon')}
            type="button"
            onClick={() => setOpenStatus(true)}
          >
            Autres filtres
          </button>
          {isOpened && (
            <div className="overlay">
              <div className="overlay-fade" onClick={() => setOpenStatus(false)} />
              <div className="filters-dropdown">
                <StationField
                  name="startStation.label"
                  disabled={false}
                  className="icon route-from"
                  placeholder="Départ"
                  saveAction={requestUpdateFilters}
                />
                <StationField
                  name="endStation.label"
                  disabled={false}
                  className="icon route-to"
                  placeholder="Arrivée"
                  saveAction={requestUpdateFilters}
                />
                <DateIntervalFilters
                  changeFormValue={change}
                  currentFromDateValue={filtersValues.fromStartDateTime}
                  currentToDateValue={filtersValues.toStartDateTime}
                  fromDateFieldName="fromStartDateTime"
                  toDateFieldName="toStartDateTime"
                  maxIntervalWidth={moment.duration(1, 'months')}
                  offline={false}
                  reloadListAction={requestUpdateFilters}
                />
                <RailwayCompanyField
                  name="sender.id"
                  disabled={false}
                  iconText="De"
                  placeholder="EF émettrice"
                  saveAction={requestUpdateFilters}
                />
                <RailwayCompanyField
                  name="receiver.id"
                  disabled={false}
                  iconText="À"
                  placeholder="EF réceptrice"
                  saveAction={requestUpdateFilters}
                />
                <div className="btn-group">
                  <Field
                    component={CheckButton}
                    id="filter-status-empty"
                    name="emptyStatus"
                    disabled={false}
                    className="btn state-1"
                    label="Pré-annonce vide"
                    saveAction={requestUpdateFilters}
                  />
                  <Field
                    component={CheckButton}
                    id="filter-status-nv"
                    name="notValidatedStatus"
                    disabled={false}
                    className="btn state-2"
                    label="Non validée"
                    saveAction={requestUpdateFilters}
                  />
                  <Field
                    component={CheckButton}
                    id="filter-status-validated"
                    name="validatedStatus"
                    disabled={false}
                    className="btn state-3"
                    label="Validée"
                    saveAction={requestUpdateFilters}
                  />
                </div>
                <ResetFiltersButton onClick={resetFilters} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

PreAdviceFilters.propTypes = {
  loadPreAdviceSummaries: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

const FiltersForm = reduxForm({
  form: 'preAdviceSummariesFiltersForm',
  ...asyncValidation({
    'startStation.label': asyncValidateStation,
    'endStation.label': asyncValidateStation,
  }),
})(PreAdviceFilters);

const mapStateToDispatch = (state) => ({
  filtersValues: getFormValues('preAdviceSummariesFiltersForm')(state),
  initialValues: getPreAdviceSummariesState(state).filters,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data, propertyToUpdate) => dispatch(requestUpdateFilters(data, propertyToUpdate)),
  resetFilters: () => dispatch(requestResetFilters()),
});

export default connect(mapStateToDispatch, mapDispatchToProps)(FiltersForm);
