/**
 * DamageReportsFilters topbar component
 */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import Input from '../input/Input';
import { DamageReportFiltersFormValues, damageReportsFiltersToFormInitialValues } from '../../filters/filters';
import { getDamageReports, getOfflineState, selectSecurityContextRoles } from '../../selectors/selectors';
import {
  initializeLazyDamageReportFilters,
  resetDamageReportsFilters,
  updateDamageReportsFilters,
} from '../../../damage-reports/damage-reports-table/damageReportsDucks';
import FiltersPopup from '../filters/FiltersPopup';
import './damageReportsFiltersStyles.scss';
import DateIntervalFilters from '../filters/DateIntervalFilters';
import OwnerSelect from '../owner-select/OwnerSelect';
import MarketSelect from '../market-select/MarketSelect';
import Dropdown from '../dropdown/Dropdown';
import { labelsDictionary } from '../../../damage-reports/damageReportUtils';
import CheckButton from '../check-button/CheckButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../reducers/rootReducer';
import { useViewportWidth } from '../../responsive/hooks';
import { CompanyOptions } from '../../model/common';
import Switch2 from '../switch2/Switch2';
import ResetFiltersButton from '../filters/ResetFiltersButton';
import CheckboxFormComponent from '../checkbox/CheckboxFormComponent';
import { DAMAGE_REPORT_VALIDATE } from '../../security/userRoles';

type DamageReportsFiltersProps = InjectedFormProps<DamageReportFiltersFormValues>;

const DamageReportsFilters = ({ handleSubmit, change }: DamageReportsFiltersProps) => {
  const dispatch = useAppDispatch();
  const offline: boolean = useSelector(getOfflineState);
  const { isMobile } = useViewportWidth();

  const roles = useAppSelector(selectSecurityContextRoles);
  const canFilterDisabledReports = roles.includes(DAMAGE_REPORT_VALIDATE);

  const filtersValues = useSelector(getFormValues('damageReportsFiltersForm')) as DamageReportFiltersFormValues;

  useEffect(() => {
    dispatch(initializeLazyDamageReportFilters());
  }, [dispatch]);

  return (
    <div className="filters damage-reports-filters">
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          dispatch(updateDamageReportsFilters(null, data));
        })}
      >
        <Field
          component={Switch2}
          id="filter-only-mine"
          className="only-mine"
          labelOff="Mes PVCA"
          labelOn="Mes PVCA"
          name="onlyMine"
          disabled={offline}
          onChange={() => dispatch(updateDamageReportsFilters(filtersValues, { onlyMine: !filtersValues.onlyMine }))}
        />
        <Field
          component={Input}
          name="wagonRegistration"
          disabled={offline}
          placeholder={isMobile ? 'Wagon' : 'Filtrer par wagon'}
          maxLength={12}
          pattern="[0-9]*" // iPad numeric keyboard
          inputMode="numeric"
          saveAction={updateDamageReportsFilters}
        />
        <FiltersPopup>
          <div className="filters-dropdown">
            <Field
              component={Input}
              name="number"
              placeholder="N° de PVCA"
              disabled={offline}
              pattern="[0-9]*" // iPad numeric keyboard
              inputMode="numeric"
              saveAction={updateDamageReportsFilters}
            />
            <Field
              component={Input}
              name="damageTemplateId"
              placeholder="Code d'avarie"
              disabled={offline}
              pattern="[0-9]*" // iPad numeric keyboard
              inputMode="numeric"
              saveAction={updateDamageReportsFilters}
            />
            <DateIntervalFilters
              changeFormValue={change}
              currentFromDateValue={filtersValues.fromDate}
              currentToDateValue={filtersValues.toDate}
              reloadListAction={updateDamageReportsFilters}
              offline={offline}
            />
            <Field
              component={OwnerSelect}
              id="owners"
              name="owners"
              disabled={offline}
              className="icon star"
              placeholder="Détenteurs"
              saveAction={updateDamageReportsFilters}
            />
            <Field
              component={MarketSelect}
              id="markets"
              name="markets"
              className="icon star"
              placeholder="Marchés"
              saveAction={updateDamageReportsFilters}
            />
            <Field
              component={Dropdown}
              disabled={offline}
              name="label"
              className="icon labels"
              options={labelsDictionary}
              placeholder="Étiquette posée"
              saveAction={updateDamageReportsFilters}
            />
            <div className="btn-group">
              <Field
                component={CheckButton}
                id="filter-status-empty"
                name="statusInProgress"
                disabled={offline}
                className="btn state-1"
                label="En cours"
                saveAction={updateDamageReportsFilters}
              />
              <Field
                component={CheckButton}
                id="filter-status-nv"
                name="statusSubmitted"
                disabled={offline}
                className="btn state-2"
                label="À valider"
                saveAction={updateDamageReportsFilters}
              />
              <Field
                component={CheckButton}
                id="filter-status-validated"
                name="statusValidated"
                disabled={offline}
                className="btn state-3"
                label="Validé"
                saveAction={updateDamageReportsFilters}
              />
            </div>
            {canFilterDisabledReports && (
              <Field
                component={CheckboxFormComponent}
                disabled={offline}
                label="Inclure les PVCAs désactivés"
                name="statusDisabled"
                saveAction={updateDamageReportsFilters}
              />
            )}
            <Field
              component={Dropdown}
              name="company"
              placeholder="Entreprise"
              saveAction={updateDamageReportsFilters}
              options={CompanyOptions}
            />
            <ResetFiltersButton onClick={() => dispatch(resetDamageReportsFilters())} disabled={offline} />
          </div>
        </FiltersPopup>
      </form>
    </div>
  );
};

const FiltersForm = reduxForm<DamageReportFiltersFormValues>({
  form: 'damageReportsFiltersForm',
})(DamageReportsFilters);

const mapStateToProps = (state: RootState) => {
  const { filters } = getDamageReports(state);
  return {
    initialValues: damageReportsFiltersToFormInitialValues(filters),
  };
};

export default connect(mapStateToProps)(FiltersForm as any);
