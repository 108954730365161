import { getFormValues, initialize } from 'redux-form';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REHYDRATE, RehydrateAction } from 'redux-persist';

import offlineUtils from '../../commons/offline/offlineUtils';
import {
  defaultTrainFilters,
  TrainFilters,
  TrainFiltersFormValues,
  trainFiltersToFormInitialValues,
  trainFiltersToParams,
  trainFormToFilters,
} from '../../commons/filters/filters';
import { loadTrains, offlineUpdateCompositionStatus } from '../offline/offlineTrainsDucks';
import { TrainSummary } from '../../commons/model/Train';
import { RootState } from '../../commons/reducers/rootReducer';
import { AppDispatch } from '../../commons/store/store';
import marketCache from '../../commons/templates/marketCache';
import stationCache from '../../commons/templates/stationCache';
import apiHelper from '../../api/apiHelper';
import { selectSecurityContext, selectTrainSummaries } from '../../commons/selectors/selectors';

export type TrainSummariesState = {
  data: TrainSummary[];
  loading: boolean;
  error: boolean;
  filters: TrainFilters;
};

export const initialState: TrainSummariesState = {
  data: [], // all items in the cache
  loading: false,
  error: false,
  filters: { ...defaultTrainFilters },
};

export const loadTrainSummaries = createAsyncThunk<
  TrainSummary[] | 'offline',
  void,
  {
    state: RootState;
  }
>('trainSummaries/load', async (_, { getState }) => {
  if (!offlineUtils.isOnline()) {
    return 'offline';
  }

  const { filters } = selectTrainSummaries(getState());

  try {
    return await apiHelper.get('/api/trainSummaries', trainFiltersToParams(filters));
  } catch (e) {
    if (offlineUtils.isOnline()) {
      throw e;
    } else {
      // Connection lost - ignore the error
      return 'offline';
    }
  }
});

const changeFilters = createAction<TrainFilters>('trainSummaries/filter');

export const initializeTrainFilters = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const { marketIds } = selectSecurityContext(getState());
  const newFilters = {
    ...defaultTrainFilters,
    marketIds: marketIds || [],
  };
  dispatch(changeFilters(newFilters));
};

export const resetTrainFilters = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(initializeTrainFilters());

  const { filters } = selectTrainSummaries(getState());
  const syncFormValues = trainFiltersToFormInitialValues(filters);
  const markets = await Promise.all(filters.marketIds.map((marketId) => marketCache.findItemById(marketId)));
  dispatch(initialize('filtersForm', { ...syncFormValues, markets }));
  dispatch(loadTrainSummaries());
  dispatch(loadTrains());
};

export const updateTrainFilters =
  (_data: TrainFilters | null, propertyToUpdate: Partial<TrainFiltersFormValues>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { filters } = selectTrainSummaries(getState());
    dispatch(changeFilters(trainFormToFilters(filters, propertyToUpdate)));
    dispatch(loadTrainSummaries());
    dispatch(loadTrains());
  };

export const initializeLazyTrainFilters = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const { filters } = selectTrainSummaries(state);
  const syncFormValues = getFormValues('filtersForm')(state) as TrainFiltersFormValues;
  const asyncFormValues = {
    markets: await Promise.all(filters.marketIds.map((marketId) => marketCache.findItemById(marketId))),
    startStation: filters.startStationId ? await stationCache.findItemById(filters.startStationId) : null,
    endStation: filters.endStationId ? await stationCache.findItemById(filters.endStationId) : null,
  };
  dispatch(initialize('filtersForm', { ...syncFormValues, ...asyncFormValues }));
};

export const trainSummariesSlice = createSlice({
  name: 'trainSummaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTrainSummaries.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loadTrainSummaries.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(loadTrainSummaries.fulfilled, (state, action) => {
        if (action.payload !== 'offline') {
          state.data = action.payload;
        }
        state.loading = false;
        state.error = false;
      })
      .addCase(changeFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(offlineUpdateCompositionStatus, (state, action) => {
        const trainToUpdate = state.data.find((train) => train.steps.some((step) => step.id === action.payload.id));
        if (trainToUpdate) {
          const stepToUpdate = trainToUpdate.steps.find((step) => step.id === action.payload.id);
          if (stepToUpdate) {
            stepToUpdate.status = action.payload.status;
          }
        }
      })
      .addCase<string, RehydrateAction>(REHYDRATE, (state, action) => {
        if (action.key === 'trainSummaries') {
          const payload = action.payload as TrainSummariesState | undefined;
          // @ts-ignore
          state.data = payload?.data ?? [];
          state.filters = { ...defaultTrainFilters, ...payload?.filters };
        }
      });
  },
});

export default trainSummariesSlice.reducer;
