import React from 'react';
import { Field, FormErrors, getFormMeta, getFormSyncErrors, getFormValues } from 'redux-form';
import Input from '../input/Input';
import { formatDecimalNumber, formatDecimalNumber2Decimal } from '../../redux-form/valueFormatter';
import { normalizePositiveFloatTwoDecimal } from '../../redux-form/valueNormalizer';
import { parseToFloat } from '../../redux-form/valueParser';
import './utiStyles.scss';
import { updateWagon } from '../../../trains/step/vehicleActions';
import UtiField from './UtiField';
import FieldLabelWithTooltip from '../form/FieldLabelWithTooltip';
import CheckboxFormComponent from '../checkbox/CheckboxFormComponent';
import {
  resetUtiFieldsInReduxForm,
  updateCommandWithUtiFieldsReset,
  wagonFormName,
} from '../vehicles/edit/vehicleEditUtils';
import { useDispatch, useSelector } from 'react-redux';
import DisabledInput from '../input/DisabledInput';
import VehicleUtils from '../../model/VehicleUtils';
import { Wagon } from '../../model/Vehicle';
import { WagonFormValues } from '../vehicles/edit/wagon/WagonForm';
import { FormMeta } from '../../model/typeUtils';

type UtiFormFieldsProps = {
  wagon: Wagon;
  readOnly: boolean;
};

const nbUtiFieldNames: (keyof WagonFormValues)[] = ['nbUtiContainer', 'nbUtiTrailer', 'nbUtiVehicle', 'nbUtiOther'];

const UtiFormFields = ({ readOnly, wagon }: UtiFormFieldsProps) => {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues(wagonFormName)) as WagonFormValues;
  const formLoadWeight = formValues.loadWeight || '0';
  const utiFieldsReadOnly = readOnly || formLoadWeight === '0' || Boolean(formValues.noUti);
  const noUtiReadOnly = readOnly || formLoadWeight === '0';

  const formMeta = useSelector(getFormMeta(wagonFormName)) as FormMeta<WagonFormValues>;

  const formErrors = useSelector(getFormSyncErrors(wagonFormName)) as FormErrors<WagonFormValues>;

  const errorMessage = formErrors.noUti;
  const shouldDisplayError = () => {
    if (!errorMessage) {
      return false;
    }
    const touchedField = nbUtiFieldNames.find((fieldName) => formMeta[fieldName]?.touched);
    return Boolean(touchedField);
  };

  const handleClickNoUtiCheckbox = (
    data: Wagon,
    propertyToUpdate: Pick<
      WagonFormValues,
      'nbUtiContainer' | 'nbUtiTrailer' | 'nbUtiVehicle' | 'nbUtiOther' | 'noUti'
    >,
  ) => {
    let updateCommand: any = propertyToUpdate;
    if (propertyToUpdate.noUti) {
      resetUtiFieldsInReduxForm(dispatch);
      updateCommand = updateCommandWithUtiFieldsReset(propertyToUpdate);
    }
    return updateWagon(data, updateCommand);
  };

  return (
    <div className="uti">
      <h2>UTI</h2>
      {shouldDisplayError() && (
        <div className="uti-row error-message" data-testid="global-nb-uti-error">
          {errorMessage}
        </div>
      )}
      <div>
        <div className="uti-row">
          <Field
            component={UtiField}
            data={wagon}
            disabled={utiFieldsReadOnly}
            labelText="Nombre de conteneurs et de caisses mobiles *"
            name="nbUtiContainer"
            saveAction={updateWagon}
          />
          <Field
            component={UtiField}
            data={wagon}
            disabled={utiFieldsReadOnly}
            labelText="Nombre de semi-remorques (non accompagnées) *"
            name="nbUtiTrailer"
            saveAction={updateWagon}
          />
        </div>
        <div className="uti-row">
          <Field
            component={UtiField}
            data={wagon}
            disabled={utiFieldsReadOnly}
            labelText="Nombre de véhicules routiers (accompagnés) *"
            name="nbUtiVehicle"
            saveAction={updateWagon}
          />
          <Field
            component={UtiField}
            data={wagon}
            disabled={utiFieldsReadOnly}
            labelText="Nombre d'UTI restantes *"
            name="nbUtiOther"
            saveAction={updateWagon}
          />
        </div>
        <div className="uti-row">
          <DisabledInput
            name="calculatedEvp"
            labelText={
              <FieldLabelWithTooltip
                label="EVP calculé"
                tooltipText="Calculé à partir de la longueur du wagon lors de la présence d'au moins 1 UTI"
              />
            }
            value={formatDecimalNumber2Decimal(VehicleUtils.calculatedEvp(wagon) ?? 0)}
          />
          <Field
            name="manualEvp"
            labelText="Surcharger manuellement l'EVP"
            component={Input}
            format={formatDecimalNumber}
            disabled={utiFieldsReadOnly}
            normalize={normalizePositiveFloatTwoDecimal}
            normalizeOnSave={(evp: number | string) => (evp === '' ? null : parseToFloat(evp))}
            pattern="[0-9,]*"
            saveAction={updateWagon}
            data={wagon}
            data-testid="manual-evp"
          />
        </div>
      </div>
      <div className="uti-row">
        <Field
          component={CheckboxFormComponent}
          data={wagon}
          disabled={noUtiReadOnly}
          label="Ce wagon n'a pas d'UTI"
          name="noUti"
          data-testid="noUti"
          saveAction={handleClickNoUtiCheckbox}
        />
      </div>
    </div>
  );
};

export default UtiFormFields;
