import React from 'react';
import CopyWagonsSearchResult from './CopyWagonsSearchResult';
import { AppAction } from '../../store/store';
import { ImportSourceCandidate, ImportSourceType } from '../../model/importSource';
import { useRailwayCompanies } from '../../templates/railwayCompanyCache';

type CopyWagonsSearchResultsProps = {
  candidateIdToExclude?: string;
  copyWagonsAction: (candidate: ImportSourceCandidate) => AppAction;
  importSourceCandidates: ImportSourceCandidate[];
  targetType: ImportSourceType;
  targetMarketId?: number;
};

const CopyWagonsSearchResults = ({
  candidateIdToExclude,
  copyWagonsAction,
  importSourceCandidates,
  targetType,
  targetMarketId,
}: CopyWagonsSearchResultsProps) => {
  const { getRailwayCompanyName } = useRailwayCompanies();
  return (
    <div className="table search-results">
      <div className="table-body">
        {importSourceCandidates.length === 0 && (
          <div className="no-result">
            <span className="message">Aucun résultat pour cette recherche</span>
          </div>
        )}
        {importSourceCandidates.length > 0 &&
          importSourceCandidates
            .filter((c) => c.id !== candidateIdToExclude)
            .map((importSourceCandidate) => (
              <CopyWagonsSearchResult
                key={importSourceCandidate.id}
                copyWagonsAction={copyWagonsAction}
                importSourceCandidate={importSourceCandidate}
                targetType={targetType}
                targetMarketId={targetMarketId}
                getRailwayCompanyName={getRailwayCompanyName}
              />
            ))}
      </div>
    </div>
  );
};

export default CopyWagonsSearchResults;
