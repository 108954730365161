import { Signature, SignatureOrOffline } from './common';

export type BrakingBulletinStatus = 'DRAFT' | 'SIGNED' | 'INVALIDATED';

export type BrakingBulletinObservationType =
  | 'DANGEROUS_MATERIAL'
  | 'CHARGE_D'
  | 'ATE'
  | 'GB_GAUGE'
  | 'VEHICLE_ENGINE'
  | 'PNEUMATIC_VEHICLE'
  | 'LL'
  | 'WAGON_SPEED_LIMIT'
  | 'BRAKING_INSUFFICIENT'
  | 'ISOLATED_VEHICLE'
  | 'MAIN_LINE_INTERRUPTED'
  | 'QUEUED_ENGINE'
  | 'QUEUED_WAGON'
  | 'QUEUED_WAGON_NO_MAIN_LINE'
  | 'FCV_VEHICLES'
  | 'FCV_FULL'
  | 'MULTIPLE_WAGONS_UNITS'
  | 'UNBRAKED_HEAD_VEHICLE'
  | 'FULL_TRAIN'
  | 'REINFORCED_COUPLING'
  | 'OTHER';

export const BRAKING_BULLETIN_OBSERVATION_TYPES: Record<BrakingBulletinObservationType, string> = {
  DANGEROUS_MATERIAL: 'Matières dangereuses',
  CHARGE_D: 'Charge D',
  ATE: 'Transport exceptionnel',
  GB_GAUGE: 'Gabarit GB',
  VEHICLE_ENGINE: 'Locotracteur en véhicule',
  PNEUMATIC_VEHICLE: 'Véhicule(s) à suspension pneumatique',
  LL: 'Train Freiné LL',
  WAGON_SPEED_LIMIT: "Wagon(s) inapte(s) à la vitesse de l'indice du train",
  BRAKING_INSUFFICIENT: 'Masse freinée nécessaire non réalisée',
  ISOLATED_VEHICLE: 'Isolement de wagon(s) ou EM(s) en véhicule',
  MAIN_LINE_INTERRUPTED: 'Interruption de la conduite principale',
  QUEUED_ENGINE: "Machine en véhicule en queue d'un train MA",
  QUEUED_WAGON: "Véhicule à mettre en queue autre qu'EM",
  QUEUED_WAGON_NO_MAIN_LINE: "Véhicule en queue autre qu'EM, non relié à la CG",
  FCV_VEHICLES: 'Véhicules au FCV dans un train MA freiné au FCM',
  FCV_FULL: 'Train MA freiné entièrement au FCV',
  MULTIPLE_WAGONS_UNITS: 'Unités flexibles chargées sur plus de deux wagons',
  UNBRAKED_HEAD_VEHICLE: 'Exceptionnellement Véhicule de tête non freiné',
  FULL_TRAIN: 'Train entier',
  REINFORCED_COUPLING: 'Attelage renforcé 135t',
  OTHER: 'Autre',
};

export const COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES: Set<BrakingBulletinObservationType> = new Set([
  'DANGEROUS_MATERIAL',
  'CHARGE_D',
  'ATE',
  'GB_GAUGE',
]);

export const EDITABLE_BRAKING_BULLETIN_OBSERVATION_TYPES = Object.fromEntries(
  Object.entries(BRAKING_BULLETIN_OBSERVATION_TYPES).filter(
    ([key]) => !COMPUTED_BRAKING_BULLETIN_OBSERVATION_TYPES.has(key as BrakingBulletinObservationType),
  ),
) as Record<BrakingBulletinObservationType, string>;

export type BrakingBulletinCompositionSummary = Readonly<{
  compositionIndex: string | null;
  hasChargeD: boolean;
  hasATE: boolean;
  hasGbGauge: boolean;
  hasDangerousGoods: boolean;
  vehiclesNumber: number;
  vehiclesLength: number;
  vehiclesWeight: number;
  tractionEnginesNumber: number;
  tractionEnginesLength: number;
  tractionEnginesWeight: number;
  vehicleEnginesNumber: number;
  vehicleEnginesLength: number;
  vehicleEnginesWeight: number;
  pushEnginesNumber: number;
  pushEnginesLength: number;
  pushEnginesWeight: number;
  vehiclesBrakedWeight: number;
  tractionEnginesBrakedWeight: number;
  vehicleEnginesBrakedWeight: number;
  pushEnginesBrakedWeight: number;
}>;

export type BrakingBulletinObservation = Readonly<{
  type: BrakingBulletinObservationType;
  text1?: string | null;
  text2?: string | null;
  number1?: number | null;
  number2?: number | null;
}>;

export type BrakingBulletinBrakedWeightDetails = Readonly<{
  fixed: number | null;
  stopping: number | null;
  drift: number | null;
  driftSecondHalf: number | null;
}>;

export type BrakingBulletinEffectiveBrakedWeightDetails = Readonly<{
  driftSecondHalf: number | null;
}>;

export type BrakingBulletinSignatureType = 'TESJ' | 'DRIVER';

export const BRAKING_BULLETIN_SIGNATURE_TYPES: Record<BrakingBulletinSignatureType, string> = {
  TESJ: 'Habilité à TES J',
  DRIVER: 'Conducteur',
};

export const isBrakingBulletinSignatureType = (
  value: string | null | undefined,
): value is BrakingBulletinSignatureType =>
  value != null && Object.keys(BRAKING_BULLETIN_SIGNATURE_TYPES).includes(value);

export type BrakingBulletinSignature = Signature &
  Readonly<{
    // Optional for compatibility
    type?: BrakingBulletinSignatureType;
  }>;

export type BrakingBulletin = Readonly<{
  id: string;
  compositionId: string;
  status: BrakingBulletinStatus;
  revision: number;
  indiceComposition: BrakingBulletinIndiceComposition | null;
  startLocation: string | null;
  compositionSummary: BrakingBulletinCompositionSummary;
  observations: readonly BrakingBulletinObservation[];
  brakedWeightDetails: BrakingBulletinBrakedWeightDetails;
  effectiveBrakedWeightDetails: BrakingBulletinEffectiveBrakedWeightDetails;
  signatures: readonly SignatureOrOffline<BrakingBulletinSignature>[];
}>;

export type BrakingBulletinIndiceComposition =
  | 'AGC'
  | 'AR160'
  | 'AUTOM'
  | 'AUTOR'
  | 'B16C'
  | 'E13N'
  | 'E16N'
  | 'E20C'
  | 'E32C'
  | 'ES2'
  | 'EVO'
  | 'HLP'
  | 'MA100'
  | 'MA80'
  | 'MA90'
  | 'ME100'
  | 'ME120'
  | 'ME140'
  | 'MV160'
  | 'MVGV'
  | 'R160'
  | 'TM'
  | 'V120'
  | 'V140'
  | 'V160';
