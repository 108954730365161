/**
 * Input tags component
 */
import React from 'react';
import classNames, { Argument as ClassNamesArgument } from 'classnames';
import './inputTagsStyles.scss';

type InputTagsProps<T> = {
  tags: T[];
  onRemove: (tag: T) => any;
  getLabel: (tag: T) => string;
  getClassNames: (tag: T) => ClassNamesArgument;
  disabled: boolean;
};

const InputTags = <T,>({
  tags,
  onRemove,
  getLabel = (tag: T) => String(tag),
  getClassNames = () => null,
  disabled,
}: InputTagsProps<T>) => {
  if (!tags?.length) {
    return null;
  }
  return (
    <div className="input-tags">
      <div className="tags">
        {tags.map((tag, i) => (
          <span
            key={`tag-${i}`}
            className={classNames('tag', { disabled }, getClassNames(tag))}
            onClick={() => !disabled && onRemove(tag)}
          >
            {getLabel(tag)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default InputTags;
