/**
 * Textarea component
 */
import React from 'react';
import classNames from 'classnames';
import './inputStyles.scss';

const getPropertyToUpdate = (input, transform) => ({
  [input.name]: transform ? transform(input.value) : input.value,
});

class TextareaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false };
  }

  onFocus = (event) => {
    this.setState({ saving: false });

    // Call native redux form event
    this.props.input.onFocus(event);
  };

  onBlur = (event) => {
    const { input, meta, data, onSave, saveAction } = this.props;

    if (saveAction && meta.valid) {
      this.setState({ saving: true }, () => {
        const propertyToUpdate = getPropertyToUpdate(input, onSave);
        meta.dispatch(saveAction(data, propertyToUpdate));
      });
    }
    // Call native redux form event
    input.onBlur(event);
  };

  render() {
    const { id, labelText, input, meta, placeholder, data, onSave, saveAction, ...textareaProps } = this.props;

    return (
      <div
        className={classNames('input', {
          'input-label': labelText,
          'input-error': meta && meta.touched && meta.invalid,
          'input-valid': this.state.saving,
        })}
      >
        {labelText && <label htmlFor={id}>{labelText}</label>}

        <textarea
          id={id}
          placeholder={placeholder || 'Saisir'}
          {...input}
          {...textareaProps}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />

        {meta && meta.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
      </div>
    );
  }
}

export default TextareaComponent;
