/**
 * Wagon component
 */
import React, { useMemo } from 'react';
import { change, FieldArray, reduxForm } from 'redux-form';
import 'rc-tooltip/assets/bootstrap.css';

import VehicleField from '../../../input/VehicleField';
import HazmatEdit from '../../../hazmat-edit/HazmatEdit';
import Switch3 from '../../../switch3/Switch3';
import { updateWagon } from '../../../../../trains/step/vehicleActions';
import { addHazmats } from '../../../../../trains/step/hazmatActions';
import { parseToFloat, parseToInt } from '../../../../redux-form/valueParser';
import {
  normalizePositiveFloatTwoDecimal,
  normalizePositiveNumber,
  normalizeRegistration,
} from '../../../../redux-form/valueNormalizer';
import Mask from '../../../../mask/Mask';
import { formatDecimalAndSeparateThousands, formatDecimalNumber } from '../../../../redux-form/valueFormatter';
import { COMPOSITION_WRITE, RoleId } from '../../../../security/userRoles';
import OwnerField from '../../../input/OwnerField';
import { validateWagon, validateWagonCharge } from '../../../../validation/validationUtils';
import UtiFormFields from '../../../uti/UtiFormFields';
import { isFieldReadOnly, wagonFormName } from '../vehicleEditUtils';
import WagonLoadWeight from './WagonLoadWeight';
import wagonCache from '../../../../templates/wagonCache';
import { asyncValidateOwner, asyncValidation } from '../../../input/asyncValidation';
import { Charge, isWagon, Wagon } from '../../../../model/Vehicle';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { TrainStep } from '../../../../model/Train';
import VehiclesUtils from '../../../../model/VehiclesUtils';
import { AppDispatch } from '../../../../store/store';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import VehicleUtils from '../../../../model/VehicleUtils';
import DisabledInput from '../../../input/DisabledInput';
import { PackingGroup, RID, WagonTemplate } from '../../../../model/templates';
import { selectSecurityContextRoles } from '../../../../selectors/selectors';
import CheckboxFormComponent from '../../../checkbox/CheckboxFormComponent';
import { AteCache } from '../../../../templates/ateCache';

type WagonFormProps = {
  step: TrainStep;
  wagon: Wagon;
};

type HazMatProps = {
  wagon: Wagon;
  roles: RoleId[];
  dispatch: AppDispatch;
};

export type WagonHazardousMaterialFormValues = {
  hazardousMaterial: {
    id: number | null;
    dangerIdentificationNumber: string | null;
    unitedNationsCode: number | string | null;
    packingGroup: PackingGroup | null;
    candidates: string | null;
    rid: RID | null;
  };
  weight: number | null;
  volume: number | null;
};

export type WagonFormValues = {
  registration: string | null;
  owner: {
    name: string | null;
  };
  index: number;
  length: number | null;
  tare: number | null;
  loadWeight: number | null;
  effectiveBrakedWeight: number | null;
  nbAxles: number | null;
  ateFileNumber: string | null;
  gbGauge: boolean | null;
  charge: Charge | null;
  nbUtiContainer: number | null;
  nbUtiTrailer: number | null;
  nbUtiVehicle: number | null;
  nbUtiOther: number | null;
  manualEvp: number | null;
  noUti: boolean | null;
  doubleWagon: boolean | null;
  hazardousMaterials: readonly WagonHazardousMaterialFormValues[];
};

export const initializeWagonFormValues = (wagon: Wagon): WagonFormValues => {
  const {
    registration,
    owner,
    index,
    length,
    tare,
    loadWeight,
    effectiveBrakedWeight,
    nbAxles,
    ateFileNumber,
    gbGauge,
    charge,
    nbUtiContainer,
    nbUtiTrailer,
    nbUtiVehicle,
    nbUtiOther,
    manualEvp,
    noUti,
    doubleWagon,
    hazardousMaterials,
  } = wagon;
  return {
    registration,
    owner: { name: owner?.name ?? null },
    index,
    length,
    tare,
    loadWeight,
    effectiveBrakedWeight,
    nbAxles,
    ateFileNumber,
    gbGauge,
    charge,
    nbUtiContainer,
    nbUtiTrailer,
    nbUtiVehicle,
    nbUtiOther,
    manualEvp,
    noUti,
    doubleWagon,
    hazardousMaterials: hazardousMaterials.map(({ hazardousMaterial, weight, volume }) => ({
      hazardousMaterial: {
        id: hazardousMaterial?.id ?? null,
        dangerIdentificationNumber: hazardousMaterial?.dangerIdentificationNumber ?? null,
        unitedNationsCode: hazardousMaterial?.unitedNationsCode ?? null,
        packingGroup: hazardousMaterial?.packingGroup ?? null,
        candidates: hazardousMaterial?.label ?? null,
        rid: hazardousMaterial?.rid ?? null,
      },
      weight,
      volume,
    })),
  };
};

const renderHazmats = ({ fields, wagon, roles, dispatch }: HazMatProps & WrappedFieldArrayProps) => (
  <div className="hazmats">
    {fields.map((fieldName, index) => (
      <HazmatEdit key={fieldName} fields={fields} index={index} name={fieldName} wagon={wagon} />
    ))}
    {roles.includes(COMPOSITION_WRITE) && !VehicleUtils.isStatusValidated(wagon) && (
      <button
        type="button"
        className="btn btn-link btn-icon plus"
        onClick={() => {
          dispatch(addHazmats(wagon));
          fields.push({ hazardousMaterial: {} });
        }}
      >
        Ajouter une matière dangereuse
      </button>
    )}
  </div>
);

const WagonForm = ({ wagon, step: { combinedMarket, vehicles } }: WagonFormProps) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectSecurityContextRoles);
  const nbWagons = VehiclesUtils.getNbWagon(vehicles);

  const ateCache = useMemo(
    () =>
      new AteCache(
        vehicles
          .filter((vehicle) => isWagon(vehicle) && vehicle.ateFileNumber)
          .map((wagon) => (wagon as Wagon).ateFileNumber!),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wagon.id],
  );

  return (
    <form className="fields" autoComplete="off">
      <VehicleField<WagonTemplate>
        name="registration"
        labelText="Immatriculation *"
        placeholder="Ex: 12 34 567 8901-2"
        format={(value) => value && Mask.REGISTRATION.format(value)}
        numeric={true}
        normalize={normalizeRegistration}
        autocompleter={wagonCache}
        allowManualValues={true}
        normalizeOnSave={normalizeRegistration}
        saveAction={(data: Wagon, propertyToUpdate) => (dispatchFn: AppDispatch) => {
          const updateCommand = { ...propertyToUpdate, status: null };
          if (updateCommand.ownerId) {
            updateCommand.owner = {
              id: updateCommand.ownerId,
              name: updateCommand.ownerName,
            };
            dispatch(change(wagonFormName, 'owner.name', updateCommand.ownerName));
          } else {
            updateCommand.owner = null;
            dispatch(change(wagonFormName, 'owner.name', ''));
          }
          dispatchFn(updateWagon(data, updateCommand));
        }}
        data={wagon}
      />
      <OwnerField<typeof VehicleField>
        className="icon star"
        component={VehicleField}
        data={wagon}
        labelText="Détenteur *"
        name="owner.name"
        placeholder="Détenteur *"
        saveAction={updateWagon}
        wagonRegistration={wagon.registration}
      />
      <VehicleField
        name="index"
        labelText="Position dans le train *"
        unit={'/' + nbWagons}
        numeric={true}
        normalize={normalizePositiveNumber}
        parse={parseToInt}
        saveAction={updateWagon}
        data={wagon}
      />
      <VehicleField
        name="length"
        labelText="Longueur *"
        unit="m"
        format={formatDecimalNumber}
        numeric={true}
        normalize={normalizePositiveFloatTwoDecimal}
        normalizeOnSave={parseToFloat}
        saveAction={updateWagon}
        data={wagon}
      />
      <VehicleField
        name="tare"
        labelText="Tare *"
        unit="kg"
        format={formatDecimalAndSeparateThousands}
        numeric={true}
        normalize={normalizePositiveNumber}
        normalizeOnSave={parseToInt}
        saveAction={updateWagon}
        data={wagon}
      />
      <WagonLoadWeight wagon={wagon} combinedMarket={combinedMarket} />
      <DisabledInput
        name="calculatedTotalWeight"
        labelText="Masse totale"
        placeholder=""
        unit="kg"
        value={formatDecimalAndSeparateThousands(VehicleUtils.totalWeight(wagon))}
      />
      <VehicleField
        name="effectiveBrakedWeight"
        labelText="Masse freinée réalisée *"
        unit="t"
        format={formatDecimalNumber}
        numeric={true}
        normalize={normalizePositiveFloatTwoDecimal}
        normalizeOnSave={parseToFloat}
        saveAction={updateWagon}
        data={wagon}
      />
      <VehicleField
        name="nbAxles"
        labelText="Nombre d’essieux *"
        numeric={true}
        normalize={normalizePositiveNumber}
        parse={parseToInt}
        saveAction={updateWagon}
        data={wagon}
      />
      <VehicleField
        name="ateFileNumber"
        labelText="ATE"
        placeholder="Facultatif"
        autocompleter={ateCache}
        allowManualValues={true}
        saveAction={updateWagon}
        data={wagon}
      />
      <div className="input input-checkboxes">
        <VehicleField
          component={CheckboxFormComponent}
          name="doubleWagon"
          label="Wagon double"
          saveAction={updateWagon}
          data={wagon}
        />
        <VehicleField
          component={CheckboxFormComponent}
          name="gbGauge"
          label="Gabarit GB"
          saveAction={updateWagon}
          data={wagon}
        />
      </div>
      <VehicleField
        component={Switch3}
        getError={validateWagonCharge}
        name="charge"
        labelText="Charge *"
        labelLeft="C"
        labelRight="D"
        saveAction={updateWagon}
        data={wagon}
      />
      <div className="input" />
      {combinedMarket && <UtiFormFields readOnly={isFieldReadOnly(wagon, roles)} wagon={wagon} />}
      <FieldArray name="hazardousMaterials" component={renderHazmats} wagon={wagon} roles={roles} dispatch={dispatch} />
    </form>
  );
};

export default reduxForm<WagonFormValues, WagonFormProps>({
  form: wagonFormName,
  validate: (formValues: WagonFormValues, { step }: WagonFormProps) =>
    validateWagon(formValues, step.combinedMarket, VehiclesUtils.getNbWagon(step.vehicles)),
  ...asyncValidation({
    'owner.name': asyncValidateOwner,
  }),
})(WagonForm);
