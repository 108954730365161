import React, { useEffect } from 'react';
import UsersTable from './users-table/UsersTable';
import UserDetails from './user-details/UserDetails';
import { sortUsers, startGetUsers } from './usersDucks';
import { createUser, loadUser } from './userDucks';
import { getUsers, selectOverlay, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { USER_WRITE } from '../../commons/security/userRoles';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { useViewportWidth } from '../../commons/responsive/hooks';
import classNames from 'classnames';
import { usePageTitle } from '../../commons/components/hooks';
import { User } from '../../commons/model/userProfile';
import { Sort } from '../../commons/model/sort';
import { startGetProfiles } from '../users-profiles/profilesDucks';

const Users = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectSecurityContextRoles);
  const users = useAppSelector(getUsers);
  const overlay = useAppSelector(selectOverlay);
  const { isMobile } = useViewportWidth();

  usePageTitle('Utilisateurs');

  useEffect(() => {
    dispatch(startGetUsers());
    dispatch(startGetProfiles());
  }, [dispatch]);

  const handleLoadUsers = (user: User) => dispatch(loadUser(user));

  const handleCreateUser = () => dispatch(createUser());

  const handleSortUsers = (sort: Sort<User>) => dispatch(sortUsers(sort));

  return (
    <div className="content-parent table-page">
      <div className="topbar">
        <h1 className="title">Utilisateurs</h1>
        {users.loading && <div className="loader" />}
        {roles.includes(USER_WRITE) && (
          <button
            className={classNames('btn btn-success', isMobile && 'btn-round plus')}
            onClick={handleCreateUser}
            type="button"
          >
            Ajouter
          </button>
        )}
      </div>
      <UsersTable
        users={users}
        onRowClick={handleLoadUsers}
        onSortClick={handleSortUsers}
        readOnly={!roles.includes(USER_WRITE)}
      />
      {overlay.active === 'user' && <UserDetails />}
    </div>
  );
};

export default Users;
