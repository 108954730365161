import React from 'react';
import Checkbox from '../checkbox/Checkbox';
import { WrappedFieldProps } from 'redux-form';
import { useAppSelector } from '../../store/hooks';
import { getProfiles } from '../../selectors/selectors';
import { Profile } from '../../model/userProfile';

type ProfileCheckboxesProps = {
  labelText: string;
};

const ProfileCheckboxes = ({
  labelText,
  input: { value, onChange },
  meta,
}: ProfileCheckboxesProps & WrappedFieldProps) => {
  const profiles: Profile[] = value;

  const availableProfiles = useAppSelector(getProfiles).data;

  const hasProfile = (profile: Profile) => profiles.some((up) => up.id === profile.id);

  const removeProfile = (profile: Profile) => {
    const newProfiles = profiles.filter((p) => p.id !== profile.id);
    onChange(newProfiles);
  };

  const addProfile = (profile: Profile) => {
    const newProfiles = [...profiles];
    if (newProfiles.every((p) => p.id !== profile.id)) {
      newProfiles.push({ ...profile });
    }
    onChange(newProfiles);
  };

  return (
    <div className="checkboxes">
      <div className="label">{labelText}</div>
      {availableProfiles.map((profile) => {
        const checked = hasProfile(profile);
        return (
          <Checkbox
            key={`profile-${profile.id}`}
            on={checked}
            label={profile.name}
            onClick={() => {
              if (checked) {
                removeProfile(profile);
              } else {
                addProfile(profile);
              }
            }}
          />
        );
      })}
      {meta?.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
    </div>
  );
};

export default ProfileCheckboxes;
