import offlineUtils from '../offline/offlineUtils';
import {
  DamageTemplate,
  EngineSeries,
  EngineTemplate,
  HazardousMaterial,
  Market,
  Owner,
  RailwayCompany,
  Station,
  WagonTemplate,
} from '../model/templates';
import marketCache from './marketCache';
import stationCache from './stationCache';
import engineSeriesCache from './engineSeriesCache';
import engineCache from './engineCache';
import wagonCache from './wagonCache';
import hazmatCache from './hazmatCache';
import ateCache from './ateCache';
import damageCache from './damageCache';
import ownerCache from './ownerCache';
import railwayCompanyCache from './railwayCompanyCache';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiHelper from '../../api/apiHelper';

type Templates = {
  series: EngineSeries[];
  engines: EngineTemplate[];
  wagons: WagonTemplate[];
  hazmats: HazardousMaterial[];
  stations: Station[];
  markets: Market[];
  ates: string[];
  owners: Owner[];
  damages: DamageTemplate[];
  companies: RailwayCompany[];
};

export type TemplatesState = {
  loading: boolean;
  error: boolean;
};

const initialState: TemplatesState = {
  loading: false,
  error: false,
};

// Workaround flag to avoid clearing the wagons cache, which locks Firefox for 25 seconds on each page reload
const clearWagonsCache = (localStorage.getItem('clearWagonsCache') ?? 'true') === 'true';

export const loadTemplates = createAsyncThunk<void>('templates/load', async () => {
  if (!offlineUtils.isOnline()) {
    // Don't try to load the templates from the server if offline
    return;
  }

  try {
    const data: Templates = await apiHelper.get('/api/templates');
    await marketCache.saveItems(data.markets);
    await engineSeriesCache.saveItems(data.series);
    await engineCache.saveItems(data.engines);
    await ateCache.saveItems(data.ates);
    await damageCache.saveItems(data.damages);
    await ownerCache.saveItems(data.owners);
    await railwayCompanyCache.saveItems(data.companies);
    await hazmatCache.saveItems(data.hazmats);
    await stationCache.saveItems(data.stations);
    await wagonCache.saveItems(data.wagons, { clear: clearWagonsCache });
  } catch (e) {
    if (offlineUtils.isOnline()) {
      throw e;
    } else {
      // Connection lost - ignore the error
    }
  }
});

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTemplates.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loadTemplates.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(loadTemplates.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
export default templatesSlice.reducer;
