import { useEffect, useState } from 'react';

const tabletMinWidth = 744;
const desktopMinWidth = 1024;

export const useViewportWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return {
    isMobile: width < tabletMinWidth,
    isTablet: width >= tabletMinWidth && width < desktopMinWidth,
    isDesktop: width >= desktopMinWidth,
  };
};
