import { getFormValues, SubmissionError } from 'redux-form';
import { hideOverlay, showOverlay } from '../../commons/components/overlay/overlayDucks';
import { startToast } from '../../commons/components/toast/toastDucks';
import { VEHICLES_FILTERS_FORM } from './vehicles-tobpar/vehiclesTopBarUtils';
import apiHelper from '../../api/apiHelper';
import { sortVehicles } from './vehiclesLibraryUtils';
import wagonCache from '../../commons/templates/wagonCache';
import engineCache from '../../commons/templates/engineCache';
import { IdRegistration, VehicleTemplate, WagonTemplate } from '../../commons/model/templates';
import { createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { VehiclesFilterFormData } from './vehicles-tobpar/VehiclesTopbar';
import { AppDispatch } from '../../commons/store/store';
import { ConfirmModalFormValues } from '../../commons/components/modal/confirmModalDucks';
import { RootState } from '../../commons/reducers/rootReducer';

type VehicleLibraryState = {
  libraryCollection: VehicleTemplate[];
  error: boolean;
  loading: boolean;
  editedWagon: WagonTemplate | null;
};

const initialState: VehicleLibraryState = {
  libraryCollection: [],
  error: false,
  loading: false,
  editedWagon: null,
};

export const loadLibrary = createAsyncThunk<
  VehicleTemplate[],
  void,
  {
    state: RootState;
  }
>('vehiclesLibrary/load', async (_, { getState }) => {
  const { showEngines, showWagons, registration, owners, onlyWagonsToVerify, manager } = getFormValues(
    VEHICLES_FILTERS_FORM,
  )(getState()) as VehiclesFilterFormData;

  let vehicleLibrary: VehicleTemplate[] = [];

  if (showEngines) {
    vehicleLibrary = (await engineCache.findAllItems()).map((engine) => ({ ...engine, wagon: false }));
  }
  if (showWagons) {
    vehicleLibrary = vehicleLibrary.concat(
      (await wagonCache.findAllItems()).map((wagon) => ({ ...wagon, wagon: true })),
    );
  }

  if (owners && owners.length !== 0) {
    vehicleLibrary = vehicleLibrary.filter(
      (vehicle) => vehicle.wagon && vehicle.ownerId && owners.map((o) => o.id).includes(vehicle.ownerId),
    );
  }

  if (registration && registration !== '') {
    vehicleLibrary = vehicleLibrary.filter(
      (vehicle) =>
        vehicle.registration &&
        (vehicle.registration.startsWith(registration) || vehicle.registration.endsWith(registration)),
    );
  }

  if (onlyWagonsToVerify) {
    vehicleLibrary = vehicleLibrary.filter((vehicle) => vehicle.wagon && vehicle.status === 'TO_REVIEW');
  }
  if (manager && manager !== 'NONE' && manager !== 'ALL') {
    vehicleLibrary = vehicleLibrary.filter((vehicle) => vehicle.wagon && vehicle.manager === manager);
  }
  if (manager === 'NONE') {
    vehicleLibrary = vehicleLibrary.filter((vehicle) => vehicle.wagon && !vehicle.manager);
  }
  vehicleLibrary = sortVehicles(vehicleLibrary);
  return vehicleLibrary;
});

export const updateLibraryWagon = createAsyncThunk<
  WagonTemplate,
  WagonTemplate,
  {
    dispatch: AppDispatch;
  }
>('vehiclesLibrary/wagon/update', async (updatedWagon, { dispatch }) => {
  try {
    const savedTemplate: WagonTemplate = await apiHelper.put(`/api/wagon-templates/${updatedWagon.id}`, updatedWagon);
    await wagonCache.addOrUpdateTemplatesFromLibrary([savedTemplate]);
    dispatch(loadLibrary());
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'Le modèle de wagon a été modifié', className: 'success' }));
    return savedTemplate;
  } catch (error: any) {
    console.error(error);
    if (error.httpStatus === 400) {
      dispatch(startToast({ text: 'Vérifiez votre saisie.', className: 'error' }));
      // Display a validation error if any
      throw new SubmissionError(error.errors);
    } else {
      dispatch(startToast({ text: "Erreur : Le modèle de wagon n'a pas été modifié.", className: 'error' }));
    }
    throw error;
  }
});

export const createLibraryWagon = createAsyncThunk<
  WagonTemplate,
  WagonTemplate,
  {
    dispatch: AppDispatch;
  }
>('vehiclesLibrary/wagon/create', async (createdWagon, { dispatch, rejectWithValue }) => {
  try {
    const savedTemplate: WagonTemplate = await apiHelper.post('/api/wagon-templates', {
      ...createdWagon,
      wagon: true,
    });
    await wagonCache.addOrUpdateTemplatesFromLibrary([savedTemplate]);
    dispatch(loadLibrary());
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'Le modèle de wagon a été créé', className: 'success' }));
    return { ...createdWagon, id: savedTemplate.id };
  } catch (error: any) {
    if (error.httpStatus === 400) {
      dispatch(startToast({ text: "Erreur lors de l'enregistrement du wagon.", className: 'error' }));
      return rejectWithValue(error.errors);
    } else {
      dispatch(startToast({ text: "Erreur : le modèle de wagon n'a pas été créé.", className: 'error' }));
      throw error;
    }
  }
});

export const deleteLibraryWagon =
  (deletedWagon: WagonTemplate) => (_: ConfirmModalFormValues<any>) => async (dispatch: AppDispatch) => {
    try {
      await apiHelper.del(`/api/wagon-templates/${deletedWagon.id}`);
      await wagonCache.deleteTemplates([deletedWagon]);
      dispatch(loadLibrary());
      dispatch(hideOverlay());
      dispatch(startToast({ text: 'Le modèle de wagon a été supprimé.', className: 'success' }));
    } catch (e) {
      dispatch(startToast({ text: "Erreur : le modèle de wagon n'a pas été supprimé.", className: 'error' }));
      throw e;
    }
  };

export const reviewWagonTemplates = createAsyncThunk<
  void,
  IdRegistration[],
  {
    dispatch: AppDispatch;
  }
>('vehiclesLibrary/wagon/review', async (wagonTemplates, { dispatch }) => {
  try {
    await apiHelper.post(
      '/api/wagon-templates/review',
      wagonTemplates.map(({ id }) => id),
    );
    dispatch(startToast({ text: 'Les wagons ont été marqués comme vérifiés.', className: 'success' }));
    await wagonCache.reviewTemplates(wagonTemplates.map(({ registration }) => registration));
    dispatch(loadLibrary());
  } catch (e) {
    dispatch(startToast({ text: "Erreur: les wagons n'ont pas été marqués comme vérifiés.", className: 'error' }));
    throw e;
  }
});

export const vehiclesLibrarySlice = createSlice({
  name: 'vehiclesLibrary',
  initialState,
  reducers: {
    resetLibrary(state) {
      state.error = false;
      state.loading = false;
      state.editedWagon = null;
      state.libraryCollection = [];
    },
    startEditWagon(state, action: PayloadAction<WagonTemplate | null>) {
      state.editedWagon = action.payload;
    },
    cancelEditWagon(state) {
      state.editedWagon = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLibrary.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.editedWagon = null;
      })
      .addCase(loadLibrary.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(loadLibrary.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        state.libraryCollection = action.payload;
      })
      .addCase(updateLibraryWagon.fulfilled, (state, action) => {
        state.editedWagon = null;
        const editedWagon = state.libraryCollection.find(
          (vehicle) => vehicle.wagon && vehicle.id === action.payload.id,
        ) as Draft<WagonTemplate>;
        editedWagon.registration = action.payload.registration;
        editedWagon.tare = Number(action.payload.tare);
        editedWagon.length = Number(action.payload.length);
        editedWagon.nbAxles = action.payload.nbAxles;
        editedWagon.ownerId = action.payload.ownerId;
        editedWagon.ownerName = action.payload.ownerName;
      });
  },
});

export const { resetLibrary, startEditWagon, cancelEditWagon } = vehiclesLibrarySlice.actions;
export default vehiclesLibrarySlice.reducer;

// Delegating action creators

export const addLibraryWagon = () => (dispatch: AppDispatch) => {
  dispatch(showOverlay('vehicle-library'));
  dispatch(startEditWagon(null));
};
