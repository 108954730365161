import { createTransform } from 'redux-persist';

const mapToObj = (map) => {
  if (!(map instanceof Map)) {
    return {};
  }
  const obj = {};
  for (const [k, v] of map) {
    obj[k] = v;
  }
  return obj;
};
const objToMap = (obj) => {
  if (!obj) {
    return new Map();
  }
  const map = new Map();
  for (const k of Object.keys(obj)) {
    map.set(k, obj[k]);
  }
  return map;
};

/**
 * Transform the "cache" key from Map to object to persist, and from object to Map to restore.
 */
export const cacheTransform = createTransform(
  (inboundState, key) => ({ ...inboundState, cache: mapToObj(inboundState.cache) }),
  (outboundState, key) => ({ ...outboundState, cache: objToMap(outboundState.cache) }),
  { whitelist: ['cache'] },
);
