// CONSTANTS
import { getFormValues, initialize, reset } from 'redux-form';
import { formName as wagonFormName } from '../preAdviceWagonFormUtils';
import { formName } from './copyJourneyFormUtils';
import { getCopyJourneyState, getPreAdvice } from '../../../commons/selectors/selectors';
import { successUpdateWagons } from '../../pre-advice/preAdviceDucks';
import { startToast } from '../../../commons/components/toast/toastDucks';
import apiHelper from '../../../api/apiHelper';

const OPEN_COPY_JOURNEY_DIALOG = 'OPEN_COPY_JOURNEY_DIALOG';
const CLOSE_COPY_JOURNEY_DIALOG = 'CLOSE_COPY_JOURNEY_DIALOG';

// REDUCER
const initialState = {
  open: false,
  nbWagons: null,
  startStation: null,
  endStation: null,
  sender: null,
  addressee: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COPY_JOURNEY_DIALOG:
      return { ...state, open: true, ...action.payload };
    case CLOSE_COPY_JOURNEY_DIALOG:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

// ACTIONS
export const openCopyJourneyDialog = (wagon) => (dispatch, getState) => {
  const state = getState();
  const {
    journey: { startStation, endStation, sender, addressee },
  } = getFormValues(wagonFormName)(state);
  const preAdvice = getPreAdvice(state);
  dispatch({
    type: OPEN_COPY_JOURNEY_DIALOG,
    payload: {
      nbWagons: 0,
      startStation,
      endStation,
      sender,
      addressee,
    },
  });
  dispatch(initialize(formName, { fromWagon: wagon.position + 1, toWagon: preAdvice.getNbWagons() }));
};

export const closeCopyJourneyDialog = () => (dispatch) => {
  dispatch({
    type: CLOSE_COPY_JOURNEY_DIALOG,
  });
  dispatch(reset(formName));
};

export const confirmCopyJourney =
  ({ fromWagon, toWagon }) =>
  async (dispatch, getState) => {
    const state = getState();
    const preAdvice = getPreAdvice(getState());
    const { startStation, endStation, sender, addressee } = getCopyJourneyState(state);

    try {
      const serverWagons = await apiHelper.post(`/api/pre-advices/${preAdvice.id}/copy-journey`, {
        fromWagon,
        toWagon,
        startStationId: startStation?.id,
        endStationId: endStation?.id,
        sender,
        addressee,
      });
      dispatch(successUpdateWagons(serverWagons));
      dispatch(closeCopyJourneyDialog());
      dispatch(startToast({ text: `Trajet propagé sur ${serverWagons.length} wagons.`, className: 'success' }));
    } catch {
      dispatch(startToast({ text: 'Erreur : trajet non propagé.', className: 'error' }));
    }
  };
