import { INIT_START, INIT_SUCCESS_OFFLINE, INIT_SUCCESS_ONLINE, OFFLINE_CHANGE } from './appStateConstants';
import { AnyAction } from 'redux';

// Reducer is not located in Ducks file to avoid cyclic dependencies in index.js file

export type AppState = {
  initialized: boolean;
  offline: boolean;
};

const initialAppState: AppState = {
  initialized: false,
  offline: false,
};

// REDUCER
export default (state = initialAppState, action: AnyAction) => {
  switch (action.type) {
    case INIT_START: {
      return { ...state };
    }
    case INIT_SUCCESS_ONLINE: {
      return { ...state, offline: action.payload.offline, initialized: true };
    }
    case INIT_SUCCESS_OFFLINE: {
      return { ...state, offline: action.payload.offline, initialized: true };
    }
    case OFFLINE_CHANGE: {
      return { ...state, offline: action.payload.offline };
    }
    default:
      return state;
  }
};
