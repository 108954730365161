import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCopyJourneyDialog, confirmCopyJourney } from './copyJourneyDucks';
import { Field, reduxForm } from 'redux-form';
import { formName } from './copyJourneyFormUtils';
import Input from '../../../commons/components/input/Input';
import { parseToInt } from '../../../commons/redux-form/valueParser';
import { normalizePositiveNumber } from '../../../commons/redux-form/valueNormalizer';
import { getCopyJourneyState } from '../../../commons/selectors/selectors';

const validateFromWagon = (fromWagon, { toWagon }) => {
  if (!Number.isInteger(fromWagon)) {
    return 'Champ obligatoire';
  }
  if (fromWagon < 1) {
    return 'Minimum 1';
  }
  if (fromWagon > toWagon) {
    return 'Le premier wagon ne peut pas être après le dernier wagon';
  }
};

const validateToWagon = (toWagon, { fromWagon }) => {
  if (!Number.isInteger(toWagon)) {
    return 'Champ obligatoire';
  }
  if (toWagon < 1) {
    return 'Minimum 1';
  }
  if (fromWagon > toWagon) {
    return 'Le dernier wagon ne peut pas être avant le premier wagon';
  }
};

const CopyJourneyModal = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { open, startStation, endStation, sender, addressee } = useSelector(getCopyJourneyState);
  if (!open) {
    return null;
  }
  const onClose = () => dispatch(closeCopyJourneyDialog());
  return (
    <form onSubmit={handleSubmit((values) => dispatch(confirmCopyJourney(values)))} autoComplete="off">
      <div className="modal large">
        <div className="modal-fade" onClick={onClose} />
        <div className="modal-body">
          <div className="modal-title">Propager le trajet aux autres wagons ?</div>
          <div className="modal-content">
            <strong>Gare d'origine :</strong> {startStation?.label}
            <br />
            <strong>Gare de destination :</strong> {endStation?.label}
            <br />
            <strong>Expéditeur :</strong> {sender}
            <br />
            <strong>Destinataire :</strong> {addressee}
            <br />
          </div>
          <div className="columns">
            <Field
              name="fromWagon"
              component={Input}
              type="text"
              pattern="[0-9]*"
              parse={parseToInt}
              normalize={normalizePositiveNumber}
              maxLength={2}
              className="right"
              labelText="Du wagon"
              validate={validateFromWagon}
            />
            <Field
              name="toWagon"
              component={Input}
              type="text"
              autoFocus={true}
              pattern="[0-9]*"
              parse={parseToInt}
              normalize={normalizePositiveNumber}
              maxLength={2}
              className="right"
              labelText="Au wagon"
              validate={validateToWagon}
            />
          </div>
          <button type="submit" className="modal-btn success">
            Propager
          </button>
          <button type="button" className="modal-btn modal-btn-cancel" onClick={onClose}>
            Annuler
          </button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: formName,
})(CopyJourneyModal);
