import React from 'react';
import Tooltip from 'rc-tooltip';

import './fieldLabelWithTooltipStyles.scss';

const FieldLabelWithTooltip = ({ label, tooltipText }) => (
  <span>
    {tooltipText && (
      <Tooltip placement="top" trigger={['hover', 'click']} overlay={tooltipText}>
        <span className="info-pin">i</span>
      </Tooltip>
    )}
    {label}
  </span>
);

export default FieldLabelWithTooltip;
