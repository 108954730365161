import { REHYDRATE } from 'redux-persist';
import { getFormValues, initialize } from 'redux-form';
import offlineUtils from '../../commons/offline/offlineUtils';
import DamageReportsDataCache from './damageReportsDataCache';
import { getDamageReports, getPersist, selectSecurityContext } from '../../commons/selectors/selectors';
import { mergeDamageReport, updateDamageReportsFromOfflineChanges } from '../../commons/cache/cacheSynchronizer';
import {
  DAMAGE_REPORTS_PERSIST_FAILURE,
  DAMAGE_REPORTS_PERSIST_SCHEDULE,
  DAMAGE_REPORTS_PERSIST_START,
  DAMAGE_REPORTS_PERSIST_SUCCESS,
} from '../../persist/damageReportPersistActions';
import {
  damageReportsFiltersToFormInitialValues,
  damageReportsFiltersToParams,
  damageReportsFormToFilters,
  defaultDamageReportsFilters,
} from '../../commons/filters/filters';
import { ADD_OR_UPDATE_DAMAGE_REPORT_IN_CACHE, REMOVE_DAMAGE_REPORT_FROM_CACHE } from './damageReportsCacheActions';
import apiHelper from '../../api/apiHelper';
import marketCache from '../../commons/templates/marketCache';
import ownerCache from '../../commons/templates/ownerCache';
import { SortDirection } from '../../commons/model/sort';
import debugFactory from 'debug';

const debug = debugFactory('damageReportsDucks');

// Constants
const LOAD_DAMAGE_REPORTS_START = 'LOAD_DAMAGE_REPORTS_START';
const LOAD_DAMAGE_REPORTS_SUCCESS = 'LOAD_DAMAGE_REPORTS_SUCCESS';
const LOAD_DAMAGE_REPORTS_FAILURE = 'LOAD_DAMAGE_REPORTS_FAILURE';
const LOAD_DAMAGE_REPORTS_OFFLINE = 'LOAD_DAMAGE_REPORTS_OFFLINE';

const DAMAGE_REPORTS_FILTER_CHANGE = 'DAMAGE_REPORTS_FILTER_CHANGE';

const DAMAGE_REPORTS_UPDATE_COLUMN_SORTING = 'DAMAGE_REPORTS_UPDATE_COLUMN_SORTING';

const defaultSort = { field: 'number', direction: SortDirection.DESC };

const initialState = {
  data: [], // all items in the cache
  loading: false,
  error: false,
  cache: new DamageReportsDataCache(), // damageReport cache
  sortDefinition: defaultSort,
  filters: defaultDamageReportsFilters([], 'CTF'),
};

const updateOfflineState = (state, damageReportsUpdated) => {
  if (damageReportsUpdated && damageReportsUpdated.length > 0) {
    const cache = updateDamageReportsFromOfflineChanges(state.cache, damageReportsUpdated);

    return {
      ...state,
      data: cache.loadFromCache(),
      cache,
    };
  }
  return state;
};

const persistSuccessCase = (state, action) => {
  const { cache } = action.payload;
  return {
    ...state,
    data: cache.loadFromCache(),
    cache,
  };
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DAMAGE_REPORTS_START: {
      const data = state.cache.loadFromCache();
      return {
        ...state,
        data,
        loading: true,
        error: false,
      };
    }
    case LOAD_DAMAGE_REPORTS_SUCCESS: {
      const damageReports = action.payload;
      const damageReportsToUpdate = damageReports.map((damageReport) =>
        mergeDamageReport(state.cache.loadByKeyFromCache(damageReport.id), damageReport),
      );
      const cache = state.cache.createNewCache(damageReportsToUpdate);
      return {
        ...state,
        data: cache.loadFromCache(),
        cache,
        loading: false,
        error: false,
      };
    }
    case LOAD_DAMAGE_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case LOAD_DAMAGE_REPORTS_OFFLINE:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case DAMAGE_REPORTS_PERSIST_SCHEDULE:
    case DAMAGE_REPORTS_PERSIST_START:
    case DAMAGE_REPORTS_PERSIST_FAILURE: {
      return updateOfflineState(state, action.payload.damageReportsUpdated);
    }
    case DAMAGE_REPORTS_PERSIST_SUCCESS: {
      return persistSuccessCase(state, action);
    }
    case DAMAGE_REPORTS_FILTER_CHANGE: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case DAMAGE_REPORTS_UPDATE_COLUMN_SORTING: {
      return {
        ...state,
        sortDefinition: action.payload,
      };
    }
    case ADD_OR_UPDATE_DAMAGE_REPORT_IN_CACHE: {
      const cache = state.cache.createUpdatedCache(action.payload);
      return {
        ...state,
        cache,
      };
    }
    case REMOVE_DAMAGE_REPORT_FROM_CACHE: {
      const cache = state.cache.createUpdatedCacheWithout(action.payload);
      return {
        ...state,
        cache,
      };
    }
    case REHYDRATE: {
      if (action.key === 'damageReports') {
        const cache = new DamageReportsDataCache();
        cache.restoreFromStorage(action.payload?.cache);

        if (action.payload?.filters) {
          let filters = action.payload?.filters;

          if (!Object.hasOwn(action.payload?.filters, 'onlyMine')) {
            // update the filter part of an old cache stored before the new filter "onlyMine" is added
            filters = { ...filters, onlyMine: true };
          }

          if (!Object.hasOwn(action.payload?.filters, 'company')) {
            // update the filter part of an old cache stored before the new filter "company" is added
            filters = { ...filters, company: 'CTF' };
          }

          return {
            ...state,
            cache,
            filters,
          };
        }

        return {
          ...state,
          cache,
          filters: initialState.filters,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

// Actions
export const loadDamageReportsOffline = () => ({
  type: LOAD_DAMAGE_REPORTS_OFFLINE,
});

export const loadDamageReportsFailure = () => ({
  type: LOAD_DAMAGE_REPORTS_FAILURE,
});

export const loadDamageReportsSuccess = (data) => ({
  type: LOAD_DAMAGE_REPORTS_SUCCESS,
  payload: data,
});

export const loadDamageReports = () => async (dispatch, getState) => {
  const state = getState();
  if (getPersist(state).savingDamageReports) {
    debug("Don't load again during a saving");
    return;
  }
  dispatch({ type: LOAD_DAMAGE_REPORTS_START });

  if (!offlineUtils.isOnline()) {
    // Don't try to load the damageReports if offline
    dispatch(loadDamageReportsOffline());
    return null;
  }
  const { filters } = getDamageReports(getState());
  try {
    const damageReports = await apiHelper.get('/api/damageReports', damageReportsFiltersToParams(filters));
    dispatch(loadDamageReportsSuccess(damageReports));
  } catch {
    if (offlineUtils.isOnline()) {
      dispatch(loadDamageReportsFailure());
    } else {
      // Connection lost
      dispatch(loadDamageReportsOffline());
    }
  }
};

export const initializeDamageReportsFilters = () => (dispatch, getState) => {
  const currentUser = selectSecurityContext(getState());

  dispatch({
    type: DAMAGE_REPORTS_FILTER_CHANGE,
    payload: defaultDamageReportsFilters(currentUser.roles, currentUser.company),
  });
};

export const resetDamageReportsFilters = () => (dispatch, getState) => {
  dispatch(initializeDamageReportsFilters());

  const { filters } = getDamageReports(getState());
  dispatch(initialize('damageReportsFiltersForm', damageReportsFiltersToFormInitialValues(filters)));
  dispatch(loadDamageReports());
};

export const updateDamageReportsFilters = (_data, propertyToUpdate) => (dispatch, getState) => {
  const { filters } = getDamageReports(getState());
  dispatch({
    type: DAMAGE_REPORTS_FILTER_CHANGE,
    payload: damageReportsFormToFilters(filters, propertyToUpdate),
  });
  dispatch(loadDamageReports());
};

export const updateColumnSorting = (sort) => ({
  type: DAMAGE_REPORTS_UPDATE_COLUMN_SORTING,
  payload: sort,
});

export const initializeLazyDamageReportFilters = () => async (dispatch, getState) => {
  const state = getState();
  const currentUserCompany = selectSecurityContext(state).company;
  const { filters } = getDamageReports(state);
  const syncFormValues = getFormValues('damageReportsFiltersForm')(state);
  const asyncFormValues = {
    markets: await Promise.all(filters.marketIds.map((marketId) => marketCache.findItemById(marketId))),
    owners: await Promise.all(filters.ownerIds.map((ownerId) => ownerCache.findItemById(ownerId))),
  };
  dispatch(
    initialize('damageReportsFiltersForm', {
      ...syncFormValues,
      ...asyncFormValues,
      company: filters.company ?? currentUserCompany,
    }),
  );
};
