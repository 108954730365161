/**
 * Hazmat plate input component
 */
import React from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import HazardousMaterialPlateInput from './HazardousMaterialPlateInput';
import Dropdown from '../dropdown/Dropdown';
import ErrorField from '../fields/ErrorField';
import './hazardousMaterialStyles.scss';
import { normalizeUpperCase } from '../../redux-form/valueNormalizer';
import hazmatCache from '../../templates/hazmatCache';
import { Wagon } from '../../model/Vehicle';
import { AppAction, AppDispatch } from '../../store/store';
import { HazardousMaterial, PACKING_GROUPS } from '../../model/templates';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../reducers/rootReducer';

type HazardousMaterialPlateProps = {
  fieldName: string;
  formName: string;
  saveAction: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
  setHazardousMaterialCandidates: (candidates: HazardousMaterial[]) => any;
  readOnly: boolean;
  wagon: Wagon;
};

const HazardousMaterialPlate = ({
  fieldName,
  formName,
  saveAction,
  setHazardousMaterialCandidates,
  wagon,
  readOnly,
}: HazardousMaterialPlateProps) => {
  const hazardousMaterial = useAppSelector((state) => formValueSelector(formName)(state, fieldName).hazardousMaterial);

  /*
   * When either the danger identifier, the United Nations code or the packing group
   * changes, a matching hazardous material template is searched.
   * Then:
   *   - Hazardous material templates candidates are updated ;
   *   - Data is persisted ;
   *   - Form fields are autofilled.
   */
  const autoCompleteHazardousMaterial =
    (w: Wagon, propertyToUpdate: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
      // Update form values
      dispatch(change(formName, Object.keys(propertyToUpdate)[0], Object.values(propertyToUpdate)[0]));
      const formHazardousMaterial = formValueSelector(formName)(getState(), fieldName).hazardousMaterial;

      const autocompleteValue = {
        dangerIdentificationNumber: formHazardousMaterial.dangerIdentificationNumber,
        unitedNationsCode: formHazardousMaterial.unitedNationsCode,
        packingGroup: formHazardousMaterial.packingGroup,
      };
      const results = await hazmatCache.autocomplete(autocompleteValue);

      // Update hazardous material template candidates
      setHazardousMaterialCandidates(results);

      if (results.length === 1) {
        // Persist hazardous material if there is only one match
        const hazardousMaterialToPersist = { ...results[0] };
        const persistCommand = { [`${fieldName}.hazardousMaterial`]: hazardousMaterialToPersist };
        dispatch(saveAction(w, persistCommand));

        // Autofill the other fields with the match
        dispatch(change(formName, `${fieldName}.hazardousMaterial`, hazardousMaterialToPersist));
      } else {
        // Reset the autofilled fields
        dispatch(
          change(formName, `${fieldName}.hazardousMaterial`, {
            ...formHazardousMaterial,
            id: null,
            label: null,
            rid: null,
            dangerLabels: [],
          }),
        );
      }
    };

  const getDangerIdPlaceholder = () => {
    if (hazardousMaterial?.id) {
      return '';
    }
    return 'N° Ident.';
  };

  return (
    <div className="col">
      <div className="hazmat-plate">
        <Field
          component={HazardousMaterialPlateInput}
          name={`${fieldName}.hazardousMaterial.dangerIdentificationNumber`}
          type="text"
          placeholder={getDangerIdPlaceholder()}
          inputProps={{
            pattern: 'X?[0-9.]{2,4}[A-Z]?',
            maxLength: 5,
            inputMode: 'numeric',
          }}
          format={normalizeUpperCase}
          normalize={normalizeUpperCase}
          autoCapitalize="characters"
          saveAction={autoCompleteHazardousMaterial}
          data={wagon}
          disabled={readOnly}
        />
        <Field
          component={HazardousMaterialPlateInput}
          name={`${fieldName}.hazardousMaterial.unitedNationsCode`}
          type="text"
          placeholder="N° ONU"
          inputProps={{
            pattern: '[0-9]{4}',
            maxLength: 4,
            inputMode: 'numeric',
          }}
          saveAction={autoCompleteHazardousMaterial}
          data={wagon}
          disabled={readOnly}
        />
        <Field component={ErrorField} name={`${fieldName}.hazardousMaterial.dangerIdentificationNumber`} />
        <Field component={ErrorField} name={`${fieldName}.hazardousMaterial.unitedNationsCode`} />
      </div>

      <div className="grouping hazmat-packing-group">
        <Field
          component={Dropdown}
          labelText="Groupe d'emballage *"
          name={`${fieldName}.hazardousMaterial.packingGroup`}
          options={PACKING_GROUPS}
          saveAction={autoCompleteHazardousMaterial}
          data={wagon}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default HazardousMaterialPlate;
