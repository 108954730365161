/**
 * Sidebar component
 */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import helpers, { APP_BUILD_DATE_TIME_FORMAT, SIDEBAR_DATE_TIME_FORMAT } from '../../helpers/helpers';
import { getOfflineState, getSidebar, selectSecurityContext } from '../../selectors/selectors';
import { collapseSidebar, expandSidebar, toggleSidebar } from './sidebarDucks';
import { logout } from '../../security/securityContextDucks';
import { openConfirmDialog } from '../modal/confirmModalDucks';
import {
  ADMIN_SENT_MESSAGES,
  COMPOSITION_READ,
  DAMAGE_REPORT_VALIDATE,
  DAMAGE_REPORT_WRITE,
  LIBRARY_READ,
  MAILING_LIST_READ,
  OWNER_READ,
  PRE_ADVICE_READ,
  PROFILE_READ,
  USER_READ,
} from '../../security/userRoles';
import './sidebarStyles.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useViewportWidth } from '../../responsive/hooks';

/**
 * Return user initials
 *
 * @param name and surname
 * @return initials
 */
const getInitials = (name: string): string => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const appVersion = process.env.REACT_APP_APP_VERSION || 'dev';
const appCommitId = process.env.REACT_APP_APP_COMMIT_ID || '';
const appTimestamp = process.env.REACT_APP_APP_TIMESTAMP || '';
const appBuildDate =
  appTimestamp && ' - ' + helpers.dateTimeFormat(parseInt(appTimestamp, 10), APP_BUILD_DATE_TIME_FORMAT);

const Sidebar = () => {
  const dispatch = useAppDispatch();

  const securityContext = useAppSelector(selectSecurityContext);
  const sidebar = useAppSelector(getSidebar);
  const offline = useAppSelector(getOfflineState);

  const { isMobile } = useViewportWidth();

  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleLogoutClick = () => {
    dispatch(openConfirmDialog({ title: 'Se déconnecter ?', actionText: 'Oui, se déconnecter', action: logout }));
  };

  return (
    <>
      {isMobile && !sidebar.hidden && (
        <>
          <div className="menu-button">
            <button type="button" className="btn btn-round menu" onClick={() => dispatch(expandSidebar())} />
          </div>
          <div
            className={classNames('sidebar-backdrop', { hidden: !isMobile || !sidebar.expanded })}
            onClick={() => dispatch(collapseSidebar())}
          />
        </>
      )}
      <div
        className={classNames('sidebar', {
          collapsed: !sidebar.expanded,
          hidden: sidebar.hidden || (isMobile && !sidebar.expanded),
          mobile: isMobile,
        })}
      >
        <div className="sidebar-inner">
          <div className="sidebar-btn-group">
            <button
              type="button"
              className={sidebar.expanded ? 'sidebar-btn sidebar-btn-collapse' : 'sidebar-btn sidebar-btn-expand'}
              onClick={() => dispatch(toggleSidebar())}
            >
              <span>iCompo</span>
            </button>
            <div className="sidebar-separator" />
            {securityContext.roles.includes(COMPOSITION_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-trains', isActive && 'on')}
                to="/trains"
              >
                <span>Trains</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(PRE_ADVICE_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-preadvice', isActive && 'on')}
                to="/pre-advices"
              >
                <span>Pré-annonces</span>
              </NavLink>
            )}
            {(securityContext.roles.includes(DAMAGE_REPORT_WRITE) ||
              securityContext.roles.includes(DAMAGE_REPORT_VALIDATE)) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-damage-reports', isActive && 'on')}
                to="/damage-reports"
              >
                <span>PVCA</span>
              </NavLink>
            )}
            <div className="sidebar-separator" />
            {!offline && securityContext.roles.includes(USER_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-users', isActive && 'on')}
                to="/admin/users"
              >
                <span>Utilisateurs</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(PROFILE_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-users-profiles', isActive && 'on')}
                to="/admin/profiles"
              >
                <span>Profils utilisateurs</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(LIBRARY_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-vehicles-library', isActive && 'on')}
                to="/admin/vehicles-library"
              >
                <span>Véhicules</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(OWNER_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-owners', isActive && 'on')}
                to="/admin/owners"
              >
                <span>Détenteurs</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(MAILING_LIST_READ) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-mailing', isActive && 'on')}
                to="/admin/mailing-lists"
              >
                <span>Listes de diffusion</span>
              </NavLink>
            )}
            {!offline && securityContext.roles.includes(ADMIN_SENT_MESSAGES) && (
              <NavLink
                className={({ isActive }) => classNames('sidebar-btn sidebar-btn-sent-messages', isActive && 'on')}
                to="/admin/sent-messages"
              >
                <span>Messages envoyés</span>
              </NavLink>
            )}
            {!offline && (
              <div>
                <div className="sidebar-separator" />
                <button type="button" className="sidebar-btn sidebar-btn-logout" onClick={handleLogoutClick}>
                  <span>Déconnexion</span>
                </button>
              </div>
            )}
          </div>
          <div className="sidebar-footer">
            <div className="sidebar-footer-initials">{getInitials(securityContext.username)}</div>
            <div className="sidebar-footer-user">
              <div className="sidebar-footer-username">{securityContext.username}</div>
              <div className="sidebar-footer-role">{securityContext.profiles}</div>
            </div>
            <div className="sidebar-footer-meta">
              <div className="footer-version" title={appCommitId + appBuildDate}>
                VER. {appVersion}
              </div>
              <div className="sidebar-footer-time">{helpers.dateTimeFormat(time, SIDEBAR_DATE_TIME_FORMAT)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
