import React from 'react';

const DamageReportAudit = ({ currentUser, damageReport, isFormDirty }) => {
  const { createdBy = {}, updatedBy = {} } = damageReport;
  const creator = createdBy.name ? createdBy.name : currentUser.username;
  const updator = updatedBy.name && !isFormDirty ? updatedBy.name : currentUser.username;
  return (
    <div>
      Créé par {creator}, modifié par {updator}
    </div>
  );
};

export default DamageReportAudit;
