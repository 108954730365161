import { VehicleTemplate } from '../../commons/model/templates';

const compareRegistrations = (vehicle1: VehicleTemplate, vehicle2: VehicleTemplate) => {
  const registration1 = vehicle1.registration ?? 'Z';
  const registration2 = vehicle2.registration ?? 'Z';
  return registration1.localeCompare(registration2);
};

const compareStatuses = (vehicle1: VehicleTemplate, vehicle2: VehicleTemplate) => {
  const status1 = (vehicle1.wagon && vehicle1.status) || 'REVIEWED';
  const status2 = (vehicle2.wagon && vehicle2.status) || 'REVIEWED';
  if (status1 === status2) {
    return compareRegistrations(vehicle1, vehicle2);
  }
  if (status1 === 'TO_REVIEW') {
    return -1;
  }
  return 1;
};

export const sortVehicles = (vehicles: VehicleTemplate[] = []) => {
  const copy = [...vehicles];
  copy.sort(compareStatuses);
  return copy;
};
