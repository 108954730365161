import { useEffect } from 'react';

const suffix = 'iCompo';

const setPageTitle = (title: string) => {
  document.title = title ? `${title} - ${suffix}` : suffix;
};

export const usePageTitle = (title: string) => {
  useEffect(() => {
    setPageTitle(title);
  }, [title]);
};
