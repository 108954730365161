/**
 * Input component
 */
import React, { HTMLAttributes, MouseEventHandler } from 'react';
import classNames from 'classnames';
import './checkboxStyles.scss';

export type CheckboxProps = {
  on: boolean;
  partial?: boolean;
  onClick?: MouseEventHandler;
  label?: string;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Checkbox = ({ disabled, on, partial, onClick, label, ...props }: CheckboxProps) => (
  <div
    {...props}
    className={classNames('checkbox', { on, partial }, disabled && 'disabled-checkbox')}
    onClick={onClick}
  >
    {label}
  </div>
);

export default Checkbox;
