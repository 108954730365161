import { StoreNames } from 'idb';

import { dbPromise, ICompoDBSchema } from '../db/db';
import helpers from '../helpers/helpers';
import { AbstractDbCache, Item, Key, Tx } from './AbstractDbCache';
import { Autocompleter } from './Autocompleter';

/**
 * A cache for a template type.
 *
 * @template Name The name of the store in IndexedDB.
 * @template T The actual type handled by this cache (ignoring the generated fields)
 * @template I The input type when autocompleting.
 */
abstract class AbstractTemplateCache<Name extends StoreNames<ICompoDBSchema>, T = Item<Name>, I = string>
  extends AbstractDbCache<Name, T>
  implements Autocompleter<T, I>
{
  protected abstract validateInput(inputValue: I): boolean;
  protected abstract findTemplates(normalizedInputValue: I, tx: Tx<Name>): Promise<T[]>;
  public abstract formatItem(item: T): string;

  protected getTemplateId(template: T): Key<Name> {
    return (template as any).id;
  }

  protected normalizeInput(inputValue: I): I {
    return helpers.trimBadSpaces(inputValue as unknown as string).toUpperCase() as unknown as I;
  }

  public autocompleteWithFilter =
    (filter?: (template: T) => boolean) =>
    async (inputValue: I | null): Promise<T[]> => {
      if (inputValue == null || !this.validateInput(inputValue)) {
        return [];
      }

      const db = await dbPromise;
      const tx = db.transaction(this.getStoreName());
      const res = await this.findTemplates(this.normalizeInput(inputValue), tx);
      await tx.done;
      if (filter) {
        return res.filter(filter);
      }
      return res;
    };

  public blacklistFilter = (blacklist: Key<Name>[]) => (template: T) =>
    !blacklist.includes(this.getTemplateId(template));

  public autocompleteWithBlacklist = (blacklist: Key<Name>[]) =>
    this.autocompleteWithFilter(this.blacklistFilter(blacklist));

  public autocomplete = this.autocompleteWithFilter();
}

export default AbstractTemplateCache;
