import { Sort } from './sort';

export const sortList = <T>(list: T[] | readonly T[], sort: Sort<T, keyof T>, key?: keyof T[keyof T]): T[] => {
  const normalizeValue = (value: any) => (value && typeof value === 'string' ? value.toLowerCase() : value);

  const sortedList = [...list];
  sortedList.sort((item1, item2) => {
    const field1 = item1[sort.field];
    const field2 = item2[sort.field];

    if (key) {
      if (!field1 && !field2) {
        return 0;
      }
      if (!field1 && field2) {
        return sort.direction;
      }
      if (field1 && !field2) {
        return -sort.direction;
      }
      if (field1[key] === field2[key]) {
        return 0;
      }
      return normalizeValue(field1[key]) > normalizeValue(field2[key]) ? sort.direction : -sort.direction;
    }
    // no key
    if (field1 === field2) {
      return 0;
    }
    return normalizeValue(field1) > normalizeValue(field2) ? sort.direction : -sort.direction;
  });

  return sortedList;
};
