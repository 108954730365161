/**
 * Station field component
 */
import React from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import Input, { InputProps } from './Input';
import helpers from '../../helpers/helpers';
import './inputStyles.scss';
import { Station } from '../../model/templates';
import stationCache from '../../templates/stationCache';

type StationFieldProps = BaseFieldProps & InputProps<Station>;

const StationField = ({ component, ...props }: StationFieldProps) => (
  <Field
    component={component ?? Input<Station>}
    id={`composition-${helpers.camelCaseToHyphen(props.name)}`}
    type="text"
    autocompleter={stationCache}
    {...props}
  />
);

export default StationField;
