/**
 * Users table administration component
 */
import React from 'react';
import classNames from 'classnames';
import GlobalError from '../../../commons/components/GlobalError';
import SortTableHeader from '../../../commons/components/table/SortTableHeader';
import './usersTableStyle.scss';
import { Market } from '../../../commons/model/templates';
import { UsersState } from '../usersDucks';
import { User, UserProfile } from '../../../commons/model/userProfile';
import { Sort } from '../../../commons/model/sort';
import { CompanyOptions } from '../../../commons/model/common';

type UserTableProps = {
  users: UsersState;
  onRowClick: (user: User) => void;
  onSortClick: (sort: Sort<User>) => void;
  readOnly: boolean;
};

const UsersTable = ({ users, onRowClick, onSortClick, readOnly }: UserTableProps) => {
  if (users.error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des utilisateurs.
      </GlobalError>
    );
  }

  if (!users.data.length) {
    return <div className="maincontent">{!users.loading && <span>Aucun utilisateur trouvé.</span>}</div>;
  }
  return (
    <div className="table table-users">
      <div className="table-header">
        <SortTableHeader<User>
          title="Nom"
          className="cell-username"
          field="fullName"
          sort={users.sort}
          onClick={onSortClick}
        />
        <SortTableHeader<User>
          title="Email"
          className="cell-email"
          field="email"
          sort={users.sort}
          onClick={onSortClick}
        />
        <div className="cell-contracts">Marchés</div>
        <div className="cell-company">Entreprise</div>
        <div className="cell-status">Statut</div>
      </div>
      <div className="table-body">
        {users.data.map((user: User) => (
          <div
            key={`user-${user.id}`}
            className={classNames('table-body-row', {
              inactive: !user.active,
              disabled: readOnly,
            })}
            onClick={() => !readOnly && onRowClick(user)}
          >
            <div className="cell-username">
              <div className="name">{user.fullName}</div>
              <div>{user.profiles.map((profile: UserProfile) => profile.name).join(', ')}</div>
            </div>
            <div className="cell-email">{user.email}</div>
            <div className="cell-contracts">
              {user.markets.map((market: Market) => (
                <span key={`market-${market.id}`} className={classNames('tag', { inactive: !market.active })}>
                  {market.name}
                </span>
              ))}
            </div>
            <div className="cell-company">{CompanyOptions[user.company]}</div>
            <div className={classNames('cell-status', { on: user.active })} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsersTable;
