import { parseToFloat } from './valueParser';

/**
 * Calculate the sum of every digit in a number.
 * ex: "16" => 1 + 6 = 7
 */
const digitSum = (number: number) => [...number.toString()].map((e) => parseInt(e, 10)).reduce((a, b) => a + b);

/**
 * Calculates the checksum of first 11 chars of a registration.
 * - Multiply each digit by 2 for even position or 1 for odd, ex "33 80 244 4294" => "6 3 0 16 0 0 4 4 8 0 4 4 9 8"
 * - Sum every digit of previous series of number "6 3 0 16 0 0 4 4 8 0 4 4 9 8" => 6+3+0+1+6+0+0+4+4+8+0+4+4+9+8 = 57
 */
const registrationChecksum = (value: string) =>
  [...value.substring(0, 11)]
    .map((e, i) => digitSum(parseInt(e, 10) * (2 - (i % 2)))) // multiply and sum digits
    .reduce((a, b) => a + b);

/**
 * Checks if registration last digit (checksum digit) is valid.
 * See https://eogile.jira.com/browse/ICPO-106.
 * - Calculates the difference between the checksum (rounded to next tens) and the checksum.
 * ex: if checksum is "57" => 60 - 57 = 3
 */
const registrationChecksumMatches = (value: string) => {
  const checksum = registrationChecksum(value);
  return parseInt(value[11], 10) === Math.ceil(checksum / 10) * 10 - checksum;
};

export const isRegistrationIgnoringChecksum = (value: string | null | undefined) =>
  Boolean(value) && /^(\d{12})$/.test(value!);

/**
 * Registration value should always be 12 digits unformatted string (no space, no '-') in browser cache and server.
 * We just apply a redux-form format to make registration appear as '12 34 567 8901-2'.
 * This method checks that a registration is valid only if it's 12 digits.
 */
export const isRegistration = (value: string | null | undefined) =>
  Boolean(value) && isRegistrationIgnoringChecksum(value!) && registrationChecksumMatches(value!);

export const isFieldEmpty = (value: string | number | null | undefined) =>
  value === undefined || value === null || value === '';

export const isRequiredFieldNotEmpty = (value: string | number | null | undefined) => !isFieldEmpty(value);

export const hasMinLength = (value: string | null | undefined, minLength: number) => (value?.length ?? 0) >= minLength;

export const hasMaxLength = (value: string | null | undefined, maxLength: number) => (value?.length ?? 0) <= maxLength;

export const hasMinValue = (value: string | number | null | undefined, minValue: number) =>
  (typeof value === 'string' ? parseToFloat(value) : value ?? 0) > minValue;

export const hasMinOrEqualValue = (value: string | number | null | undefined, minValue: number) =>
  (typeof value === 'string' ? parseToFloat(value) : value ?? 0) >= minValue;

export const hasMaxOrEqualValue = (value: string | number | null | undefined, maxValue: number) =>
  (typeof value === 'string' ? parseToFloat(value) : value ?? 0) <= maxValue;

/**
 * Checks if string is a list of comma/space separated emails
 */
export const isEmailsList = (value: string) => {
  // made from http://emailregex.com/
  const emailsRegex =
    /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[,\s]*)+$/;

  return value.match(emailsRegex);
};
