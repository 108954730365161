import React from 'react';
import GlobalError from './GlobalError';
import { usePageTitle } from './hooks';

const RouteNotFound = () => {
  usePageTitle('Page non trouvée');
  return <GlobalError title="Cette page n'existe pas">La page que vous avez demandée n'existe pas.</GlobalError>;
};

export default RouteNotFound;
