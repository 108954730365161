import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getDamageReport, getOfflineState } from '../../../../commons/selectors/selectors';
import { addSignature, removeSignature } from '../../damageReportDucks';
import DrawingCanvas from './DrawingCanvas';
import SignatureModal from './SignatureModal';
import DamageReportEntity from '../../../../commons/entity/DamageReportEntity';

class Signature extends React.Component {
  static propTypes = {
    damageReport: PropTypes.shape(DamageReportEntity.reactShape()),
  };

  state = {
    opened: false,
  };

  onOpen = () => {
    this.setState({ opened: true });
  };

  onClose = () => {
    this.setState({
      opened: false,
    });
  };

  onChangeSignature = (signature) => {
    this.setState({ signature });
  };

  onResetSignature = () => {
    this.setState({ signature: null });
    this.canvas.resetDrawing();
  };

  onSaveSignature = () => {
    const { dispatch, damageReport } = this.props;
    const { signature } = this.state;
    dispatch(addSignature(signature, damageReport.data.id));
    this.setState({
      opened: false,
    });
  };

  onDeleteSignature = () => {
    const { dispatch, damageReport } = this.props;
    dispatch(removeSignature(damageReport.data));
  };

  attachmentUrl = (attachment) => {
    if (!attachment || !attachment.id) {
      return null;
    }
    return `/api/damageReports/attachments/${attachment.id}`;
  };

  render() {
    const { opened } = this.state;
    const { damageReport, offline } = this.props;
    if (!damageReport) {
      return null;
    }
    const signature = damageReport.data.cause.responsibleThirdParty
      ? damageReport.data.cause.responsibleThirdParty.signature
      : null;
    const canvas = (
      <DrawingCanvas onChange={this.onChangeSignature} width={320} height={260} ref={(el) => (this.canvas = el)} />
    );
    return (
      <div className="input input-label">
        <div className="signature">
          {signature && !offline ? (
            <div className="column">
              <label htmlFor={damageReport.data.id}>Signature du tiers</label>
              <div className="signature-image-container">
                <img className="signatureImage" alt="Signature tiers" src={this.attachmentUrl(signature)} />
              </div>
              <div className="column buttons">
                <button type="button" className="btn btn-link" onClick={() => this.onOpen()}>
                  Modifier la signature
                </button>
                <button type="button" className="btn btn-link" onClick={() => this.onDeleteSignature()}>
                  Supprimer la signature
                </button>
              </div>
            </div>
          ) : (
            !offline && (
              <button type="button" className="btn btn-success btn-signature" onClick={() => this.onOpen()}>
                Ajouter la signature du tiers
              </button>
            )
          )}
          {offline && (
            <div className="btn-signature offline">
              Vous devez être en ligne pour visualiser ou modifier la signature du tiers.
            </div>
          )}
        </div>
        <SignatureModal
          hidden={!opened}
          canvas={canvas}
          handleSubmit={this.onSaveSignature}
          resetSignature={this.onResetSignature}
          closeSignatureDialog={this.onClose}
        />
      </div>
    );
  }
}

const selector = (state) => ({
  damageReport: getDamageReport(state),
  offline: getOfflineState(state),
});

export default connect(selector)(Signature);
