import {
  hasMaxLength,
  isFieldEmpty,
  isRegistration,
  isRequiredFieldNotEmpty,
} from '../../../commons/redux-form/valueValidator';
import DamageReportEntity from '../../../commons/entity/DamageReportEntity';
import damageCache from '../../../commons/templates/damageCache';
import { asyncValidateOwner, asyncValidateWagon } from '../../../commons/components/input/asyncValidation';

export const validateWagonAvailabilityMode = (availabilityMode) => {
  if (Object.keys(DamageReportEntity.WAGON_AVAILABILITY_MODE).includes(availabilityMode)) {
    return null;
  }
  if (!availabilityMode || /^\s*$/.test(availabilityMode)) {
    return 'Le statut est obligatoire';
  }
  return 'Valeur invalide';
};

const validateWagonData = (damageReport) => {
  const errors = { wagon: { owner: {}, railCompany: {} } };
  const { wagon = {} } = damageReport;
  if (isFieldEmpty(wagon.registration)) {
    errors.wagon.registration = 'Immatriculation obligatoire';
  } else if (!isRegistration(wagon.registration)) {
    errors.wagon.registration = "L'immatriculation n'est pas conforme";
  }
  if (isFieldEmpty(wagon.owner?.name)) {
    errors.wagon.owner = {
      name: 'Détenteur obligatoire',
    };
  }
  if (isFieldEmpty(damageReport.market?.name)) {
    errors.market = {
      name: 'Marché obligatoire',
    };
  }

  if (wagon.railCompany) {
    if (wagon.railCompany.type && wagon.railCompany.type !== 'none') {
      if (isFieldEmpty(wagon.railCompany.name)) {
        errors.wagon.railCompany.name = 'Nom obligatoire';
      } else if (!hasMaxLength(wagon.railCompany.name, 64)) {
        errors.wagon.railCompany.name = 'Longueur maximale: ' + 64;
      }
    }
  }
  errors.wagon.availabilityMode = validateWagonAvailabilityMode(wagon.availabilityMode);
  return errors;
};

const asyncValidateWagonData = async (damageReport) => {
  const { wagon = {} } = damageReport;
  const wagonErrors = {};
  if (wagon.registration && isRegistration(wagon.registration)) {
    const registrationError = await asyncValidateWagon(wagon.registration);
    if (registrationError) {
      wagonErrors.registration = registrationError;
    }
  }
  const ownerError = await asyncValidateOwner(wagon.owner?.name);
  if (ownerError) {
    wagonErrors.owner = { name: ownerError };
  }
  return { wagon: wagonErrors };
};

const validateTrainSection = (damageReport) => {
  const errors = { train: {} };
  const { train = {} } = damageReport;

  if (isFieldEmpty(train.trainNumber)) {
    errors.train.trainNumber = 'N° de train obligatoire';
  }
  if (train.trainNumber && !/^[0-9]{5,6}$/.test(train.trainNumber)) {
    errors.train.trainNumber = 'Le numéro de train doit être composé de 5 ou 6 chiffres';
  }

  if (isFieldEmpty(train.startStation)) {
    errors.train.startStation = "Gare d'expédition obligatoire";
  } else if (!hasMaxLength(train.startStation, 64)) {
    errors.train.startStation = 'Longueur maximale: ' + 64;
  }

  if (isFieldEmpty(train.endStation)) {
    errors.train.endStation = 'Gare de destination obligatoire';
  } else if (!hasMaxLength(train.endStation, 64)) {
    errors.train.endStation = 'Longueur maximale: ' + 64;
  }

  if (isFieldEmpty(train.startDateTime)) {
    errors.train.startDateTime = "Date d'expédition obligatoire";
  }

  return errors;
};

const validateDamageSection = (damageReport) => {
  const errors = {
    wagonDamages: {
      observation: {},
      damages: [],
    },
  };
  const { wagonDamages = {} } = damageReport;
  const { damages = [], observation = {}, comment } = wagonDamages;

  if (isFieldEmpty(observation.location)) {
    errors.wagonDamages.observation.location = 'Lieu de constatation des dommages obligatoire';
  } else if (!hasMaxLength(observation.location, 64)) {
    errors.wagonDamages.observation.location = 'Longueur maximale: ' + 64;
  }
  if (isFieldEmpty(observation.dateTime)) {
    errors.wagonDamages.observation.dateTime = 'Date de constatation des dommages obligatoire';
  }
  damages.forEach((damage, index) => {
    errors.wagonDamages.damages[index] = {};
    if (isFieldEmpty(damage?.damageTemplateId)) {
      errors.wagonDamages.damages[index].damageTemplateId = 'Code obligatoire';
    }
    if (isFieldEmpty(damage?.comment)) {
      errors.wagonDamages.damages[index].comment = 'Désignation obligatoire';
    } else if (damage && !hasMaxLength(damage.comment, 1000)) {
      errors.wagonDamages.damages[index].comment = 'Longueur maximale: ' + 1000;
    }
  });

  if (comment && !hasMaxLength(comment, 1000)) {
    errors.wagonDamages.comment = 'Longueur maximale: ' + 1000;
  }

  return errors;
};

const asyncValidateDamageSection = async (damageReport) => {
  const errors = {
    wagonDamages: {
      damages: [],
    },
  };
  const { wagonDamages = {} } = damageReport;
  const { damages = [] } = wagonDamages;
  for (const damage of damages) {
    const index = damages.indexOf(damage);
    errors.wagonDamages.damages[index] = {};
    if (
      damage &&
      isRequiredFieldNotEmpty(damage.damageTemplateId) &&
      damage.damageTemplateId !== 'Autre' &&
      !(await damageCache.findItemById(damage.damageTemplateId))
    ) {
      errors.wagonDamages.damages[index].damageTemplateId = 'Code introuvable';
    }
  }
  return errors;
};

const validateLabelling = (damageReport) => {
  const errors = {
    labelling: {},
  };
  const { labelling = {}, newLabels = [] } = damageReport;

  if (labelling.railCompany && !hasMaxLength(labelling.railCompany, 64)) {
    errors.labelling.railCompany = 'Longueur maximale: ' + 64;
  }

  if (newLabels.length === 0) {
    errors.newLabels = 'Étiquetage obligatoire';
  }

  return errors;
};

const validateCauseSection = (damageReport) => {
  const errors = {
    cause: {
      responsibleThirdParty: {},
    },
  };
  const { cause = {} } = damageReport;
  if (isFieldEmpty(cause.type)) {
    errors.cause.type = 'Responsable obligatoire';
  }
  const thirdParty = cause.responsibleThirdParty || {};
  if (thirdParty.name && !hasMaxLength(thirdParty.name, 64)) {
    errors.cause.responsibleThirdParty.name = 'Longueur maximale: ' + 64;
  }

  if (thirdParty.address && !hasMaxLength(thirdParty.address, 255)) {
    errors.cause.responsibleThirdParty.address = 'Longueur maximale: ' + 255;
  }

  return errors;
};

const validateAuthoringSection = (damageReport) => {
  const errors = {};
  if (isFieldEmpty(damageReport.reportLocation)) {
    errors.reportLocation = 'Lieu obligatoire';
  } else if (!hasMaxLength(damageReport.reportLocation, 64)) {
    errors.reportLocation = 'Longueur maximale: ' + 64;
  }
  if (isFieldEmpty(damageReport.reportDateTime)) {
    errors.reportDateTime = 'Date obligatoire';
  }
  return errors;
};

export const validateDamageReport = (damageReport) => ({
  ...validateWagonData(damageReport),
  ...validateTrainSection(damageReport),
  ...validateDamageSection(damageReport),
  ...validateLabelling(damageReport),
  ...validateCauseSection(damageReport),
  ...validateAuthoringSection(damageReport),
});

export const asyncValidateDamageReport = async (damageReport) => {
  const errors = {
    ...(await asyncValidateWagonData(damageReport)),
    ...(await asyncValidateDamageSection(damageReport)),
  };
  if (
    errors.wagon.registration ||
    errors.wagon.owner ||
    errors.wagonDamages.damages.some((damageError) => damageError.damageTemplateId)
  ) {
    throw errors;
  }
};

export default validateDamageReport;
