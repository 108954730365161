import React from 'react';
import PropTypes from 'prop-types';
import DamageReportEntity from '../../commons/entity/DamageReportEntity';
import { labelsDictionary } from '../damageReportUtils';

const DamageReportTableItemLabels = ({ damageReport }) => {
  const newLabels = damageReport.getNewLabels();
  if (newLabels.length === 0) {
    return null;
  }
  return (
    <span className="cell-labels">
      {newLabels.map((label, index) => (
        <span key={label} className={`damage-label-${label}`}>
          {labelsDictionary[label]}
          {index < newLabels.length - 1 && ', '}
        </span>
      ))}
    </span>
  );
};

DamageReportTableItemLabels.propTypes = {
  damageReport: PropTypes.instanceOf(DamageReportEntity).isRequired,
};

export default DamageReportTableItemLabels;
