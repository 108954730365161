import ApiError from './ApiError';

const csrfToken = (): string => {
  const { cookie } = document;
  if (cookie.indexOf('CSRF-TOKEN') < 0) {
    return '';
  }

  // The regular expression was found here:
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  return cookie.replace(/(?:^|.*;\s*)CSRF-TOKEN\s*=\s*([^;]*).*$|^.*$/, '$1');
};

/**
 * Return the HTTP headers to include to the HTTP request.
 */
const generateHeaders = (customHeaders: HeadersInit | undefined, withToken: boolean = true): Headers => {
  const headers = new Headers(customHeaders);
  if (withToken) {
    headers.append('X-CSRF-TOKEN', csrfToken());
  }
  return headers;
};

/**
 * Transform the given object containing params into the matching query
 * string.
 */
export const toQueryString = (params: any): string =>
  Object.keys(params)
    .filter((key) => params[key] != null)
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value.map((arrayValue) => `${key}%5B%5D=${encodeURIComponent(arrayValue)}`).join('&');
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

/**
 * Returns the path with query string that matches the given parameters.
 */
const pathWithQueryString = (path: string, params: any = {}): string => {
  const queryString = toQueryString(params);
  return queryString ? `${path}?${queryString}` : path;
};

const responseFileName = (response: Response, defaultFileName?: string): string | undefined => {
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return defaultFileName;
};

const execute = (
  path: string,
  method: RequestInit['method'],
  params: any,
  body: BodyInit | null | undefined,
  headers: HeadersInit,
  binary: boolean = false,
) =>
  fetch(pathWithQueryString(path, params), {
    method,
    credentials: 'same-origin',
    body,
    headers: generateHeaders(headers),
  }).then((response) => {
    if (response.status === 204) {
      return Promise.resolve();
    }
    const contentType = response.headers.get('Content-Type') || '';
    const bodyPromise = binary
      ? response.blob().then((blob) => ({ blob, fileName: responseFileName(response) }))
      : contentType.indexOf('application/json') === 0
      ? response.json()
      : response.text();
    return bodyPromise.then((responseBody) => {
      if (response.ok) {
        return responseBody;
      }
      console.error('API error:', responseBody);
      throw new ApiError(response.status, responseBody);
    });
  });

const get = (path: string, params: any = {}, headers: HeadersInit = {}) => execute(path, 'GET', params, null, headers);

const getBinary = (path: string, params: any = {}, headers: HeadersInit = {}) =>
  execute(path, 'GET', params, null, headers, true);

const del = (path: string, params: any = {}, headers: HeadersInit = {}) =>
  execute(path, 'DELETE', params, null, headers);

const postRaw = (path: string, body?: BodyInit, params: any = {}, headers: HeadersInit = {}) =>
  execute(path, 'POST', params, body, headers);

const post = (path: string, jsonBody?: any, params: any = {}, headers: HeadersInit = {}) =>
  postRaw(path, JSON.stringify(jsonBody), params, {
    ...headers,
    'Content-Type': 'application/json',
  });

const multipartFormData = (
  jsonPartName: string,
  jsonBody: any,
  attachmentPartName: string,
  attachment: string | Blob,
) => {
  const formData = new FormData();
  formData.append(jsonPartName, new Blob([JSON.stringify(jsonBody)], { type: 'application/json' }));
  if (attachment) {
    formData.append(attachmentPartName, attachment);
  }
  return formData;
};

const postMultipartJson = (
  path: string,
  jsonPartName: string,
  attachmentPartName: string,
  jsonBody: any,
  attachment: string | Blob,
  params: any = {},
  headers: HeadersInit = {},
) => postRaw(path, multipartFormData(jsonPartName, jsonBody, attachmentPartName, attachment), params, headers);

const putRaw = (path: string, body?: BodyInit, params: any = {}, headers: HeadersInit = {}) =>
  execute(path, 'PUT', params, body, headers);

const put = (path: string, jsonBody: any, params: any = {}, headers: HeadersInit = {}) =>
  putRaw(path, JSON.stringify(jsonBody), params, {
    ...headers,
    'Content-Type': 'application/json',
  });

const putMultipartJson = (
  path: string,
  jsonPartName: string,
  attachmentPartName: string,
  jsonBody: any,
  attachment: string | Blob,
  params: any = {},
  headers: HeadersInit = {},
) => putRaw(path, multipartFormData(jsonPartName, jsonBody, attachmentPartName, attachment), params, headers);

export default {
  get,
  getBinary,
  del,
  delete: del,
  postRaw,
  post,
  multipartFormData,
  postMultipartJson,
  putRaw,
  put,
  putMultipartJson,
  toQueryString,
};
