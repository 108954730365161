import { createSlice } from '@reduxjs/toolkit';

export type DragAndDropState = {
  draggingEngine: boolean;
  draggingWagon: boolean;
};

const initialState: DragAndDropState = {
  draggingEngine: false,
  draggingWagon: false,
};

const dragAndDropSlice = createSlice({
  name: 'dragAndDrop',
  initialState,
  reducers: {
    startDragEngine: (state) => {
      state.draggingEngine = true;
    },
    startDragWagon: (state) => {
      state.draggingWagon = true;
    },
    stopDragVehicle: (state) => {
      state.draggingEngine = false;
      state.draggingWagon = false;
    },
  },
});
export default dragAndDropSlice.reducer;
export const { startDragEngine, startDragWagon, stopDragVehicle } = dragAndDropSlice.actions;
