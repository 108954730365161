import React from 'react';

type TableHeaderProps = {
  title: string;
  className?: string;
};

const TableHeader = ({ title, className }: TableHeaderProps) => <div className={className}>{title}</div>;

export default TableHeader;
