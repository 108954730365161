import Overlay from '../../../commons/components/overlay/Overlay';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { selectTrainStep } from '../../../commons/selectors/selectors';
import { closeBrakingBulletin } from '../trainStepDucks';
import React from 'react';
import BrakingBulletinForm, { BrakingBulletinFormData, brakingBulletinFormName } from './BrakingBulletinForm';
import { startToast } from '../../../commons/components/toast/toastDucks';
import { isDirty } from 'redux-form';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { scrollToFirstError } from '../../../commons/validation/validationUtils';

const BrakingBulletinOverlay = () => {
  const { brakingBulletin } = useAppSelector(selectTrainStep);
  const formDirty = useAppSelector(isDirty(brakingBulletinFormName));
  const dispatch = useAppDispatch();

  const formInitialValues: BrakingBulletinFormData | undefined = brakingBulletin
    ? {
        indiceComposition: brakingBulletin.indiceComposition,
        startLocation: brakingBulletin.startLocation,
        observations: [...brakingBulletin.observations],
        brakedWeightDetails: brakingBulletin.brakedWeightDetails,
        effectiveBrakedWeightDetails: brakingBulletin.effectiveBrakedWeightDetails,
      }
    : undefined;

  const handleClose = (): Promise<boolean> => {
    if (formDirty) {
      return new Promise((resolve) => {
        dispatch(
          openConfirmDialog({
            title: 'Vous avez des modifications en cours. Voulez-vous fermer sans enregistrer ?',
            actionText: 'Fermer sans enregistrer',
            action: () => () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
            actionClass: 'danger',
          }),
        );
      });
    }
    return Promise.resolve(true);
  };

  return (
    <Overlay
      name="braking-bulletin"
      title={`Bulletin de freinage V${brakingBulletin?.revision}`}
      onRequestClose={handleClose}
      onClosed={() => dispatch(closeBrakingBulletin())}
      closeButton
    >
      {brakingBulletin && (
        <BrakingBulletinForm
          brakingBulletin={brakingBulletin}
          initialValues={formInitialValues}
          onSubmitFail={(errors) => {
            scrollToFirstError(errors);
            dispatch(
              startToast({
                text: 'Données invalides. Veuillez vérifier le bulletin avant de signer',
                className: 'error',
              }),
            );
          }}
        />
      )}
    </Overlay>
  );
};
export default BrakingBulletinOverlay;
