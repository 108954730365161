import React from 'react';
import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';

import Overlay from '../overlay/Overlay';
import CopyWagonsForm, { CopyWagonsFormData } from './CopyWagonsForm';
import CopyWagonsSearchResults from './CopyWagonsSearchResults';
import { searchImportSources } from './copyWagonsDucks';
import { getImportSourceCandidates } from '../../selectors/selectors';
import './copyWagonsStyles.scss';
import { useAppDispatch } from '../../store/hooks';
import { ImportSourceCandidate, ImportSourceType } from '../../model/importSource';
import { AppAction, AppDispatch } from '../../store/store';
import { DateString } from '../../model/common';
import { RootState } from '../../reducers/rootReducer';
import { isMoment, Moment } from 'moment';

type CopyWagonsOverlayProps = {
  authorizedTypes: ImportSourceType[];
  candidateIdToExclude?: string;
  copyWagonsAction: (candidate: ImportSourceCandidate) => AppAction;
  marketId?: number;
  marketName?: string;
  startDateTime: DateString | Moment;
  targetType: ImportSourceType;
};

const CopyWagonsOverlay = ({
  authorizedTypes,
  copyWagonsAction,
  candidateIdToExclude,
  startDateTime,
  marketId,
  marketName,
  targetType,
}: CopyWagonsOverlayProps) => {
  const dispatch = useAppDispatch();
  const importSourceCandidates = useSelector(getImportSourceCandidates);
  const formInitialValues: CopyWagonsFormData = {
    startDateTime: isMoment(startDateTime) ? startDateTime.format() : startDateTime,
    market:
      marketId && marketName
        ? {
            id: marketId,
            name: marketName,
          }
        : undefined,
  };
  const onSearchImportSources = () => (_: AppDispatch, getState: () => RootState) => {
    dispatch(
      searchImportSources({
        ...((getFormValues('copyWagonsForm')(getState()) as CopyWagonsFormData) || formInitialValues),
        types: authorizedTypes,
      }),
    );
  };
  return (
    <Overlay
      name="copy-wagons"
      onShow={() => dispatch(onSearchImportSources())}
      title="Importer des wagons"
      closeButton
    >
      <CopyWagonsForm
        form="copyWagonsForm"
        initialValues={formInitialValues}
        searchImportSources={onSearchImportSources}
      />
      <CopyWagonsSearchResults
        copyWagonsAction={copyWagonsAction}
        candidateIdToExclude={candidateIdToExclude}
        importSourceCandidates={importSourceCandidates}
        targetType={targetType}
        targetMarketId={marketId}
      />
    </Overlay>
  );
};

export default CopyWagonsOverlay;
