import { touch } from 'redux-form';
import DamageReportEntity from '../../commons/entity/DamageReportEntity';
import helpers from '../../commons/helpers/helpers';
import { startToast } from '../../commons/components/toast/toastDucks';
import {
  addOrUpdateDamageReportInCache,
  removeDamageReportFromCache,
} from '../damage-reports-table/damageReportsCacheActions';
import { damageReportsPersistSchedule } from '../../persist/damageReportPersistActions';
import { hideOverlay } from '../../commons/components/overlay/overlayDucks';

import validateDamageReport, { asyncValidateDamageReport } from './damage-report-form/damageReportValidation';
import hasValidationErrors from '../../commons/validation/validationUtils';
import { browserHistory } from '../../commons/routes/routerHistoryConfig';

import {
  LOAD_DAMAGE_REPORT_FAILURE,
  LOAD_DAMAGE_REPORT_OFFLINE,
  LOAD_DAMAGE_REPORT_START,
  LOAD_DAMAGE_REPORT_SUCCESS,
} from './damageReportLoadActions';
import wagonCache from '../../commons/templates/wagonCache';
import { getDamageReport, selectSecurityContext } from '../../commons/selectors/selectors';
import marketCache from '../../commons/templates/marketCache';
import apiHelper from '../../api/apiHelper';

// Constants
const RESET_STATE = 'DAMAGE_REPORT/RESET_STATE';

const CREATE_DAMAGE_REPORT = 'CREATE_DAMAGE_REPORT';
const SAVE_DAMAGE_REPORT = 'SAVE_DAMAGE_REPORT';

const ADD_PENDING_ATTACHMENT_UPLOAD = 'ADD_PENDING_ATTACHMENT_UPLOAD';
const REMOVE_PENDING_ATTACHMENT_UPLOAD = 'REMOVE_PENDING_ATTACHMENT_UPLOAD';

const initialState = {
  data: new DamageReportEntity(), // Current damageReport
  loading: false,
  error: 0, // This property will store the API error code, so we default to 0 to avoid changing type
  /**
   * Array containing files being currently uploading the current damage report.
   */
  pendingAttachmentUploads: [],
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DAMAGE_REPORT_START: {
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: 0,
        pendingAttachmentUploads: state.data.id === action.payload.id ? state.pendingAttachmentUploads : [],
      };
    }
    case LOAD_DAMAGE_REPORT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: 0,
        pendingAttachmentUploads: state.data.id === action.payload.id ? state.pendingAttachmentUploads : [],
      };
    }
    case LOAD_DAMAGE_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        /*
         * If the damage report was loaded from the cache, then no
         * error is displayed to the user.
         */
        error: state.data && state.data.id ? 0 : action.payload.statusCode || 1,
      };
    case LOAD_DAMAGE_REPORT_OFFLINE:
      return {
        ...state,
        loading: false,
        error: 0,
      };
    case CREATE_DAMAGE_REPORT: {
      const damageReport = action.payload;
      return {
        ...state,
        data: damageReport,
        loading: false,
        error: 0,
        pendingAttachmentUploads: [],
      };
    }
    case SAVE_DAMAGE_REPORT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ADD_PENDING_ATTACHMENT_UPLOAD:
      return {
        ...state,
        pendingAttachmentUploads: state.pendingAttachmentUploads.concat([action.payload.file]),
      };
    case REMOVE_PENDING_ATTACHMENT_UPLOAD:
      return {
        ...state,
        pendingAttachmentUploads: state.pendingAttachmentUploads.filter((f) => f !== action.payload.file),
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

// Actions

/**
 * Return the action to reset the reducer state. This will typically be used
 * after deleting a damage report and when the damage report page is closed.
 */
export const resetState = () => ({
  type: RESET_STATE,
  payload: {},
});

export const loadDamageReportOffline = () => ({
  type: LOAD_DAMAGE_REPORT_OFFLINE,
});

export const loadDamageReportFailure = (statusCode) => ({
  type: LOAD_DAMAGE_REPORT_FAILURE,
  payload: { statusCode },
});

const addPendingAttachmentUpload = (file) => ({
  type: ADD_PENDING_ATTACHMENT_UPLOAD,
  payload: { file },
});

const removePendingAttachmentUpload = (file) => ({
  type: REMOVE_PENDING_ATTACHMENT_UPLOAD,
  payload: { file },
});

export const loadDamageReportSuccess = (data) => (dispatch) => {
  const damageReport = new DamageReportEntity(data);
  dispatch({
    type: LOAD_DAMAGE_REPORT_SUCCESS,
    payload: damageReport,
  });
  dispatch(addOrUpdateDamageReportInCache(damageReport));
};

export const saveDamageReport = (data, propertyToUpdate) => (dispatch) => {
  const damageReport = helpers
    .setIn(new DamageReportEntity(data), propertyToUpdate)
    .shallowCopyForDirty(propertyToUpdate);
  dispatch({
    type: SAVE_DAMAGE_REPORT,
    payload: damageReport,
  });
  // Update damage reports cache
  dispatch(addOrUpdateDamageReportInCache(damageReport));
  dispatch(damageReportsPersistSchedule([damageReport]));
};

export const addDamageReport = (navigateAfterAddition, wagon, owner, train, loaded) => async (dispatch, getState) => {
  const securityContext = selectSecurityContext(getState());
  let market = null;

  if (train) {
    market = await marketCache.findItemById(train.marketId);
  } else if (securityContext.marketIds.length === 1) {
    market = await marketCache.findItemById(securityContext.marketIds[0]);
  }

  const damageReport = DamageReportEntity.createNewDamageReport(
    wagon,
    owner,
    train,
    loaded,
    market,
    securityContext.company,
    securityContext.username,
  );
  dispatch({
    type: CREATE_DAMAGE_REPORT,
    payload: damageReport,
  });
  // Add to damage reports cache
  dispatch(addOrUpdateDamageReportInCache(damageReport));
  dispatch(saveDamageReport(damageReport));
  const wagonNumber = damageReport.wagon.registration;
  const message = wagonNumber
    ? `Un PVCA a bien été ouvert concernant le wagon n°${wagonNumber}`
    : 'Un PVCA a bien été ouvert';
  dispatch(hideOverlay());
  dispatch(
    startToast({
      text: message,
      className: 'success',
    }),
  );
  if (navigateAfterAddition) {
    browserHistory.push(`/damage-reports/${damageReport.id}`);
  }
};

export const deleteDamageReport = (damageReport) => (dispatch) => {
  if (damageReport.isNew() && !damageReport.isUpdatingBack()) {
    dispatch(resetState());
    dispatch(removeDamageReportFromCache(damageReport));
  } else {
    const damageReportUpdated = damageReport.shallowCopyForDeletion();
    dispatch(damageReportsPersistSchedule([damageReportUpdated]));
  }
  browserHistory.push('/damage-reports');
};

export const deactivateDamageReport = (damageReport) => async (dispatch, getState) => {
  if (!damageReport.isStatusValidated()) {
    dispatch(
      startToast({
        text: 'Le statut du PVCA ne permet pas cette opération',
        className: 'error',
      }),
    );
    return;
  }

  const cmd = {
    damageReport,
    status: DamageReportEntity.VALIDATION_STATUSES.DISABLED,
    successMessage: 'Le PVCA a été désactivé.',
    errorMessage: 'Impossible de désactiver ce PVCA',
  };
  await updateStatus(dispatch, getState, cmd);
};

const updateStatus = async (dispatch, getState, updateCommand) => {
  const { damageReport, status, successMessage, errorMessage } = updateCommand;
  // noinspection JSUnresolvedVariable
  const formData = getState().form.damageReportForm;
  // Validate form, not underlying entity to be able to see last incorrect changes
  const dataErrors = validateDamageReport(damageReport);
  const formError = validateDamageReport(formData.values);
  const asyncFormError = await asyncValidateDamageReport(formData.values);
  const hasError =
    hasValidationErrors(dataErrors) || hasValidationErrors(formError) || hasValidationErrors(asyncFormError);
  if (!hasError) {
    dispatch(
      saveDamageReport(damageReport, {
        status,
      }),
    );
    dispatch(
      startToast({
        text: successMessage,
        className: 'success',
      }),
    );
    if (status === DamageReportEntity.VALIDATION_STATUSES.VALIDATED) {
      const { registration, owner } = damageReport.wagon;
      await wagonCache.updateWagonOwner(registration, owner);
    }
  } else {
    dispatch(
      startToast({
        text: errorMessage,
        className: 'error',
      }),
    );
    dispatch(
      touch(
        'damageReportForm',
        ...Object.keys(formData.registeredFields).map((key) => formData.registeredFields[key].name),
      ),
    );
  }
};

export const askForValidation = () => async (dispatch, getState) => {
  const damageReport = getDamageReport(getState()).data;
  if (damageReport.isStatusSubmitted() || damageReport.isStatusValidated()) {
    dispatch(
      startToast({
        text: 'Le statut du PVCA ne permet pas cette opération',
        className: 'error',
      }),
    );
    return;
  }

  const cmd = {
    damageReport,
    status: DamageReportEntity.VALIDATION_STATUSES.SUBMITTED,
    successMessage: 'Le PVCA est en attente de validation.',
    errorMessage: 'Veuillez compléter le formulaire pour pouvoir demander la validation du PVCA',
  };
  await updateStatus(dispatch, getState, cmd);
};

export const validate = () => async (dispatch, getState) => {
  const damageReport = getDamageReport(getState()).data;
  if (damageReport.isStatusValidated()) {
    dispatch(
      startToast({
        text: 'Le statut du PVCA ne permet pas cette opération',
        className: 'error',
      }),
    );
    return;
  }

  const cmd = {
    damageReport,
    status: DamageReportEntity.VALIDATION_STATUSES.VALIDATED,
    successMessage: 'Le PVCA a été validé.',
    errorMessage: 'Veuillez compléter le formulaire pour pouvoir valider le PVCA',
  };
  await updateStatus(dispatch, getState, cmd);
};

export const addAnnexes = (files, damageReportId) => (dispatch, getState) => {
  files.forEach(async (file) => {
    dispatch(addPendingAttachmentUpload(file));
    const formData = new FormData();
    formData.append('attachment', file, file.attachmentName || file.name);

    try {
      const attachment = await apiHelper.postRaw('/api/damageReports/attachments', formData);
      const damageReport = getState().damageReport.data;
      // If the displayed damage report changed, attachment is not added
      if (damageReportId === damageReport.id) {
        const attachments = damageReport.attachments || [];
        attachments.push(attachment);
        dispatch(
          saveDamageReport(damageReport, {
            attachments,
          }),
        );
        dispatch(startToast({ text: "L'annexe a été ajoutée.", className: 'success' }));
      }
    } catch (error) {
      startToast({ text: "Erreur : l'annexe n'a pas été ajoutée.", className: 'error' });
    } finally {
      dispatch(removePendingAttachmentUpload(file));
    }
  });
};

export const removeAnnexe = (damageReport, annexe) => (dispatch) => {
  const attachments = damageReport.attachments.filter((a) => a !== annexe);
  dispatch(
    saveDamageReport(damageReport, {
      attachments,
    }),
  );
};

export const addSignature = (dataURL, damageReportId) => async (dispatch, getState) => {
  const file = helpers.dataURLtoBlob(dataURL);
  const formData = new FormData();
  formData.append('attachment', file, `${damageReportId}-signature`);

  try {
    const signature = await apiHelper.postRaw('/api/damageReports/attachments/signature', formData);
    const damageReport = getState().damageReport.data;
    // If the displayed damage report changed, signature is not added
    if (damageReportId === damageReport.id) {
      dispatch(
        saveDamageReport(damageReport, {
          'cause.responsibleThirdParty.signature': signature,
        }),
      );
      dispatch(startToast({ text: 'La signature a été ajoutée.', className: 'success' }));
    }
  } catch {
    dispatch(startToast({ text: "Erreur : la signature n'a pas été ajoutée.", className: 'error' }));
  }
};

export const removeSignature = (damageReport) => (dispatch) => {
  dispatch(
    saveDamageReport(damageReport, {
      'cause.responsibleThirdParty.signature': null,
    }),
  );
};
