import {
  validateNbAxles,
  validateRegistration,
  validateWagonLength,
  validateWagonTare,
  ValidationErrors,
} from '../../../commons/validation/validationUtils';
import { isRequiredFieldNotEmpty } from '../../../commons/redux-form/valueValidator';
import { WagonTemplate } from '../../../commons/model/templates';

const validateOwnerId = (ownerId: number | null): ValidationErrors<'ownerId'> => {
  if (isRequiredFieldNotEmpty(ownerId)) {
    return {};
  }
  return {
    ownerId: 'Détenteur obligatoire',
  };
};

export const validate = (wagonTemplate: WagonTemplate): ValidationErrors<any> => {
  if (!wagonTemplate) {
    return {};
  }
  return {
    ...validateRegistration(wagonTemplate.registration),
    ...validateWagonLength(wagonTemplate.length),
    ...validateWagonTare(wagonTemplate.tare),
    ...validateNbAxles(wagonTemplate.nbAxles),
    ...validateOwnerId(wagonTemplate.ownerId),
  };
};

export const initialFormValuesForNewWagonTemplates: () => Partial<WagonTemplate> = () => ({
  doubleWagon: false,
});
