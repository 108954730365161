import React from 'react';

import AnnexeLine from './AnnexeLine';

const AnnexesList = ({ damageReport, offline, pendingUploads, readOnly }) => (
  <ul className="damage-report-annexes-list">
    {(damageReport.attachments || []).map((attachment) => (
      <AnnexeLine
        attachment={attachment}
        damageReport={damageReport}
        key={attachment.id}
        offline={offline}
        pending={false}
        readOnly={readOnly}
      />
    ))}
    {pendingUploads.map((file) => (
      <AnnexeLine
        attachment={file}
        damageReport={damageReport}
        key={file.attachmentName || file.name}
        offline={offline}
        pending={true}
        readOnly={readOnly}
      />
    ))}
    {(damageReport.attachments || []).length + pendingUploads.length === 0 && <div>Aucune annexe.</div>}
  </ul>
);

export default AnnexesList;
