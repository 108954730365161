import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import React, { useEffect, useState } from 'react';
import { invalidateAteTransmission, signAteTransmission } from './TraceabilityActions';
import { isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import { TransmissionSignature } from '../../../commons/model/Traceability';
import List from '../../../commons/components/list/List';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import { withDerivedProps } from '../../../commons/components/hoc';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import { validateAteTransmissionForm } from '../../../commons/validation/validationUtils';

export type AteTransmissionFormData = {
  transmitted: boolean;
  noRestriction: boolean;
};

export type AteTransmissionSubsectionProps = {
  signatures: SignatureOrOffline<TransmissionSignature>[];
  editable: boolean;
};

const AteTransmissionSubsection = ({
  signatures,
  editable,
  handleSubmit,
  change,
  error,
  submitFailed,
}: AteTransmissionSubsectionProps & InjectedFormProps<AteTransmissionFormData>) => {
  const { username, email } = useAppSelector(selectSecurityContext);
  const dispatch = useAppDispatch();
  const [formOpen, setFormOpen] = useState(editable && signatures.length === 0);

  useEffect(() => {
    setFormOpen(editable && signatures.length === 0);
  }, [editable, signatures]);

  const doHandleSubmit = (formValues: AteTransmissionFormData) => {
    dispatch(signAteTransmission(formValues.noRestriction));
  };

  const handleInvalidate = (signature: SignatureOrOffline<TransmissionSignature>) => () => {
    dispatch(invalidateAteTransmission(isOfflineSignature(signature) ? null : signature.userId));
  };

  return (
    <>
      {signatures.map((signature, index) => (
        <div className="subsection-content" key={index}>
          <List
            items={[
              {
                key: 'Observations',
                value: <div>{signature.noRestriction ? 'Pas de restriction au conducteur' : 'Néant'}</div>,
              },
              ...SignatureUtils.basicSignatureFields(signature, username),
            ]}
          />
          {editable && (
            <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
          )}
        </div>
      ))}
      {editable &&
        (formOpen ? (
          <form className="Transmission-form" autoComplete="off" noValidate onSubmit={handleSubmit(doHandleSubmit)}>
            <Field
              component={CheckboxFormComponent}
              name="transmitted"
              label="Restrictions transmises"
              onCheck={(checked: boolean) => {
                if (checked) {
                  change('noRestriction', false);
                }
              }}
            />
            <Field
              component={CheckboxFormComponent}
              name="noRestriction"
              label="Pas de restriction au conducteur"
              onCheck={(checked: boolean) => {
                if (checked) {
                  change('transmitted', false);
                }
              }}
            />
            {submitFailed && error && <div className="error-message">{error}</div>}
            <div className="buttons">
              <button className="btn btn-accent" type="submit">
                Valider et signer
              </button>
            </div>
          </form>
        ) : (
          signatures.every((signature) => !isOfflineSignature(signature) && signature.userEmail !== email) && (
            <button className="btn btn-round small plus" onClick={() => setFormOpen(true)} type="button" />
          )
        ))}
      {!editable && signatures.length === 0 && <div className="empty">Non signé</div>}
    </>
  );
};

const transformProps = ({ signatures }: AteTransmissionSubsectionProps) => {
  const lastSignature = signatures[signatures.length - 1];
  return {
    initialValues: {
      transmitted: lastSignature && !lastSignature.noRestriction,
      noRestriction: lastSignature?.noRestriction ?? false,
    },
  };
};

export default withDerivedProps(transformProps)(
  reduxForm<AteTransmissionFormData, AteTransmissionSubsectionProps>({
    form: 'traceabilityTransmissionForm',
    initialValues: {
      transmitted: false,
      noRestriction: false,
    },
    validate: validateAteTransmissionForm,
  })(AteTransmissionSubsection as any) as any,
);
