import React from 'react';
import SortTableHeader from '../../commons/components/table/SortTableHeader';

const PreAdvicesTableHeader = ({ sortDefinition, updateSort }) => (
  <div className="table-header">
    <SortTableHeader
      title="N° de train"
      className="cell-train-no"
      field="trainNumber"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader
      title="Date"
      className="cell-date"
      field="startDateTime"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader
      title="Statut"
      className="cell-composition"
      field="status"
      sort={sortDefinition}
      onClick={updateSort}
    />
  </div>
);

export default PreAdvicesTableHeader;
