import { SubmissionError } from 'redux-form';
import { hideOverlay, showOverlay } from '../../../commons/components/overlay/overlayDucks';
import { startToast } from '../../../commons/components/toast/toastDucks';
import { loadOwners } from '../ownersDucks';
import { toSaveCommand } from './ownerUtils';
import ownerCache from '../../../commons/templates/ownerCache';
import apiHelper from '../../../api/apiHelper';

/*
 ***********************
 * ACTION
 ***********************
 */

const DISPLAY_NEW_OWNER_FORM = 'DISPLAY_NEW_OWNER_FORM';
const DISPLAY_EXISTING_OWNER_FORM = 'DISPLAY_EXISTING_OWNER_FORM';

const DELETE_OWNER_SUCCESS = 'DELETE_OWNER_SUCCESS';
const PERSIST_OWNER_SUCCESS = 'PERSIST_OWNER_SUCCESS';

/*
 ***********************
 * ACTION CREATORS
 ***********************
 */

export const displayNewOwnerForm = () => (dispatch) => {
  dispatch({ type: DISPLAY_NEW_OWNER_FORM });
  dispatch(showOverlay('owner'));
};

export const displayExistingOwnerForm = (owner) => (dispatch) => {
  dispatch({
    type: DISPLAY_EXISTING_OWNER_FORM,
    payload: { owner },
  });
  dispatch(showOverlay('owner'));
};

export const deleteOwnerSuccess = () => ({
  type: DELETE_OWNER_SUCCESS,
});

export const persistOwnerSuccess = (owner) => ({
  type: PERSIST_OWNER_SUCCESS,
  payload: { owner },
});

export const deleteOwner = (ownerId) => async (dispatch) => {
  try {
    await apiHelper.delete(`/api/owners/${ownerId}`);
    await ownerCache.delete(ownerId);
    dispatch(loadOwners()); // Reload owners from cache for the list
    dispatch(deleteOwnerSuccess());
    dispatch(hideOverlay());
    dispatch(startToast({ text: 'Le détenteur a été désactivé', className: 'success' }));
  } catch {
    dispatch(startToast({ text: "Erreur : le détenteur n'a pas été désactivé", className: 'error' }));
  }
};

export const persistOwner = (owner, id) => async (dispatch) => {
  const body = toSaveCommand(owner);
  const action = id ? 'mis à jour' : 'créé';
  try {
    const persistedOwner = await (id ? apiHelper.put(`/api/owners/${id}`, body) : apiHelper.post('/api/owners', body));
    dispatch(persistOwnerSuccess(persistedOwner));
    await ownerCache.addOrUpdateOwner(persistedOwner);
    dispatch(loadOwners()); // Reload owners from cache for the list
    dispatch(hideOverlay());
    dispatch(startToast({ text: `Le détenteur a été ${action}.`, className: 'success' }));
  } catch (error) {
    if (error?.httpStatus === 400 && error.errors) {
      throw new SubmissionError(error.errors);
    } else {
      dispatch(startToast({ text: `Erreur : le détenteur n'a pas été ${action}.`, className: 'error' }));
    }
  }
};

/*
 ***********************
 * REDUCER
 ***********************
 */

const initialState = {
  owner: {
    id: null,
    name: '',
    vkm: '',
    emails: [],
  },
  loading: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DISPLAY_NEW_OWNER_FORM:
      return initialState;
    case DISPLAY_EXISTING_OWNER_FORM:
      return {
        ...initialState,
        owner: payload.owner,
      };
    case DELETE_OWNER_SUCCESS:
      return initialState;
    case PERSIST_OWNER_SUCCESS:
      return {
        ...initialState,
        owner: payload.owner,
      };
    default:
      return state;
  }
};
