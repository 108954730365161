import React from 'react';
import PropTypes from 'prop-types';
import PreAdviceWagonImportErrorEntity from '../../../commons/entity/PreAdviceWagonImportErrorEntity';

export const getErrorLabel = (error) => {
  const dangerId = error.dangerIdentificationNumber ?? 'manquant';
  const unCode = error.unitedNationsCode ?? 'manquant';
  const packingGroup = error.packingGroup ?? 'manquant';
  return `Numéro d'identification du danger : ${dangerId}, code ONU : ${unCode}, groupe d'emballage : ${packingGroup}`;
};

const PreAdviceWagonUnknownHazardousMaterialErrors = ({ errors }) => (
  <div className="pre-advice-wagon-import-errors pre-advice-wagon-warning">
    Certaines matières dangereuses n'ont pas été reconnues :
    <ul>
      {errors.map((error) => (
        <li key={error.id}>{getErrorLabel(error)}</li>
      ))}
    </ul>
  </div>
);

PreAdviceWagonUnknownHazardousMaterialErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.instanceOf(PreAdviceWagonImportErrorEntity)).isRequired,
};

export default PreAdviceWagonUnknownHazardousMaterialErrors;
