import React, { MouseEvent } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import VehicleListItem from '../../../commons/components/vehicles/vehicle/VehicleListItem';
import config from '../../../commons/config/config';
import { requestDeleteWagon } from '../preAdviceDucks';
import { requestUpdateWagonPosition } from '../../wagon/preAdviceWagonDucks';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import PreAdviceWagonImportErrorEntity, { severities } from '../../../commons/entity/PreAdviceWagonImportErrorEntity';
import PreAdviceWagonEntity from '../../../commons/entity/PreAdviceWagonEntity';
import { AppDispatch } from '../../../commons/store/store';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';
import PreAdviceActions from '../actions/PreAdviceActions';
import PreAdviceValidation from '../actions/PreAdviceValidation';

type PreAdviceWagonsListProps = {
  preAdvice: PreAdviceEntity;
  canModifyPreAdvices?: boolean;
  activeWagonId?: string;
  isCollapsed?: boolean;
  onChangeTab?: (newIndex: number) => void;
};

const handleSortEnd =
  (dispatch: AppDispatch, wagons: PreAdviceWagonEntity[]) =>
  ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const wagonToMove = wagons.find((wagon) => wagon.position === oldIndex);
      dispatch(requestUpdateWagonPosition(wagonToMove, newIndex));
    }
  };

/**
 * Return a boolean indicating if a "warning" pictogram should be displayed
 * for the given wagon.
 */
export const shouldDisplayWarningPictogram = (wagon: PreAdviceWagonEntity) => {
  return wagon.errors.some(
    (importError: PreAdviceWagonImportErrorEntity) => importError.getSeverity() === severities.WARNING,
  );
};

const PreAdviceWagonsListItem = SortableElement(
  ({ isActive, readOnly, wagon }: { isActive: boolean; readOnly: boolean; wagon: PreAdviceWagonEntity }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onDelete = (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(
        openConfirmDialog({
          title: `Supprimer le wagon ${wagon.getFormattedRegistrationNumber()} ?`,
          actionText: 'Oui, supprimer',
          action: requestDeleteWagon(wagon.id, false),
          actionClass: 'danger',
        }),
      );
    };
    const onClick = () => {
      navigate(`/pre-advices/${wagon.preAdviceId}/wagons/${wagon.id}`);
    };
    return (
      <VehicleListItem
        canDeleteItem={!readOnly && !wagon.isValidated()}
        effectiveBrakedWeight={wagon.effectiveBrakedWeight}
        shouldDisplayWarningPictogram={shouldDisplayWarningPictogram(wagon)}
        hazardousMaterials={wagon.hazardousMaterials}
        isActive={isActive}
        isAteOrGauge={Boolean(wagon.ateNumbers?.frenchAteNumber || wagon.ateNumbers?.foreignAteNumber)}
        isChargeD={wagon.charge === 'D'}
        isFilled={(wagon.loadWeight ?? 0) > 0}
        length={wagon.referentialData?.length}
        position={wagon.position + 1}
        onClick={onClick}
        onDelete={onDelete}
        registration={wagon.referentialData?.registration}
        status={wagon.status}
        totalWeight={wagon.getTotalWeight()}
        totalWeightText="masse totale"
        type="pre_advice_wagon"
      />
    );
  },
);

const MainSortableContainer = SortableContainer(
  ({
    activeWagonId,
    className,
    isDragDropDisabled,
    readOnly,
    wagons,
  }: {
    activeWagonId?: string;
    className: string;
    isDragDropDisabled: boolean;
    readOnly: boolean;
    wagons: PreAdviceWagonEntity[];
  }) => (
    <div className={className}>
      {wagons
        .filter((wagon, index) => wagon.position === index)
        .map((wagon, index) => (
          <PreAdviceWagonsListItem
            key={`vehicle-${wagon.id}`}
            isActive={activeWagonId === wagon.id}
            index={index}
            disabled={isDragDropDisabled}
            readOnly={readOnly}
            wagon={wagon}
          />
        ))}
    </div>
  ),
);

const PreAdviceWagonsList = ({
  preAdvice,
  canModifyPreAdvices = false,
  activeWagonId,
  isCollapsed = false,
  onChangeTab,
}: PreAdviceWagonsListProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useViewportWidth();
  const readOnly = !canModifyPreAdvices || preAdvice.isValidated();
  return (
    <div
      className={classNames('vehicles-list-container vehicles-list-preadvices', {
        collapsed: isCollapsed,
      })}
    >
      {!isCollapsed && (
        <div className="topbar">
          {isMobile ? (
            <button className="btn btn-icon btn-basic preadvice" onClick={() => onChangeTab?.(0)} type="button">
              <strong>&lt;</strong>
            </button>
          ) : (
            <div />
          )}
          {canModifyPreAdvices && <PreAdviceActions preAdvice={preAdvice} />}
        </div>
      )}
      <MainSortableContainer
        activeWagonId={activeWagonId}
        className={classNames('vehicles-list', {
          'vehicles-list-zero': !preAdvice.wagons.length,
          collapsed: isCollapsed,
        })}
        distance={config.isTouchScreen ? 0 : 20}
        helperClass="drag"
        isDragDropDisabled={readOnly || isCollapsed}
        pressDelay={config.isTouchScreen ? 200 : 0}
        readOnly={readOnly}
        shouldCancelStart={() => readOnly}
        wagons={preAdvice.wagons}
        onSortEnd={handleSortEnd(dispatch, preAdvice.wagons)}
      />
      {!isMobile && isCollapsed && (
        <div className="vehicle-edit-back">
          <Link to={`/pre-advices/${preAdvice.id}?tab=vehicles`}>
            <button className="btn btn-accent" type="button">
              Retour
            </button>
          </Link>
        </div>
      )}
      {!isCollapsed && canModifyPreAdvices && <PreAdviceValidation preAdvice={preAdvice} />}
    </div>
  );
};

export default PreAdviceWagonsList;
