import React from 'react';
import { WrappedFieldProps } from 'redux-form';

const ErrorField = ({ meta }: WrappedFieldProps) => {
  if (meta && meta.touched && meta.error) {
    return <div className="error-message">{meta.error}</div>;
  }
  return null;
};

export default ErrorField;
