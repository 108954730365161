import helpers from '../helpers/helpers';

export const normalizePositiveNumber = (value: string, previousValue: number | string = '') => {
  if (value === '') {
    return value;
  }
  if (/^(\d|\s)*$/.test(value)) {
    return Number.parseInt(helpers.thousandSeparatorUnformat(value), 10);
  }
  return previousValue;
};

export const normalizeTons = (value: string, previousValue: number | string = '') => {
  let previousValueNumber: number = 0;
  if (typeof previousValue === 'number') {
    previousValueNumber = previousValue;
  } else if (previousValue) {
    previousValueNumber = parseInt(previousValue);
  }
  // previous value is in kilograms, be sure to convert it to tons
  const result = normalizePositiveNumber(value, previousValueNumber / 1000);
  return typeof result === 'number' ? result * 1000 : result;
};

/**
 * For floats, work with strings as :
 * - if user types '25.' then normalizer would return number 25 (we'd lose the dot)
 */
export const normalizePositiveFloatTwoDecimal = (value: string, previousValue: number | string = '') => {
  if (value === '') {
    return value;
  }
  if (/^(\d|\s)*\d[,.]?\d?\d?$/.test(value)) {
    return helpers
      .thousandSeparatorUnformat(value)
      .replace(',', '.')
      .replace(/^0+([1-9]+)/, '$1');
  }
  return previousValue;
};

export const normalizeRegistration = (value: string, previousValue: string = '') => {
  if (/^(\d|\s|-)*$/.test(value)) {
    return helpers.registrationUnformat(value);
  }
  return previousValue;
};

export const normalizeUpperCase = (value: string) => {
  if (!value) {
    return value;
  }
  return value.toUpperCase();
};
