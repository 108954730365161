/**
 * Damage field component
 */
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import Input, { InputProps } from './Input';
import './inputStyles.scss';
import damageCache from '../../templates/damageCache';
import { DamageTemplate } from '../../model/templates';

type DamageFieldProps = {
  name: string;
  disabled?: boolean;
  allowIntermediary?: boolean;
} & InputProps<DamageTemplate>;

const DamageField = ({ allowIntermediary = true, ...props }: DamageFieldProps) => {
  const autocompleter = useMemo(
    () => ({
      autocomplete: damageCache.autocompleteWithFilter((damage) => allowIntermediary || !damage.intermediary),
      formatItem: (damage: DamageTemplate) => damage.id,
    }),
    [allowIntermediary],
  );

  return <Field component={Input} type="text" autocompleter={autocompleter} {...props} />;
};

export default DamageField;
