import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getMailingLists, selectOverlay, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { MAILING_LIST_WRITE } from '../../commons/security/userRoles';
import MailingListsTopbar from './mailing-lists-topbar/MailingListsTopbar';
import MailingListsTable from './mailing-lists-table/MailingListsTable';
import MailingListDetails from './mailing-list-details/MailingListDetails';
import * as mailingListsActions from './mailingListsDucks';
import * as mailingListActions from './mailingListDucks';
import { usePageTitle } from '../../commons/components/hooks';

const MailingListsComponent = ({
  readOnly,
  mailingLists,
  filterMailingLists,
  sortMailingLists,
  startEditMailingList,
  startGetMailingLists,
  overlay,
}) => {
  usePageTitle('Listes de diffusion');

  useEffect(() => {
    startGetMailingLists();
  }, [startGetMailingLists]);

  return (
    <div className="content-parent table-page">
      <MailingListsTopbar readOnly={readOnly} mailingLists={mailingLists} filterMailingLists={filterMailingLists} />

      <MailingListsTable
        mailingLists={mailingLists}
        sortMailingLists={sortMailingLists}
        startEditMailingList={startEditMailingList}
        readOnly={readOnly}
      />

      {overlay.active === 'mailing-list' && <MailingListDetails />}
    </div>
  );
};

const selector = (state) => ({
  mailingLists: getMailingLists(state),
  overlay: selectOverlay(state),
  readOnly: !selectSecurityContextRoles(state).includes(MAILING_LIST_WRITE),
});

export default connect(selector, { ...mailingListActions, ...mailingListsActions })(MailingListsComponent);
