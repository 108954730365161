import helpers from '../helpers/helpers';
import { Train, TrainProblemStatus, TrainStep, TrainSummary } from './Train';
import VehiclesUtils from './VehiclesUtils';
import { DateString, ValidationStatus } from './common';
import VehicleUtils from './VehicleUtils';

const STEP_PROBLEM_STATUS_NAMES: Record<TrainProblemStatus, string> = {
  STALLED: 'Calé',
  ACCIDENT: 'Accident',
  AGT_NON_AVAILABILITY: 'AGT',
  DIVERSE: 'Problème inconnu',
  OK: 'OK',
  CANCELLED: 'Annulé',
};

export type TrainStepTab = 'summary' | 'vehicles';

function isAutomaticallyValidated(step: TrainStep): boolean {
  return (
    step.indiceComposition === 'HLP' &&
    step.catStat === '08H' &&
    step.status === 'VALIDATED' &&
    step.validatedBy == null &&
    !step.manuallyUpdated
  );
}

export default {
  formatValidatedDate(step: TrainStep): string {
    return helpers.dateTimeFormat(step.validatedDate);
  },
  formatValidatedBy(step: TrainStep) {
    return isAutomaticallyValidated(step) ? 'Validation automatique' : step.validatedBy;
  },
  formatStartDate(step: TrainStep): string {
    return helpers.dateTimeFormat(step.startDate);
  },
  formatEndDate(step: TrainStep): string {
    return helpers.dateTimeFormat(step.endDate);
  },
  formatRoute(step: TrainStep): string {
    return `${step.startLocationLabel} → ${step.endLocationLabel}`;
  },
  formatMarketName(step: TrainStep): string {
    return step.marketName;
  },
  formatTrainNumber(step: TrainStep): string {
    return step.trainNumber;
  },
  formatTrainStatus(step: TrainStep): string {
    return STEP_PROBLEM_STATUS_NAMES[step.stepProblemStatus] ?? '';
  },
  formatIndiceComposition(step: TrainStep): string {
    return step.indiceComposition ?? 'inconnu';
  },
  formatTotalLength(step: TrainStep): string {
    const totalLength = step.vehicles
      .map((vehicle) => vehicle.length)
      .filter((l) => l != null)
      .reduce((sum, l) => sum! + l!, 0)!;
    return `${helpers.integerFormat(Math.ceil(totalLength))} m`;
  },
  formatTotalWeight(step: TrainStep): string {
    const totalWeight = step.vehicles
      .map((vehicle) => VehicleUtils.totalWeight(vehicle) ?? 0)
      .reduce((sum, w) => sum + w, 0);
    return `${helpers.integerFormat(Math.ceil(totalWeight / 1000))} t`;
  },
  formatTotalEffectiveBrakedWeight(step: TrainStep): string {
    const totalEffectiveBrakedWeight = step.vehicles
      .map((vehicle) => vehicle.effectiveBrakedWeight)
      .filter((ebw) => ebw != null)
      .reduce((sum, ebw) => sum! + ebw!, 0)!;
    return `${helpers.integerFormat(Math.ceil(totalEffectiveBrakedWeight))} t`;
  },
  formatAteOrGauge(step: TrainStep): string[] {
    const ateWagons = VehiclesUtils.getWagonATEs(VehiclesUtils.getWagons(step.vehicles));
    if (VehiclesUtils.hasGbGauge(step.vehicles)) {
      ateWagons.push('GB');
    }
    // We want to see 'NON' if no ATE or gauge
    return ateWagons.length ? ateWagons : ['NON'];
  },
  formatHazardousMaterials(step: TrainStep): string[] | string {
    return VehiclesUtils.hasHazardousMaterials(VehiclesUtils.getWagons(step.vehicles)) ? 'OUI' : 'NON';
  },
  formatFirstAgent(step: TrainStep): string {
    if (!step.agentNames || !step.agentNames.length) {
      return '—';
    }
    // Capitalize names
    return step.agentNames[0].toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
  },
  hasStepProblem(step: TrainStep): boolean {
    return step.stepProblemStatus && step.stepProblemStatus !== 'OK';
  },
  isStatusNew(step: TrainStep): boolean {
    return step.status === 'EMPTY';
  },
  isStatusValidated(step: TrainStep): boolean {
    return step.status === 'VALIDATED';
  },
  isStatusNotValidated(step: TrainStep): boolean {
    return step.status === 'NOT_VALIDATED';
  },
  withVehicleIndices(step: TrainStep): TrainStep {
    if (!step) {
      return step;
    }
    return {
      ...step,
      vehicles: VehiclesUtils.mapToVehiclesAndIndex(step.vehicles, step),
    };
  },

  /**
   * The step used to sort a train in the list.
   *
   * The active step for a past train is the last one.
   * The active step for a future train is the first one.
   */
  findSortStepIndex(train: TrainSummary, now: DateString): number {
    const activeStepIndex = train.steps.findIndex((step) => step.endDate >= now);
    if (activeStepIndex >= 0) {
      return activeStepIndex;
    } else {
      // The last step is the "active" step for a past train
      return train.steps.length - 1;
    }
  },

  /**
   * Active step for a train, according to the step start and end dates.
   *
   * A future or past train has no active step, so we return -1
   */
  findActiveStepIndex(train: Train, now: DateString): number {
    if (train.steps[0].startDate >= now) {
      return -1;
    }
    return train.steps.findIndex((step) => step.endDate >= now);
  },

  trainStatus(train: TrainSummary): ValidationStatus {
    const trainStatuses = new Set(train.steps.map((step) => step.status));
    if (trainStatuses.size === 1) {
      if (trainStatuses.has('EMPTY')) {
        return 'EMPTY';
      } else if (trainStatuses.has('VALIDATED')) {
        return 'VALIDATED';
      }
    }
    return 'NOT_VALIDATED';
  },

  linkToTrain(trainId: string): string {
    return `/trains/${encodeURIComponent(trainId)}`;
  },

  linkToTrainStep(stepId: string, tab?: TrainStepTab): string {
    if (tab) {
      return `/trains/steps/${stepId}?tab=${tab}`;
    }
    return `/trains/steps/${stepId}`;
  },
};
