import apiHelper from '../../api/apiHelper';

export const keepalive = () => fetch('/api/keepalive', { credentials: 'same-origin' });

export const authVerifier = () => fetch('/api/auth/verifier', { credentials: 'same-origin' });

export const getUserInfo = () => fetch('/api/auth/userInfo', { credentials: 'same-origin' });

export const logout = () => {
  return apiHelper.post('/api/logout');
};

export const redirectToLogout = () => {
  const form = document.createElement('form');
  form.action = '/api/logout';
  form.method = 'post';
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const redirectToLogin = () => {
  window.location.href = '/oauth2/authorization/azure';
};
