import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import helpers from '../../../helpers/helpers';
import './vehicleListItemStyles.scss';
import warningImg from '../../../assets/images/warning.svg';
import { VehicleType, WagonHazardousMaterial } from '../../../model/Vehicle';
import { formatDecimalNumber, formatIntegerNumber, ValidationStatus } from '../../../model/common';

type VehicleListItemProps = {
  canDeleteItem: boolean;
  effectiveBrakedWeight: number | null;
  hazardousMaterials: readonly WagonHazardousMaterial[];
  isActive: boolean;
  isAteOrGauge: boolean;
  isChargeD: boolean;
  isRollingGoods?: boolean;
  isFilled: boolean;
  isRat?: boolean;
  length: number | null;
  position: string | number | null;
  onClick: MouseEventHandler;
  onDelete: MouseEventHandler;
  registration: string | null;
  shouldDisplayWarningPictogram?: boolean;
  status: ValidationStatus;
  totalWeight: number | null;
  totalWeightText: string;
  type: VehicleType | 'pre_advice_wagon';
};

const formatDangerIdentificationNumber = (hazardousMaterials: readonly WagonHazardousMaterial[]) => {
  switch (hazardousMaterials.length) {
    case 0:
      return '';
    case 1:
      return hazardousMaterials[0].hazardousMaterial?.dangerIdentificationNumber;
    default:
      return 'XXX';
  }
};

const formatUnitedNationCode = (hazardousMaterials: readonly WagonHazardousMaterial[]) => {
  switch (hazardousMaterials.length) {
    case 0:
      return '';
    case 1:
      return hazardousMaterials[0].hazardousMaterial?.unitedNationsCode;
    default:
      return 'XXX';
  }
};

const VehicleListItem = ({
  canDeleteItem,
  effectiveBrakedWeight,
  hazardousMaterials,
  isActive,
  isAteOrGauge,
  isChargeD,
  isRollingGoods = false,
  isFilled,
  isRat,
  length,
  position,
  onClick,
  onDelete,
  registration,
  shouldDisplayWarningPictogram,
  status,
  totalWeight,
  totalWeightText,
  type,
}: VehicleListItemProps) => {
  const hasHazardousMaterials = hazardousMaterials.length > 0;
  const classes = {
    active: isActive,
    ate: isAteOrGauge,
    engine: type === 'engine',
    filled: isFilled,
    plate: hasHazardousMaterials,
    validated: status === 'VALIDATED',
    'not-validated': status === 'NOT_VALIDATED',
    vehicle: true,
    wagon: ['pre_advice_wagon', 'wagon'].includes(type),
  };
  const weightUnit = type === 'engine' ? 't' : 'kg';
  const weight = type === 'engine' && totalWeight != null ? totalWeight / 1000 : totalWeight;

  return (
    <div className={classNames(classes)} onClick={onClick}>
      <div className="vehicle-index">{position}</div>
      <div className="vehicle-serial">{helpers.registrationFormat(registration ?? '') ?? '-'}</div>
      {hasHazardousMaterials && (
        <div className="vehicle-hazmat">
          <div className="vehicle-danger-number">{formatDangerIdentificationNumber(hazardousMaterials)}</div>
          <div className="vehicle-un-code">{formatUnitedNationCode(hazardousMaterials)}</div>
        </div>
      )}
      <ul className="vehicle-props">
        <li>
          <div className="vehicle-props-value">{formatDecimalNumber(length, 'm')}</div>
          <div className="vehicle-props-label">longueur</div>
        </li>
        <li>
          <div className="vehicle-props-value">{formatIntegerNumber(weight, weightUnit)}</div>
          <div className="vehicle-props-label">{totalWeightText}</div>
        </li>
        <li>
          <div className="vehicle-props-value">{formatDecimalNumber(effectiveBrakedWeight, 't')}</div>
          <div className="vehicle-props-label">masse freinée réalisée</div>
        </li>
      </ul>
      {canDeleteItem && <button className="btn btn-round small remove" onClick={onDelete} type="button" />}
      <div className="vehicle-pictograms">
        {type === 'wagon' && <div className={classNames('rat', { on: isRat })}>RAT</div>}
        {(type === 'wagon' || type === 'pre_advice_wagon') && (
          <div className={classNames('charge-d', { on: isChargeD })}>D</div>
        )}
        {type === 'engine' && <div className={classNames('rolling-goods', { on: isRollingGoods })}>MR</div>}
      </div>
      {shouldDisplayWarningPictogram && (
        <div className="pre-advice-status-import-error-status">
          <img
            src={warningImg}
            alt="Erreurs pendant l'import"
            title="Des erreurs ont été rencontrées pendant l'import du wagon"
          />
        </div>
      )}
    </div>
  );
};

export default VehicleListItem;
