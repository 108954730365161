import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import React, { useEffect, useState } from 'react';
import { invalidateFirstLast, signFirstLast } from './TraceabilityActions';
import { isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import { FirstLastSignature } from '../../../commons/model/Traceability';
import FormSection from '../../../commons/components/form/FormSection';
import classNames from 'classnames';
import List from '../../../commons/components/list/List';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import { TraceabilitySectionProps } from './types';
import { withDerivedProps } from '../../../commons/components/hoc';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import { validateFirstLastForm } from '../../../commons/validation/validationUtils';

export type FirstLastFormData = {
  allBraked: boolean;
  firstNotBraked: boolean;
  lastNotBraked: boolean;
};

export type FirstLastSectionProps = TraceabilitySectionProps & {
  hasVehicles: boolean;
};

const FirstLastSection = ({
  traceability,
  editable,
  handleSubmit,
  change,
  error,
  submitFailed,
  hasVehicles,
}: FirstLastSectionProps & InjectedFormProps<FirstLastFormData>) => {
  const { username, email } = useAppSelector(selectSecurityContext);
  const dispatch = useAppDispatch();
  const signatures = traceability.firstLastSignatures;
  const [formOpen, setFormOpen] = useState(editable && signatures.length === 0);

  useEffect(() => {
    setFormOpen(editable && signatures.length === 0);
  }, [editable, signatures]);

  const doHandleSubmit = (formValues: FirstLastFormData) => {
    dispatch(signFirstLast(formValues.firstNotBraked, formValues.lastNotBraked));
  };

  const handleInvalidate = (signature: SignatureOrOffline<FirstLastSignature>) => () => {
    dispatch(invalidateFirstLast(isOfflineSignature(signature) ? null : signature.userId));
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      change('firstNotBraked', false);
      change('lastNotBraked', false);
    }
  };

  const handleCheckOne = (checked: boolean) => {
    if (checked) {
      change('allBraked', false);
    }
  };

  return (
    <FormSection
      title="Assurance premier et dernier véhicules freinés"
      className={classNames({ signed: Boolean(signatures.length) || !hasVehicles })}
    >
      {hasVehicles ? (
        <>
          {signatures.map((signature, index) => (
            <div className="subsection-content" key={index}>
              <List
                items={[
                  {
                    key: 'Observations',
                    value: (
                      <div>
                        {signature.firstNotBraked && <div>Premier wagon non freiné</div>}
                        {signature.lastNotBraked && <div>Dernier wagon non freiné</div>}
                        {!signature.firstNotBraked && !signature.lastNotBraked && <div>Néant</div>}
                      </div>
                    ),
                  },
                  ...SignatureUtils.basicSignatureFields(signature, username),
                ]}
              />
              {editable && (
                <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
              )}
            </div>
          ))}
          {editable &&
            (formOpen ? (
              <form className="first-last-form" autoComplete="off" noValidate onSubmit={handleSubmit(doHandleSubmit)}>
                <Field
                  component={CheckboxFormComponent}
                  name="allBraked"
                  label="Premier et dernier wagons freinés"
                  onCheck={handleCheckAll}
                />
                <Field
                  component={CheckboxFormComponent}
                  name="firstNotBraked"
                  label="Premier wagon non freiné"
                  onCheck={handleCheckOne}
                />
                <Field
                  component={CheckboxFormComponent}
                  name="lastNotBraked"
                  label="Dernier wagon non freiné"
                  onCheck={handleCheckOne}
                />
                {submitFailed && error && <div className="error-message">{error}</div>}
                <div className="buttons">
                  <button className="btn btn-accent" type="submit">
                    Valider et signer
                  </button>
                </div>
              </form>
            ) : (
              signatures.every((signature) => !isOfflineSignature(signature) && signature.userEmail !== email) && (
                <button className="btn btn-round small plus" onClick={() => setFormOpen(true)} type="button" />
              )
            ))}
          {!editable && signatures.length === 0 && <div className="empty">Non signé</div>}
        </>
      ) : (
        <div className="empty">Ce train n'a pas de wagons ou EMs en véhicule</div>
      )}
    </FormSection>
  );
};

const transformProps = ({ traceability }: FirstLastSectionProps) => {
  const lastSignature = traceability.firstLastSignatures[traceability.firstLastSignatures.length - 1];
  return {
    initialValues: {
      allBraked: lastSignature && !lastSignature.firstNotBraked && !lastSignature.lastNotBraked,
      firstNotBraked: lastSignature?.firstNotBraked ?? false,
      lastNotBraked: lastSignature?.lastNotBraked ?? false,
    },
  };
};

export default withDerivedProps(transformProps)(
  reduxForm<FirstLastFormData, FirstLastSectionProps>({
    form: 'traceabilityFirstLastForm',
    initialValues: {
      allBraked: false,
      firstNotBraked: false,
      lastNotBraked: false,
    },
    validate: validateFirstLastForm,
  })(FirstLastSection as any) as any,
);
