import moment from 'moment';
import PreAdviceWagonEntity from './PreAdviceWagonEntity';
import { CAPTRAIN_FRANCE, CAPTRAIN_FRANCE_ID } from '../model/templates';

export default class PreAdviceEntity {
  static STATUSES = {
    EMPTY: 'EMPTY',
    NOT_VALIDATED: 'NOT_VALIDATED',
    VALIDATED: 'VALIDATED',
  };

  static IMPORT_ERROR_STATUSES = {
    WITH_ERRORS: 'WITH_ERRORS',
    WITH_WARNINGS: 'WITH_WARNINGS',
    NO_ERROR: 'NO_ERROR',
  };

  /**
   * Validate the pre-advice form.
   */
  static validate(preAdvice) {
    const {
      id,
      receiver,
      trainNumber,
      journey: { endStation, startDateTime, startStation } = {},
      borderCrossing: { station, initialEstimatedDateTime, estimatedDateTime } = {},
    } = preAdvice;
    const errors = { journey: {}, borderCrossing: {} };
    if (!receiver?.id) {
      errors.receiver = { id: 'Le destinataire est obligatoire' };
    }
    if (!startStation?.label) {
      errors.journey.startStation = { label: 'La gare de départ est obligatoire' };
    }
    if (!endStation?.label) {
      errors.journey.endStation = { label: "La gare d'arrivée est obligatoire" };
    }
    if (!startDateTime) {
      errors.journey.startDateTime = 'La date de départ est obligatoire';
    }
    if (!trainNumber) {
      errors.trainNumber = 'Le numéro de train est obligatoire';
    } else if (!/^\d{5,6}$/.test(trainNumber)) {
      errors.trainNumber = 'Le numéro de train doit être composé de 5 ou 6 chiffres';
    }
    if (id) {
      if (!station?.label) {
        errors.borderCrossing.station = { label: 'La gare du passage de frontière est obligatoire' };
      }
      if (!initialEstimatedDateTime) {
        errors.borderCrossing.initialEstimatedDateTime = 'La date de passage initialement prévue est obligatoire';
      }
      if (!estimatedDateTime) {
        errors.borderCrossing.estimatedDateTime = 'La date de passage prévue est obligatoire';
      }
    }
    return errors;
  }

  /**
   * Validate the wagons statuses.
   */
  validateWagons() {
    if (!this.wagons?.length) {
      return 'La pré-annonce ne contient aucun wagons';
    } else if (this.getNbValidatedWagons() !== this.wagons.length) {
      return "Au moins un wagon n'est pas validé";
    }
    return null;
  }

  static newPreAdvice() {
    return new PreAdviceEntity({
      id: null,
      status: 'EMPTY',
      sender: CAPTRAIN_FRANCE,
      receiver: null,
      receptionDateTime: null,
      usedInCompositionStatus: 'NOT_USED',
      journey: {
        startDateTime: null,
        startStation: null,
        endStation: null,
      },
      borderCrossing: {
        station: null,
        initialEstimatedDateTime: null,
        estimatedDateTime: null,
      },
      validation: {
        dateTime: null,
        validatorFirstName: null,
        validatorLastName: null,
      },
      wagons: [],
      importErrors: [],
    });
  }

  constructor(serverPreAdvice) {
    this.id = serverPreAdvice.id;
    this.status = serverPreAdvice.status;
    this.trainNumber = serverPreAdvice.trainNumber;
    this.sender = serverPreAdvice.sender;
    this.receiver = serverPreAdvice.receiver;
    this.receptionDateTime = serverPreAdvice.receptionDateTime;
    this.usedInCompositionStatus = serverPreAdvice.usedInCompositionStatus;
    this.journey = {
      startDateTime: serverPreAdvice.journey.startDateTime ? moment(serverPreAdvice.journey.startDateTime) : null,
      startStation: serverPreAdvice.journey.startStation,
      endStation: serverPreAdvice.journey.endStation,
    };
    const {
      station = null,
      initialEstimatedDateTime = null,
      estimatedDateTime = null,
    } = serverPreAdvice.borderCrossing || {};
    this.borderCrossing = {
      station,
      initialEstimatedDateTime,
      estimatedDateTime,
    };
    // noinspection JSUnresolvedVariable
    const { dateTime = null, validatorFirstName = null, validatorLastName = null } = serverPreAdvice.validation || {};
    this.validation = {
      dateTime,
      validatorFirstName,
      validatorLastName,
    };

    this.wagons = serverPreAdvice.wagons.map((serverWagon) => new PreAdviceWagonEntity(serverWagon));
    this.importErrors = serverPreAdvice.importErrors;
  }

  toSaveOrUpdateCommand() {
    return {
      trainNumber: this.trainNumber,
      receiverId: this.receiver.id,
      startDateTime: moment(this.journey.startDateTime).format(),
      startStationId: this.journey.startStation.id,
      endStationId: this.journey.endStation.id,
      borderCrossingStationId: this.borderCrossing.station ? this.borderCrossing.station.id : null,
      borderCrossingInitialEstimatedDateTime: this.borderCrossing.initialEstimatedDateTime,
      borderCrossingEstimatedDateTime: this.borderCrossing.estimatedDateTime,
    };
  }

  isValidated() {
    return this.status === PreAdviceEntity.STATUSES.VALIDATED;
  }

  getNbWagons() {
    return this.wagons.length;
  }

  getNbValidatedWagons() {
    return this.wagons.filter((wagon) => wagon.isValidated()).length;
  }

  copyAndReplaceWagons(wagonEntities) {
    const copy = PreAdviceEntity.newPreAdvice();
    copy.id = this.id;
    copy.status = PreAdviceEntity.STATUSES.NOT_VALIDATED;
    copy.trainNumber = this.trainNumber;
    copy.sender = {
      ...this.sender,
    };
    copy.receiver = {
      ...this.receiver,
    };
    copy.journey = {
      startStation: {
        ...this.journey.startStation,
      },
      endStation: {
        ...this.journey.endStation,
      },
      startDateTime: this.journey.startDateTime ? moment(this.journey.startDateTime) : null,
    };
    copy.borderCrossing = {
      station: {
        ...this.borderCrossing.station,
      },
      borderCrossingInitialEstimatedDateTime: this.borderCrossing.borderCrossingInitialEstimatedDateTime
        ? moment(this.borderCrossing.borderCrossingInitialEstimatedDateTime)
        : null,
      borderCrossingEstimatedDateTime: this.borderCrossing.borderCrossingEstimatedDateTime
        ? moment(this.borderCrossing.borderCrossingEstimatedDateTime)
        : null,
    };
    copy.wagons = wagonEntities;
    return copy;
  }

  /**
   * Return a copy of the current pre-advice with the given persisted wagons.
   *
   * The given wagons are a subset of the the current wagons (they have been updated) or they
   * may not yet be associated with the current pre-advice (they have been added).
   *
   * @param updatedWagonEntities
   * @returns {PreAdviceEntity}
   */
  copyWithPersistedWagons(updatedWagonEntities) {
    const updatedWagonIds = updatedWagonEntities.map((w) => w.id);
    const notUpdatedWagons = this.wagons.filter((w) => !updatedWagonIds.includes(w.id));
    const wagons = notUpdatedWagons.concat(updatedWagonEntities).sort((w1, w2) => w1.position - w2.position);
    return this.copyAndReplaceWagons(wagons);
  }

  copyWithoutWagon(wagonId) {
    const wagons = this.wagons.filter((w) => w.id !== wagonId);
    return this.copyAndReplaceWagons(wagons);
  }

  isReceivedByVfli() {
    return this.receiver?.id === CAPTRAIN_FRANCE_ID;
  }
}
