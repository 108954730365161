/**
 * Profiles table administration component
 */
import React from 'react';
import GlobalError from '../../../commons/components/GlobalError';
import roles, { RoleId } from '../../../commons/security/userRoles';
import './profilesTableStyles.scss';
import { ProfilesState } from '../profilesDucks';
import classNames from 'classnames';
import { Profile } from '../../../commons/model/userProfile';

const rolesList = (profile: Profile) =>
  Object.keys(roles).map((stringRoleId: string) => {
    const roleId: RoleId = stringRoleId as RoleId;
    return (
      <div key={`role-${roleId}`} className={classNames('role', { on: profile.roles.includes(roleId) })}>
        {roles[roleId].name}
      </div>
    );
  });

type ProfilesTableProps = {
  profiles: ProfilesState;
  onRowClick: any;
  readOnly: boolean;
};

const ProfilesTable = ({ profiles, onRowClick, readOnly }: ProfilesTableProps) => {
  if (profiles.error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des profils.
      </GlobalError>
    );
  }

  if (!profiles.data.length) {
    return <div className="maincontent">{!profiles.loading && <span>Aucun profil trouvé.</span>}</div>;
  }

  return (
    <div className="table table-users-profiles">
      <div className="table-body">
        {profiles.data.map((profile: Profile) => (
          <div
            key={`profile-${profile.id}`}
            className="table-body-row"
            onClick={() => !readOnly && onRowClick(profile)}
          >
            <div className="cell-profile-title">{profile.name}</div>
            <div className="cell-profile-roles">{rolesList(profile)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfilesTable;
