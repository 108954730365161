import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Overlay from '../../../commons/components/overlay/Overlay';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';

import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';

import Input from '../../../commons/components/input/Input';
import { getOwner } from '../../../commons/selectors/selectors';

import { deleteOwner, persistOwner } from './ownerDucks';
import FieldLabelWithTooltip from '../../../commons/components/form/FieldLabelWithTooltip';
import { toFormValues } from './ownerUtils';

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
// noinspection RegExpRedundantEscape
const EMAIL_REG_EXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
  const trimmedEmail = email.trim();
  if (!EMAIL_REG_EXP.test(trimmedEmail)) {
    return 'Adresses email invalides';
  }
  if (trimmedEmail.length > 128) {
    return 'Chaque adresse doit faire au plus 128 caractères';
  }
  return null;
};

export const validateEmails = (emails) => {
  if (!emails || /^\s*$/.test(emails)) {
    return 'Au moins une adresse doit être fournie';
  }
  return emails
    .split(',')
    .map(validateEmail)
    .find((errorMessage) => Boolean(errorMessage));
};

const validateName = (name) => {
  if (!name || /^\s*$/.test(name)) {
    return 'Le nom est obligatoire';
  }
  return name.length > 64 ? 'Longueur maximale : 64 caractères' : null;
};

const validateVKM = (vkm) => {
  if (!vkm || /^\s*$/.test(vkm)) {
    return 'Le VKM est obligatoire';
  }
  return vkm.length > 64 ? 'Longueur maximale : 64 caractères' : null;
};

const OwnerDetails = ({
  handleSubmit,
  isNewOwner,
  ownerId,
  requestHideOverlay,
  requestOpenConfirmDialog,
  requestPersistOwner,
}) => (
  <Overlay
    name="owner"
    className="owner-details"
    title={isNewOwner ? 'Ajouter un détenteur' : 'Modifier un détenteur'}
    closeButton
  >
    <form
      autoComplete={false}
      className="fields"
      onSubmit={handleSubmit((values) => requestPersistOwner(values, ownerId))}
    >
      <div className="overlay-form">
        <Field component={Input} labelText="Nom" name="name" type="text" validate={validateName} />
        <Field component={Input} labelText="VKM" name="vkm" type="text" validate={validateVKM} />
        <Field
          component={Input}
          labelText={
            <FieldLabelWithTooltip label="Emails" tooltipText="Les adresses doivent être séparées par des virgules" />
          }
          name="emails"
          multiple={true}
          type="email"
          validate={validateEmails}
        />
        <div className="btn-group">
          <button className="btn btn-success" type="submit">
            {isNewOwner ? 'Ajouter' : 'Enregistrer'}
          </button>
          <button className="btn btn-link" onClick={requestHideOverlay} type="button">
            Annuler
          </button>
          {ownerId && (
            <button
              className="btn btn-icon remove"
              type="button"
              onClick={() =>
                requestOpenConfirmDialog({
                  title: 'Confirmez-vous la désactivation du détenteur ?',
                  actionText: 'Désactiver',
                  action: () => deleteOwner(ownerId),
                  actionClass: 'danger',
                })
              }
            >
              Désactiver
            </button>
          )}
        </div>
      </div>
    </form>
  </Overlay>
);

const OwnerDetailsForm = reduxForm({
  enableReinitialize: true,
  form: 'ownerForm',
})(OwnerDetails);

const mapStateToProps = (state) => {
  const { error, loading, owner } = getOwner(state);
  return {
    error,
    initialValues: toFormValues(owner),
    isNewOwner: !owner.id,
    loading,
    ownerId: owner.id,
  };
};

const mapDispatchToProps = {
  requestHideOverlay: hideOverlay,
  requestOpenConfirmDialog: openConfirmDialog,
  requestPersistOwner: persistOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDetailsForm);
