import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  normalizePositiveFloatTwoDecimal,
  normalizePositiveNumber,
  normalizeRegistration,
} from '../../commons/redux-form/valueNormalizer';
import Mask from '../../commons/mask/Mask';
import { getPreAdviceWriteableWagonNumbers } from '../../commons/selectors/selectors';
import Input from '../../commons/components/input/Input';
import { parseToFloat, parseToInt } from '../../commons/redux-form/valueParser';
import { formatDecimalAndSeparateThousands, formatDecimalNumber } from '../../commons/redux-form/valueFormatter';
import { requestUpdatePreAdviceWagon, requestUpdateWagonPosition } from './preAdviceWagonDucks';
import StationField from '../../commons/components/input/StationField';
import FormSection from '../../commons/components/form/FormSection';
import Dropdown from '../../commons/components/dropdown/Dropdown';
import { foreignCountries } from '../../commons/countries/foreignCountries';
import PreAdviceHazardousMaterials from './hazardous-material/PreAdviceHazardousMaterials';
import { formName, getErrorTypesForCategory, IMPORT_ERROR_CATEGORIES } from './preAdviceWagonFormUtils';
import WagonWeightLabel from '../../commons/components/vehicles/vehicle/WagonWeightLabel';
import PreAdviceWagonDamages from './damages/PreAdviceWagonDamages';
import { openCopyJourneyDialog } from './journey/copyJourneyDucks';
import brakeTypes from '../../commons/brake-types/brakeTypes';
import { errorTypes } from '../../commons/entity/PreAdviceWagonImportErrorEntity';
import PreAdviceWagonImportErrors from './import-error/PreAdviceWagonImportErrors';
import { asyncValidateStation, asyncValidation } from '../../commons/components/input/asyncValidation';
import wagonCache from '../../commons/templates/wagonCache';
import CheckboxFormComponent from '../../commons/components/checkbox/CheckboxFormComponent';
import DisabledInput from '../../commons/components/input/DisabledInput';
import Switch3 from '../../commons/components/switch3/Switch3';
import { validateWagonCharge } from '../../commons/validation/validationUtils';

const frenchAteNumberSaveAction = (changeFormField) => (wagon, propertyToUpdate) => {
  if (!propertyToUpdate['ateNumbers.frenchAteNumber']) {
    const ateNumbers = {
      frenchAteNumber: null,
      foreignAteNumber: null,
      foreignAteCountry: null,
      speedLimit: null,
    };
    changeFormField('ateNumbers', ateNumbers);
    return requestUpdatePreAdviceWagon(wagon, { ateNumbers });
  }
  return requestUpdatePreAdviceWagon(wagon, propertyToUpdate);
};

export const shouldDisplayJourney = ({ journey, errors }, readOnly) => {
  return Boolean(
    !readOnly ||
      journey.startStation?.id ||
      journey.endStation?.id ||
      journey.sender ||
      journey.addressee ||
      errors.some((importError) => importError.type === errorTypes.STATION),
  );
};

export const shouldDisplayDamages = (wagon, readOnly) =>
  Boolean(
    !readOnly ||
      wagon.damages.damageTemplates.length ||
      wagon.getErrorsOfTypes(getErrorTypesForCategory(IMPORT_ERROR_CATEGORIES.DAMAGE)).length > 0,
  );

const PreAdviceWagonForm = ({ change, canModifyPreAdvices, wagon, nbWagons }) => {
  const dispatch = useDispatch();
  const writeableWagons = useSelector(getPreAdviceWriteableWagonNumbers, shallowEqual);
  const hasWriteableWagons = writeableWagons.some((position) => position !== wagon.position);
  const readOnly = !canModifyPreAdvices || wagon.isValidated();
  return (
    <form autoComplete="off">
      <div className="fields">
        <Field
          component={Input}
          disabled={readOnly}
          name="referentialData.registration"
          labelText="Immatriculation *"
          placeholder="Ex: 12 34 567 8901-2"
          format={(value) => value && Mask.REGISTRATION.format(value)}
          pattern="[0-9]*"
          inputMode="numeric"
          normalize={normalizeRegistration}
          autocompleter={wagonCache}
          allowManualValues={true}
          normalizeOnSave={normalizeRegistration}
          saveAction={requestUpdatePreAdviceWagon}
          data={wagon}
        />
        <Field
          component={Input}
          disabled={readOnly}
          name="position"
          labelText="Position dans le train *"
          unit={'/' + nbWagons}
          pattern="[0-9]*"
          inputMode="numeric"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          saveAction={(w, propertyToUpdate) => (dispatch) => {
            // Stored positions are 0-based, displayed positions are 1-based
            dispatch(requestUpdateWagonPosition(w, propertyToUpdate.position - 1));
          }}
          data={wagon}
        />
        <Field
          component={Dropdown}
          data={wagon}
          disabled={readOnly}
          labelText="Régime de freinage *"
          name="brakeType"
          options={brakeTypes}
          saveAction={requestUpdatePreAdviceWagon}
        />
        <Field
          component={Input}
          disabled={readOnly}
          name="referentialData.length"
          labelText="Longueur *"
          unit="m"
          format={formatDecimalNumber}
          pattern="[0-9]*"
          inputMode="numeric"
          normalize={normalizePositiveFloatTwoDecimal}
          normalizeOnSave={parseToFloat}
          saveAction={requestUpdatePreAdviceWagon}
          data={wagon}
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText="Tare *"
          name="referentialData.tare"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
          unit="kg"
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText={<WagonWeightLabel hazardousMaterials={wagon.hazardousMaterials} />}
          name="loadWeight"
          normalize={normalizePositiveFloatTwoDecimal}
          normalizeOnSave={parseToFloat}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
          unit="kg"
        />
        <DisabledInput
          name="calculatedTotalWeight"
          labelText="Masse totale"
          placeholder=""
          unit="kg"
          value={formatDecimalAndSeparateThousands(wagon.getTotalWeight())}
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          inputMode="numeric"
          labelText="Masse freinée réalisée *"
          name="effectiveBrakedWeight"
          format={formatDecimalNumber}
          normalize={normalizePositiveFloatTwoDecimal}
          normalizeOnSave={parseToFloat}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
          unit="t"
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          inputMode="numeric"
          labelText="Nombre d’essieux *"
          name="referentialData.nbAxles"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText="Régime de vitesse lié à la construction *"
          name="referentialData.speedLimit"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
          unit="km/h"
        />
        <Field
          component={Input}
          disabled={readOnly}
          data={wagon}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText="Vitesse max pour cette circulation *"
          name="speedLimitForCurrentTrain"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          pattern="[0-9]*"
          saveAction={requestUpdatePreAdviceWagon}
          unit="km/h"
        />
        <div className="input input-checkboxes">
          <Field
            component={CheckboxFormComponent}
            disabled={readOnly}
            name="referentialData.doubleWagon"
            label="Wagon double"
            saveAction={requestUpdatePreAdviceWagon}
            data={wagon}
          />
        </div>
        <Field
          component={Switch3}
          disabled={readOnly}
          getError={validateWagonCharge}
          name="charge"
          labelText="Charge *"
          labelLeft="C"
          labelRight="D"
          saveAction={requestUpdatePreAdviceWagon}
          data={wagon}
        />
      </div>

      {shouldDisplayJourney(wagon, readOnly) && (
        <FormSection title="Trajet">
          <PreAdviceWagonImportErrors wagon={wagon} errorCategory={IMPORT_ERROR_CATEGORIES.STATION} />
          <div className="fields two-columns">
            <StationField
              disabled={readOnly}
              name="journey.startStation.label"
              className="icon route-from"
              placeholder="De"
              labelText="Gare d'origine *"
              saveAction={requestUpdatePreAdviceWagon}
              data={wagon}
            />
            <StationField
              disabled={readOnly}
              name="journey.endStation.label"
              className="icon route-to"
              placeholder="À"
              labelText="Gare de destination *"
              saveAction={requestUpdatePreAdviceWagon}
              data={wagon}
            />
            <Field
              disabled={readOnly}
              component={Input}
              data={wagon}
              labelText="Expéditeur *"
              name="journey.sender"
              saveAction={requestUpdatePreAdviceWagon}
            />
            <Field
              disabled={readOnly}
              component={Input}
              data={wagon}
              labelText="Destinataire *"
              name="journey.addressee"
              saveAction={requestUpdatePreAdviceWagon}
            />
            {canModifyPreAdvices && hasWriteableWagons && (
              <button type="button" className="btn btn-link" onClick={() => dispatch(openCopyJourneyDialog(wagon))}>
                Propager aux autres wagons
              </button>
            )}
          </div>
        </FormSection>
      )}
      {Boolean(
        !readOnly ||
          wagon.ateNumbers.frenchAteNumber ||
          wagon.ateNumbers.speedLimit ||
          wagon.ateNumbers.foreignAteNumber ||
          wagon.ateNumbers.foreignAteCountry,
      ) && (
        <FormSection title="Transport exceptionnel">
          <div className="fields two-columns">
            <Field
              component={Input}
              data={wagon}
              disabled={readOnly}
              labelText="Numéro ATE ou gabarit français"
              name="ateNumbers.frenchAteNumber"
              saveAction={frenchAteNumberSaveAction(change)}
            />
            <Field
              component={Input}
              data={wagon}
              disabled={readOnly || !wagon.isAte()}
              format={formatDecimalAndSeparateThousands}
              inputMode="numeric"
              labelText={`Vitesse maximale pour transport exceptionnel ${wagon.isAte() ? '*' : ''}`}
              name="ateNumbers.speedLimit"
              normalize={normalizePositiveNumber}
              normalizeOnSave={parseToInt}
              pattern="[0-9]*"
              placeholder={`${wagon.isAte() ? 'Saisir' : ' '}`}
              saveAction={requestUpdatePreAdviceWagon}
              unit="km/h"
            />
            <Field
              component={Input}
              data={wagon}
              disabled={readOnly || !wagon.isAte()}
              inputMode="numeric"
              labelText={`Numéro ATE ou gabarit étranger ${wagon.isAte() ? '*' : ''}`}
              name="ateNumbers.foreignAteNumber"
              pattern="[0-9]*"
              placeholder={`${wagon.isAte() ? 'Saisir' : ' '}`}
              saveAction={requestUpdatePreAdviceWagon}
            />
            <Field
              component={Dropdown}
              data={wagon}
              disabled={readOnly || !wagon.isAte()}
              labelText={`Pays ${wagon.isAte() ? '*' : ''}`}
              name="ateNumbers.foreignAteCountry"
              options={foreignCountries}
              placeholder={`${wagon.isAte() ? 'Sélectionner' : ''}`}
              saveAction={requestUpdatePreAdviceWagon}
            />
          </div>
        </FormSection>
      )}
      {shouldDisplayDamages(wagon, readOnly) && (
        <FormSection title="Avaries">
          <PreAdviceWagonImportErrors wagon={wagon} errorCategory={IMPORT_ERROR_CATEGORIES.DAMAGE} />
          <PreAdviceWagonDamages wagon={wagon} readOnly={readOnly} />
        </FormSection>
      )}

      <PreAdviceWagonImportErrors wagon={wagon} errorCategory={IMPORT_ERROR_CATEGORIES.HAZARDOUS_MATERIAL} />
      <FieldArray component={PreAdviceHazardousMaterials} name="hazardousMaterials" readOnly={readOnly} wagon={wagon} />
    </form>
  );
};

export default reduxForm({
  form: formName,
  ...asyncValidation({
    'journey.startStation.label': asyncValidateStation,
    'journey.endStation.label': asyncValidateStation,
  }),
})(PreAdviceWagonForm);
