import React from 'react';
import { useSelector } from 'react-redux';

import { COMPOSITION_WRITE, DAMAGE_REPORT_WRITE } from '../../../../security/userRoles';
import Switch2 from '../../../switch2/Switch2';
import { updateWagon } from '../../../../../trains/step/vehicleActions';
import './wagonHeaderAdditionalActionsStyles.scss';
import { addDamageReport } from '../../../../../damage-reports/damage-report/damageReportDucks';
import wagonCache from '../../../../templates/wagonCache';
import ownerCache from '../../../../templates/ownerCache';
import { TrainStep } from '../../../../model/Train';
import { Wagon } from '../../../../model/Vehicle';
import TrainUtils from '../../../../model/TrainUtils';
import { useAppDispatch } from '../../../../store/hooks';
import { selectSecurityContextRoles } from '../../../../selectors/selectors';

type WagonHeaderAdditionalActionsProps = {
  step: TrainStep;
  wagon: Wagon;
};

const WagonHeaderAdditionalActions = ({ step, wagon }: WagonHeaderAdditionalActionsProps) => {
  const roles = useSelector(selectSecurityContextRoles);
  const dispatch = useAppDispatch();

  const canUpdateRat = roles.includes(COMPOSITION_WRITE) && !TrainUtils.isStatusValidated(step);
  const canOpenPvca = roles.includes(DAMAGE_REPORT_WRITE);

  const updateRat = () => dispatch(updateWagon(wagon, { rat: !wagon.rat }));

  const openPvca = async () => {
    const wagonTemplate = await wagonCache.findItemById(wagon.registration!);
    const ownerTemplate = await ownerCache.findItemById(wagonTemplate!.ownerId!);
    const loaded = (wagon?.loadWeight ?? 0) > 0;
    dispatch(addDamageReport(true, wagon, ownerTemplate, step, loaded));
  };
  return (
    <>
      {canOpenPvca && (
        <>
          <button
            className="btn btn-icon btn-link btn-open-damage-report"
            disabled={!wagon.registration}
            onClick={openPvca}
            type="button"
          >
            Ouvrir un PVCA
          </button>
        </>
      )}
      <Switch2 id="switch-rat" labelOn="RAT" value={wagon.rat} disabled={!canUpdateRat} onChange={updateRat} />
    </>
  );
};

export default WagonHeaderAdditionalActions;
