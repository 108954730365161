import React, { ReactNode, UIEventHandler, useEffect, useState } from 'react';

const CHUNK_SIZE = 100;

type ScrollableTableBodyProps = {
  children: (() => ReactNode)[];
};

const ScrollableTableBody = ({ children }: ScrollableTableBodyProps) => {
  const [size, setSize] = useState(0);
  useEffect(() => {
    setSize(Math.min(CHUNK_SIZE, children.length));
  }, [children]);

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target as HTMLDivElement;
    const scrollPosition = scrollHeight - clientHeight;

    if (scrollPosition - scrollTop < 400 && size < children.length) {
      setSize(Math.min(size + CHUNK_SIZE, children.length));
    }
  };

  return (
    <div className="table-body" onScroll={handleScroll}>
      {children.slice(0, size).map((child) => child())}
    </div>
  );
};

export default ScrollableTableBody;
