import React from 'react';
import { change, Field } from 'redux-form';

import Dropdown from '../dropdown/Dropdown';
import hazmatCache from '../../templates/hazmatCache';
import { HazardousMaterial } from '../../model/templates';
import { Wagon, WagonHazardousMaterial } from '../../model/Vehicle';
import { AppAction, AppDispatch } from '../../store/store';
import PreAdviceHazardousMaterialEntity from '../../entity/PreAdviceHazardousMaterialEntity';

type HazardousMaterialLabelProps = {
  /**
   * Name of the Redux form
   */
  formName: string;
  /**
   * The edited hazardous material
   */
  hazardousMaterial: PreAdviceHazardousMaterialEntity | WagonHazardousMaterial;
  /**
   * The candidates
   */
  hazardousMaterialCandidates: HazardousMaterial[];
  /**
   * A callback to change the candidates
   */
  setHazardousMaterialCandidates: (candidates: HazardousMaterial[]) => any;
  /**
   * The field name of the hazardous material
   */
  hazardousMaterialFieldName: string;
  /**
   * Boolean indicating whether the form is read-only
   */
  readOnly: boolean;
  /**
   * The edited wagon
   */
  wagon: Wagon;
  /**
   * The action to call when a hazardous material has been selected
   */
  saveAction: (wagon: Wagon, propertiesToUpdate: object) => AppAction;
};

/**
 * Component that displays:
 *   - The hazardous material label if there is a selected one ;
 *   - Or the select field if several candidates are available ;
 *   - Or a default message.
 */
const HazardousMaterialLabel = ({
  formName,
  hazardousMaterial,
  hazardousMaterialCandidates,
  setHazardousMaterialCandidates,
  hazardousMaterialFieldName,
  readOnly,
  saveAction,
  wagon,
}: HazardousMaterialLabelProps) => {
  const templateFieldName = `${hazardousMaterialFieldName}.hazardousMaterial`;
  const fieldName = `${templateFieldName}.candidates`;
  const innerSaveAction = (data: Wagon, propertyToUpdate: any) => (dispatch: AppDispatch) => {
    const selectedHazardMaterial = hazardousMaterialCandidates[propertyToUpdate[fieldName]];
    if (!selectedHazardMaterial) {
      return;
    }

    // Persist data server-side
    const updateCommand = { [templateFieldName]: selectedHazardMaterial };
    dispatch(saveAction(data, updateCommand));

    // Fill other fields of hazardous material form
    dispatch(change(formName, templateFieldName, selectedHazardMaterial));
    dispatch(change(formName, fieldName, propertyToUpdate[fieldName]));

    // Update the candidates, since only one match all the fields now
    setHazardousMaterialCandidates([selectedHazardMaterial]);
  };

  return (
    <div className="hazmat-description">
      <div className="hazmat-description-title">Libellé</div>
      {!readOnly && (
        <Field
          component={Dropdown}
          data={wagon}
          displayDropDownElement={hazardousMaterialCandidates.length > 1}
          name={fieldName}
          options={hazardousMaterialCandidates}
          formatOption={(hazmat: HazardousMaterial) => hazmatCache.formatItem(hazmat)}
          saveAction={innerSaveAction}
        />
      )}

      {hazardousMaterial.hazardousMaterial?.label ??
        'Remplissez les informations de la plaque orange ' +
          "et le groupe d'emballage pour obtenir les détails de la matière dangereuse"}
    </div>
  );
};

export default HazardousMaterialLabel;
