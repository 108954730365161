import { AppDispatch } from '../../../commons/store/store';
import { RootState } from '../../../commons/reducers/rootReducer';
import { selectSecurityContextRoles, selectTrainDiffs, selectTrainStep } from '../../../commons/selectors/selectors';
import { BrakingBulletin } from '../../../commons/model/BrakingBulletin';
import { offlineUpdateBrakingBulletin, persistTrainsIfNeeded } from '../../offline/offlineTrainsDucks';
import { TrainStep } from '../../../commons/model/Train';
import { closeBrakingBulletin, openBrakingBulletin, trainStepUpdated } from '../trainStepDucks';
import { applyBrakingBulletinDiff, applyTrainDiffs } from '../../../commons/model/TrainDiff';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';
import { BrakingBulletinFormData, BrakingBulletinSignFormData } from './BrakingBulletinForm';
import { hideOverlay, showOverlay } from '../../../commons/components/overlay/overlayDucks';
import { COMPOSITION_WRITE } from '../../../commons/security/userRoles';
import { writeDefaultSignatureType } from './brakingBulletinUtils';

export const afterBrakingBulletinUpdated = (step: TrainStep) => (dispatch: AppDispatch, getState: () => RootState) => {
  const diffs = selectTrainDiffs(step.id)(getState());
  dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
  dispatch(persistTrainsIfNeeded());
};

/**
 * Add a new braking bulletin to the current step.
 */
export const addBrakingBulletin = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const step = selectTrainStep(getState()).data!;
  const brakingBulletin = BrakingBulletinUtils.createNewBrakingBulletin(step);
  dispatch(
    offlineUpdateBrakingBulletin({
      stepId: step.id,
      diff: {
        id: brakingBulletin.id,
        brakingBulletin,
      },
    }),
  );
  dispatch(afterBrakingBulletinUpdated(step));
  dispatch(openBrakingBulletin(brakingBulletin));
  dispatch(showOverlay('braking-bulletin'));
};

/**
 * Open an existing braking bulletin, updating its denormalized data from the current step if it is in draft status.
 */
export const openBrakingBulletinAndDenormalize =
  (brakingBulletin: BrakingBulletin) => (dispatch: AppDispatch, getState: () => RootState) => {
    if (brakingBulletin.status !== 'DRAFT') {
      dispatch(openBrakingBulletin(brakingBulletin));
      dispatch(showOverlay('braking-bulletin'));
      return;
    }

    const userRoles = selectSecurityContextRoles(getState());
    const step = selectTrainStep(getState()).data!;
    const bbDiff = BrakingBulletinUtils.createBrakingBulletinDiffFromStep(brakingBulletin, step);
    let updatedBB: BrakingBulletin;
    if (userRoles.includes(COMPOSITION_WRITE)) {
      dispatch(offlineUpdateBrakingBulletin({ stepId: step.id, diff: bbDiff }));
      dispatch(afterBrakingBulletinUpdated(step));
      // Get the updated braking bulletin from the updated step
      updatedBB = selectTrainStep(getState()).data!.brakingBulletins.find(
        (bb) => bb.revision === brakingBulletin.revision,
      )!;
    } else {
      updatedBB = applyBrakingBulletinDiff(brakingBulletin, bbDiff)[0]!;
    }
    dispatch(openBrakingBulletin(updatedBB));
    dispatch(showOverlay('braking-bulletin'));
  };

/**
 * Re-compute the composition summary (in particular the effective braked weight), when the "LL" observation is added
 * or removed from the braking bulletin.
 */
export const updateBrakingBulletinLLFlag = (ll: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
  const trainStepState = selectTrainStep(getState());
  const step = trainStepState.data!;
  const brakingBulletin = trainStepState.brakingBulletin!;
  const updatedBB = {
    ...brakingBulletin,
    compositionSummary: BrakingBulletinUtils.computeBrakingBulletinCompositionSummary(step, ll),
  };
  // Do not persist the modification, just update the local state
  dispatch(openBrakingBulletin(updatedBB));
};

/**
 * Save modifications to the currently opened braking bulletin.
 */
export const updateBrakingBulletin =
  (formData: BrakingBulletinFormData) =>
  (confirmFormValues: BrakingBulletinSignFormData) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (confirmFormValues.signatureType) {
      writeDefaultSignatureType(confirmFormValues.signatureType);
    }
    const stepState = selectTrainStep(getState());
    const step = stepState.data!;
    const brakingBulletin = stepState.brakingBulletin!;
    const bbDiff = BrakingBulletinUtils.createBrakingBulletinDiffFromForm(brakingBulletin, {
      ...formData,
      ...confirmFormValues,
    });
    dispatch(offlineUpdateBrakingBulletin({ stepId: step.id, diff: bbDiff }));
    if (formData.sign) {
      dispatch(invalidateSignedBrakingBulletinsIfNeeded(step));
    }

    dispatch(afterBrakingBulletinUpdated(step));
    dispatch(closeBrakingBulletin());
    dispatch(hideOverlay());
  };

/**
 * Invalidate the signed braking bulletin(s) of a step.
 *
 * Note that there should be at most one "signed" braking bulletin in a step at any given time, but we invalidate every
 * one we find, in case there was a synchronization issue.
 */
export const invalidateSignedBrakingBulletinsIfNeeded = (step: TrainStep) => (dispatch: AppDispatch) => {
  step.brakingBulletins
    .filter((bb) => bb.status === 'SIGNED')
    .forEach((bb) => {
      dispatch(
        offlineUpdateBrakingBulletin({
          stepId: step.id,
          diff: BrakingBulletinUtils.createBrakingBulletinDiffForInvalidate(bb),
        }),
      );
    });
};

/**
 * Add a secondary signature to an already signed braking bulletin
 */
export const signBrakingBulletin =
  (confirmFormValues: BrakingBulletinSignFormData) => (dispatch: AppDispatch, getState: () => RootState) => {
    if (confirmFormValues.signatureType) {
      writeDefaultSignatureType(confirmFormValues.signatureType);
    }
    const stepState = selectTrainStep(getState());
    const step = stepState.data!;
    const brakingBulletin = stepState.brakingBulletin!;
    const bbDiff = BrakingBulletinUtils.createBrakingBulletinDiffForSigning(brakingBulletin, confirmFormValues);
    dispatch(offlineUpdateBrakingBulletin({ stepId: step.id, diff: bbDiff }));

    dispatch(afterBrakingBulletinUpdated(step));
    dispatch(closeBrakingBulletin());
    dispatch(hideOverlay());
  };

/**
 * Delete the currently opened braking bulletin.
 */
export const deleteBrakingBulletin = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const stepState = selectTrainStep(getState());
  const step = stepState.data!;
  const brakingBulletin = stepState.brakingBulletin!;
  const bbDiff = BrakingBulletinUtils.createBrakingBulletinDiffForDelete(brakingBulletin);
  dispatch(offlineUpdateBrakingBulletin({ stepId: step.id, diff: bbDiff }));

  dispatch(afterBrakingBulletinUpdated(step));
  dispatch(closeBrakingBulletin());
  dispatch(hideOverlay());
};

export const pdfBrakingBulletinUrl = (step: TrainStep) => `/api/trains/steps/${step.id}/braking-bulletin.pdf`;

export const pdfTraceabilityUrl = (step: TrainStep) => `/api/trains/steps/${step.id}/traceability.pdf`;
