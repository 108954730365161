import AbstractTemplateCache from './AbstractTemplateCache';
import { CachedStation, Station } from '../model/templates';
import { Tx } from './AbstractDbCache';

class StationCache extends AbstractTemplateCache<'station', Station> {
  protected getStoreName(): 'station' {
    return 'station';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  private normalizeLabel(label: string) {
    if (!label) {
      return label;
    }
    const labelWithoutAccents = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return labelWithoutAccents.toUpperCase().replace('-', ' ').replace('SAINT', 'ST');
  }

  protected normalizeInput(inputValue: string) {
    return this.normalizeLabel(inputValue);
  }

  protected addItemMetadata(item: Station): CachedStation {
    return { ...item, normalizedLabel: this.normalizeLabel(item.label) };
  }

  protected removeItemMetadata({ normalizedLabel, ...item }: CachedStation): Station {
    return item;
  }

  protected findTemplates(normalizedInputValue: string, tx: Tx<'station'>) {
    return this.findItemsByIndexPrefix(normalizedInputValue, tx, 'by-label');
  }

  public findByLabel(label: string): Promise<Station | undefined> {
    // "by-label" actually indexes the normalized label
    return this.findItemByIndex(this.normalizeLabel(label), 'by-label');
  }

  public formatItem(item: Station): string {
    return item.label;
  }
}

export default new StationCache();
