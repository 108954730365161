import rootReducer from '../reducers/rootReducer';
import { configureStore, isRejected, Middleware, SerializedError } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

/**
 * Convert a redux-toolkit SerializedError back to an actual Error that can be handled by Sentry.
 */
class SentryError extends Error {
  public code?: string;
  constructor(serializedError: SerializedError) {
    super(serializedError.message);
    if (serializedError.name) {
      this.name = serializedError.name;
    }
    this.code = serializedError.code;
    this.stack = serializedError.stack;
  }
}

const sentryAsyncRejection: Middleware = () => (next) => (action) => {
  if (isRejected(action)) {
    Sentry.captureException(new SentryError(action.error), { extra: { action: action.type } });
  }

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(sentryAsyncRejection),
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type AppAction = Parameters<AppDispatch>[0];

export type AppStore = typeof store;

export default store;
