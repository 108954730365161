import { Traceability } from './Traceability';
import { TraceabilityDiff } from './TrainDiff';
import { TrainStep, TrainSummary } from './Train';
import VehiclesUtils from './VehiclesUtils';

const hasSignedRAT = (traceability?: Traceability): boolean => Boolean(traceability?.ratSignature);

const hasSignedBrakeTest = (traceability?: Traceability): boolean => Boolean(traceability?.brakeTestSignatures.length);

const hasSignedRearSignaling = (traceability?: Traceability): boolean =>
  Boolean(traceability?.rearSignalingSignatures.length);

const hasSignedFirstLast = (traceability?: Traceability): boolean => Boolean(traceability?.firstLastSignatures.length);

const hasSignedATE = (traceability?: Traceability): boolean =>
  Boolean(
    traceability?.ateAgreementSignatures.length &&
      traceability?.ateBrakingBulletinSignatures.length &&
      traceability?.ateTransmissionSignatures.length,
  );

const hasPartiallySignedATE = (traceability?: Traceability): boolean =>
  !hasSignedATE(traceability) &&
  Boolean(
    traceability?.ateAgreementSignatures.length ||
      traceability?.ateBrakingBulletinSignatures.length ||
      traceability?.ateTransmissionSignatures.length,
  );

const hasSignedVisit = (traceability?: Traceability): boolean => Boolean(traceability?.visitSignatures.length);

const trainHasSignedTraceability = (train: TrainSummary): boolean => {
  const manualSteps = train.steps.filter((step) => step.manualTraceability);
  return manualSteps.length > 0 && manualSteps.every((step) => step.hasSignedTraceability);
};

const stepHasSignedTraceability = ({ traceability, vehicles }: TrainStep): boolean =>
  (hasSignedRAT(traceability) || VehiclesUtils.getNbWagon(vehicles) === 0) &&
  hasSignedBrakeTest(traceability) &&
  hasSignedRearSignaling(traceability) &&
  (hasSignedFirstLast(traceability) || VehiclesUtils.getNbWagonsOrVehicleEMs(vehicles) === 0) &&
  (hasSignedATE(traceability) || !VehiclesUtils.hasATE(vehicles));

const stepAtLeastHasPartiallySignedTraceability = ({ traceability }: TrainStep): boolean =>
  hasSignedRAT(traceability) ||
  hasSignedBrakeTest(traceability) ||
  hasSignedRearSignaling(traceability) ||
  hasSignedFirstLast(traceability) ||
  hasPartiallySignedATE(traceability) ||
  hasSignedVisit(traceability);

export const createEmptyTraceabilityDiff = (): TraceabilityDiff => ({
  sign: [],
  invalidate: [],
});

export default {
  hasSignedRAT,
  hasSignedBrakeTest,
  hasSignedRearSignaling,
  hasSignedFirstLast,
  hasSignedATE,
  hasPartiallySignedATE,
  hasSignedVisit,
  trainHasSignedTraceability,
  stepHasSignedTraceability,
  stepAtLeastHasPartiallySignedTraceability,
};
