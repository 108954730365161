import React, { MouseEvent, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { showSidebar } from '../../commons/components/sidebar/sidebarDucks';
import { loadTrain, unloadTrain } from './trainDucks';
import { getOfflineState, selectSecurityContextRoles, selectTrain } from '../../commons/selectors/selectors';
import GlobalError from '../../commons/components/GlobalError';
import './trainStyles.scss';
import TrainUtils from '../../commons/model/TrainUtils';
import { formatOptionalValue } from '../../commons/model/common';
import TrainStepSummaryList from '../step/summary/TrainStepSummaryList';
import { excelVehiclesReportUrl, pdfVehiclesReportUrl } from '../step/trainStepDucks';
import Tooltip from 'rc-tooltip';
import { Train, TrainStep } from '../../commons/model/Train';
import BrakingBulletinUtils from '../../commons/model/BrakingBulletinUtils';
import TraceabilityUtils from '../../commons/model/TraceabilityUtils';
import ReadyForDispatchUtils from '../../commons/model/ReadyForDispatchUtils';
import moment from 'moment/moment';
import { BRAKING_BULLETIN } from '../../commons/security/userRoles';
import { usePageTitle } from '../../commons/components/hooks';
import helpers from '../../commons/helpers/helpers';

const openPdfReport = (step: TrainStep) => (event: MouseEvent) => {
  event.stopPropagation();
  // Open the PDF in a new tab, since it might be displayed inline by the browser.
  window.open(pdfVehiclesReportUrl(step));
};

const openExcelReport = (step: TrainStep) => (event: MouseEvent) => {
  event.stopPropagation();
  // The Excel report is an attachment, so open it in this tab to avoid screen flickering
  window.location.href = excelVehiclesReportUrl(step);
};

const trainTitle = (train: Train) => {
  const trainNumbers: string[] = [];
  train.steps.forEach((step) => {
    if (!trainNumbers.includes(step.trainNumber)) {
      trainNumbers.push(step.trainNumber);
    }
  });
  return `Train ${trainNumbers.join('/')} du ${helpers.dateFormat(train.steps[0].startDate)}`;
};

const TrainPage = () => {
  const { trainId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showSidebar());
    dispatch(loadTrain(trainId!));
    return () => {
      // Free up the state when leaving the page
      unloadTrain();
    };
  }, [dispatch, trainId]);

  const { data: train, loading, error } = useAppSelector(selectTrain);
  const offline = useAppSelector(getOfflineState);

  const roles = useAppSelector(selectSecurityContextRoles);
  const canSeeBB = roles.includes(BRAKING_BULLETIN);

  const pageTitle = useMemo(() => (train && train.id === trainId ? trainTitle(train) : 'Train'), [train, trainId]);
  usePageTitle(pageTitle);

  if (error) {
    if (error === 404) {
      return <GlobalError title="Étape introuvable">Aucun train trouvé pour ce numéro.</GlobalError>;
    } else if (error === 403) {
      return <GlobalError title="Accès refusé">Vous n'avez pas accès à ce train.</GlobalError>;
    } else if (!train) {
      return (
        <GlobalError title="Erreur">Une erreur inattendue s'est produite durant la récupération du train.</GlobalError>
      );
    }
  }

  if (!train) {
    // Skeleton page
    return (
      <div className="train-details">
        <h1 className="title">
          Détails du Train
          <div className="loader" />
        </h1>
      </div>
    );
  }

  const activeStepIndex = TrainUtils.findActiveStepIndex(train, moment().format());

  return (
    <div className="train-details">
      <h1 className="title">
        Détails du Train
        {loading && <div className="loader" />}
      </h1>
      <div className="contract">{train.steps[0]?.marketName}</div>
      {train.steps.map((step, index) => {
        const copiedComposition = index > 0 && !step.manuallyUpdated && step.validatedBy != null;
        return (
          <div
            key={step.id}
            className={classNames('step', {
              validated: step.status === 'VALIDATED',
              'not-validated': step.status === 'NOT_VALIDATED',
              copied: copiedComposition,
              active: index === activeStepIndex,
              inactive: activeStepIndex !== -1 && index !== activeStepIndex,
            })}
          >
            <Link to={TrainUtils.linkToTrainStep(step.id)}>
              {!offline && step.status === 'VALIDATED' && (
                <div className="actions">
                  <Tooltip placement="top" trigger={['hover', 'click']} overlay="Télécharger le relevé au format PDF">
                    <button className="btn btn-link btn-icon pdf" onClick={openPdfReport(step)} type="button"></button>
                  </Tooltip>
                  <Tooltip placement="top" trigger={['hover', 'click']} overlay="Télécharger le relevé au format Excel">
                    <button
                      className="btn btn-link btn-icon xls"
                      onClick={openExcelReport(step)}
                      type="button"
                    ></button>
                  </Tooltip>
                </div>
              )}
              <div className="header">
                <div className="train-no">{formatOptionalValue(step.trainNumber)}</div>
                <div className="route">{TrainUtils.formatRoute(step)}</div>
              </div>
              <div className="content">
                <div className="content-col">
                  <TrainStepSummaryList
                    step={step}
                    parts={copiedComposition ? ['dates', 'status'] : ['dates', 'status', 'composition']}
                  />
                </div>
                <div className="content-col">
                  <TrainStepSummaryList
                    step={step}
                    parts={copiedComposition ? ['agent'] : ['validation', 'agent', 'vehicles']}
                  />
                </div>
              </div>
              {canSeeBB && (step.manualBrakingBulletin || !copiedComposition) && (
                <div className="statuses">
                  <div
                    className={classNames('status', { on: BrakingBulletinUtils.stepHasSignedBrakingBulletin(step) })}
                  >
                    BF
                  </div>
                  <div className={classNames('status', { on: TraceabilityUtils.stepHasSignedTraceability(step) })}>
                    Traçabilité
                  </div>
                  <div
                    className={classNames('status', { on: ReadyForDispatchUtils.stepHasSignedReadyForDispatch(step) })}
                  >
                    PPE
                  </div>
                </div>
              )}
              {copiedComposition && (
                <div className="copied-composition">
                  {step.status === 'VALIDATED'
                    ? "Composition copiée de l'étape précédente"
                    : `La composition sera copiée de l'étape précédente lors de sa validation${
                        offline ? ' quand vous retrouverez du réseau' : '' // NOSONAR
                      }`}
                </div>
              )}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default TrainPage;
