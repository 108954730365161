import React, { CSSProperties, MouseEventHandler, ReactNode, useRef, useState } from 'react';
import { FormSection as ReduxFormSection } from 'redux-form';
import classNames from 'classnames';

import './formSectionStyles.scss';

type FormSectionContentProps = {
  title: ReactNode;
  children: ReactNode;
  collapsible?: boolean;
  className?: string;
  collapsedClassName?: string;
  onClick?: MouseEventHandler;
};

type FormSectionProps = FormSectionContentProps & {
  name?: string;
};

const ANIMATION_DURATION = 350;

const FormSectionContent = ({
  children,
  title,
  collapsible,
  className,
  collapsedClassName,
  onClick,
}: FormSectionProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [animating, setAnimating] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const animatingTimer = useRef<number>();

  let contentStyle: CSSProperties | undefined = undefined;
  if (contentRef.current) {
    if (collapsed) {
      contentStyle = { height: '0px' };
    } else if (animating) {
      contentStyle = { height: contentRef.current.scrollHeight + 'px' };
    }
  }

  const doToggle = (newCollapsed: boolean) => {
    // Define the height
    setAnimating(true);
    // On the next render, start animating
    window.setTimeout(() => setCollapsed(newCollapsed), 10);

    // After the animation ended, unrestrict the height
    clearTimeout(animatingTimer.current);
    animatingTimer.current = window.setTimeout(() => {
      setAnimating(false);
    }, ANIMATION_DURATION);
  };

  const handleToggle: MouseEventHandler = (e) => {
    e.stopPropagation();
    doToggle(!collapsed);
  };

  const handleContainerClick: MouseEventHandler = (e) => {
    if (collapsed) {
      doToggle(false);
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={classNames(
        'form-section',
        {
          collapsible,
          collapsed,
          clickable: collapsed || onClick,
        },
        className,
        collapsed && collapsedClassName,
      )}
      onClick={handleContainerClick}
    >
      {collapsible && (
        <button
          className={classNames('btn btn-round small collapse', { collapsed })}
          onClick={handleToggle}
          type="button"
        />
      )}
      <div className="title">{title}</div>
      <div className="content" ref={contentRef} style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

const FormSection = ({ name, children, ...props }: FormSectionProps) => {
  const content = <FormSectionContent {...props}>{children}</FormSectionContent>;
  return name ? <ReduxFormSection name={name}>{content}</ReduxFormSection> : content;
};

export default FormSection;
