/**
 * Owner field component
 */
import React, { ComponentType, useEffect, useState } from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import Input, { InputProps } from './Input';
import helpers from '../../helpers/helpers';
import './inputStyles.scss';
import { Owner } from '../../model/templates';
import { GenericFieldHTMLAttributes } from 'redux-form/lib/Field';
import ownerCache from '../../templates/ownerCache';
import wagonCache from '../../templates/wagonCache';

type OwnerFieldProps<
  P extends GenericFieldHTMLAttributes | BaseFieldProps = GenericFieldHTMLAttributes | BaseFieldProps,
> = Omit<BaseFieldProps<P>, 'component'> &
  InputProps<Owner> & {
    /**
     * The current wagon registration in the form.
     */
    wagonRegistration: string | null;
    component?: ComponentType<P>;
    disabled?: boolean;
  };

/**
 * Return a boolean indicating whether the "owner" field should be in read-only mode.
 *
 * @param registration The current wagon registration form value.
 * @returns Boolean indicating if the field should be in read-only mode.
 */
const isOwnerReadOnly = async (registration: string | null): Promise<boolean> => {
  if (!registration) {
    return false;
  } else {
    const wagonTemplate = await wagonCache.findItemById(registration);
    if (!wagonTemplate?.ownerId) {
      return false;
    } else {
      const ownerTemplate = await ownerCache.findItemById(wagonTemplate.ownerId);
      return Boolean(ownerTemplate?.active);
    }
  }
};

/**
 * Component displaying a select element to choose an owner. This field
 * may be in read-only mode if the form is read-only or if the currently
 * selected wagon is associated with an owner in the wagon templates referential
 * or if the associated owner is inactive.
 */
const OwnerField = <
  P extends GenericFieldHTMLAttributes & BaseFieldProps = GenericFieldHTMLAttributes & BaseFieldProps,
>({
  component,
  disabled,
  wagonRegistration,
  ...props
}: OwnerFieldProps<P>) => {
  const Comp = component ?? Field<P>;
  const [ownerIsReadOnly, setOwnerIsReadOnly] = useState(true);

  useEffect(() => {
    (async () => setOwnerIsReadOnly(await isOwnerReadOnly(wagonRegistration)))();
  }, [wagonRegistration]);

  return (
    // @ts-ignore
    <Comp
      component={Input}
      id={`damage-report-${helpers.camelCaseToHyphen(props.name)}`}
      type="text"
      autocompleter={ownerCache}
      {...props}
      disabled={Boolean(disabled) || ownerIsReadOnly}
    />
  );
};

export default OwnerField;
