/**
 * Profile details component
 */
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Overlay from '../../../commons/components/overlay/Overlay';
import Input from '../../../commons/components/input/Input';
import RoleCheckboxes from '../../../commons/components/role-checkboxes/RoleCheckboxes';
import { getProfile } from '../../../commons/selectors/selectors';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import * as profileActions from '../profileDucks';
import { persistProfileStart } from '../profileDucks';
import './profileDetailsStyles.scss';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { AppDispatch } from '../../../commons/store/store';
import { connect } from 'react-redux';
import { RootState } from '../../../commons/reducers/rootReducer';
import { Profile } from '../../../commons/model/userProfile';

type ProfileDetailsProps = InjectedFormProps<Profile>;

const ProfileDetails = ({ handleSubmit }: ProfileDetailsProps) => {
  const profile: Profile | null = useAppSelector(getProfile).data;
  const isEdit: boolean = Boolean(profile?.id);
  const dispatch: AppDispatch = useAppDispatch();

  const handleCancel = () => dispatch(hideOverlay());

  const confirmDelete = (profile: Profile) => () => {
    dispatch(
      openConfirmDialog({
        title: `Supprimer le profil ${profile.name} ?`,
        actionText: 'Oui, supprimer',
        action: profileActions.deleteProfile(profile),
        actionClass: 'danger',
      }),
    );
  };

  return (
    <Overlay
      name="profile"
      className="profile-details"
      title={isEdit ? 'Modifier le profil' : 'Ajouter un profil'}
      closeButton
    >
      <form
        className="fields overlay-form"
        autoComplete="off"
        onSubmit={handleSubmit((updatedProfile: Profile) => dispatch(persistProfileStart(updatedProfile, profile?.id)))}
      >
        <Field component={Input} type="text" id="profile-name" name="name" labelText="Nom" data={profile} />
        <Field component={RoleCheckboxes} id="profile-roles" name="roles" labelText="Rôles" data={profile} />
        <div className="btn-group">
          <button className="btn btn-success" type="submit">
            {isEdit ? 'Enregistrer' : 'Ajouter'}
          </button>
          <button className="btn btn-link" onClick={handleCancel} type="button">
            Annuler
          </button>
          {isEdit && (
            <button className="btn btn-icon remove" type="button" onClick={confirmDelete(profile!)}>
              Supprimer
            </button>
          )}
        </div>
      </form>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: getProfile(state).data!,
});

// Decorate with reduxForm().
const ProfileDetailsForm = reduxForm<Profile>({
  form: 'profileForm',
  enableReinitialize: true,
})(ProfileDetails);

export default connect(mapStateToProps)(ProfileDetailsForm);
