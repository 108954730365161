// Constants
import { AppDispatch } from '../../store/store';
import { AnyAction } from 'redux';

const START_TOAST = 'START_TOAST';
const STOP_TOAST = 'STOP_TOAST';

export type ToastConfig = {
  text: string;
  className?: string;
  autoHide?: boolean;
  toastDelay?: number;
  left?: boolean;
  higher?: boolean;
};

export type ToastState = {
  hidden: boolean;
} & Required<Omit<ToastConfig, 'autoHide' | 'toastDelay'>>;

let timeoutID: number | undefined = undefined;

// reducer
const initialState: ToastState = {
  hidden: true,
  text: '',
  className: 'online',
  left: false,
  higher: false,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_TOAST:
      return { ...initialState, hidden: false, ...action.payload };
    case STOP_TOAST:
      return initialState;
    default:
      return state;
  }
};

export const stopToast = () => ({
  type: STOP_TOAST,
});

// Actions
export const startToast =
  ({ autoHide = true, toastDelay = 5000, ...config }: ToastConfig) =>
  (dispatch: AppDispatch) => {
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }
    dispatch({
      type: START_TOAST,
      payload: { ...initialState, ...config, hidden: false },
    });

    if (autoHide) {
      timeoutID = window.setTimeout(() => {
        dispatch(stopToast());
        timeoutID = undefined;
      }, toastDelay);
    }
  };
