import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestLoadPreAdviceWagon, unloadPreAdviceWagon } from './preAdviceWagonDucks';
import { hideSidebar, showSidebar } from '../../commons/components/sidebar/sidebarDucks';
import {
  getPreAdvice,
  getPreAdviceWagon,
  getPreAdviceWagonIsError,
  getPreAdviceWagonIsLoading,
  selectSecurityContextRoles,
} from '../../commons/selectors/selectors';
import { PRE_ADVICE_WRITE } from '../../commons/security/userRoles';
import NotLoadedPreAdviceWagon from './NotLoadedPreAdviceWagon';
import PreAdviceWagonDetails from './PreAdviceWagonDetails';
import PreAdviceWagonsList from '../pre-advice/wagons/PreAdviceWagonsList';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useParams } from 'react-router-dom';
import CopyJourneyModal from './journey/CopyJourneyModal';
import PreAdviceWagonEntity from '../../commons/entity/PreAdviceWagonEntity';
import { usePageTitle } from '../../commons/components/hooks';
import { preAdviceTitle } from '../pre-advice/PreAdvice';

const preAdviceWagonTitle = (wagon: PreAdviceWagonEntity) => `Wagon ${wagon.position + 1}`;

const PreAdviceWagon = () => {
  const { preAdviceId, wagonId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLoadPreAdviceWagon(preAdviceId, wagonId) as any);
    dispatch(hideSidebar());
    return () => {
      dispatch(unloadPreAdviceWagon());
      dispatch(showSidebar());
    };
  }, [dispatch, preAdviceId, wagonId]);

  useEffect(() => {
    // wait for a paint to scroll to the active vehicle
    window.requestAnimationFrame(() => {
      const activeElement = document.querySelector('.vehicle.active');
      if (activeElement) {
        scrollIntoView(activeElement, { block: 'nearest', scrollMode: 'if-needed', behavior: 'smooth' });
      }
    });
  });

  const wagon = useSelector(getPreAdviceWagon);
  const loading = useSelector(getPreAdviceWagonIsLoading);
  const error = useSelector(getPreAdviceWagonIsError);
  const preAdvice = useSelector(getPreAdvice);
  const canModifyPreAdvices = useSelector(selectSecurityContextRoles).includes(PRE_ADVICE_WRITE);

  usePageTitle(
    wagon && wagon.id === wagonId
      ? `${preAdviceWagonTitle(wagon)} - ${preAdviceTitle(preAdvice)}`
      : 'Wagon - Pré-annonce',
  );

  if (!wagon || !preAdvice) {
    return <NotLoadedPreAdviceWagon error={error} loading={loading} />;
  }

  return (
    <div className="composition pre-advice expanded">
      <PreAdviceWagonsList activeWagonId={wagonId} isCollapsed={true} preAdvice={preAdvice} />
      <PreAdviceWagonDetails canModifyPreAdvices={canModifyPreAdvices} preAdvice={preAdvice} wagon={wagon} />
      {canModifyPreAdvices && !preAdvice.isValidated() && <CopyJourneyModal />}
    </div>
  );
};

export default PreAdviceWagon;
