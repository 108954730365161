import { errorTypes } from '../../commons/entity/PreAdviceWagonImportErrorEntity';

export const formName = 'preAdviceWagonForm';

export const getInitialValues = (wagon) => ({
  ...wagon,
  position: wagon.position + 1, // Stored positions are 0-based, displayed positions are 1-based
});

export const IMPORT_ERROR_CATEGORIES = {
  STATION: 'STATION',
  HAZARDOUS_MATERIAL: 'HAZARDOUS_MATERIAL',
  DAMAGE: 'DAMAGE',
};

export const getErrorTypesForCategory = (errorCategory) => {
  switch (errorCategory) {
    case IMPORT_ERROR_CATEGORIES.STATION:
      return [errorTypes.STATION];
    case IMPORT_ERROR_CATEGORIES.HAZARDOUS_MATERIAL:
      return [errorTypes.HAZARDOUS_MATERIAL];
    case IMPORT_ERROR_CATEGORIES.DAMAGE:
      return [errorTypes.DAMAGE, errorTypes.AMBIGUOUS_DAMAGE];
    default:
      return [];
  }
};
