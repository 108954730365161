import { reset } from 'redux-form';
import { AnyAction } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppAction, AppDispatch } from '../../store/store';
import { RootState } from '../../reducers/rootReducer';
import { selectConfirmModal } from '../../selectors/selectors';
import { ReactNode } from 'react';

// CONSTANTS
export const OPEN_CONFIRM_DIALOG = 'OPEN_CONFIRM_DIALOG';
export const EXECUTE_ACTION_CONFIRM_DIALOG = 'EXECUTE_ACTION_CONFIRM_DIALOG';
export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG';

export type ConfirmModalActionClass = 'success' | 'danger' | 'default';

export type ConfirmModalFieldType = 'number' | 'text' | 'checkbox' | 'dropdown';

type ConfirmModalFieldTypes = {
  [fieldName: string]: ConfirmModalFieldType;
};

export type ConfirmModalFormValues<T extends ConfirmModalFieldTypes> = {
  [fieldName in keyof T]: (T[fieldName] extends 'checkbox' ? boolean : string) | null;
};

type ConfirmModalField<T extends ConfirmModalFieldType> = {
  type: T;
  label?: string;
  required?: T extends 'checkbox' ? never : boolean;
  defaultValue?: T extends 'checkbox' ? boolean : string;
  options?: T extends 'dropdown' ? Record<string, string> : never;
};

type AnyConfirmModalField =
  | ConfirmModalField<'number'>
  | ConfirmModalField<'text'>
  | ConfirmModalField<'checkbox'>
  | ConfirmModalField<'dropdown'>;

export type ConfirmModalConfig<T extends ConfirmModalFieldTypes> = {
  title: ReactNode;
  actionText?: string;
  cancelText?: string;
  actionClass?: ConfirmModalActionClass;
  action: AppAction | ((values: ConfirmModalFormValues<T>) => AppAction);
  onCancel?: () => void;
  fields?: { [fieldName in keyof T]: AnyConfirmModalField };
};

export type ConfirmModalState = Required<ConfirmModalConfig<any>> & {
  hidden: boolean;
};

const initialState: ConfirmModalState = {
  hidden: true,
  title: '',
  actionText: 'OK',
  cancelText: 'Annuler',
  actionClass: 'success',
  action: undefined,
  onCancel: () => {},
  fields: {},
};

export default (state = initialState, action: AnyAction): ConfirmModalState => {
  switch (action.type) {
    case OPEN_CONFIRM_DIALOG:
      return { ...initialState, hidden: false, ...action.payload };
    case EXECUTE_ACTION_CONFIRM_DIALOG:
    case CLOSE_CONFIRM_DIALOG:
      return initialState;
    default:
      return state;
  }
};

// ACTIONS
export const openConfirmDialog = <T extends ConfirmModalFieldTypes>(
  config: ConfirmModalConfig<T>,
): PayloadAction<ConfirmModalConfig<T>> => ({
  type: OPEN_CONFIRM_DIALOG,
  payload: config,
});

export const closeConfirmDialog = () => (dispatch: AppDispatch) => {
  dispatch({
    type: CLOSE_CONFIRM_DIALOG,
  });
  dispatch(reset('inputConfirmModal'));
};

export const executeActionConfirmDialog =
  <T extends ConfirmModalFieldTypes>(values: ConfirmModalFormValues<T>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const confirmModal = selectConfirmModal(getState());
    dispatch({
      type: EXECUTE_ACTION_CONFIRM_DIALOG,
    });
    if (confirmModal.action) {
      if (typeof confirmModal.action === 'function') {
        dispatch(confirmModal.action(values));
      } else {
        dispatch(confirmModal.action);
      }
    }
    dispatch(reset('inputConfirmModal'));
  };
