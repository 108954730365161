import { ComponentType, FunctionComponent } from 'react';

/**
 * Higher order component to add derived props, without introducing a dependency to Redux.
 */
export const withDerivedProps =
  <InnerProps extends object, OuterProps extends object>(transformProps: (props: OuterProps) => InnerProps) =>
  (WrappedComponent: ComponentType<InnerProps & OuterProps>): FunctionComponent<OuterProps> => {
    return (props: OuterProps) => <WrappedComponent {...props} {...transformProps(props)} />;
  };
