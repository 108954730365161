export default class ApiError extends Error {
  httpStatus: number;
  message: string;
  body: any;
  exception: any;
  errors: any;

  constructor(httpStatus: number, content: string | { message: string; exception?: any; errors?: any }) {
    let message = typeof content === 'string' ? content : content.message;
    if (!message) {
      message = `Http error ${httpStatus}`;
    }
    super(message);
    this.message = message;
    this.httpStatus = httpStatus;
    this.body = content;
    if (typeof content !== 'string') {
      this.exception = content?.exception;
      this.errors = content?.errors;
    }
  }
}
