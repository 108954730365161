import { BRAKE_TEST_TYPES, BrakeTestSignature, BrakeTestType } from '../../../commons/model/Traceability';
import { Field, initialize, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { DateString, isOfflineSignature, SignatureOrOffline } from '../../../commons/model/common';
import { invalidateBrakeTest, signBrakeTest } from './TraceabilityActions';
import FormSection from '../../../commons/components/form/FormSection';
import classNames from 'classnames';
import List from '../../../commons/components/list/List';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { validateBrakeTestForm } from '../../../commons/validation/validationUtils';
import { TraceabilitySectionProps } from './types';
import SignatureUtils from '../../../commons/model/SignatureUtils';
import DateTimeField from '../../../commons/components/input/DateTimeField';
import helpers from '../../../commons/helpers/helpers';

export type BrakeTestFormData = {
  type: BrakeTestType | null;
  dateTime: DateString | null;
};

const initialFormValues = (): BrakeTestFormData => ({
  type: null,
  dateTime: null,
});

const BrakeTestSection = ({
  traceability,
  editable,
  handleSubmit,
}: TraceabilitySectionProps & InjectedFormProps<BrakeTestFormData>) => {
  const { username } = useAppSelector(selectSecurityContext);
  const dispatch = useAppDispatch();
  const signatures = traceability.brakeTestSignatures;
  const [formOpen, setFormOpen] = useState(false);

  const openForm = useCallback(() => {
    dispatch(initialize('brakeTestForm', initialFormValues()));
    setFormOpen(true);
  }, [dispatch]);

  useEffect(() => {
    if (editable && signatures.length === 0 && !formOpen) {
      // Open form on when there is no signature
      openForm();
    }
  }, [editable, signatures, openForm, formOpen]);

  const doHandleSubmit = (formValues: BrakeTestFormData) => {
    dispatch(signBrakeTest(formValues.type!, formValues.dateTime!));
    setFormOpen(false);
  };

  const handleInvalidate = (signature: SignatureOrOffline<BrakeTestSignature>) => () => {
    dispatch(
      invalidateBrakeTest(
        isOfflineSignature(signature) ? null : signature.userId,
        signature.type,
        signature.testDateTime,
      ),
    );
  };

  return (
    <FormSection title="Essai de freins" className={classNames({ signed: Boolean(signatures.length) })}>
      {signatures.map((signature, index) => (
        <div className="subsection-content" key={index}>
          <List
            items={[
              { key: 'Type', value: BRAKE_TEST_TYPES[signature.type] },
              { key: 'Réalisé le', value: helpers.dateTimeFormat(signature.testDateTime) },
              ...SignatureUtils.basicSignatureFields(signature, username).slice(1),
            ]}
          />
          {editable && (
            <button className="btn btn-round small reset" onClick={handleInvalidate(signature)} type="button" />
          )}
        </div>
      ))}
      {editable &&
        (formOpen ? (
          <form className="brake-test-form" autoComplete="off" noValidate onSubmit={handleSubmit(doHandleSubmit)}>
            <Field component={Dropdown} name="type" options={BRAKE_TEST_TYPES} labelText="Type" />
            <DateTimeField
              name="dateTime"
              inputReadOnly
              labelText="Date et heure de l'essai"
              data={{}}
              timeFormat="HH:mm"
            />
            <div className="buttons">
              <button className="btn btn-accent" type="submit">
                Valider et signer
              </button>
            </div>
          </form>
        ) : (
          <button className="btn btn-round small plus" onClick={openForm} type="button" />
        ))}
      {!editable && signatures.length === 0 && <div className="empty">Non signé</div>}
    </FormSection>
  );
};

export default reduxForm<BrakeTestFormData, TraceabilitySectionProps>({
  form: 'brakeTestForm',
  validate: validateBrakeTestForm,
})(BrakeTestSection as any);
