/**
 * Hazmat form component
 */
import React from 'react';
import { autofillLoadWeight, removeHazmat, updateHazmat } from '../../../trains/step/hazmatActions';
import { COMPOSITION_WRITE } from '../../security/userRoles';
import HazardousMaterialFormFields from '../hazardous-material/HazardousMaterialFormFields';
import { useAppSelector } from '../../store/hooks';
import { Wagon } from '../../model/Vehicle';
import { FieldArrayFieldsProps } from 'redux-form/lib/FieldArray';
import { WagonHazardousMaterialFormValues } from '../vehicles/edit/wagon/WagonForm';
import VehicleUtils from '../../model/VehicleUtils';
import { selectSecurityContextRoles } from '../../selectors/selectors';

type HazmatEditProps = {
  index: number;
  name: string;
  wagon: Wagon;
  fields: FieldArrayFieldsProps<WagonHazardousMaterialFormValues>;
};

const HazmatEdit = ({ fields, index, name, wagon }: HazmatEditProps) => {
  const roles = useAppSelector(selectSecurityContextRoles);
  const readOnly = !roles.includes(COMPOSITION_WRITE) || VehicleUtils.isStatusValidated(wagon);

  return (
    <HazardousMaterialFormFields
      fieldName={name}
      formName="wagonForm"
      hazardousMaterialFields={fields}
      onDelete={removeHazmat}
      onSave={updateHazmat(index)}
      onSaveWeight={autofillLoadWeight}
      position={index}
      readOnly={readOnly}
      wagon={wagon}
    />
  );
};

export default HazmatEdit;
