import React from 'react';
import { change, getFormValues } from 'redux-form';

import { requestUpdatePreAdviceWagon } from '../preAdviceWagonDucks';
import HazardousMaterialFormFields from '../../../commons/components/hazardous-material/HazardousMaterialFormFields';
import { formName } from '../preAdviceWagonFormUtils';
import PreAdviceWagonEntity from '../../../commons/entity/PreAdviceWagonEntity';
import { parseToFloat } from '../../../commons/redux-form/valueParser';

const PreAdviceHazardousMaterial = ({ fieldName, hazardousMaterialFields, position, readOnly, wagon }) => {
  const deleteAction = (w) => (dispatch) => {
    const hazardousMaterials = [...w.hazardousMaterials];
    hazardousMaterials.splice(position, 1);
    dispatch(requestUpdatePreAdviceWagon(w, { hazardousMaterials }));
  };

  const saveWeightAction = (w, propertyToUpdate) => (dispatch, getState) => {
    const { loadWeight, hazardousMaterials } = getFormValues(formName)(getState());
    const previousHazardousMaterialWeight = w.getHazardousMaterialWeight();
    if (!loadWeight || parseToFloat(loadWeight) === previousHazardousMaterialWeight) {
      propertyToUpdate.loadWeight = PreAdviceWagonEntity.getHazardousMaterialWeight({ hazardousMaterials });
      // Update loadWeight in Redux-form
      dispatch(change(formName, 'loadWeight', propertyToUpdate.loadWeight));
    }
    // Persist data
    dispatch(requestUpdatePreAdviceWagon(w, propertyToUpdate));
  };

  return (
    <HazardousMaterialFormFields
      fieldName={fieldName}
      formName={formName}
      hazardousMaterialFields={hazardousMaterialFields}
      onDelete={deleteAction}
      onSave={requestUpdatePreAdviceWagon}
      onSaveWeight={saveWeightAction}
      position={position}
      readOnly={readOnly}
      wagon={wagon}
    />
  );
};

export default PreAdviceHazardousMaterial;
