import React from 'react';
import Damages from '../../../commons/components/damage/Damages';
import { Field, FieldArray, change } from 'redux-form';

import { requestUpdatePreAdviceWagon } from '../preAdviceWagonDucks';
import Input from '../../../commons/components/input/Input';
import { formatDecimalAndSeparateThousands } from '../../../commons/redux-form/valueFormatter';
import { normalizePositiveNumber } from '../../../commons/redux-form/valueNormalizer';
import { parseToInt } from '../../../commons/redux-form/valueParser';
import { formName } from '../preAdviceWagonFormUtils';

const onUpdateList = (wagon, propertyToUpdate) => (dispatch) => {
  if (propertyToUpdate['damages.damageTemplates'] && !propertyToUpdate['damages.damageTemplates'].length) {
    dispatch(change(formName, 'damages.speedLimit', null));
    return dispatch(requestUpdatePreAdviceWagon(wagon, { ...propertyToUpdate, 'damages.speedLimit': null }));
  }
  return dispatch(requestUpdatePreAdviceWagon(wagon, propertyToUpdate));
};

const PreAdviceWagonDamages = ({ wagon, readOnly }) => {
  const hasDamages = Boolean(wagon.damages.damageTemplates.length);
  return (
    <>
      <div className="fields full-width">
        <FieldArray
          allowIntermediary={false}
          name="damages.damageTemplates"
          canLabelBeChanged={false}
          component={Damages}
          data={wagon}
          disabled={readOnly}
          isManuelCodeAuthorized={false}
          minDamages={0}
          maxDamages={9}
          saveAction={onUpdateList}
        />
      </div>
      <div className="fields two-columns">
        <Field
          component={Input}
          data={wagon}
          disabled={readOnly || !hasDamages}
          format={formatDecimalAndSeparateThousands}
          inputMode="numeric"
          labelText={`Vitesse maximale pour avaries ${hasDamages ? '*' : ''}`}
          name="damages.speedLimit"
          normalize={normalizePositiveNumber}
          normalizeOnSave={parseToInt}
          pattern="[0-9]*"
          placeholder={`${hasDamages ? 'Saisir' : ' '}`}
          saveAction={requestUpdatePreAdviceWagon}
          unit="km/h"
        />
      </div>
    </>
  );
};

export default PreAdviceWagonDamages;
