import AbstractTemplateCache from './AbstractTemplateCache';
import { HazardousMaterial, PACKING_GROUPS, PackingGroup } from '../model/templates';
import helpers from '../helpers/helpers';
import { Tx } from './AbstractDbCache';

type HazmatInput = {
  dangerIdentificationNumber: string | null;
  unitedNationsCode: string | null;
  packingGroup: PackingGroup | null;
};

class HazmatCache extends AbstractTemplateCache<'hazmat', HazardousMaterial, HazmatInput> {
  protected getStoreName(): 'hazmat' {
    return 'hazmat';
  }

  protected validateInput(inputValue: HazmatInput): boolean {
    return Boolean(inputValue.dangerIdentificationNumber || inputValue.unitedNationsCode || inputValue.packingGroup);
  }

  protected normalizeInput(inputValue: HazmatInput) {
    return inputValue;
  }

  protected async findTemplates(inputValue: HazmatInput, tx: Tx<'hazmat'>): Promise<HazardousMaterial[]> {
    const matches = (
      await Promise.all([
        inputValue.dangerIdentificationNumber
          ? this.findItemsByIndexPrefix(
              inputValue.dangerIdentificationNumber,
              tx,
              'by-dangerIdentificationNumber',
              9999,
            )
          : null,
        inputValue.unitedNationsCode
          ? this.findItemsByIndexPrefix(inputValue.unitedNationsCode, tx, 'by-unitedNationsCode', 9999)
          : null,
        inputValue.packingGroup ? tx.store.index('by-packingGroup').getAll(inputValue.packingGroup) : null,
      ])
    )
      .filter((match) => match)
      .map((match) => match as HazardousMaterial[]);
    return helpers.arrayIntersection('id', ...matches).slice(0, this.getMaxResults());
  }

  public formatItem(item: HazardousMaterial) {
    let str = '';
    if (item.dangerIdentificationNumber) {
      str += item.dangerIdentificationNumber;
    }
    if (item.unitedNationsCode) {
      str += ` - ${item.unitedNationsCode}`;
    }
    if (item.packingGroup) {
      str += ` - ${PACKING_GROUPS[item.packingGroup]}`;
    }
    return str + `: ${item.label}`;
  }
}

export default new HazmatCache();
