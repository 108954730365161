import Overlay from '../../../commons/components/overlay/Overlay';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContextRoles, selectTrainStep } from '../../../commons/selectors/selectors';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import React from 'react';
import FormSection from '../../../commons/components/form/FormSection';
import { EMPTY_TRACEABILITY, Traceability } from '../../../commons/model/Traceability';
import { COMPOSITION_WRITE } from '../../../commons/security/userRoles';
import classNames from 'classnames';
import TraceabilityUtils from '../../../commons/model/TraceabilityUtils';
import VehiclesUtils from '../../../commons/model/VehiclesUtils';
import {
  invalidateAteBrakingBulletin,
  invalidateRearSignaling,
  invalidateVisit,
  signAteBrakingBulletin,
  signRearSignaling,
  signVisit,
} from './TraceabilityActions';
import TraceabilitySubsection from './TraceabilitySubsection';
import BrakeTestSection from './BrakeTestSection';
import FirstLastSection from './FirstLastSection';
import AteAgreementSubsection from './AteAgreementSubsection';
import AteTransmissionSubsection from './AteTransmissionSubsection';

const TraceabilityOverlay = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectTrainStep).data;
  const hasWagons = Boolean(step && VehiclesUtils.getNbWagon(step.vehicles));
  const hasVehicles = Boolean(step && VehiclesUtils.getNbWagonsOrVehicleEMs(step.vehicles));
  const hasATE = Boolean(step && VehiclesUtils.hasATE(step.vehicles));
  const traceability: Traceability = step?.traceability ?? EMPTY_TRACEABILITY;
  const userRoles = useAppSelector(selectSecurityContextRoles);
  const editable = userRoles.includes(COMPOSITION_WRITE);

  const handleClose = () => {
    dispatch(hideOverlay());
  };

  return (
    <Overlay name="traceability" title="Traçabilité" closeButton>
      <div className="overlay-form">
        <div className="traceability-form">
          <FormSection
            title="RAT"
            className={classNames({ signed: TraceabilityUtils.hasSignedRAT(traceability) || !hasWagons })}
          >
            {hasWagons ? (
              <TraceabilitySubsection
                signatures={traceability.ratSignature ? [traceability.ratSignature] : []}
                editable={false}
                e={true}
              />
            ) : (
              <div className="empty">Ce train n'a pas de wagons</div>
            )}
          </FormSection>
          <BrakeTestSection traceability={traceability} editable={editable} />
          <FormSection
            title="Signalisation d'arrière"
            className={classNames({ signed: TraceabilityUtils.hasSignedRearSignaling(traceability) })}
          >
            <TraceabilitySubsection
              signatures={traceability.rearSignalingSignatures}
              editable={editable}
              e={true}
              onSign={() => dispatch(signRearSignaling())}
              onInvalidate={(userId: number | null) => dispatch(invalidateRearSignaling(userId))}
            />
          </FormSection>
          <FirstLastSection traceability={traceability} editable={editable} hasVehicles={hasVehicles} />
          <FormSection
            title="Transport Exceptionnel"
            className={classNames({
              partial: TraceabilityUtils.hasPartiallySignedATE(traceability),
              signed: !hasATE || TraceabilityUtils.hasSignedATE(traceability),
            })}
          >
            {hasATE ? (
              <>
                <div className="subsection">
                  <div className="subsection-title">Accord d'incorporation</div>
                  <AteAgreementSubsection signatures={traceability.ateAgreementSignatures} editable={editable} />
                </div>
                <div className="subsection">
                  <div className="subsection-title">Annotation cadre observation du BF</div>
                  <TraceabilitySubsection
                    signatures={traceability.ateBrakingBulletinSignatures}
                    editable={editable}
                    e={true}
                    onSign={() => dispatch(signAteBrakingBulletin())}
                    onInvalidate={(userId: number | null) => dispatch(invalidateAteBrakingBulletin(userId))}
                  />
                </div>
                <div className="subsection">
                  <div className="subsection-title">Transmission des restrictions de circulation au conducteur</div>
                  <AteTransmissionSubsection signatures={traceability.ateTransmissionSignatures} editable={editable} />
                </div>
              </>
            ) : (
              <div className="empty">Ce train n'a pas d'ATE</div>
            )}
          </FormSection>
          <FormSection
            title="Visite Technique d'Échange (facultatif)"
            className={classNames({ signed: TraceabilityUtils.hasSignedVisit(traceability) })}
          >
            <TraceabilitySubsection
              signatures={traceability.visitSignatures}
              editable={editable}
              e={true}
              onSign={() => dispatch(signVisit())}
              onInvalidate={(userId: number | null) => dispatch(invalidateVisit(userId))}
            />
          </FormSection>
        </div>
        <div className="btn-group">
          <button className="btn btn-accent" onClick={handleClose} type="button">
            Fermer
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default TraceabilityOverlay;
