import React from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmModalFormValues, openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import {
  requestAddWagons,
  requestDeletePreAdvice,
  requestInvalidatePreAdvice,
  requestInversePreAdvice,
  requestResetPreAdvice,
} from '../preAdviceDucks';
import { showOverlay } from '../../../commons/components/overlay/overlayDucks';
import classNames from 'classnames';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';

type PreAdviceActionsProps = {
  preAdvice: PreAdviceEntity;
};

const PreAdviceActions = ({ preAdvice }: PreAdviceActionsProps) => {
  const dispatch = useDispatch();
  if (!preAdvice.id) {
    return null;
  }
  const actions = [
    {
      key: 'export-pdf',
      icon: 'pdf',
      text: 'Exporter au format PDF',
      href: `/api/pre-advices/${preAdvice.id}.pdf`,
      matches: preAdvice.isValidated(),
    },
    {
      key: 'export-csv',
      icon: 'csv',
      text: 'Exporter au format CSV',
      href: `/api/pre-advices/${preAdvice.id}.csv`,
      matches: preAdvice.isValidated(),
    },
    {
      key: 'invalidate',
      icon: 'unlock',
      text: 'Invalider',
      onClick: () => dispatch(requestInvalidatePreAdvice(preAdvice)),
      matches: preAdvice.isValidated() && !preAdvice.isReceivedByVfli(),
    },
    {
      key: 'import',
      icon: 'template',
      text: 'Importer des wagons',
      onClick: () => dispatch(showOverlay('copy-wagons')),
      matches: !preAdvice.isValidated(),
    },
    {
      key: 'add_wagons',
      icon: 'wagon-plus',
      text: 'Ajouter des wagons',
      onClick: () =>
        dispatch(
          openConfirmDialog({
            title: 'Combien de wagons voulez-vous ajouter ?',
            actionText: 'Ajouter les wagons',
            action: ({ nbWagons }: ConfirmModalFormValues<{ nbWagons: 'number' }>) =>
              requestAddWagons(Number.parseInt(nbWagons!, 10)),
            fields: {
              nbWagons: {
                type: 'number',
                label: 'Nb wagons',
              },
            },
          }),
        ),
      matches: !preAdvice.isValidated(),
    },
    {
      key: 'inverse_wagons',
      icon: 'inverse',
      text: 'Inverser les wagons',
      onClick: () =>
        dispatch(
          openConfirmDialog({
            title: "Souhaitez-vous inverser l'ordre des wagons ?",
            actionText: 'Inverser',
            action: () => requestInversePreAdvice(preAdvice),
          }),
        ),
      matches: !preAdvice.isValidated(),
    },
    {
      key: 'reset',
      icon: 'reset',
      text: 'Réinitialiser la pré-annonce',
      onClick: () =>
        dispatch(
          openConfirmDialog({
            title: 'Souhaitez-vous réinitialiser les wagons de la pré-annonce ?',
            actionText: 'Réinitialiser',
            action: () => requestResetPreAdvice(preAdvice),
            actionClass: 'danger',
          }),
        ),
    },
    {
      key: 'delete_preadvice',
      icon: 'remove danger',
      text: 'Supprimer la pré-annonce',
      onClick: () =>
        dispatch(
          openConfirmDialog({
            title: 'Voulez-vous vraiment supprimer cette pré-annonce ?',
            actionText: 'Supprimer la pré-annonce',
            action: requestDeletePreAdvice(),
            actionClass: 'danger',
          }),
        ),
      matches: !preAdvice.isValidated(),
    },
  ].filter((action) => action.matches);

  return (
    <div className="btn-group">
      {actions.map((action) =>
        action.href ? (
          <a
            key={action.key}
            className={classNames('btn btn-round', action.icon)}
            href={action.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {action.text}
          </a>
        ) : (
          <button
            key={action.key}
            className={classNames('btn btn-round', action.icon)}
            onClick={action.onClick}
            type="button"
          >
            {action.text}
          </button>
        ),
      )}
    </div>
  );
};

export default PreAdviceActions;
