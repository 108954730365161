import React from 'react';
import classNames from 'classnames';
import { shouldTryToValidateWagons } from './copyWagonsUtils';
import helpers from '../../helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmModalConfig, openConfirmDialog } from '../modal/confirmModalDucks';
import { COMPOSITION_VALIDATE_WAGONS_ON_COPY, RoleId } from '../../security/userRoles';
import {
  ImportSourceCandidate,
  ImportSourceType,
  isPreAdviceImportSourceCandidate,
  isTrainImportSourceCandidate,
  PRE_ADVICE_TYPE,
  TRAIN_TYPE,
} from '../../model/importSource';
import { AppAction } from '../../store/store';
import { selectSecurityContext } from '../../selectors/selectors';
import { CAPTRAIN_FRANCE_ID } from '../../model/templates';

type CopyWagonsSearchResultProps = {
  copyWagonsAction: (candidate: ImportSourceCandidate) => AppAction;
  importSourceCandidate: ImportSourceCandidate;
  targetType: ImportSourceType;
  targetMarketId?: number;
  getRailwayCompanyName: (id: string) => string;
};

const getConfirmActionText = (
  securityContext: { roles: RoleId[] },
  sourceType: ImportSourceType,
  targetType: ImportSourceType,
) => {
  if (shouldTryToValidateWagons(securityContext, sourceType, targetType)) {
    return 'Oui, copier et valider les wagons';
  }
  return 'Oui, copier';
};

/**
 * Return a boolean indicating whether the "copy load" checkbox
 * should be displayed.
 *
 * If the target is a pre-advice, the checkbox should be displayed.
 * Otherwise, the checkbox is displayed if the current user does not have
 * the "validate wagon on copy" role.
 */
export const displayCopyLoadCheckbox = (securityContext: { roles: RoleId[] }, targetType: ImportSourceType) => {
  return !securityContext.roles.includes(COMPOSITION_VALIDATE_WAGONS_ON_COPY) || targetType === PRE_ADVICE_TYPE;
};

export const copyLoadDefaultValue = (
  securityContext: { roles: RoleId[] },
  sourceType: ImportSourceType,
  targetType: ImportSourceType,
) => {
  //cas des conducteurs
  if (securityContext.roles.includes(COMPOSITION_VALIDATE_WAGONS_ON_COPY)) {
    return true;
  }
  return sourceType === PRE_ADVICE_TYPE || targetType === PRE_ADVICE_TYPE;
};

const fullCopyLabel = (securityContext: { roles: RoleId[] }) => {
  if (securityContext.roles.includes('BRAKING_BULLETIN')) {
    return 'Copier la composition validée,\nle bulletin de freinage,\net la traçabilité';
  }
  return 'Copier la composition validée';
};

const CopyWagonsSearchResult = ({
  copyWagonsAction,
  importSourceCandidate,
  targetType,
  targetMarketId,
  getRailwayCompanyName,
}: CopyWagonsSearchResultProps) => {
  const dispatch = useDispatch();
  const securityContext = useSelector(selectSecurityContext);
  const { status, usedInCompositionStatus, trainNumber, journey, type } = importSourceCandidate;
  const isTrain = isTrainImportSourceCandidate(importSourceCandidate);
  const isPreAdvice = isPreAdviceImportSourceCandidate(importSourceCandidate);
  const computeClasses = {
    'not-validated': status === 'NOT_VALIDATED',
    validated: status === 'VALIDATED',
    'received-pre-advice': isPreAdvice && importSourceCandidate.preAdvice.receiverId === CAPTRAIN_FRANCE_ID,
    'received-pre-advice-used': usedInCompositionStatus === 'USED',
    train: isTrain,
    'pre-advice': isPreAdvice,
  };
  const showConfirmationDialog = () => {
    const fields: Partial<ConfirmModalConfig<{ copyWagonsLoad: 'checkbox'; fullCopy: 'checkbox' }>['fields']> = {};
    if (displayCopyLoadCheckbox(securityContext, targetType)) {
      fields.copyWagonsLoad = {
        type: 'checkbox',
        defaultValue: copyLoadDefaultValue(securityContext, type, targetType),
        label: 'Copier le chargement',
      };
    }
    if (
      securityContext.roles.includes(COMPOSITION_VALIDATE_WAGONS_ON_COPY) &&
      targetType === TRAIN_TYPE &&
      status === 'VALIDATED' &&
      isTrain &&
      importSourceCandidate.trainStep.marketId === targetMarketId
    ) {
      fields.fullCopy = {
        type: 'checkbox',
        label: fullCopyLabel(securityContext),
      };
    }
    dispatch(
      openConfirmDialog({
        title: 'Confirmez-vous la copie des wagons ?',
        actionText: getConfirmActionText(securityContext, type, targetType),
        action: copyWagonsAction(importSourceCandidate),
        fields,
      }),
    );
  };

  return (
    <div className={classNames('table-body-row', computeClasses)} onClick={showConfirmationDialog}>
      <div className="cell-type">N°{trainNumber}</div>
      <div className="cell-date">{helpers.dateTimeFormat(journey.startDateTime)}</div>
      {isTrain ? (
        <div className="cell-contract">{importSourceCandidate.trainStep.marketName}</div>
      ) : (
        <div className="cell-sender-receiver">
          <span className="cell-sender">{getRailwayCompanyName(importSourceCandidate.preAdvice.senderId)}</span>
          <span className="cell-receiver">{getRailwayCompanyName(importSourceCandidate.preAdvice.receiverId)}</span>
        </div>
      )}
      <div className="cell-route">
        <div className="cell-route-from">{journey.startStationName ?? 'Gare inconnue'}</div>
        <div className="cell-route-to">{journey.endStationName ?? 'Gare inconnue'}</div>
      </div>
    </div>
  );
};

export default CopyWagonsSearchResult;
