import offlineUtils from '../../commons/offline/offlineUtils';
import { AppDispatch } from '../../commons/store/store';
import { AnyAction } from 'redux';
import { Profile } from '../../commons/model/userProfile';
import apiHelper from '../../api/apiHelper';

// ACTIONS
const START_GET_PROFILES = 'START_GET_PROFILES';
const SUCCESS_GET_PROFILES = 'SUCCESS_GET_PROFILES';
const FAILURE_GET_PROFILES = 'FAILURE_GET_PROFILES';

// REDUCER
const initialState: ProfilesState = {
  data: [],
  loading: false,
  error: false,
};

export type ProfilesState = {
  data: Profile[];
  loading: boolean;
  error: boolean;
};

export default (state: ProfilesState = initialState, action: AnyAction): ProfilesState => {
  switch (action.type) {
    case START_GET_PROFILES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SUCCESS_GET_PROFILES:
      return {
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILURE_GET_PROFILES:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

// ACTIONS
export const failureGetProfiles = () => ({
  type: FAILURE_GET_PROFILES,
});

export const successGetProfiles = (profiles: Profile[]) => ({
  type: SUCCESS_GET_PROFILES,
  payload: profiles,
});

export const startGetProfiles = () => async (dispatch: AppDispatch) => {
  dispatch({ type: START_GET_PROFILES });

  if (offlineUtils.isOnline()) {
    try {
      const profiles: Profile[] = await apiHelper.get('/api/profiles');
      dispatch(successGetProfiles(profiles));
    } catch {
      dispatch(failureGetProfiles());
    }
  }
};
