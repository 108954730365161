import React, { ReactNode } from 'react';
import './listStyles.scss';

type ListItem = {
  key: string;
  value: ReactNode | ReactNode[];
};

type ListProps = {
  items: ListItem[];
};

const List = ({ items }: ListProps) => (
  <ul className="list">
    {items.map((listItem, listItemIndex) => (
      <li key={`item-${listItemIndex}`}>
        <span className="list-key">{listItem.key}</span>
        <span className="list-value">
          {Array.isArray(listItem.value)
            ? listItem.value.map((arrayItem, arrayIndex) => (
                <span className="list-value-item" key={`value-${arrayIndex}`}>
                  {arrayItem}
                </span>
              ))
            : listItem.value}
        </span>
      </li>
    ))}
  </ul>
);

export default List;
