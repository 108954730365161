import { useAppDispatch } from '../../../commons/store/hooks';
import { startNewMailingList } from '../mailingListDucks';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import classNames from 'classnames';

const MailingListAddButton = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useViewportWidth();

  return (
    <button
      className={classNames('btn btn-success', isMobile && 'btn-round plus')}
      onClick={() => dispatch(startNewMailingList())}
      type="button"
    >
      Ajouter une liste de diffusion
    </button>
  );
};
export default MailingListAddButton;
