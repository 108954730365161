import PropTypes from 'prop-types';
import React from 'react';

const GlobalError = ({ title, children }) => (
  <div>
    <div className="topbar">
      <h1 className="title">{title}</h1>
    </div>
    <div className="maincontent">{children}</div>
  </div>
);

GlobalError.propTypes = {
  title: PropTypes.string.isRequired,
};

export default GlobalError;
