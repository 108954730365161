/**
 * Trains table component
 */
import React, { useEffect, useMemo } from 'react';
import GlobalError from '../../commons/components/GlobalError';
import { loadTrainSummaries } from './trainSummariesDucks';
import { loadTrains } from '../offline/offlineTrainsDucks';
import './trainsTableStyles.scss';
import ScrollableTableBody from '../../commons/components/table/ScrollableTableBody';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import TrainUtils from '../../commons/model/TrainUtils';
import moment from 'moment';
import { selectSecurityContextRoles, selectTrainSummaries } from '../../commons/selectors/selectors';
import { BRAKING_BULLETIN } from '../../commons/security/userRoles';
import TrainItem from './TrainItem';

const TrainsTable = () => {
  const { data: trains, error, loading } = useAppSelector(selectTrainSummaries);
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectSecurityContextRoles);
  const canSeeBB = roles.includes(BRAKING_BULLETIN);

  useEffect(() => {
    dispatch(loadTrainSummaries());
    dispatch(loadTrains());
  }, [dispatch]);

  const data = useMemo(() => {
    const now = moment().format();
    const trainsWithActiveStep = trains.map((train) => ({
      ...train,
      sortStepIndex: TrainUtils.findSortStepIndex(train, now),
    }));
    trainsWithActiveStep.sort((train1, train2) =>
      moment(train1.steps[train1.sortStepIndex].startDate).diff(moment(train2.steps[train2.sortStepIndex].startDate)),
    );
    return trainsWithActiveStep;
  }, [trains]);

  if (error && trains.length === 0) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des prochains trains.
      </GlobalError>
    );
  }

  if (!loading && (!trains || trains.length === 0)) {
    return <div className="maincontent">Aucun train ne correspond à la recherche.</div>;
  }

  return (
    <div className="table table-trains">
      <ScrollableTableBody>
        {data.map((train) => () => <TrainItem key={train.id} train={train} canSeeBB={canSeeBB} />) /*NOSONAR*/}
      </ScrollableTableBody>
    </div>
  );
};

export default TrainsTable;
