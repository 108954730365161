export const ICMessageStatuses = {
  CREATING: 'CREATING',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR_NO_ENGINE: 'ERROR_NO_ENGINE',
  ERROR_NO_SILLON: 'ERROR_NO_SILLON',
  ERROR_PREV_STEP: 'ERROR_PREV_STEP',
  ERROR_TRAIN_NB: 'ERROR_TRAIN_NB',
  ERROR_CREATION: 'ERROR_CREATION',
  ERROR_SEND: 'ERROR_SEND',
  SUCCESS_IC: 'SUCCESS_IC',
  ERROR_SNCF_RESEAU: 'ERROR_SNCF_RESEAU',
};

export const statusOptions = [
  {
    id: ICMessageStatuses.SUCCESS_IC,
    label: 'En succès',
  },
  {
    id: ICMessageStatuses.SUCCESS,
    label: 'En attente de réponse',
  },
  {
    id: ICMessageStatuses.ERROR_NO_ENGINE,
    label: 'Composition sans EM',
  },
  {
    id: ICMessageStatuses.ERROR_TRAIN_NB,
    label: 'Numéro de train invalide',
  },
  {
    id: ICMessageStatuses.ERROR_PREV_STEP,
    label: 'Étape précédente non saisie',
  },
  {
    id: ICMessageStatuses.ERROR_NO_SILLON,
    label: 'Étape sans sillon',
  },
  {
    id: ICMessageStatuses.ERROR_CREATION,
    label: 'Erreur lors de la création du message',
  },
  {
    id: ICMessageStatuses.ERROR_SEND,
    label: 'Erreur lors de l’envoi',
  },
  {
    id: ICMessageStatuses.ERROR_SNCF_RESEAU,
    label: 'Erreur lors du traitement par SNCF Réseau',
  },
];
