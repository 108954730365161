import FormSection from '../../../commons/components/form/FormSection';
import { Field } from 'redux-form';
import Input from '../../../commons/components/input/Input';
import React from 'react';
import { BrakingBulletinCompositionSummary } from '../../../commons/model/BrakingBulletin';
import DisabledInput from '../../../commons/components/input/DisabledInput';
import { formatDecimalAndSeparateThousands } from '../../../commons/redux-form/valueFormatter';
import { normalizePositiveFloatTwoDecimal } from '../../../commons/redux-form/valueNormalizer';
import helpers from '../../../commons/helpers/helpers';
import BrakingBulletinUtils from '../../../commons/model/BrakingBulletinUtils';

type EffectiveBrakingSectionProps = {
  compositionSummary: BrakingBulletinCompositionSummary;
  readOnly: boolean;
};

const EffectiveBrakingSection = ({ compositionSummary, readOnly }: EffectiveBrakingSectionProps) => {
  return (
    <FormSection title="Masse freinée réalisée" className="braking-bulletin-effective">
      <DisabledInput
        value={helpers.integerFormat(BrakingBulletinUtils.computeTotalEffectiveBrakedWeight(compositionSummary))}
        labelText="Total"
        unit="t"
        className="right"
      />
      <DisabledInput
        value={formatDecimalAndSeparateThousands(compositionSummary.vehiclesBrakedWeight)}
        labelText="Wagons"
        unit="t"
        className="right"
      />
      <DisabledInput
        value={formatDecimalAndSeparateThousands(compositionSummary.tractionEnginesBrakedWeight)}
        labelText="Machines de remorque"
        unit="t"
        className="right"
      />
      <DisabledInput
        value={formatDecimalAndSeparateThousands(compositionSummary.vehicleEnginesBrakedWeight)}
        labelText="Machines en véhicule"
        unit="t"
        className="right"
      />
      <DisabledInput
        value={formatDecimalAndSeparateThousands(compositionSummary.pushEnginesBrakedWeight)}
        labelText="Machines de pousse"
        unit="t"
        className="right"
      />
      <Field
        component={Input}
        name="effectiveBrakedWeightDetails.driftSecondHalf"
        labelText="Freinage de la 2e moitié du train"
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
          className: 'right',
        }}
        unit="t"
        format={formatDecimalAndSeparateThousands}
        normalize={normalizePositiveFloatTwoDecimal}
        disabled={readOnly}
      />
    </FormSection>
  );
};

export default EffectiveBrakingSection;
