import React from 'react';

import warningImg from '../../../../commons/assets/images/warning.svg';
import './brakingBulletinValidationDialogStyles.scss';

function BrakingBulletinValidationDialogTitle() {
  return (
    <div className="braking-bulletin-validation-dialog">
      <img width={100} src={warningImg} alt="Avertissement" />
      <br />
      Vous êtes sur le point de valider le bulletin de freinage.
      <br />
      <strong>Avez-vous vérifié toutes les règles de composition et de freinage ?</strong>
    </div>
  );
}

export default BrakingBulletinValidationDialogTitle;
