import React from 'react';
import PropTypes from 'prop-types';
import PreAdviceWagonImportErrorEntity from '../../../commons/entity/PreAdviceWagonImportErrorEntity';

const ciCodeLabel = ({ code }) => (code ? code : 'inconnu');
const nameLabel = ({ name }) => (name ? name : 'nom inconnu');

const PreAdviceWagonUnknownStationErrors = ({ errors }) => {
  const unknownStartStation = errors.find((error) => error.stationType === 'START');
  const unknownEndStation = errors.find((error) => error.stationType === 'END');

  return (
    <div className="pre-advice-wagon-import-errors pre-advice-wagon-info">
      {unknownStartStation && (
        <div>
          L'origine du wagon est une gare inconnue (début du code CI: {ciCodeLabel(unknownStartStation)}, nom:{' '}
          {nameLabel(unknownStartStation)})
        </div>
      )}
      {unknownEndStation && (
        <div>
          La destination du wagon est une gare inconnue (début du code CI: {ciCodeLabel(unknownEndStation)}, nom:{' '}
          {nameLabel(unknownEndStation)})
        </div>
      )}
    </div>
  );
};

PreAdviceWagonUnknownStationErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.instanceOf(PreAdviceWagonImportErrorEntity)).isRequired,
};

export default PreAdviceWagonUnknownStationErrors;
