/**
 * Contains configuration variables
 * @module config
 */
export default {
  baseAnimationTime: 325, // Base duration of animation

  contentMinWidth: 744, // Minimum width of page container. The horizontal scroll appear, if the width is less
  sidebarMaxWidth: 250, // Width of unfolded sidebar
  sidebarMinWidth: 72, // Width of folded sidebar
  topbarHeight: 72, // Height of toolbar container above the table

  toastDelay: 5000, // Delay time of toast

  isTouchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
};
