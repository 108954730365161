import { DateString } from './common';
import { PreAdviceStatus, UsedInCompositionStatus } from './PreAdvice';

export type ImportSourceType = 'TRAIN' | 'PRE_ADVICE';
export const TRAIN_TYPE: ImportSourceType = 'TRAIN';
export const PRE_ADVICE_TYPE: ImportSourceType = 'PRE_ADVICE';

export type ImportSourceJourney = Readonly<{
  startDateTime: DateString;
  startStationId: string;
  startStationName: string;
  endStationId: string;
  endStationName: string;
}>;

export type ImportSourceTrainStep = Readonly<{
  marketId: number;
  marketName: string;
}>;

export type ImportSourcePreAdvice = Readonly<{
  senderId: string;
  receiverId: string;
}>;

type ImportSourceCandidateBase = Readonly<{
  id: string;
  type: ImportSourceType;
  trainNumber: string;
  status: PreAdviceStatus;
  usedInCompositionStatus?: UsedInCompositionStatus;
  journey: ImportSourceJourney;
}>;

type TrainImportSourceCandidate = ImportSourceCandidateBase &
  Readonly<{
    type: 'TRAIN';
    trainStep: ImportSourceTrainStep;
  }>;

type PreAdviceImportSourceCandidate = ImportSourceCandidateBase &
  Readonly<{
    type: 'PRE_ADVICE';
    preAdvice: ImportSourcePreAdvice;
  }>;

export type ImportSourceCandidate = TrainImportSourceCandidate | PreAdviceImportSourceCandidate;

export const isTrainImportSourceCandidate = (
  candidate: ImportSourceCandidate,
): candidate is TrainImportSourceCandidate => candidate.type === 'TRAIN';

export const isPreAdviceImportSourceCandidate = (
  candidate: ImportSourceCandidate,
): candidate is PreAdviceImportSourceCandidate => candidate.type === 'PRE_ADVICE';
